import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent, IModelService, ModelServiceFactory, ValuesService } from '../../../core';

@Component({
    templateUrl: "/template/lists/accessories/pages/accessories-list.cshtml"
})
export class ListsAccessoriesPageComponent {
	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * Instance model service
	 */
	public modelService: IModelService;

	constructor(
		private _router: Router,
        private _uiService: UIService,
        private _valuesService: ValuesService,
		private _modelServiceFactory: ModelServiceFactory) {
        this.modelService = _modelServiceFactory.createService('listAccessories');
	}

	/**
	 * Redirekt na stránku pro přidání.
	 */
    add() {
        this._router.navigateByUrl('/app/list/accessories/0');
	}

	/**
	 * Smazání
	 * @param item
	 */
    delete(item: IAccessoriesListModel) {
		this._uiService
            .showConfirmation('Odstranění záznamu', `Opravdu si přejete odstranit záznam ${item.name}?`)
            .then(result => {
                if (result) {
                    this._uiService.showLoader();

                    this.modelService.delete(item.id, null).then(
                        result => {
                            this._uiService.hideLoader();
                            this._grid.loadData();
                            this._valuesService.clearCacheItem("Accessories");
                        });
                }
			});
    }

	/**
	 * Redirekt na stránku s editací
	 * @param item
	 */
    edit(item: IAccessoriesListModel) {
        this._router.navigateByUrl(`/app/list/accessories/${item.id}`);
	}
}