import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../core';
import { SvtModule } from './';

// Pages
import { PhilipsPageComponent } from './pages/philips';
import { SamsungPageComponent } from './pages/samsung';


const routes: Routes = [
    { path: 'philips', component: PhilipsPageComponent },
    { path: 'samsung', component: SamsungPageComponent }
];

@NgModule({
    imports: [
        SvtModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SvtRoutingModule { }