import { Input, Component, ViewChild, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UIService, GridComponent, IModelService, ModelServiceFactory, AuthService } from '../../../core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 * Funkcionalita autorizací v detailu zakázky
 */

@Component({
	selector: 'div.kt-portal-commission-orders',
	templateUrl: "/template/portal/commissions/components/portal-commission-orders.cshtml"
})
export class PortalCommissionOrdersComponent implements OnInit, OnDestroy {
	private _subs: Array<Subscription> = [];

	/**
	 * Reference na grid komponentu
	 */
	@ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * Vstup id zakázky pro kterou mají být zobrazeny objednávky
	 */
	@Input() number: string;

	/**
	 * Vstup pro id garanta
	 */
	@Input() garantId: number;

	/**
	 * Vstup pro id servisu
	 */
	@Input() branchId: number;

	// Událost spuštěna po načtení dat
	@Output() onDataLoaded: EventEmitter<any[]> = new EventEmitter();

	/**
	 * Instance model service
	 */
	public modelService: IModelService;
	public authService: AuthService;

	constructor(
		protected _router: Router,
		protected _activatedRoute: ActivatedRoute,
		private _uiService: UIService,
		private _modelServiceFactory: ModelServiceFactory,
		protected _authService: AuthService) {

		this.modelService = _modelServiceFactory.createService('portalOrder');
		this.authService = _authService;
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		// Nastavíme commission id aby bylo posíláno na API jako filtr pro autorizace
		this._grid.customFilter = {
			number: this.number,
			branchId: this.branchId
		};

		// Forwardujeme událost o načtení dat z gridu výše
		this._subs.push(
			this._grid.onDataLoaded.subscribe(x => {
				this.onDataLoaded.next(x);
			})
		);
	}

	/**
	 * Destrukce komponenty
	 */
	ngOnDestroy() {
		// Odhlásíme ze všech observables
		this._subs.forEach(x => x.unsubscribe());
	}

	/**
	 * Navigate to order in new tab.
	 * @param item
	 */
	public openDetail(item: any) {
		let url = this._router.createUrlTree(['app', 'portal', 'orders', this.garantId, item.id], {}).toString();
		url = window.location.origin + "#" + url;
		console.log('item', item);
		console.log('url', url);
		window.open(url, "_blank");
	}
}