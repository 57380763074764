import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import {
	UIService,
	FormComponentBase,
	HelperService,
	AppScopeService,
	AuthService,
	IModelService,
	ModelServiceFactory	
} from '../../core';

@Component({
	templateUrl: "/template/contacts/modals/place-of-business.cshtml"
})
export class PlaceOfBusinessModalComponent extends FormComponentBase implements OnInit {
    private _contactId: number;
    private _contactParentId: number;
	private _modelService: IModelService = null;

    public isNew: boolean;
    public contactId: number; 

    constructor(
        public modal: SuiModal<IPlaceOfBusinessModalComponentContext, void>,
        protected _uiService: UIService,
        private _modelServiceFactory: ModelServiceFactory,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		protected _appScopeService: AppScopeService) {

		super(_uiService, _helperService, _authService, _appScopeService);

        this._contactId = modal.context.contactId;
        this._contactParentId = modal.context.parentContactId;
		this._modelService = _modelServiceFactory.createService('contact');
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this._uiService.showLoader();

        // Načteme model ze serveru
        this._modelService.get(this._contactId, {})
            .then(model => {
                this._uiService.hideLoader();
				super.setModel(0, model);
			});
        
        this.isNew = this._contactId == 0;
        this.contactId = this._contactId;
	}

	/**
	 * Ukládáme, vracíme a zavíráme
	 */
    save() {

        // U nove pobocky je potreba podvrhnout parenta. Jinak by se zalozila jako firma.
        if (this._contactId == 0) {
            this.model.parentId = this._contactParentId;
        }

        this._modelService.set(this._contactId, this.model, {})
			.then(model => {
				this.modal.approve(model);
			});
	}

	/**
	 * Zavře modal => cancel
	 */
	back() {
		this.modal.deny(null);
	}
}

export class PlaceOfBusinessModal extends ComponentModalConfig<IPlaceOfBusinessModalComponentContext, void, void> {
    constructor(contactId: number, parentContactId: number) {
        super(PlaceOfBusinessModalComponent, { contactId, parentContactId });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IPlaceOfBusinessModalComponentContext {
    contactId: number;
    parentContactId: number;
}