import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
    IModelService,
    ModelServiceFactory,
    PrintingService,
    GridComponent,
    ModalService,
    UIService,
    DataTableComponent
} from '../../core';
import { CommissionIsEditedModal, ICommissionIsEditedModalResult } from '../modals/commission-is-edited'
import { CommissionsService } from '../services/commissions';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { MessageModalType } from '../../core/modals/message';

@Component({
    templateUrl: "/template/commissions/pages/commission-list.cshtml"
})
export class CommissionListPageComponent implements OnInit {
    private _hotkeys: Array<Hotkey> = [];

    /**
     * Reference na grid komponentu
     */
    @ViewChild(DataTableComponent, { static: true }) private _grid: DataTableComponent;

    /**
     * Instance model service
     */
    public modelService: IModelService;

    constructor(
        private _router: Router,
        private _modelServiceFactory: ModelServiceFactory,
        private _printingService: PrintingService,
        private _modalService: ModalService,
        private _commissionService: CommissionsService,
        private _hotkeysService: HotkeysService,
        private _uiService: UIService) {
        this.modelService = _modelServiceFactory.createService('commission');
    }

    /**
     * Inicializace komponenty
     */
    ngOnInit() {
        this._setCommissionKeyboardShortcuts();
    }

    /**
     * Destroy komponenty 
     **/
    ngOnDestroy() {
        this._hotkeys.forEach(x => this._hotkeysService.remove(x));
    }

    /**
     * Redirekt na stránku pro přidání.
     */
    public add(): void {
        this._router.navigateByUrl('/app/commissions/0');
    }

    /**
     * Redirekt na stránku s editací
     * @param item
     */
    public edit(item: any): void {
        this._router.navigateByUrl(`/app/commissions/${item.id}`);
    }

    /**
     * Spustí export do excelu 
     **/
    public exportToExcel(): void {
        this._grid.exportToExcel();
    }

    /**
     * Export dat pro Hisense 
     **/
    public exportHisense(): void {
        this._commissionService.exportToHisense(this._grid.checkedIds.items)
            .then(result => {
                this._uiService.showMessage('Export dat Hisense', result, MessageModalType.TextArea);
            });
    }

    /**
     * Vrací true/false jestli je zaškrtnutý nějaký řádek 
     **/
    public hasSelectedRows(): boolean {
        return this._grid.checkedIds.items.length > 0;
    }

    /**
     * Zobrazí pro stáhnutí seznam zakázek v PDF
     **/
    public openCommissionList(): void {

    }

    /**
     * Zobrazí pro stáhnutí
     **/
    public openCommissionReceipt(): void {
        if (!this.hasSelectedRows()) {
            return;
        }

        this._printingService.openNewWindow('commission-receipt', { 'cid': this._grid.checkedIds.items.join('|') });
    }

    /**
     * Zobrazí pro stáhnutí 
     * @param type
     */
    public openCommissionSheet(type: number): void {
        if (!this.hasSelectedRows()) {
            return;
        }

        this._printingService.openNewWindow('commission-sheet', { 'cid': this._grid.checkedIds.items.join('|'), 'type': type });
    }

    /**
     * Spustí tisk potvrzení přijetí
     **/
    public printCommissionReceipt(): void {
        if (!this.hasSelectedRows()) {
            return;
        }

        this._printingService.print('commission-receipt', { 'cid': this._grid.checkedIds.items.join('|') });
    }

    /**
     * Spustí tisk zakázkového listu
     * @param type
     */
    public printCommissionSheet(type: number): void {
        if (!this.hasSelectedRows()) {
            return;
        }

        this._printingService.print('commission-sheet', { 'cid': this._grid.checkedIds.items.join('|'), 'type': type });
    }

    /**
     * Spustí tisk seznamu zakázek
     **/
    public printCommissionList(): void {

    }

    /**
     * Nastaveni klavesovych zkratek pro detail zakazky 
     * */
    private _setCommissionKeyboardShortcuts(): void {
        let self = this;

        //automaticky definovane v nastaveni
        this._commissionService.getShortcut()
            .then(shortcuts => {
                let keyService = this._hotkeysService;
                let base = this;

                shortcuts.forEach(function (shortcut) {
                    if (shortcut.isPrint) {
                        let key = new Hotkey(shortcut.shortcut, (event: KeyboardEvent): boolean => {
                            if (shortcut.eventId === 30) {
                                base.printCommissionReceipt();
                            } else {
                                base.printCommissionSheet(shortcut.eventId);
                            }
                            return false; // Prevent bubbling
                        }, ['INPUT', 'TEXTAREA', 'SELECT']);


                        keyService.add(key);
                        self._hotkeys.push(key);
                    }
                })
            }, reason => {
                console.log('reason');
            }).catch((ex) => {
                console.log('catch', ex);
            });
    }
}