import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent } from '../../core';
import { LogService } from '../services/logs';

@Component({
	templateUrl: "/template/logs/pages/log-list.cshtml"
})
export class LogListPageComponent {
    /**
    * Reference na grid komponentu
    */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

    constructor(
        private _router: Router,
        private _uiService: UIService,
        public logServices: LogService) {
    }

    /**
     * Redirekt na stránku s editací.
     * @param log
     */
    edit(log: any) {
        this._router.navigateByUrl(`/app/logs/${log.id}`);
    }

    public stateFilter: any = { stateId: null };

}