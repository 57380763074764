import { Injectable, EventEmitter } from '@angular/core';
import { HelperService } from './helper';
import { AuthService } from './auth';
import { UIService } from './ui';
import * as signalR from '@aspnet/signalr';
import { AppSettings } from './app-settings';
import { ValuesService } from './values';
import { CommissionPartState } from '../../core';

/**
 * Posktyuje notifikace ze serveru
 */
@Injectable({
	providedIn: 'root'
})
export class AppNotificationsService {

	// Connection do SignalR
	private _hubConnection: signalR.HubConnection;

	// Události spouštěné ze serveru
	public onAppLogoutUsers: EventEmitter<IAppLogoutUsersNotification> = new EventEmitter();
	public onCommissionAllPartOrdered: EventEmitter<ICommissionAllPartOrderedNotification> = new EventEmitter();
	public onCommissionOpen: EventEmitter<ICommissionOpenAppNotification> = new EventEmitter();
	public onCommissionPaired: EventEmitter<ICommissionPairedAppNotification> = new EventEmitter();
    public onCommissionPartOrdered: EventEmitter<ICommissionPartOrderedAppNotification> = new EventEmitter();
	public onCommissionPaymentPaired: EventEmitter<ICommissionPaymentPairedAppNotification> = new EventEmitter();
	public onExportToPayerResultRetrieved: EventEmitter<IExportToPayerResultRetrieved> = new EventEmitter();
    public onValuePairsChanged: EventEmitter<IValuePairsChangedAppNotification> = new EventEmitter();

	constructor(
		private _helperService: HelperService,
		private _appSettings: AppSettings,
		private _authService: AuthService,
        private _uiService: UIService,
        private _valuesService: ValuesService) {

		// Build connection
		this._hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(this._helperService.createAbsoluteUrl(_appSettings.appNotifications.url))
			.configureLogging(_appSettings.appNotifications.logLevel)
			.build();

		// Re-connect pokud disconneted
		this._hubConnection
			.onclose(() => {
				let timerId = <any>setTimeout(() => this._startConnection(), _appSettings.appNotifications.reconnectAfterSeconds * 1000);
				this.startConnTimersId.push(timerId);
			});

		// Attach handlery
		this._hubConnection.on('AppLogoutUsersNotification', this.handleAppLogoutUsersNotification.bind(this));
		this._hubConnection.on('CommissionAllPartOrderedNotification', this.handleCommissionAllPartOrderedNotification.bind(this));
		this._hubConnection.on("CommissionOpenAppNotification", this.handleCommissionOpenAppNotification.bind(this));
		this._hubConnection.on('CommissionPairedAppNotification', this.handleCommissionPairedAppNotification.bind(this));
        this._hubConnection.on('CommissionPartOrderedAppNotification', this.handleCommissionPartOrderedAppNotification.bind(this));
		this._hubConnection.on('CommissionPaymentPairedAppNotification', this.handleCommissionPaymentPairedAppNotification.bind(this));
		this._hubConnection.on('ExportToPayerResultRetrieved', this.handleExportToPayerResultRetrieved.bind(this));
        this._hubConnection.on('ValuePairsChangedAppNotification', this.handleValuePairsChangedAppNotification.bind(this));

		this._startConnection();
	}

	private startConnTimersId: number[] = [];
	private lostConnIntervalId: number;

	/**
	 * Připojí SignalR 
	 */
    private _startConnection(): void {
		clearInterval(this.lostConnIntervalId);
        console.log("app-notif-startconnection");

		this._hubConnection
			.start()
			.then(() => {
				console.log('Connection started');
				this.startConnTimersId.forEach(x => clearTimeout(x));
			})
			.catch(err => {				
				console.log('Error while starting connection: ' + err);				
				let timerId = <any>setTimeout(() => this._startConnection(), this._appSettings.appNotifications.reconnectAfterSeconds * 1000);
				this.startConnTimersId.push(timerId);
			});

		this.lostConnIntervalId = <any>setInterval(() => {
			if (this._hubConnection.state === signalR.HubConnectionState.Disconnected) {
				this._uiService.showError("Nepodařilo se navázat spojení se serverem. Zkontrolujte prosím své připojení k internetu.", 'Chyba spojení se serverem');
			}
		}, 5000);
	}

	/**
	 * Zpracovává AppLogoutUsersAppNotification
	 * @param args
	 */
	private handleAppLogoutUsersNotification(args: any): void {
		let notification = <IAppLogoutUsersNotification>args;

		console.log("logout user notif", notification.userIds, this._authService.currentUser.id);

		if (notification.userIds.some(x => x === this._authService.currentUser.id)) {
			console.log("logout user notif yes");

			this._uiService.showMessage('Systemové upozornění', `Váš účet byl správcem pozměněn. Budete odhlášeni.`)
				.then(x => {
					this._authService.logOut();
				});
		}

		this.onAppLogoutUsers.emit(notification);
	}

	/**
	 * Zpracovává CommissionAllPartOrderedNotification
	 * @param args
	 */
	private handleCommissionAllPartOrderedNotification(args: any): void {
		let notification = <ICommissionAllPartOrderedNotification>args;

		if (notification.requestorUserId == this._authService.currentUser.id) {
			this._uiService.showInfo(`Zakázce ${notification.commissionNumber} byl na základě blokace objednaných dílu nastaven stav ${notification.stateName}.`);
		}

		this.onCommissionAllPartOrdered.emit(notification);
	}

    /**
     * Zpracovává CommissionOpenAppNotification
     * @param args
     */
	private handleCommissionOpenAppNotification(args: any): void {
		let notification = <ICommissionOpenAppNotification>args;
		this.onCommissionOpen.emit(notification);
	}

	/**
	 * Zpracovává CommissionPairedAppNotification
	 * @param args
	 */
	private handleCommissionPairedAppNotification(args: any): void {
		let notification = <ICommissionPairedAppNotification>args;

        if (notification.requestorUserId == this._authService.currentUser.id) {
            this._uiService.showInfo(`Zakázka ${notification.commissionNumber} byla spárována.`);
		}

		this.onCommissionPaired.emit(notification);
	}

	/**
	 * Zpracovává CommissionPartOrderedAppNotification
	 * @param args
	 */
	private handleCommissionPartOrderedAppNotification(args: any): void {
		let notification = <ICommissionPartOrderedAppNotification>args;

        if (notification.requestorUserId == this._authService.currentUser.id) {

            if (notification.commissionPartState == CommissionPartState.Blocked)
            {
                this._uiService.showInfo(`Díl ${notification.commissionPartCode} pro zakázku ${notification.commissionNumber} je ve stavu blokováno.`);
            }
            else if (notification.commissionPartState == CommissionPartState.Ordered)
            {
                this._uiService.showInfo(`Díl ${notification.commissionPartCode} pro zakázku ${notification.commissionNumber} je ve stavu objednáno.`);
            }
            else if (notification.commissionPartState == CommissionPartState.Used) {
                this._uiService.showInfo(`Díl ${notification.commissionPartCode} pro zakázku ${notification.commissionNumber} je ve stavu vydáno.`);
			}
			else if (notification.commissionPartState == CommissionPartState.Unavailable) {
				this._uiService.showInfo(`Díl ${notification.commissionPartCode} pro zakázku ${notification.commissionNumber} je ve stavu nedostupný.`);
			}

		}

		this.onCommissionPartOrdered.emit(notification);
    }

	/**
	 * Zpracovává CommissionPaymentPairedAppNotification
	 * @param args
	 */
	private handleCommissionPaymentPairedAppNotification(args: any): void {
		let notification = <ICommissionPaymentPairedAppNotification>args;

		if (notification.requestorUserId == this._authService.currentUser.id) {
			this._uiService.showInfo(`Platba pro zakázku ${notification.commissionNumber} byla spárována.`);
		}

		this.onCommissionPaymentPaired.emit(notification);
	}

	/**
	 * Zpracovává ExportToPayerResultRetrieved
	 * @param args
	 */
	private handleExportToPayerResultRetrieved(args: any): void {
		let notification = <IExportToPayerResultRetrieved>args;
		this.onExportToPayerResultRetrieved.emit(notification);
	}

    /**
     * Zpracovává ValuePairsChangedAppNotification
     * @param args
     */
    private handleValuePairsChangedAppNotification(args: any): void {
        let notification = <IValuePairsChangedAppNotification>args;
        this._valuesService.clearCacheItem(notification.sourceName);

        this.onValuePairsChanged.emit(notification);
    }
}