import { Injectable } from '@angular/core';
import { ModelService, ApiService, UIService } from '../../core';

/**
 * Poskytuje operace nad uživateli.
 */
@Injectable()
export class UsersService extends ModelService {

	constructor(
        apiService: ApiService,
		uiService: UIService) {
        // Voláme konstruktor bázové třídy
        super('user', apiService, uiService);
	}

	public emailExists(id: number, email: string): Promise<boolean> {
		return this.apiService.get<boolean>(super.createUrl('email-exist/' + id + '/' + email));
	}

	public usernameExists(id: number, username: string): Promise<boolean> {
		return this.apiService.get<boolean>(super.createUrl('username-exist/' + id + '/' + username));
	}

	public getDefaultPortalAgent(portalGarantId: number): Promise<any> {
		return this.apiService.get<any>(super.createUrl('default-portal-agent/' + portalGarantId));
    }
       
}
