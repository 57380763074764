import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import {
	AuthenticatedGuard,
	AppLayoutComponent,
	NotFoundPageComponent,
	NotAuthorizedPageComponent,
	SignInPageComponent,
	LostPasswordComponent,
	DashboardPageComponent,
	PasswordChangeComponent,

	FirstLoggedComponent
} from './core';

import { SyncModule } from './sync';
import { LogModule } from './logs';
import { ContactsModule } from './contacts';
import { NotificationsModule } from './notifications';
import { LogisticsModule } from './logistics';
import { AppModule } from './app.module';

const appRoutes: Routes = [
	{
		path: '',
		redirectTo: 'app/dashboard',
		pathMatch: 'full'
	},
	{
		path: 'signin',
		component: SignInPageComponent,
		data: {
			bodyClass: 'signin-page'
		}
	},
	{
		path: 'lost-password',
		component: LostPasswordComponent,
		data: {
			bodyClass: 'signin-page'
		}
	},
	{
		path: 'first-logged',
		component: FirstLoggedComponent,
		data: {
			bodyClass: 'signin-page'
		}
	},
	{
		path: 'app',
		component: AppLayoutComponent,
		canActivateChild: [AuthenticatedGuard],
		children: [
			{
				path: 'not-authorized',
				component: NotAuthorizedPageComponent
			},
			{
				path: 'password-change',
				component: PasswordChangeComponent

			},
			{
				path: 'dashboard',
				component: DashboardPageComponent,
			},
			{
				path: 'commissions',
				loadChildren: () => import('./commissions/index').then(m => m.CommissionsModule),
				data: {
					appScope: 'Commissions'
				}
			},
			{
				path: 'portal',
				children: [
					{
						path: 'commissions',
						loadChildren: () => import('./portal/commissions/index').then(m => m.PortalCommissionsModule),
						data: {
							appScope: 'Portal.{garantId}.Commission'
						}
					},
					{
						path: 'complaints',
						loadChildren: () => import('./portal/complaints/index').then(m => m.PortalComplaintsModule),
						data: {
							appScope: 'Portal.{garantId}.Complaint'
						}
					},
					{
						path: 'docs',
						loadChildren: () => import('./portal/docs/index').then(m => m.PortalDocsModule),
						data: {
							appScope: 'Portal.{garantId}.Docs'
						}
					},
					{
						path: 'instructions',
						loadChildren: () => import('./portal/instruction/index').then(m => m.PortalInstructionModule),
						data: {
							appScope: 'Portal.{garantId}.Instruction'
						}
					},
					{
						path: 'orders',
						loadChildren: () => import('./portal/orders/index').then(m => m.PortalOrdersModule),
						data: {
							appScope: 'Portal.{garantId}.Order'
						}
					},
					{
						path: 'support',
						loadChildren: () => import('./portal/support/index').then(m => m.PortalSupportModule),
						data: {
							appScope: 'Portal.{garantId}.Support'
						}
					}
				],
				data: {
					appScope: 'Portal'
				},
				canLoad: [AuthenticatedGuard]
			},
			{
				path: 'list',
				children: [
					{
						path: 'transport-carriers',
						loadChildren: () => import('./lists/transport-carriers/index.routing').then(m => m.TransportCarrierRoutingModule),
						data: {
							appScope: 'Lists.Transport.Carrier'
						}
					},
					{
						path: 'transport-types',
						loadChildren: () => import('./lists/transport-types/index.routing').then(m => m.TransportTypeRoutingModule),
						data: {
							appScope: 'Lists.Transport.Types'
						}
					},
					{
						path: 'commission-events',
						loadChildren: () => import('./lists/commission-events/index.routing').then(m => m.CommissionEventsRoutingModule),
						data: {
							appScope: 'Lists.Commission.Event'
						}
					},
					{
						path: 'commission-placeofcomplaint',
						loadChildren: () => import('./lists/commission-placeofcomplaints/index.routing').then(m => m.CommissionPlaceOfComplaintRoutingModule),
						data: {
							appScope: 'Lists.Commission.PlaceOfComplaint'
						}
					},
					{
						path: 'commission-kinds',
						loadChildren: () => import('./lists/commission-kinds/index.routing').then(m => m.CommissionKindRoutingModule),
						data: {
							appScope: 'Lists.Commission.Kind'
						}
					},
					{
						path: 'commission-solvers',
						loadChildren: () => import('./lists/commission-solvers/index.routing').then(m => m.CommissionSolverRoutingModule),
						data: {
							appScope: 'Lists.Commission.Solver'
						}
					},
					{
						path: 'commission-states',
						loadChildren: () => import('./lists/commission-states/index.routing').then(m => m.CommissionStateRoutingModule),
						data: {
							appScope: 'Lists.Commission.State'
						}
					},
					{
						path: 'commission-types',
						loadChildren: () => import('./lists/commission-types/index.routing').then(m => m.CommissionTypeRoutingModule),
						data: {
							appScope: 'Lists.Commission.Type'
						}
					},
					{
						path: 'device-category',
						loadChildren: () => import('./lists/device-category/index.routing').then(m => m.DeviceCategoryRoutingModule),
						data: {
							appScope: 'Lists.Device.Category'
						}
					},
					{
						path: 'device-model',
						loadChildren: () => import('./lists/device-model/index.routing').then(m => m.DeviceModelRoutingModule),
						data: {
							appScope: 'Lists.Device.Model'
						}
					},
					{
						path: 'device-policy',
						loadChildren: () => import('./lists/device-policy/index.routing').then(m => m.DevicePolicyRoutingModule),
						data: {
							appScope: 'Lists.Device.Policy'
						}
					},
					{
						path: 'device-producer',
						loadChildren: () => import('./lists/device-producer/index.routing').then(m => m.DeviceProducerRoutingModule),
						data: {
							appScope: 'Lists.Device.Producer'
						}
					},
					{
						path: 'device-type',
						loadChildren: () => import('./lists/device-type/index.routing').then(m => m.DeviceTypeRoutingModule),
						data: {
							appScope: 'Lists.Device.Type'
						}
					},
					{
						path: 'operationtypes',
						loadChildren: () => import('./lists/operation-type/index.routing').then(m => m.ListsOperationTypeRoutingModule),
						data: {
							appScope: 'Lists.OperationType'
						}
					},
					{
						path: 'fees',
						loadChildren: () => import('./lists/fees/index.routing').then(m => m.ListsFeesRoutingModule),
						data: {
							appScope: 'Lists.Fees'
						}
					},
					{
						path: 'requiredsolutions',
						loadChildren: () => import('./lists/required-solution/index.routing').then(m => m.ListsRequiredSolutionRoutingModule),
						data: {
							appScope: 'Lists.RequiredSolution'
						}
					},
					{
						path: 'finalsolutions',
						loadChildren: () => import('./lists/final-solution/index.routing').then(m => m.ListsFinalSolutionRoutingModule),
						data: {
							appScope: 'Lists.FinalSolution'
						}
					},
					{
						path: 'accessories',
						loadChildren: () => import('./lists/accessories/index.routing').then(m => m.ListsAccessoriesRoutingModule),
						data: {
							appScope: 'Lists.Accessories'
						}
					},
					{
						path: 'irisis',
						loadChildren: () => import('./lists/iris/index.routing').then(m => m.ListsIrisRoutingModule),
						data: {
							appScope: 'Lists.Iris'
						}
					},
					{
						path: 'pricelists',
						loadChildren: () => import('./lists/price-list/index.routing').then(m => m.ListsPriceListRoutingModule),
						data: {
							appScope: 'Lists.Pricelists'
						}
					},
					{
						path: 'commission-authorization-types',
						loadChildren: () => import('./lists/commission-authorization-types/index.routing').then(m => m.CommissionAuthorizationTypeRoutingModule),
						data: {
							appScope: 'Lists.CommissionAuthorization.Type'
						}
					}
				]
			},
			{
				path: 'settings',
				children: [
					{
						path: 'users',
						loadChildren: () => import('./settings/user/index.routing').then(m => m.SettingUserRoutingModule),
						data: {
							appScope: 'Settings.User'
						}
					},
					{
						path: 'branches',
						loadChildren: () => import('./settings/branch/index.routing').then(m => m.SettingBranchRoutingModule),
						data: {
							appScope: 'Settings.Branch'
						}
					},
					{
						path: 'general',
						loadChildren: () => import('./settings/general-settings/index.routing').then(m => m.SettingGeneralSettingsRoutingModule),
						data: {
							appScope: 'Settings.General'
						}
					},
					{
						path: 'roles',
						loadChildren: () => import('./settings/role/index.routing').then(m => m.SettingRoleRoutingModule),
						data: {
							appScope: 'Settings.Role'
						}
					},
					{
						path: 'modules',
						loadChildren: () => import('./settings/modules/index.routing').then(m => m.SettingModuleListRoutingModule),
						data: {
							appScope: 'Settings.Module'
						}
					},
					{
						path: 'module3',
						loadChildren: () => import('./settings/modules/module3/index.routing').then(m => m.SettingModulesModule3RoutingModule),
						data: {
							appScope: 'Settings.Module'
						}
					},
					{
						path: 'module1',
						loadChildren: () => import('./settings/modules/module1/index.routing').then(m => m.SettingModulesModule1RoutingModule),
						data: {
							appScope: 'Settings.Module'
						}
					},
					{
						path: 'servistrendconnector',
						loadChildren: () => import('./settings/modules/servistrendconnector/index.routing').then(m => m.SettingModulesServiceTrendConnectorRoutingModule),
						data: {
							appScope: 'Settings.Module'
						}
					}
				]
			},

			{
				path: 'sync',
				loadChildren: () => SyncModule,
				data: {
					appScope: 'System'
				}
			},
			{
				path: 'logs',
				loadChildren: () => LogModule,
				data: {
					appScope: 'System'
				}
			},
			{
				path: 'contacts',
				loadChildren: () => ContactsModule,
				data: {
					appScope: 'Contact'
				}
			},
			{
				path: 'notifications',
				loadChildren: () => import('./notifications/index.routing').then(m => m.NotificationsRoutingModule),
				data: {
					appScope: 'Notifications'
				}
			},
			{
				path: 'svt',
				loadChildren: () => import('./svt/index.routing').then(m => m.SvtRoutingModule),
				data: {
					appScope: 'Svt'
				}
			},
			{
				path: 'logistics',
				loadChildren: () => LogisticsModule,
				data: {
					appScope: 'Logistics'
				}
			},
			{
				path: 'modules',
				children: [
					{
						path: 'sumup',
						loadChildren: () => import('./modules/sumup/index.routing').then(m => m.SumUpRoutingModule)
					}
				]
			}
		]
	},
	{ path: '**', component: NotFoundPageComponent }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<AppModule> = RouterModule.forRoot(
	appRoutes,
	{
		useHash: true,
		enableTracing: false
	});