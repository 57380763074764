import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { ListsPriceListModule } from './';

// Pages
import { ListsPriceListPageComponent } from './pages/price-list-list';
import { ListsPriceListDetailPageComponent } from './pages/price-list-detail';


const routes: Routes = [
    { path: '', component: ListsPriceListPageComponent },
    {
        path: ':id',
        component: ListsPriceListDetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        ListsPriceListModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ListsPriceListRoutingModule { }