import {
	ViewChild,
	ElementRef,
	AfterViewInit,
	Input,
	OnDestroy,
	Renderer2,
	EventEmitter,
	Output,
	ChangeDetectorRef
} from '@angular/core';
import { SelectOption } from '../models';
import { Subscription } from 'rxjs';

/**
 * Bázová třída pro komponenty reprezentující seznamy
 */
export abstract class SelectComponentBase implements AfterViewInit, OnDestroy {
	protected _subs: Array<Subscription> = [];

	// Index vybraného option - pokud je seznam otevřený
	public selectedOptionIndex: number = null;

	// getter a setter pro _active
	public get active(): boolean {
		return this._active;
	}
	public set active(v: boolean) {
		this._active = v;
	}

	// Pole voleb zobrazených v selectu.
	public options: Array<SelectOption> = [];

	// Drží info jestli je panel volbami zobrazený.
	protected _active: boolean = false;

	// Listener pro událost klinutí na dokument
	protected _documentClickListener: () => void;

	// Root element controlu
	protected _rootElement: any;

	// Drží text hledaný v options
	private _searchBuffer: string = '';

	// Reference na div element který obsahuje options
    @ViewChild('optionsMenu', { static: true }) optionsMenu: ElementRef;

	// Input/Output

	@Input() public id: string;
	@Input() public disabled: boolean = false;

	@Output('blur') public blur: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		protected _elementRef: ElementRef,
		protected _renderer: Renderer2,
		protected _changeDetectorRef: ChangeDetectorRef) {
	}

	/**
	 * Destrukce komponenty
	 */
	ngOnDestroy() {
		if (this._documentClickListener) {
			this._documentClickListener();
		}

		this._subs.forEach(x => x.unsubscribe());
	}

	/**
	 * Po inicializaci view
	 */
	ngAfterViewInit() {
		this._rootElement = this._findRootElement(this._elementRef);

		this._changeDetectorRef.detectChanges();
	}

	/**
	 * nastaví focus na tuhle componentu 
	 */
	public focus(): void {
		this._elementRef.nativeElement.focus();
	}

	/**
	* Zpracovává keydown událost
	* @param event
	*/
	public onInputKeyDown(event: KeyboardEvent): any {
		// Zavřený panel - otevřít pokud jsou nějaké option and down key
		if (!this._active) {
			if (event.keyCode == 40 && this.options.length > 0) {
				this._open();

				event.stopPropagation();
				event.preventDefault();
			}

			return;
		}
		else {
			// ESC - zavřít pokud je otevřený
			if (event.keyCode == 27) {
				this._close();

				event.stopPropagation();
				event.preventDefault();
			}
			// UP a DOWN
			else if (event.keyCode == 38 || event.keyCode == 40) {
				// Set initial if null
				if (this.selectedOptionIndex == null) {
					this.selectedOptionIndex = 0;
					return;
				}

				// Up or Down
				let add = event.keyCode == 38 ? -1 : 1;
				this.selectedOptionIndex += add;

				// Check valid values
				if (this.selectedOptionIndex < 0) {
					this.selectedOptionIndex = 0;
				}

				if (this.selectedOptionIndex >= this.options.length) {
					this.selectedOptionIndex = this.options.length - 1;
				}

				this._ensureOptionsScroll();

				event.stopPropagation();
				event.preventDefault();
			}
			// ENTER a TAB
			else if (event.keyCode == 13 || event.keyCode == 9) {
				// Enter
				if (this.selectedOptionIndex != null) {
					this._optionSelected(this.selectedOptionIndex);
					this.selectedOptionIndex = null;
				}
				else if (event.keyCode == 9) {
					this._close();
					this.blur.next();
				}
			}
			// Ostatní - hledat
			else if (
				(event.keyCode >= 48 && event.keyCode <= 90) ||
				(event.keyCode >= 96 && event.keyCode <= 111)) {

				let key = event.key.toLowerCase();
				let index = -1;
				this._searchBuffer += key;

				// Vyhledám pro buffer
				for (let i = 0; i < this.options.length; i++) {
					let option = this.options[i];

					if (!this._optionIsSearchable(option)) {
						continue;
					}

					if (option.text.toLowerCase().startsWith(this._searchBuffer)) {
						index = i;
						break;
					}
				}

				// Pokud nebylo nic nalezeno, zkusím vyhledat pro samostatný znak
				if (index == -1) {
					this._searchBuffer = key;

					for (let i = 0; i < this.options.length; i++) {
						let option = this.options[i];

						if (!this._optionIsSearchable(option)) {
							continue;
						}

						if (option.text.toLowerCase().startsWith(this._searchBuffer)) {
							index = i;
							break;
						}
					}
				}

				// Pokud nebylo nic nalezeno, mažu buffer, jinak nastavím
				if (index == -1) {
					this._searchBuffer = '';
				}
				else {
					this.selectedOptionIndex = index;
					this._ensureOptionsScroll();
				}
			}
		}
	}

	/**
	 * On item mouse move
	 * @param index
	 * @param event
	 */
	public onItemMouseMove(index: number, event: any): void {
		if (this.selectedOptionIndex != index) {
			this.selectedOptionIndex = index;
		}
	}

	/**
	 * Zobrazí nebo skryje panel s volbami.
	 */
	public toggle(): void {
		if (this.active) {
			this._close();
		}
		else {
			this._open();
		}
	}

	/**
	 * Uzavře list položek 
	 **/
	protected _close(): void {
		this.active = false;

		if (this._documentClickListener) {
			this._documentClickListener();
		}

		// Pokud jdu na jinou stránku, když je select otevřený
		try {
			this._changeDetectorRef.detectChanges();
		}
		catch { }
	}

	/**
	 * Otestuje, jestli není vybraný opion mimo zobrazenou oblast
	 * a popř. naskroluje
	 **/
	private _ensureOptionsScroll(): void {
		if (this.selectedOptionIndex == null) {
			this.optionsMenu.nativeElement.scrollTop = 0;
			return;
		}

		// Element vybraného optionu
		let optionElement = this.optionsMenu.nativeElement.children[this.selectedOptionIndex];

		if (optionElement.offsetTop + optionElement.offsetHeight > this.optionsMenu.nativeElement.scrollTop + this.optionsMenu.nativeElement.offsetHeight) {
			this.optionsMenu.nativeElement.scrollTop = (optionElement.offsetTop + optionElement.offsetHeight) - this.optionsMenu.nativeElement.offsetHeight;
		}
		else if (optionElement.offsetTop < this.optionsMenu.nativeElement.scrollTop) {
			this.optionsMenu.nativeElement.scrollTop = optionElement.offsetTop;
		}
	}

	/**
	 * Vrací root-ový element pro element komponenty.
	 * Řeší to problém s modalem - modal má stopPropagation na click
	 * @param element
	 */
	protected _findRootElement(element: ElementRef): any {
		var parent = element.nativeElement.parentNode;

		while (parent.parentNode) {
			if (parent.className.indexOf('content') > -1 && parent.parentNode.className.indexOf('modal') > -1) {
				parent = parent.parentNode;
				break;
			}

			parent = parent.parentNode;
		}

		return parent;
	}

	/**
	 * Zpracovává událost kliknutí v dokumentu - skryje panel s volbami.
	 * @param event
	 */
	protected _onDocumentClick(event: any): void {
		let id: string = event.target.id;

		if (this.active && id !== this.id) {
			this._close();
			this.blur.next();
		}
	}

	/**
	 * Zobrazí list, pokud jsou položky
	 * @param event
	 **/
	protected _open(event?: any): void {
		this.active = true;

		if (this.active) {
			this._documentClickListener = this._renderer.listen(
				this._rootElement,
				'click',
				x => this._onDocumentClick(x)
			);
		}

		if (event) {
			event.target.id = this.id;
		}
	}

	/**
	 * Abstraktní metoda pro potomky
	 * @param index
	 */
	protected _optionSelected(index: number): void {
	}

	/**
	 * Abstraktní metoda pro potomky
	 * Vrací info, jestli může být option zahrnutý do hledání
	 * @param index
	 */
	protected _optionIsSearchable(option: SelectOption): boolean {
		return true;
	}
}