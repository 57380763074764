import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import {
    HelperService,
    ModelServiceFactory,
    IModelService,
    CommissionPaymentType,
    DiscountType,
    CommissionPriceProposalState,
    CommissionAuthorizationState,
    AppNotificationsService,
    CommissionPaymentState,
    AuthService,
    UIService
} from '../../core';
import { TransportCalculator } from './transport-calculator';
import { ModelWrapperBuilder } from './model-wrapper-builder';
import { ModelWrapper, PropertyValueChange } from '../models';
import { FormGroup } from '@angular/forms';
import { TransportCarriersService } from '../../lists/transport-carriers';
import { ListsPriceListService } from '../../lists/price-list';
import { Subscription } from 'rxjs';
import { PricingViewModel } from './pricing-view-model';
import { FormControlsState } from './form-controls-state';
import { CommissionsService } from './commissions';
import { Promise } from 'core-js';

/**
 * ViewModel pro detail zakázky
 */
@Injectable()
export class CommissionViewModel implements OnDestroy {
    //#region Definice handlerů změn hodnot

    private _valueChangedHandlers: { [path: string]: (value: any) => void } = {
        'receiptTransport.transportTypeId': x => { this.transportCalculator.calculate(this.model.receiptTransport); },
        'issueTransport.transportTypeId': x => { this.transportCalculator.calculate(this.model.issueTransport); },
        'receiptTransport.distance': x => { this.transportCalculator.calculate(this.model.receiptTransport); },
        'issueTransport.distance': x => { this.transportCalculator.calculate(this.model.issueTransport); },
        'commissionTypeId': x => this._onCommissionTypeChanged()
    };

    //#endregion

    private _subs: Array<Subscription> = []
    private _modelService: IModelService;
    private _wrapper: ModelWrapper<ICommission> = null;

    // Wrapper nad formulářem
    public get model(): ICommission {
        if (!this._wrapper) {
            return null;
        }

        return this._wrapper.model;
    }

    // FormGroup zakázky
    public get form(): FormGroup {
        if (!this._wrapper) {
            return new FormGroup({});
        }

        return this._wrapper.form;
    }

    // Vrací info jestli jde o novou zakázku
    public get isNew(): boolean {
        return this.model.id == 0;
    };

    // Událost spouštěná po inicializaci modelu
    public onInitialized: EventEmitter<void> = new EventEmitter();

    // Událost spouštěná po inicializaci seriového čísla v nové zakázce
    public onSerialNumberInitialized: EventEmitter<void> = new EventEmitter();

    // Pricing view model
    public pricing: PricingViewModel;

    // Vrací stav ovládacích prvků - disabled a spol.
    public controlsState: FormControlsState;

    // Jestli už bylo inicializováno seriové číslo
    // - probíhá pro novou zakázku
    public serialNumberInitialized: boolean = false;

    // Instance transport calculátoru
    public transportCalculator: TransportCalculator = null;

    // Jestli je hromadná fakturace.
    public isBulkInvoice: boolean = false;

    constructor(
        private _modelServiceFactory: ModelServiceFactory,
        private _helperService: HelperService,
        private _modelWrapperBuilder: ModelWrapperBuilder,
        private _transportCarriersService: TransportCarriersService,
        private _appNotificationsService: AppNotificationsService,
        private _listsPriceListService: ListsPriceListService,
        private _commissionsService: CommissionsService,
        private _authService: AuthService,
        private _uiService: UIService) {

        this._modelService = _modelServiceFactory.createService('commission');
        this.transportCalculator = new TransportCalculator(_transportCarriersService);

        this._subs.push(
            this._appNotificationsService.onCommissionPaired.subscribe((notification: ICommissionPairedAppNotification) => {
                if (!this.model || !this.model.id || this.model.id != notification.commissionId) {
                    return;
                }

                // Aktuální zakázka byla spárována
                this._wrapper.doOutsideChangeTracking(model => {
                    model.isPaired = true;
                });
            }),
            this._appNotificationsService.onCommissionPartOrdered.subscribe((notification: ICommissionPartOrderedAppNotification) => {
                if (!this.model || !this.model.id || this.model.id != notification.commissionId) {
                    return;
                }

                // Díl pro aktuální zakázku byl obejdnán
                this._wrapper.doOutsideChangeTracking(model => {
                    let part = model
                        .parts
                        .find(x => x.id == notification.commissionPartId);

                    if (part) {
                        part.orderExternalNumber = notification.orderExternalNumber;
                        part.partState = notification.commissionPartState;
                        part.salePrice = notification.salePrice;
                    }
                });
            }),
            this._appNotificationsService.onCommissionAllPartOrdered.subscribe((notification: ICommissionAllPartOrderedNotification) => {
                if (!this.model || !this.model.id || this.model.id != notification.commissionId) {
                    return;
                }

                // Veškeré díly jsou ve stavu blokováno -> automaticky se změnil stav objednávky
                this._wrapper.doOutsideChangeTracking(model => {
                    model.eventId = notification.eventId;
                    model.stateName = notification.stateName;
                });
            }),
            this._appNotificationsService.onCommissionPaymentPaired.subscribe((notification: ICommissionPaymentPairedAppNotification) => {
                if (!this.model || !this.model.id || this.model.id != notification.commissionId) {
                    return;
                }

                // Platba pro aktuální zakázku byla spárována
                this._wrapper.doOutsideChangeTracking(model => {
                    let payment = model
                        .payments
                        .find(x => x.id == notification.commissionPaymentId);

                    if (payment) {
                        payment.externalNumber = notification.externalNumber;
                        payment.paymentState = notification.externalNumber.substring(0, 3) === "999" ? CommissionPaymentState.Temporary : CommissionPaymentState.Paired;
                    }
                });
            }),
            this._appNotificationsService.onExportToPayerResultRetrieved.subscribe((notification: IExportToPayerResultRetrieved) => {
                if (!this.model || !this.model.id || this.model.id != notification.commissionId) {
                    return;
                }

                // Byla přijat výsledek z exportu zakázky do systému plátce
                this._wrapper.doOutsideChangeTracking(model => {
                    model.communicationId = notification.communicationId;
                    model.payerCommissionId = notification.payerCommissionId;

                    this._uiService.showInfo(`U aktuální zakázky došlo ke změně ID komunikace a ID zak. plátce.`);
                });
            })
        );
    }

	/**
	 * Destrukce komponenty 
	 */
    ngOnDestroy() {
        this._subs.forEach(x => x.unsubscribe());

        if (this.pricing != null) {
            this.pricing.destroy();
        }

        if (this.controlsState != null) {
            this.controlsState.destroy();
        }
    }

	/**
	 * Vloží požadavek na zálohu
	 * @param amount
	 */
    public addRequestForDeposit(amount: number, paymentMethod: PaymentMethodType): void {

        let payment: ICommissionPayment = {
            id: 0,
            paidAmount: 0,
            paidDate: null,
            paidToUser: null,
            paymentType: CommissionPaymentType.Deposit,
            requestDate: new Date(Date.now()),
            requestedAmount: amount,
            paymentState: CommissionPaymentState.NotPaid,
            paymentMethod: paymentMethod,
            externalNumber: null,
            isAutoGenerated: false,
            isPaid: false
        };

        //Vybrana metoda, automaticky pridavame uhradu
        if (paymentMethod != null) {
            payment.paidAmount = payment.requestedAmount;
            payment.paidDate = payment.requestDate;
            payment.paidToUser = this._authService.currentUser.fullName;
            payment.paymentState = CommissionPaymentState.NotSaved;
        }

        this.model.payments.push(payment);
    }

    /**
     * Odstraneni vazby na id kontaktu
     **/
    public clearPayerAddressContactId(): void {
        this._clearAddressContactId(this.model.payer);
    }

    /**
     * Zavření zakázky
     * @param id id zakázky
     */
    public close(id: number): void {
        this._commissionsService.closeCommission(id);
    }

	/**
	 * Zkopíruje (převezme) data z jedné adresy do druhé.
	 * @param from
	 * @param to
	 */
    public copyAddressFromTo(from: ICommissionAddress, to: ICommissionAddress): void {
        to.contactId = null;
        to.name = from.name;
        to.street = from.street;
        to.city = from.city;
        to.zipCode = from.zipCode;
        to.countryId = from.countryId;
        to.phone1 = from.phone1;
        to.phone1Notify = from.phone1Notify;
        to.phone2 = from.phone2;
        to.phone2Notify = from.phone2Notify;
        to.email = from.email;
        to.emailNotify = from.emailNotify;
    }

	/**
	 * Načte definovanou zakázku a překopíruje její data do aktuální zakázky
	 * @param id
	 */
    public copyDataFromCommission(id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // Načítáme model zakázky z API
            this._modelService.get<ICommission>(id).then(
                commission => {
                    if (commission == null) {
                        reject();
                        return;
                    }

                    // Kopíruju celé sekce a data
                    this.model.device = this._helperService.deepClone(commission.device);
                    this.model.payer = this._helperService.deepClone(commission.payer);
                    this.model.recipient = this._helperService.deepClone(commission.recipient);
                    this.model.seller = this._helperService.deepClone(commission.seller);
                    this.model.technicianId = commission.technicianId;

                    resolve();
                });
        });
    }

	/**
	 * vytvoří novou, ready to use autorizaci
	 */
    public createAuthorization(): ICommissionAuthorization {
        return {
            id: 0,
            number: null,
            authorizationState: CommissionAuthorizationState.Waiting,
            authorizationTypeId: null,
            authorizationTypeName: null,
            description: null
        };
    }

	/**
	 * vytvoří nový, ready to use cenový návrh 
	 */
    public createPriceProposal(): ICommissionPriceProposal {
        // Vytvoříme číslo
        let numbers = this.model.priceProposals.map(x => {
            let idx = x.number.lastIndexOf('_');
            return parseInt(x.number.substr(idx + 1, x.number.length - idx - 1));
        });

        let max = 0;
        if (numbers.length > 0) {
            max = Math.max.apply(null, numbers);
        }

        return {
            id: 0,
            number: this.model.number + '_' + this._helperService.padStart(max + 1, '0', 3),
            operationPrice: 0,
            otherPrice: 0,
            partsPurchasePrice: 0,
            partsSellingPrice: 0,
            state: CommissionPriceProposalState.Waiting,
            suggestedSolution: '',
            sumPriceWithoutVat: 0,
            sumPriceWithVat: 0,
            transportPrice: 0
        };
    }

    /**
     * Vrací info jestli má zakázka nějaký neuhrazený požadavek
     **/
    public hasUnpaidPayment(): boolean {
        return this.model.payments.filter(x => {
            return x.paymentState == CommissionPaymentState.NotPaid &&
                (x.paymentType != CommissionPaymentType.Commission || !this.isBulkInvoice);
        }).length > 0;
    }

	/**
	 * Inicializuje view model pro dané ID a jestli má být jen readonly
	 * @param id
     * @param readonly
	 */
    public init(id: number, readonly: boolean): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            // Načítáme model zakázky z API
            this._modelService.get<ICommission>(id).then(
                model => {
                    if (!model) {
                        reject();
                        return;
                    }

                    if (this._wrapper == null) {
                        // První inicializace
                        this._wrapper = this._modelWrapperBuilder.build<ICommission>(model);
                        this.pricing = new PricingViewModel(this._helperService, this._wrapper);
                        this.controlsState = new FormControlsState(this._wrapper, this._authService);

                        // Připnutí handlerů na změnu hodnot
                        for (let path in this._valueChangedHandlers) {
                            this._subs.push(
                                this.form.get(path).valueChanges.subscribe(this._valueChangedHandlers[path])
                            );
                        }
                    }
                    else {
                        // Uložit a nová/pokračovat - viewModel už existuje
                        this._wrapper.init(model);

                        // Aktualizuj pricing view model
                        this.pricing.update();
                    }

                    this.serialNumberInitialized = !this.isNew;
                    this.controlsState.init(!this.isNew, readonly);

                    // Kvuli nacteni typu zakazky - IsBulkInvoice
                    this._onCommissionTypeChanged();

                    resolve({});

                    this.onInitialized.emit();
                });
        });
    }

	/**
	 * Inicializuje seriové číslo 
	 */
    public initSerialNumber(): void {
        this.serialNumberInitialized = true;
        this.controlsState.setSerialNumberInitialized(true);

        this.onSerialNumberInitialized.emit();
    }

    /**
     * Vrací info jestli je zakázka změněna 
     **/
    public isChanged(): boolean {
        let retval = this._wrapper != null && this._wrapper.isChanged();

        if (retval) {
            console.log(this._wrapper.collectChanges());
        }

        return retval;
    }

	/**
	 * Přepočítá ceny dílů podle ceníku.
	 */
    public recomputePartsPrices(): Promise<void> {
        if (this.model.parts.length == 0 || this.model.commissionTypeId == null) {
            return Promise.resolve();
        }

        return new Promise<void>((resolve, reject) => {
            this._listsPriceListService.getByCommissionType(this.model.commissionTypeId)
                .then(
                    pricelist => {
                        this.model.parts.forEach(part => {
                            part.salePrice = this._listsPriceListService.computePriceByPricelist(pricelist, part.purchasePrice);
                        });

                        resolve();
                    },
                    reject);
        });
    }

	/**
	 * Uloží aktuální zakázku a vrací id zakázky.
	 **/
    public save(): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            let rawData = this.form.getRawValue();

            this._modelService.set(this.model.id, rawData).then(
                (model: ICommission) => {
                    model == null ? reject() : resolve(model.id);
                });
        });
    }

	/**
	 * Načte model zařízení z API a nastaví jeho hodnoty do modelu zakázky.
	 * @param deviceModelId
	 */
    public setDeviceModel(deviceModelId: number): Promise<void> {
        if (deviceModelId == 0) {
            this.model.device.modelId = 0;
            this.model.device.categoryId = null;
            this.model.device.policyId = null;
            this.model.device.producerId = null;

            return Promise.resolve();
        }
        else {
            let deviceModelService = this._modelServiceFactory.createService('listDeviceModel');

            return deviceModelService.get<IDeviceModelModel>(deviceModelId)
                .then(
                    x => {
                        this.model.device.modelId = deviceModelId;
                        this.model.device.modelName = x.name;
                        this.model.device.categoryId = x.deviceCategoryId;
                        this.model.device.policyId = x.devicePolicyId;
                        this.model.device.producerId = x.deviceProducerId;
                    });
        }
    }

	/**
	 * Načte kontakt se zadaným id a nastaví jeho data do adresy plátce.
	 * @param contactId
	 */
    public setPayerAddress(contactId: number): Promise<void> {
        return this._loadContact(contactId).then(
            x => {
                this._setPayerAddressData(x);
            }
        );
    }

	/**
	 * Načte kontakt se zadaným id a nastaví jeho data do adresy příjemce.
	 * @param contactId
	 */
    public setRecipientAddress(contactId: number): Promise<void> {
        return this._loadContact(contactId).then(
            x => {
                this._setAddressData(this.model.recipient, x);
            }
        );
    }

	/**
	 * Načte kontakt se zadaným id a nastaví jeho data do adresy prodejce.
	 * @param contactId
	 */
    public setSellerAddress(contactId: number): Promise<void> {
        return this._loadContact(contactId).then(
            x => {
                this._setAddressData(this.model.seller, x);
            }
        );
    }

    /**
     * Provede aktualizaci příloh k zakázce
     */
    public updateAttachments(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._commissionsService
                .updateCommissionAttachments(this.model.id)
                .then(
                    (attachementsModels: Array<ICommissionAttachment>) => {

                        attachementsModels.forEach(x => {
                            var id = x.id;
                            var el = this.model.attachments.find(y => y.id == id);

                            if (typeof el !== "undefined") {
                                el.attachmentType = x.attachmentType;
                                el.fileName = x.fileName;
                                el.filePath = x.filePath;
                                el.thumbnailUrl = x.thumbnailUrl;
                                el.url = x.url;
                                el.temporary = x.temporary;
                            } else {
                                var commissionAttachment: ICommissionAttachment = {
                                    attachmentType: x.attachmentType,
                                    fileName: x.fileName,
                                    filePath: x.filePath,
                                    thumbnailUrl: x.thumbnailUrl,
                                    url: x.url,
                                    temporary: x.temporary,
                                    id: x.id
                                };

                                this.model.attachments.push(commissionAttachment);
                            }
                        });

                        resolve();
                    },
                    (reason) => {
                        reject();
                    });
        });
    }

    /**
     * Promaže vazbu na kontakt
     * @param address
     */
    public _clearAddressContactId(address: ICommissionAddress | IPayerCommissionAddress): void {
        address.contactId = null;
    }

	/**
	 * Načte kontakt se zadaným id.
	 * @param contactId
	 */
    private _loadContact(contactId: number): Promise<any> {
        let contactModelService = this._modelServiceFactory.createService('contact');
        return contactModelService.get<any>(contactId);
    }

	/**
	 * Zpracovává událost změny typu zakázky 
	 */
    private _onCommissionTypeChanged(): void {
        // Typ byl zrušen, ruším slevu
        if (this.model.commissionTypeId == null) {
            this.model.discountByType.type = DiscountType.None;
            this.model.discountByType.value = null;

            this.isBulkInvoice = false;

            return;
        }

        // Načtu typ a nastavím slevu
        let commissionTypeModelService = this._modelServiceFactory.createService('listCommissionType');

        commissionTypeModelService.get<ICommissionTypeModel>(this.model.commissionTypeId)
            .then(x => {
                this.model.discountByType.type = x.commissionSaleType;
                this.model.discountByType.value = x.commissionSaleValue;

                this.isBulkInvoice = x.isBulkInvoice;
            });

        // Aktualizace ceny dílů
        this.recomputePartsPrices();
    }

	/**
	 * Data z kontaktu přepíše do dodané adresy v zakázce
	 * @param address
	 * @param contact
	 */
    private _setAddressData(address: ICommissionAddress, contact: any): void {
        address.contactId = contact.id;
        address.name = contact.name;
        address.street = contact.street;
        address.city = contact.city;
        address.zipCode = contact.zipCode;
        address.countryId = contact.countryId;
        address.phone1 = contact.phone1;
        address.phone1Notify = contact.allowNotificationPhone1;
        address.phone2 = contact.phone2;
        address.phone2Notify = contact.allowNotificationPhone2;
        address.email = contact.email;
        address.emailNotify = contact.allowNotificationEmail;
    }

	/**
	 * Data z kontaktu přepíše do adresy plátce v zakázce
	 * @param contact
	 */
    private _setPayerAddressData(contact: any): void {
        this._setAddressData(this.model.payer, contact);

        this.model.payer.ic = contact.ic;
        this.model.payer.dic = contact.dic;
    }
}