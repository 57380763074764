import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { DeviceModelModule } from './';

// Pages
import { ListsDeviceModelPageComponent } from './pages/device-model-list';
import { ListsDeviceModelDetailPageComponent } from './pages/device-model-detail';

const routes: Routes = [
	{ path: '', component: ListsDeviceModelPageComponent },
	{
		path: ':id',
		component: ListsDeviceModelDetailPageComponent,
		canDeactivate: [CanDeactivateGuard]
	}
];

@NgModule({
	imports: [
		DeviceModelModule,
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class DeviceModelRoutingModule { }