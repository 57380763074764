import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { ModelWrapper, PropertyValueChange, PropertyPath, ModelWrapperContext } from '../models';
import { Object } from 'core-js';

let changeTrackingDisabledForPath: Array<string> = [
    'isPaired'
];

/**
 * Vytváří wrappe pro model
 */
@Injectable()
export class ModelWrapperBuilder {

    constructor() {
    }

	/**
	 * Vytvoří wrapper pro daný model
	 * @param model
	 */
    public build<TModel>(model: TModel): ModelWrapper<TModel> {
        let context = new ModelWrapperContext();
        let form = <FormGroup>createAbstractControl(model);
        let wrapper = new FormGroupWrapper(form, new PropertyPath(), context);

        return new ModelWrapper<TModel>(form, <any>wrapper, model, context);
    }
}

export interface IModelWrapperContext {

}

/**
 * Wrapper na FormGroup který umožňuje přistupovat k hodnotám klasickým způsobem objekt.property.property ...
 */
class FormGroupWrapper implements IFormWrapper {
    private _wrappers: { [key: string]: IFormWrapper } = {};

    /**
     * Název fieldu v modelu.
     */
    public propertyName: string = null;

    /**
     * Cesta k fieldu v modelu
     */
    public propertyPath: string = null;

    constructor(
        private _formGroup: FormGroup,
        private _path: PropertyPath,
        private _context: ModelWrapperContext) {

        this.propertyName = this._path.getName();
        this.propertyPath = this._path.getPath();

        for (let property in _formGroup.controls) {
            let control = _formGroup.controls[property];

            this._createProperty(property, control, _path);
        }
    }

	/**
	 * Aktualizuje data ve formuláři daty z dodaného objektu
	 * @param value
	 */
    public update(value: any): void {
        for (let property in this._wrappers) {
            this._wrappers[property].update(value[property]);
        }
    }

    /**
     * Implementation of the IFormWrapper.isChanged 
     **/
    public isChanged(): boolean {
        for (let property in this._formGroup.controls) {
            if (this._getWrapper<IFormWrapper>(property).isChanged()) {
                return true;
            }
        }

        return false;
    };

    /**
     * Vyhledá a naplní do pole informace o všech změněných propertách
     * @param changes
     */
    public collectChanges(changes: Array<PropertyValueChange>): void {
        for (let property in this._wrappers) {
            let wrapper = this._getWrapper(property);

            wrapper.collectChanges(changes);
        }
    }

    private _addWrapper(property: string, wrapper: IFormWrapper): void {
        this._wrappers[property] = wrapper;
    }

    private _getWrapper<TWrapper extends IFormWrapper>(property: string): TWrapper {
        return <TWrapper>this._wrappers[property];
    }

    private _createProperty(property: string, control: AbstractControl, path: PropertyPath): void {
        path.push(property);

        if (control instanceof FormArray) {
            this._addWrapper(property, new FormArrayWrapper(<FormArray>control, path, this._context));

            Object.defineProperty(this, property, {
                get: () => {
                    return this._getWrapper<FormArrayWrapper>(property);
                },
                set: (value: any) => {
                    throw 'Set on FormArray is not supported';
                },
            });
        }
        else if (control instanceof FormControl) {
            this._addWrapper(property, new FormControlWrapper(<FormControl>control, path, this._context));

            Object.defineProperty(this, property, {
                get: () => {
                    return this._getWrapper<FormControlWrapper>(property).getValue();
                },
                set: (value: any) => {
                    this._getWrapper<FormControlWrapper>(property).setValue(value);
                },
            });
        }
        else {
            this._addWrapper(property, new FormGroupWrapper(<FormGroup>control, path, this._context));

            Object.defineProperty(this, property, {
                get: () => {
                    return this._getWrapper<FormGroupWrapper>(property);
                },
                set: (value: any) => {
                    (<FormGroup>control).patchValue(value, { onlySelf: false, emitEvent: true });
                },
            });
        }

        path.pop();
    }
}

/**
 * Wrapper na FormControl
 */
class FormControlWrapper implements IFormWrapper {
    public originalValue: any = null;

    private _changeTrackingDisabled: boolean = false;

    /**
     * Název fieldu v modelu.
     */
    public propertyName: string = null;

    /**
     * Cesta k fieldu v modelu
     */
    public propertyPath: string = null;

    constructor(
        private _formControl: FormControl,
        private _path: PropertyPath,
        private _context: ModelWrapperContext) {

        this.propertyName = this._path.getName();
        this.propertyPath = this._path.getPath();
        this.originalValue = this._formControl.value;

        this._changeTrackingDisabled = changeTrackingDisabledForPath.indexOf(this.propertyPath) > -1;
    }

    /**
     * Vrací hodnotu controlu 
     **/
    public getValue(): any {
        return this._formControl.value;
    }

    /**
     * Nastavuje hodnotu controlu
     * @param value
     */
    public setValue(value: any): void {
        if (this._formControl.value !== value) {
            if (this._context.setWithOriginal) {
                this.originalValue = value;
            }

            this._formControl.setValue(value);
        }
    }

    /**
     * Implementace IFormWrapper.update
     * @param value
     */
    public update(value: any): void {
        this.originalValue = value;

        if (this._formControl.value !== value) {
            this._formControl.setValue(value);
        }
    }

    /**
     * Implementace IFormWrapper.isChanged
     **/
    public isChanged(): boolean {
        if (this._changeTrackingDisabled) {
            return false;
        }

        // Specialitka
        if (this.originalValue == '' && this._formControl.value == null) {
            return false;
        }

        return this.originalValue !== this._formControl.value;
    };

    /**
     * Vyhledá a naplní do pole informace o všech změněných propertách
     * @param changes
     */
    public collectChanges(changes: Array<PropertyValueChange>): void {
        if (this._changeTrackingDisabled) {
            return;
        }

        // Specialitka
        if (this.originalValue == '' && this._formControl.value == null) {
            return;
        }

        if (this.originalValue !== this._formControl.value) {
            changes.push({
                newValue: this._formControl.value,
                originalValue: this.originalValue,
                propertyName: this.propertyName,
                propertyPath: this.propertyPath
            });
        }
    };
}

/**
 * Wrapper na FormArray který umožňuje přistupovat poli FormGroup jako k poli objektů
 */
class FormArrayWrapper implements IFormWrapper, Iterable<any> {
    private _items: Array<IFormWrapper>;
    private _removedItems: Array<IFormWrapper> = [];

    /**
     * Název fieldu v modelu.
     */
    public propertyName: string = null;

    /**
     * Cesta k fieldu v modelu
     */
    public propertyPath: string = null;

    constructor(
        private _formArray: FormArray,
        private _path: PropertyPath,
        private _context: ModelWrapperContext) {

        this.propertyName = this._path.getName();
        this.propertyPath = this._path.getPath();

        this._items = _formArray
            .controls
            .map((x, index) => {
                _path.push(index.toString());

                let retval = this._mapControl(x, _path);

                _path.pop();

                return retval;
            });
    }

    /**
     * Implementation of the IFormWrapper.isChanged
     **/
    public isChanged(): boolean {
        for (let item of this._items) {
            let anyItem = <any>item;

            // Pokud je id rovno 0 -> nový
            if ('id' in anyItem && anyItem.id == 0) {
                return true;
            }

            if (item.isChanged()) {
                return true;
            }
        }

        if (this._removedItems.length > 0) {
            return true;
        }

        return false;
    }

    /**
     * Vyhledá a naplní do pole informace o všech změněných propertách
     * @param changes
     */
    public collectChanges(changes: Array<PropertyValueChange>): void {
        for (let item of this._items) {
            let anyItem = <any>item;

            // Pokud je id rovno 0 -> nový
            if ('id' in anyItem && anyItem.id == 0) {
                changes.push({
                    newValue: 'new',
                    originalValue: null,
                    propertyName: item.propertyName,
                    propertyPath: item.propertyPath
                });
                continue;
            }

            item.collectChanges(changes);
        }

        for (let item of this._removedItems) {
            changes.push({
                newValue: 'deleted',
                originalValue: null,
                propertyName: item.propertyName,
                propertyPath: item.propertyPath
            });
        }
    }

	/**
	 * Aktualizuje data ve formuláři daty z dodaného pole
	 * @param value
	 */
    public update(value: Array<any>): void {
        // Odeberu všechny existující controly
        for (var i = this._formArray.length - 1; i >= 0; i--) {
            this._formArray.removeAt(i);
            this._items.splice(i, 1);
        }

        // Přidám nové
        for (var i = 0; i < value.length; i++) {
            this._formArray.insert(i, createAbstractControl(value[i]));
        }

        let path: PropertyPath = new PropertyPath();
        path.parse(this.propertyPath);

        this._items = this._formArray
            .controls
            .map((x, index) => {
                path.push(index.toString());

                let retval = this._mapControl(x, path);

                path.pop();

                return retval;
            });
    }

	/**
	 * Vrací velikost pole
	 */
    public get length(): number {
        return this._formArray.length;
    }

	/**
	 * Implementace filter
	 * @param callback
	 */
    public filter(callback): Array<any> {
        var len = this._items.length >>> 0,
            res = new Array(len), // preallocate array
            c = 0, i = -1;

        while (++i !== len) {
            // checks to see if the key was set
            if (i in this._items) {
                if (callback(this._items[i], i, this._items)) {
                    res[c++] = this._items[i];
                }
            }
        }

        res.length = c; // shrink down array to proper size
        return res;
    }

	/**
	 * Implementace find
	 * @param callback
	 */
    public find(callback) {
        let len = this._items.length >>> 0;

        if (typeof callback !== 'function') {
            throw new TypeError('callback must be a function');
        }

        let k = 0;

        while (k < len) {
            let kValue = this._items[k];

            if (callback.call(this, kValue, k, this._items)) {
                return kValue;
            }

            k++;
        }

        return undefined;
    }

	/**
	 * For Each implementace
	 * @param callbackfn
	 * @param thisArg
	 */
    public forEach(callbackfn: (value: any, index: number, array: any[]) => void, thisArg?: any): void {
        // Kontrola callbacku
        if (typeof callbackfn !== 'function') {
            throw new TypeError(callbackfn + ' is not a function');
        }

        // Iteruju AbstractControls v poli
        for (let i = 0; i < this._items.length; i++) {
            callbackfn(this._items[i], i, <any>this);
        }
    }

	/**
	 * IndexOf implementace
	 * @param value
	 */
    public indexOf(value: any): number {
        if (!value) {
            throw 'Invalid value for IndexOf';
        }

        for (var i = 0; i < this._items.length; i++) {
            if (this._items[i] == value) {
                return i;
            }
        }

        return -1;
    }

	/**
	 * Map implementace
	 * @param fn
	 */
    public map(fn: any) {
        let retval = [];

        for (let i = 0, l = this._items.length; i < l; i++) {
            retval.push(fn(this._items[i]));
        }

        return retval;
    };

	/**
	 * Push implementace
	 * @param value
	 */
    public push(value: any): number {
        let childControl: AbstractControl = createAbstractControl(value);

        let path: PropertyPath = new PropertyPath();
        path.parse(this.propertyPath);
        path.push(this._items.length.toString());

        this._items.push(this._mapControl(childControl, path));
        this._formArray.push(childControl);

        return this._formArray.length;
    }

	/**
	 * Some implementace
	 * @param fn
	 */
    public some(fn: any): boolean {
        for (let i = 0, l = this._items.length; i < l; i++) {
            if (fn(this._items[i], i)) {
                return true;
            }
        }

        return false;
    }

	/**
	 * Splice implementace
	 * @param start
	 * @param deleteCount
	 */
    public splice(start: number, deleteCount?: number): void {
        if (deleteCount != 1) {
            throw new Error('Splice for other than one element is not implemented.');
        }

        // Ukládám si odebrané položky, pokud nejsou nové
        let removedItem = <any>this._items[start];
        if ('id' in removedItem && removedItem.id > 0) {
            this._removedItems.push(this._items[start]);
        }

        this._items.splice(start, 1);
        this._formArray.removeAt(start);
    }

	/**
	 * Implementace Iterable{any} 
	 */
    [Symbol.iterator](): Iterator<any> {
        return new FormArrayIterator(this._items);
    }

    private _mapControl(control: AbstractControl, path: PropertyPath): IFormWrapper {
        if (control instanceof FormGroup) {
            return new FormGroupWrapper(<FormGroup>control, path, this._context);
        }
        else if (control instanceof FormArray) {
            return new FormArrayWrapper(<FormArray>control, path, this._context);
        }
        else {
            throw `Type ${typeof control} is not supported in _mapCOntrol`;
        }
    }
}

/**
 * Iterator pro implementaci Iterable 
 */
class FormArrayIterator implements Iterator<any> {
    private _index: number = 0;

    constructor(
        private _items: Array<any>) {
    }

    public next(value?: any): IteratorResult<any> {
        if (this._index >= this._items.length) {
            return {
                done: true,
                value: null
            };
        }

        return {
            done: false,
            value: this._items[this._index++]
        };
    }
}

/**
 * Interface pro všechny wrappery ve formuláři 
 **/
interface IFormWrapper {

    /**
     * Implementace IFormWrapper.isChanged
     **/
    isChanged(): boolean;

    /**
     * Název fieldu v modelu.
     */
    propertyName: string;

    /**
     * Cesta k fieldu v modelu
     */
    propertyPath: string;

    /**
     * Provede aktualizaci hodnoty ve fieldu modelu
     * @param value
     */
    update(value: any): void;

    /**
     * Vyhledá a naplní do pole informace o všech změněných propertách
     * @param changes
     */
    collectChanges(changes: Array<PropertyValueChange>): void;
}

/**
* Vytvoří některou s implementací AbstractControl pro danou hodnotu
* @param value
* @param forceAsControl
*/
let createAbstractControl = function (value: any, forceAsControl: boolean = false): AbstractControl {

    // V property je pole
    if (!forceAsControl && Array.isArray(value)) {
        const controls = value.map(x => createAbstractControl(x));
        return new FormArray(controls);
    }

    // V property je objekt
    if (!forceAsControl && typeof value === 'object' && value !== null && !(value instanceof Date)) {
        let controls = {};

        Object.keys(value).forEach(property => {
            // Specialitka - nemá se brát jako FormArray
            if (property == 'accessories') {
                controls[property] = createAbstractControl(value[property], true);
            }
            else {
                controls[property] = createAbstractControl(value[property]);
            }
        });

        return new FormGroup(controls);
    }

    // Bereme jako jednoduchou hodnotu
    return new FormControl(value);
}