import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';

// Pages
import { ListsAccessoriesPageComponent } from './pages/accessories-list';
import { ListsAccessoriesDetailPageComponent } from './pages/accessories-detail';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		CoreModule,
		FormsModule,
		SuiModule
	],
	exports: [
		RouterModule
	],
	declarations: [
        ListsAccessoriesPageComponent,
        ListsAccessoriesDetailPageComponent
	],
	providers: [
	],
	entryComponents: [
	]
})
export class ListsAccessoriesModule { }