import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { UIService, ModalService } from '../../core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
import { PlaceOfBusinessModal } from '../modals/place-of-business';
import { ContactsService } from '../services/contacts';
/**
 * Prehled provozoven v adresari
 */

@Component({
    selector: 'div.kt-contact-places-of-business',
    templateUrl: "/template/contacts/components/contact-places-of-business.cshtml",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PlacesOfBusinessComponent),
			multi: true
		}
	],
	viewProviders: [
		{
			provide: ControlContainer,
            useExisting: NgForm
		}
	]
})
export class PlacesOfBusinessComponent implements ControlValueAccessor, OnInit {
    
	public disabled: boolean = false;

    /**
     * Vstup pro id hlavniho kontaktu
     */
    @Input() parentId: number;

	// Model a getter/setter
	private _placesOfBusiness: Array<any> = [];

    public get placesOfBusiness(): Array<any> {
      //  console.log(this._placesOfBusiness);
        return this._placesOfBusiness;
	}
    public set placesOfBusiness(value: Array<any>) {
        
        this._placesOfBusiness = value;
    }


    // Drží 'index' naposledy pridane pobocky
    private _placesOfBusinessIdx: number;

    constructor(private _uiService: UIService,
		private _modalService: ModalService,
        public contactsService: ContactsService) {

	}

	/**
	 * Inicializace komponenty
	 */
    ngOnInit() {
	}

    /**
    * Zobrazí dialog pro přidání nové pobocky.
    */
    addPlacesOfBusinessModal(contactId: number) {
    // Otevřeme modal
    this._modalService.openModal(new PlaceOfBusinessModal(contactId, this.parentId))
        .onApprove((result: any) => {
            if (result != null) {
                // Když bylo uloženo, re-načítám data

                this._placesOfBusiness.push({
                    code: result.code,
                    id: result.id,
                    name: result.name,
                    street: result.street,
                    $i: this._placesOfBusinessIdx++
                });

  //              this._propagateChange(this._placesOfBusiness);

            }
        });
    }

    /**
    * Zobrazí dialog pro editaci nové pobocky.
    */
    editPlacesOfBusinessModal(contactId: number) {
        // Otevřeme modal
		this._modalService.openModal(new PlaceOfBusinessModal(contactId, this.parentId))
            .onApprove((result: any) => {
                if (result != null) {

                    let editedItem = this._placesOfBusiness.filter(x => x.id == contactId)[0];
                    editedItem.street = result.street;
                    editedItem.code = result.code;
                    editedItem.name = result.name;
                    
//                    this._propagateChange(this._placesOfBusiness);

                }
            });
    }

    

    /**
    * Smazání
    * @param user
    */
    removePlacesOfBusiness(placeOfBusiness: any) {
              
        this._uiService
            .showConfirmation('Odstranění záznam', `Opravdu si přejete odstranit záznam ${placeOfBusiness.name}?`)
            .then(result => {
                if (result) {
                    this._uiService.showLoader();

                    this.contactsService.delete(placeOfBusiness.id).then(
                        result => {
                            this._uiService.hideLoader();

                            let removeItem = this._placesOfBusiness.filter(x => x.id == placeOfBusiness.id)[0];
                            this._placesOfBusiness = this._placesOfBusiness.filter(obj => obj !== removeItem);
                          //  this._propagateChange(this._placesOfBusiness);

                        });
                }
            });
      
    }



	//
	// Implementace ControlValueAccessor
	//

	private _propagateChange = (_: any) => { };
	private _propagateTouched = () => { };

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
	writeValue(value: any): void {
		if (value) {
			// musíme na provozovnu nastavit interní property, která drží unikátní hodnotu
			// a uložíme si hodnotu

            //console.log(value);

            this._placesOfBusiness = value.map(function (x, i) { x.$i = i; return x; })
            this._placesOfBusinessIdx = this._placesOfBusiness.length;
		}
	}

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}