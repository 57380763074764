import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIService, ModelFormComponentBase, HelperService, AppScopeService, AuthService, ModelServiceFactory, ValuesService } from '../../../core';

@Component({
    templateUrl: "/template/lists/commission-states/pages/commission-state-detail.cshtml"
})
export class ListsCommissionStateDetailPageComponent extends ModelFormComponentBase {
    constructor(
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
        protected _appScopeService: AppScopeService,
        private _valuesService: ValuesService,
		private _modelServiceFactory: ModelServiceFactory,
        protected _router: Router) {
		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_modelServiceFactory.createService('listCommissionState'),
			_router);
	}

	/**
	 * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
	 */
	getTitle() {
        return this.modelId === 0 ? 'Nový stav zakázky' : 'Editace stavu zakázky';
    }

    /**
     * Override metody v ModelFormComponentBase - po vložení redirektujeme na editaci
     * @param model
     */
    protected onModelSaved(model: any): void {
        this._valuesService.clearCacheItem("CommissionState");
        if (this.modelId === 0) {
            this._router.navigate(['app', 'list', 'commission-states', model.id], {});
        }
    }
}