import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { ListsRequiredSolutionModule } from './';

// Pages
import { ListsRequiredSolutionPageComponent } from './pages/required-solution-list';
import { ListsRequiredSolutionDetailPageComponent } from './pages/required-solution-detail';

const routes: Routes = [
    { path: '', component: ListsRequiredSolutionPageComponent },
    {
        path: ':id',
        component: ListsRequiredSolutionDetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        ListsRequiredSolutionModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ListsRequiredSolutionRoutingModule { }