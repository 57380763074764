import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    UIService, ApiService, ModelFormComponentBase, HelperService, AppScopeService,
    AuthService, ModelServiceFactory
} from '../../core';

@Component({
	templateUrl: "/template/contacts/pages/contact-detail.cshtml"
})
export class ContactDetailPageComponent extends ModelFormComponentBase {

    // drží 'index' naposledy přidané provozovny
    private _placesOfBusinessIdx: number;

    // ID portal garanta
    public aresIco: string = "";

    public isNew: boolean = true;

    /**
     * Instance model service
     */

    constructor(
		protected _router: Router,
        protected _activatedRoute: ActivatedRoute,
        private _apiService: ApiService,
        protected _uiService: UIService,
        protected _helperService: HelperService,
        protected _authService: AuthService,
        protected _appScopeService: AppScopeService,
        private _modelServiceFactory: ModelServiceFactory) {

		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_modelServiceFactory.createService('contact'),
			_router);
    }

    /**
	 * Inicializace komponenty
	 */
    ngOnInit() {

        // Prebirame IC z aresu

        this._activatedRoute.queryParams.subscribe(params => {
            if (params['aresIco'] != undefined)
                this.aresIco = params['aresIco'];
        })

        // Volám inicializaci bázové třídy
        super.ngOnInit();
    }
    
    /**
    * Override metody v ModelFormComponentBase - potřebujeme inicializovat provozovny
    * @param model
    */
    protected onModelLoaded(model: any) {
        // musíme na každou marži nastavit interní property, která drží unikátní hodnotu
        // a uložíme si hodnotu
        model.placesOfBusiness = model.placesOfBusiness.map(function (x, i) {
            x.$i = i;
            return x;
        });
        this._placesOfBusinessIdx = model.placesOfBusiness.length;

        this.isNew = (this.modelId == 0);

    }

    /**
     * Override metody v ModelFormComponentBase - po vložení redirektujeme na editaci
     */
    protected onModelSaved(model: any) {
        if (this.modelId == 0) {

            this._router.routeReuseStrategy.shouldReuseRoute = function () {
                return false;
            };

            this._router.navigate(['app', 'contacts', 'contacts', model.id], {});
            this.isNew = false;
        }
    }

    /**
     * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
     */
    public getTitle() {
        return this.isNew ? 'Novy klient' : 'Editace klienta';
    }

     /**
     * Modalu pro editaci pobocek predava ID kontaktu, ktery se aktualne edituje. 
     */
    public getParentId() {
        return this.modelId;
    }

    /**
     * Vrátí unikátní identitu pro daný díl. Nutné rpo správnou funkci ngFor.
     * @param item
     * @param index
     */
    placesOfBusinessIdentity(item: any, index: number): any {
        return item.$i;
    }

    /**
    * Override metody v ModelFormComponentBase - potřebujeme do API posílat query string s garantId
    */
    protected getApiCustomQuery(): any {
        return { aresIco: this.aresIco };
    }
}