import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { DevicePolicyModule } from './';

// Pages
import { ListsDevicePolicyPageComponent } from './pages/device-policy-list';
import { ListsDevicePolicyDetailPageComponent } from './pages/device-policy-detail';

const routes: Routes = [
	{ path: '', component: ListsDevicePolicyPageComponent },
	{
		path: ':id',
		component: ListsDevicePolicyDetailPageComponent,
		canDeactivate: [CanDeactivateGuard]
	}
];

@NgModule({
	imports: [
		DevicePolicyModule,
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class DevicePolicyRoutingModule { }