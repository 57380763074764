import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '../services/format';

@Pipe({
	name: 'ktCurrency'
})
export class CurrencyPipe implements PipeTransform {

	constructor(
		private _formatService: FormatService) {
	}

	transform(value: number): string {
		return this._formatService.currency(value);
	}
}