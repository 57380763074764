import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { RouterModule } from '@angular/router';

// Pages
import { ListsDeviceModelPageComponent } from './pages/device-model-list';
import { ListsDeviceModelDetailPageComponent } from './pages/device-model-detail';

@NgModule({
	imports: [
		CommonModule,
		CoreModule,
		FormsModule,
		SuiModule,
		RouterModule
	],
	exports: [
		ListsDeviceModelDetailPageComponent
	],
	declarations: [
		ListsDeviceModelPageComponent,
		ListsDeviceModelDetailPageComponent
	]
})
export class DeviceModelModule { }