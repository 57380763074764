import { ModelWrapper } from '../models';
import { Subscription } from 'rxjs';
import { CommissionPaymentType, DiscountType, Constants, HelperService } from '../../core';

export class PricingViewModel {
	//#region Definice handlerů změn hodnot

	private _valueChangedHandlers: { [path: string]: (value: any) => void } = {
		'payments': x => {
			this._setUpDeposit();
			this._recalculate();
		},
		'discountByType.value': x => {
			this._setUpDiscountByType();
			this._recalculate();
		},
		'individualDiscount.value': x => {
			this._setUpIndividualDiscount();
			this._recalculate();
		},
		'receiptTransport.price': x => {
			this._setUpTransport();
			this._recalculate();
        },
        'receiptTransport.enterInAccount': x => {
            this._setUpTransport();
            this._recalculate();
        },
		'issueTransport.price': x => {
			this._setUpTransport();
			this._recalculate();
        },
        'issueTransport.enterInAccount': x => {
            this._setUpTransport();
            this._recalculate();
        },
		'fees': x => {
			this._setUpFees();
			this._recalculate();
		},
		'operations': x => {
			this._setUpOperations();
			this._recalculate();
		},
		'parts': x => {
			this._setUpParts();
			this._recalculate();
		}
	};

	//#endregion

	private _subs: Array<Subscription> = [];

    /**
     * Sumář
     */
    public summary: ICommissionPriceSummary = {
        withoutVat: 0,
        withVat: 0,
        paid: 0,
        toBePaid: 0
    };

	/**
	 * Požadovaná záloha celkem
	 */
	public requestedDeposit: number = 0;

	/**
	 * Uhrazená záloha celkem
	 */
	public paidDeposit: number = 0;

	/**
	 * Položky souhrnu 
	 */
	public transport: SummaryItem = new SummaryItem(false);
	public operation: SummaryItem = new SummaryItem(false);
	public parts: SummaryItem = new SummaryItem(false);
	public fees: SummaryItem = new SummaryItem(false);
	public discountByType: SummaryItem = new SummaryItem(true);
	public individualDiscount: SummaryItem = new SummaryItem(true);

	// Položky souhrnu v poli
	private _summaryItems: Array<SummaryItem> = [
		this.transport,
		this.operation,
		this.parts,
		this.fees,
		this.discountByType,
		this.individualDiscount
	];

	constructor(
        private _helperService: HelperService,
        private _wrapper: ModelWrapper<ICommission>) {

        // Připnutí handlerů na změnu hodnot
        for (let path in this._valueChangedHandlers) {
            this._subs.push(
                this._wrapper.form.get(path).valueChanges.subscribe(this._valueChangedHandlers[path])
            );
        }

        this.update();
	}

	/**
	 * Aktualizuje celý view model
	 **/
	public update(): void {
		this._setUpDeposit();
		this._setUpFees();
		this._setUpOperations();
		this._setUpDiscountByType();
		this._setUpIndividualDiscount();
		this._setUpTransport();
		this._setUpParts();
		this._recalculate();
	}

	/**
	 * Destrukce 
	 */
	public destroy(): void {
		this._subs.forEach(x => x.unsubscribe());
		this._wrapper = null;
	}

	/**
	 * Přepočítá sumy
	 */
	private _recalculate(): void {

		// Nastavuju procentualni slevu podle typu
		if (this._wrapper.model.discountByType.type == DiscountType.Percentage) {
			// Suma všech položek které nejsou slevy
			let sumWithoutVat = this._summaryItems
				.filter(x => !x.isDiscount)
				.map(x => x.priceWithoutVat)
				.reduce((sum, x) => sum + x, 0);

			let discount = this._helperService.round(sumWithoutVat * (this._wrapper.model.discountByType.value / 100), 2);

			this.discountByType.setWithoutVat(-discount);
		}

		// Počítám sumy
		let sumWithoutVat = 0;
		let sumVat = 0;

		this._summaryItems.forEach(x => {
			sumWithoutVat += x.priceWithoutVat;
			sumVat += x.vat;
		});

		if (sumWithoutVat <= 0) {
			this.summary.withoutVat = 0;
			this.summary.withVat = 0;
		}
		else {
			this.summary.withoutVat = sumWithoutVat;
			this.summary.withVat = sumWithoutVat + sumVat;
		}

		// Počítáme uhrazeno
        let paid = 0;
        let toBePaid = 0;
        this._wrapper.model.payments.forEach(x => {
            toBePaid += x.requestedAmount;
			if (x.paidAmount > 0) {
				paid += x.paidAmount;
			}
		});

        this.summary.paid = paid;
        this.summary.toBePaid = toBePaid - paid;
	}

	/**
	 * Nastaví poplatky 
	 */
	private _setUpFees(): void {
		let sum = this._wrapper.model
            .fees
			.map(x => x.price)
			.reduce((x, y) => x + y, 0);

		this.fees.setWithoutVat(sum);
	}

	/**
	 * Nastaví výkony 
	 */
	private _setUpOperations(): void {
		let sum = this._wrapper.model
            .operations
			.map(x => x.price)
			.reduce((x, y) => x + y, 0);

		this.operation.setWithoutVat(sum);
	}

	/**
	 * Nastaví sumu záloh
	 **/
	private _setUpDeposit(): void {
		var requestedDeposit = 0;
		var paidDeposit = 0;

		// Iterujeme platby
		this._wrapper.model.payments.forEach(x => {
			if (x.paymentType == CommissionPaymentType.Deposit) {
				requestedDeposit += x.requestedAmount;
				paidDeposit += x.paidAmount;
			}
		});

		this.requestedDeposit = requestedDeposit;
		this.paidDeposit = paidDeposit;
	}

	/**
	 * Nastaví slevu dle druhu zakázky
	 **/
	private _setUpDiscountByType(): void {

		switch (this._wrapper.model.discountByType.type) {
			case DiscountType.None:
				this.discountByType.setWithoutVat(0);
				break;

			case DiscountType.PriceWithVat:
				this.discountByType.setWithoutVat(-this._wrapper.model.discountByType.value);
				break;

			case DiscountType.Percentage:
				// Procentualni se resi az pri prepoctu celeho
				break;

			default:
				throw 'Discount type ' + this._wrapper.model.discountByType.type + ' is not supported.';
		}
	}

	/**
	 * Nastaví individuální slevu
	 **/
	private _setUpIndividualDiscount(): void {
		let discount = this._wrapper.model.individualDiscount.value;
		let type = (discount == null || discount == 0) ? DiscountType.None : DiscountType.PriceWithVat;

		if (this._wrapper.model.individualDiscount.type != type) {
			this._wrapper.model.individualDiscount.type = type;
		}

		if (discount == null || discount == 0) {
			this.individualDiscount.setWithoutVat(0);
		}
		else {
			this.individualDiscount.setWithoutVat(-discount);
		}
	}

	/**
	 * Nastaví cenu dílů 
	 */
	private _setUpParts(): void {
		let sum = this._wrapper.model
			.parts
			.map(x => x.salePrice)
			.reduce((x, y) => x + y, 0);

		this.parts.setWithoutVat(sum);
	}

	/**
	 * Nastaví cenu dopravy 
	 */
    private _setUpTransport(): void {     
		let price = 0;

		if (this._wrapper.model.issueTransport.enterInAccount) {
			price += this._wrapper.model.issueTransport.price;
		}
		if (this._wrapper.model.receiptTransport.enterInAccount) {
			price += this._wrapper.model.receiptTransport.price;
		}

		this.transport.setWithoutVat(price);
	}
}

class SummaryItem {

	private _vatVal: number = 0;
	private _priceWithoutVat: number = 0;
	private _vat: number = 0;
	private _priceWithVat: number = 0;

	constructor(
		isDiscount: boolean) {
		this._vatVal = (100 + Constants.vatValue) / 100;
		this.isDiscount = isDiscount;
	}

	/**
	 * Info jestli to je sleva
	 */
	public isDiscount: boolean;

	/**
	 * Částka bez DPH.
	 */ 
	public get priceWithoutVat(): number {
		return this._priceWithoutVat;
	}

	/**
	 * DPH
	 */
	public get vat(): number {
		return this._vat;
	}

	/**
	 * Částka s DPH
	 */
	public get priceWithVat(): number {
		return this._priceWithVat;
	}

	public setWithVat(price: number) {
		this._priceWithVat = price;
		this._priceWithoutVat = this._round(price / this._vatVal);
		this._vat = this._round(this._priceWithVat - this._priceWithoutVat);
	}

	public setWithoutVat(price: number) {
		this._priceWithoutVat = price;
		this._priceWithVat = this._round(price * this._vatVal);
		this._vat = this._round(this._priceWithVat - this._priceWithoutVat);
	}

	private _round(value: number): number {
		return Math.round(value * 100) / 100;
	}
}