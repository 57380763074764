import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { SumUpModule } from './';

// Pages
import { SumUpSettingsPageComponent } from './pages/settings';

const routes: Routes = [
    {
        path: 'settings',
        component: SumUpSettingsPageComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
            appScope: 'Settings.Module'
        }
    }
];

@NgModule({
    imports: [
        SumUpModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SumUpRoutingModule { }