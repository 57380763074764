import { Directive, Input, HostListener, ElementRef, AfterContentInit } from '@angular/core';

/**
 * Direktiva, která zajistí, že pokud je na tagu prázdný atribut href, nebo obsahuje pouze hash,
 * pak po kliknutí nedojde k navigaci a klik se ignoruje
 */

@Directive({
	selector: '[href]'
})
export class PreventDefaultLinkDirective implements AfterContentInit {
	@Input() href;
	@HostListener('click', ['$event']) onClick(event) { this._preventDefault(event); }

	constructor(
		private _el: ElementRef) {
	}

	ngAfterContentInit() {
		if (this.href.length > 0 && this.href != '#') {
			let el = <HTMLLinkElement>this._el.nativeElement;
			el.href = this.href;
		}
	}

	private _preventDefault(event): void {
		if (this.href.length === 0 || this.href === '#') {
			event.preventDefault();
		}
	}
}