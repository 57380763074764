import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { CommissionStateModule } from './';

// Pages
import { ListsCommissionStatePageComponent } from './pages/commission-state-list';
import { ListsCommissionStateDetailPageComponent } from './pages/commission-state-detail';

const routes: Routes = [
	{ path: '', component: ListsCommissionStatePageComponent },
	{
		path: ':id',
		component: ListsCommissionStateDetailPageComponent,
		canDeactivate: [CanDeactivateGuard]
	}
];

@NgModule({
	imports: [
		CommissionStateModule,
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class CommissionStateRoutingModule { }