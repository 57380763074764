import { Inject, Input, Component, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploader, FileItem, FileLikeObject, ParsedResponseHeaders } from 'ng2-file-upload';
import { ApiService } from '../services/api';
import { UIService } from '../services/ui';
import { AppSettings } from '../services/app-settings';
import { saveAs } from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Seznam příloh k zakázkám apod.
 */

@Component({
	selector: 'div.kt-attachments',
	templateUrl: "/template/core/components/attachments.cshtml",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AttachmentsComponent),
			multi: true
		}
	]
})
export class AttachmentsComponent implements OnInit, OnDestroy, ControlValueAccessor {
	public uploader: FileUploader;
	public loadingAll: boolean = false;
	public disabled: boolean = false;

	@Input() downloadAllFileName: string;
	@Input() columnsCountText: string = 'six';

	private _attachments: Array<IComponentAttachment> = [];

	constructor(
		private _apiService: ApiService,
		private _uiService: UIService,
		private _domSanitizer: DomSanitizer,
		private _appSettings: AppSettings) {

		// inicializace uploaderu
		this.uploader = new FileUploader({
			url: _apiService.createUrl('files'),
			autoUpload: true,
			authToken: 'Bearer ' + _apiService.token,
			maxFileSize: _appSettings.maxFileSize
		});

		this.uploader.onCompleteItem = this._onCompleteItem.bind(this);
		this.uploader.onWhenAddingFileFailed = this._onWhenAddingFileFailed.bind(this);
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	/**
	 * Zrušení komponenty
	 */
	ngOnDestroy() {
	}

	/**
	 * Stáhne všechny soubory v zipu
	 */
	public downloadAll(): void {
		if (this.loadingAll) {
			return;
		}

		this.loadingAll = true;

		var filePaths = this.getAttachments()
			.filter(x => !x.temporary)
			.map(x => x.filePath);

		this._apiService
			.postGetBlob('files/download', filePaths)
			.then(
				blob => {
					saveAs(blob, this.downloadAllFileName + '_attachments.zip');

					this.loadingAll = false;
				},
				reason => {
					this.loadingAll = false;
				});
	}

	/**
	 * Vrací info jestli je tlačítko Stáhnout vše viditelné
	 */
	public downloadAllVisible(): boolean {
		return this.getAttachments().filter(x => !x.temporary).length > 0;
	}

	/**
	 * Vrátí celkový seznam příloh - ty načtené z API + nově přidané
	 */
	public getAttachments(): Array<IComponentAttachment> {
		let attachments = this._attachments || [];
		let uploaded = this.uploader.queue.map(x => new UploadedFileAttachment(x));

		return attachments.concat(uploaded);
	}

	/**
	 * Vrátí příponu pro přílohy
	 * @param attachment
	 */
	public getExtension(attachment: IComponentAttachment) {
		let idx = attachment.fileName.lastIndexOf('.');
		if (idx == -1) {
			return '?';
		}

		return attachment.fileName.substr(idx);
	}

	/**
	 * Vytvoří definici stylu pro background image.
	 * @param url
	 */
	public createBackgroundUrl(url: string) {
		return 'url("' + url.replace(' ', '%20') + '")';
	}

	/**
	 * Odebere přílohu ze seznamu
	 * @param attachment
	 */
	public removeAttachment(attachment: any) {
		let idx = this._attachments.indexOf(attachment);

		if (idx > -1) {
			this._attachments.splice(idx, 1);

			this._propagateChange(this._attachments);
		}
	}

	/**
	 * Obsluhuje událost nahrání souboru na API
	 * @param item
	 * @param response
	 * @param status
	 * @param headers
	 */
	private _onCompleteItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

		if (item.isSuccess) {
			// odebereme nahraný file z fronty ...
			this.uploader.removeFromQueue(item);

			// ... a přidáme výsledek z api do seznamu příloh
			let attachments = <Array<any>>JSON.parse(response);

			this._attachments.push({
				fileName: attachments[0].fileName,
				filePath: attachments[0].filePath,
				thumbnailUrl: attachments[0].thumbnailUrl,
				url: attachments[0].url,
				progress: 0,
                temporary: true,
                id: attachments[0].id
			});

			this._propagateChange(this._attachments);
		}

		return { item, response, status, headers };
	}

	/**
	 * Obsluhuje událost chyby při přidání souboru do fronty
	 * @param item
	 * @param filter
	 * @param options
	 */
	private _onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any): any {
		if (filter.name == 'fileSize') {
			this._uiService.showError(
				'Maximální povolená velikost souboru je ' + (this._appSettings.maxFileSize / 1000000) + 'MB',
				'Soubor nelze nahrát');
		}

		return { item, filter, options };
	}

	//
	// Implementace ControlValueAccessor
	//

	private _propagateChange = (_: any) => { };
	private _propagateTouched = () => { };

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
	writeValue(value: any): void {
		this._attachments = value;
	}

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}

/**
 * 
 */
interface IComponentAttachment extends IAttachment {
	progress: number;
}

/**
 * Implementace IComponentAttachment pro zobrazení nahrávaných souborů v seznamu.
 */
export class UploadedFileAttachment implements IComponentAttachment {
	constructor(private _item: FileItem) {
		this.fileName = _item.file.name;
		this.filePath = null;
		this.thumbnailUrl = '';
		this.url = '';
        this.temporary = true;
        this.id = 0;
	}

	fileName: string;
	filePath: string;
	thumbnailUrl: string;
	url: string;
	get progress(): number { return this._item.progress; }
	set progress(value: number) { }
    temporary: boolean;
    id: number;
}