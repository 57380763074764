import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';

// Pages
import { ListsRequiredSolutionPageComponent } from './pages/required-solution-list';
import { ListsRequiredSolutionDetailPageComponent } from './pages/required-solution-detail';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        FormsModule,
        SuiModule
    ],
    exports: [
        RouterModule
    ],
    declarations: [
        ListsRequiredSolutionPageComponent,
        ListsRequiredSolutionDetailPageComponent
    ],
    providers: [
    ],
    entryComponents: [
    ]
})
export class ListsRequiredSolutionModule { }