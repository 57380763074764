
export class CloseApprovedCommissionsModel {
	fiscalMonthDate: Date = null;
}

export class CommissionBySerialNumberModel {
	id: number;
	number: string;
	garantId: number;
	dateOfReceiving: string;
}