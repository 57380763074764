import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent, IModelService, ModelServiceFactory } from '../../../core';

@Component({
	templateUrl: "/template/settings/branch/pages/branch-list.cshtml"
})
export class BranchListPageComponent {
	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * Instance model service
	 */
	public modelService: IModelService;

	constructor(
		private _router: Router,
		private _uiService: UIService,
		private _modelServiceFactory: ModelServiceFactory) {

		this.modelService = _modelServiceFactory.createService('branch');
	}

	/**
	 * Redirekt na stránku pro přidání.
	 */
    add() {
		this._router.navigateByUrl('/app/settings/branches/0');
    }

    /**
	 * Smazání
	 * @param branch
	 */
	delete(branch: any) {
		this._uiService
			.showConfirmation('Odstranění skupiny', 'Opravdu si přejete odstranit skupinu ' + branch.name + '?')
			.then(result => {
				if (result) {
					this._uiService.showLoader();

					this.modelService.delete(branch.id, null).then(
						result => {
                            this._uiService.hideLoader();
                            this._grid.loadData();
						});
				}
			});
    }

	/**
	 * Redirekt na stránku s editací
	 */
	edit(item: any) {
        this._router.navigateByUrl('/app/settings/branches/' + item.id);
	}
}