import { Injectable, EventEmitter } from '@angular/core';
import { LocationChangeEvent, PlatformLocation } from '@angular/common';

/**
 * Posktyuje metody pro location
 */
@Injectable()
export class LocationService {
	// Event emitter pro location change event
	public popState: EventEmitter<LocationChangeEvent> = new EventEmitter();

	constructor(
		private _location: PlatformLocation) {

		_location.onPopState(this._onPopState.bind(this))
	}

	/**
	 * Naviguje zpět 
	 */
	public back(): void {
		this._location.back();
	}

	/**
	 * Pushne state
	 * @param state
	 * @param title
	 * @param url
	 */
	public pushState(state: any, title: string, url: string): void {
		this._location.pushState(state, title, url);
	}

	/**
	 * Handluje popState event
	 * @param event
	 */
	private _onPopState(event: LocationChangeEvent): void {
		this.popState.next(event);
	}
}