import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    UIService, ApiService, ModelFormComponentBase, HelperService, AppScopeService, AuthService, ModelServiceFactory,
    ProjectEnumService, 
    ProjectEnumType} from '../../core';
import { TransportCarrierCollectionService } from '../services/transportcarriercollection';

@Component({
    templateUrl: "/template/logistics/pages/transportcarriercollection-detail.cshtml"
})
export class TransportCarrierCollectionDetailPageComponent extends ModelFormComponentBase {

    /**
     * Instance model service
     */

    constructor(
		protected _router: Router,
        protected _activatedRoute: ActivatedRoute,
        private _apiService: ApiService,
        protected _uiService: UIService,
        private _transportCarrierCollectionService: TransportCarrierCollectionService,
        protected _helperService: HelperService,
        protected _authService: AuthService,
        protected _appScopeService: AppScopeService,
        private _modelServiceFactory: ModelServiceFactory,
        private _projectEnumService: ProjectEnumService) {

		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_modelServiceFactory.createService('transportcarriercollection'),
			_router);
    }

    /**
	 * Inicializace komponenty
	 */
    ngOnInit() {
        // Volám inicializaci bázové třídy
        super.ngOnInit();

   }


    allowSubmit() {

        let superResult = super.allowSubmit();

        return superResult; 

    };

    public isReclamationPoZaruce(): boolean {
        return this.model.collectionReclamationTypeId == this.model.collectionReclamationTypePoZaruceId;
    }

    public allowSubmitSaveAndSend(): boolean {
        if (this.model.isPossibleSend &&
            this.model.transportCarrierId != null &&
            this.model.collectionDate != null &&
            this.model.collectionTime != null &&
            this.model.collectionTime != ""
        ) {
            return true;
        } else {
            return false;
        }
    }

    public allowSubmitSaveAndStorno(): boolean {

        if (this.model.isPossibleSend) {
            return true;
        } else {
            return false;
        }
    }

    public allowSubmitCreateCommission(): boolean {
        if (this.model.commissionRefenceNumber == "") {
            return true;
        } else {
            return false;
        }
    }

    public allowCommissionRedirect(): boolean {
        if (this.model.commissionRefenceNumber == "") {
            return false;
        } else {
            return true;
        }
    }

    public commisionRedirect(): void {
        let urlTree = this._router.createUrlTree(['app', 'commissions', this.model.commissionRefenceId]);
        let url = '#' + this._router.serializeUrl(urlTree);
        window.open(url, "_blank");
    }
    
    /**
     * Uložení
     */
    public saveAndSend() {
        this.model.SaveMode = 1;
        super.save();
    }

    public saveAndStorno() {
        this.model.SaveMode = 2;
        super.save();
    }

    public saveAndCreateCommission() {
        this.model.SaveMode = 3;
        super.save();
    }

    public checkState() {
        this._transportCarrierCollectionService.checkOrderStatus(this.model.id).then(
            result => {
                this.model.transportCarrierState = result.retval;
            })
        
    }


    public allowSubmitCheckState(): boolean {
        if (!this.model.isPossibleSend) {
            return true;
        } else {
            return false;
        }
    }


    /**
     * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
     */
    public getTitle() {
        return 'Objednávka';
    }

   
}