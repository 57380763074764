import { Directive, ElementRef, OnInit, OnDestroy, Host } from '@angular/core';
import { NgForm, AbstractControl, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth';
import { AppScopeService } from '../services/app-scope';
import { ScopePermissionType } from '../models';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Directiva která poskytuje funkcionlitu pro jeden konkrétní formulář.

@Directive({
	selector: '[kt-form]',
	exportAs: 'ktForm'
})
export class FormDirective implements OnInit, OnDestroy  {

	private _subs: Array<Subscription> = [];
	private _currentPermissionType: ScopePermissionType;

	constructor(
		private _el: ElementRef,
		@Host() private _form: NgForm,
		private _authService: AuthService,
		private _appScopeService: AppScopeService,
		private _activatedRoute: ActivatedRoute) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		// Pokud nemáme aktuální app scope, pak nic
		if (!this._appScopeService.currentScope) {
			return;
		}

		var initSubscription = this._form.valueChanges.pipe(debounceTime(500)).subscribe(x => {
			// Odhlášení
			initSubscription.unsubscribe();

			this._subs.push(this._activatedRoute.params.subscribe(x => {
				// Získávám z routy id parameter, abych mohl rozhodnout jestli je update nebo create
				let id = this._activatedRoute.snapshot.params['id'];
				if (id) {
					this._currentPermissionType = id == '0' ? ScopePermissionType.Create : ScopePermissionType.Update;
				}
				else {
					throw 'Missing required route parameter "id"';
				}

				// Proces rekurzivně 
				let scopeBuilder = new ScopeBuilder(this._appScopeService.currentScope);
				//this._processControls(this._form.controls, scopeBuilder);
			}));

		});
	}

	/**
	 * Událost destrukce direktivy.
	 */
	ngOnDestroy() {
		// Odhlásíme ze všech observables
		this._subs.forEach(x => x.unsubscribe());
	}

	private _processControls(controls: { [key: string]: AbstractControl }, scopeBuilder: ScopeBuilder) {
		for (let key of Object.keys(controls)) {
			let control = controls[key];

			// Proces FormGroup
			if (control instanceof FormGroup) {
				scopeBuilder.push(key);

				this._processControls(control.controls, scopeBuilder);

				scopeBuilder.pop();
			}

			// Proces FormControl
			if (control instanceof FormControl) {
				let controlScope = scopeBuilder.createScope(key);

				if (!this._authService.hasAnyAppScopePermission(controlScope, this._currentPermissionType)) {
					control.disable({ emitEvent: false });
				}
				else {
					control.enable({ emitEvent: false });
				}
			}
		}
	}
}

class ScopeBuilder {
	constructor(
		private scope: string) {
	}

	public push(name: string) {
		this.scope += '.' + name;
	}

	public pop() {
		let idx = this.scope.lastIndexOf('.');
		this.scope = this.scope.substr(0, idx);
	}

	public createScope(name: string): string {
		return this.scope + '.' + name;
	}
}