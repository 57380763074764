import {
	Component,
	forwardRef,
	Input,
	EventEmitter,
	Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'span.kt-notifications-switch',
	templateUrl: "/template/core/components/notifications-switch.cshtml",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NotificationsSwitchComponent),
			multi: true
		}
	]
})
export class NotificationsSwitchComponent implements ControlValueAccessor {
	/**
	 * Drží hodnoty vybraných voleb.
	 */
	private _value: boolean;

	// Getter a setter pro _value
	get value(): boolean {
		return this._value;
	}
	set value(value: boolean) {
		this._value = value;

		this.valueChange.emit(value);
		this._propagateChange(value);
	}

	@Input() public disabled: boolean = false;

	@Output('onValueChange') public valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	/**
	 * Zpracovává kliknutí na komponentu
	 **/
	public onClick(): void {
		if (this.disabled) {
			return;
		}

		this.value = !this.value;
	}

	//
	// Implementace ControlValueAccessor
	//

	private _propagateChange = (_: any) => { };
	private _propagateTouched = () => { };

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
	writeValue(value: any): void {
		this._value = value;
	}

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}