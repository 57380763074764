import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommissionViewModel } from '../services/commission-view-model';
import { SectionComponentBase } from '../classes/section-component-base';
import { DataStorageService } from '../../core';
import { FormGroup } from '@angular/forms';

/**
 * Sekce Textace
 */

@Component({
	selector: 'div.kt-texts-section',
	templateUrl: "/template/commissions/components/texts-section.cshtml"
})
export class TextsSectionComponent extends SectionComponentBase implements OnInit, OnDestroy {
	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._viewModel.form;
	};

	constructor(
		private _viewModel: CommissionViewModel,
		protected _dataStorageService: DataStorageService) {

		super(_dataStorageService, 'texts');
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	/**
	 * Destroy komponenty 
	 **/
	ngOnDestroy() {
	}
}