import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { ListsOperationTypeModule } from './';

// Pages
import { ListsOperationTypePageComponent } from './pages/operation-type-list';
import { ListsOperationTypeDetailPageComponent } from './pages/operation-type-detail';

const routes: Routes = [
    { path: '', component: ListsOperationTypePageComponent },
    {
        path: ':id',
        component: ListsOperationTypeDetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        ListsOperationTypeModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ListsOperationTypeRoutingModule { }