import { Injectable, Inject } from '@angular/core';
import { SectionItem } from '../models';
import { ApiService } from './api';
import { ModelService } from './model-service';
import { UIService } from './ui';
/**
 * Posktyuje různé metody pro core funkcionlitu
 */
@Injectable()
export class CoreService extends ModelService {

    constructor(
        apiService: ApiService,
        uiService: UIService) {
        // Voláme konstruktor bázové třídy
        super('core', apiService, uiService);
    }


    public getNavbarModel(): Promise<Array<SectionItem>> {

        return new Promise((resolve, reject) => {
            this.apiService.post<Array<SectionItem>>('core/list', "").then(
                result => {
                    resolve(result);
                },
                error => {
                    reject();
                });
        });
    }


}
