import { Component, OnInit } from '@angular/core';

@Component({
	templateUrl: "/template/svt/pages/philips.cshtml"
})
export class PhilipsPageComponent {

	/**
	 * Vrací nadpis pro stránku 
	 */
	getTitle() {
		return 'Philips SVT';
	}

}