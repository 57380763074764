import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges,
    ElementRef,
    HostListener,
    forwardRef,
    AfterViewInit,
    Renderer2,
    OnDestroy
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { eachDay, isToday, isSameDay, isSameMonth, isSameYear, format } from 'date-fns';
import { ISlimScrollOptions } from 'ngx-slimscroll';
import { UIService } from '../services/ui';
import * as csLocale from 'date-fns/locale/cs';
import * as moment from 'moment';

export interface DatepickerOptions {
    view: string; // default: 'days'
    allowedViews: Array<string>; // default: ['days', 'months', 'years']
    minYear?: number; // default: current year - 30
    maxYear?: number; // default: current year + 30
    displayFormat?: string; // default: 'MMM D[,] YYYY'
    titleFormat?: string; // default: 'MMMM YYYY'
    monthTitleFormat?: string; // default 'YYYY'
    monthFormat?: string; // default 'MMMM'
    firstCalendarDay?: number; // 0 = Sunday (default), 1 = Monday, ..
    locale?: object;
    minDate?: Date;
    maxDate?: Date;
}

/**
 * Internal library helper that helps to check if value is empty
 * @param value
 */
const isNil = (value: Date | DatepickerOptions) => {
    return (typeof value === 'undefined') || (value === null);
};

@Component({
    selector: 'ng-datepicker',
    templateUrl: "/template/core/components/datepicker.cshtml",
    exportAs: 'datepicker',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerComponent),
            multi: true
        }
    ]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() options: DatepickerOptions;

	/**
	 * Disable datepicker's input
	 */
    @Input() headless = false;

	/**
	 * Set datepicker's visibility state
	 */
    @Input() isOpened = false;

	/**
	 * Datepicker dropdown position
	 */
    @Input() position = 'bottom-right';

	/**
	 * Možné hodnoty pro pozici kalendáře
	 */
    private _positions = ['bottom-left', 'bottom-right', 'top-left', 'top-right'];

	/**
	 * Aktuální datum pro kalendář
	 */
    private _date: Date;

	/**
	 * Listener pro událost klinutí na dokument
	 */
    private _documentClickListener: () => void;

	/**
	 * hodnota controlu
	 */
    private _value: Date;

    view: string;
    allowedViews: Array<string>;
    minYear: number;
    maxYear: number;
    displayFormat: string;
    titleFormat: string;
    monthTitleFormat: string;
    monthFormat: string;
    firstCalendarDay: number;
    locale: object;

    years: { year: number, isThisYear: boolean }[];
    dayNames: string[];

    displayValue: string;
    title: string;
    scrollOptions: ISlimScrollOptions;
    @Input() public disabled: boolean = false;

    // Definice dnů které budou vykresleny
    public days: Array<IDay>;

    // Definice měsíců které budou vykresleny
    public months: Array<IMonth>;

    // Get and Set pro .value property
    public get value(): Date {
        return this._value;
    }
    public set value(val: Date) {
        //console.log(val);
        this._value = val;
        this._propagateChange(this._value);
    }

    constructor(
        private _elementRef: ElementRef,
        private _renderer: Renderer2,
        private _uiService: UIService,
    ) {
        this.scrollOptions = {
            barBackground: '#DFE3E9',
            gridBackground: '#FFFFFF',
            barBorderRadius: '3',
            gridBorderRadius: '3',
            barWidth: '6',
            gridWidth: '6',
            barMargin: '0',
            gridMargin: '0'
        };
    }

	/**
	 * Inicializace komponenty
	 */
    ngOnInit() {
        this._setOptions();

        // Pokud máme zobrazení pouze měsíců, 
        // pak bude vybrané datum vždy první den v měsíci
        if (this.view === 'months' && this.allowedViews.length == 1) {
            this._date = moment(new Date()).date(1).toDate();
        }
        else {
            this._date = new Date();
        }

        this._initDayNames();
        this._initMonths();
        this._initYears();

        // Check if 'position' property is correct
        if (this._positions.indexOf(this.position) === -1) {
            throw new TypeError(`ng-datepicker: invalid position property value '${this.position}' (expected: ${this._positions.join(', ')})`);
        }

        this._init();
    }

	/**
	 * Destrukce komponenty
	 */
    ngOnDestroy() {
        if (this._documentClickListener) {
            this._documentClickListener();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('options' in changes) {
            this._setOptions();
            this._initDayNames();
            this._init();
            this._initMonths();
            this._initYears();
        }
    }

	/**
	 * Po inicializaci view
	 */
    ngAfterViewInit() {
        this._documentClickListener = this._renderer.listen(
            this._findRootElement(this._elementRef),
            'click',
            x => this._onDocumentClick(x)
        );
    }

	/**
	 * Zpracuje nastavení controlu
	 */
    private _setOptions(): void {
        const today = new Date(); // this const was added because during my tests, I noticed that at this level this.date is undefined

        this.view = this.options && this.options.view || 'days';
        this.allowedViews = this.options && this.options.allowedViews || ['days', 'months', 'years'];
        this.minYear = this.options && this.options.minYear || moment(today).year() - 30;
        this.maxYear = this.options && this.options.maxYear || moment(today).year() + 30;
        this.displayFormat = this.options && this.options.displayFormat || 'D. M. YYYY';
        this.titleFormat = this.options && this.options.titleFormat || 'MMMM YYYY';
        this.monthTitleFormat = this.options && this.options.monthFormat || 'YYYY';
        this.monthFormat = this.options && this.options.monthFormat || 'MMMM';
        this.firstCalendarDay = this.options && this.options.firstCalendarDay || 1;
        this.locale = (this.options && this.options.locale) ? { locale: this.options.locale } : { locale: csLocale };
    }

	/**
	 * Přepne na další měsíc/rok
	 */
    public next(): void {
        if (this.view === 'months') {
            this._date = moment(this._date).add(1, 'y').toDate();
        }
        else {
            this._date = moment(this._date).add(1, 'M').toDate();
        }

        this._init();
        this._initMonths();
    }

	/**
	 * Přepne na předchozí měsíc/rok
	 */
    public prev(): void {
        if (this.view === 'months') {
            this._date = moment(this._date).subtract(1, 'y').toDate();
        }
        else {
            this._date = moment(this._date).subtract(1, 'M').toDate();
        }

        this._init();
        this._initMonths();
    }

	/**
	 * Nastaví zvolené datum
	 * @param i
	 */
    public setDate(i: number): void {
        this._date = this.days[i].date;
        this.value = this._date;
        this._init();
        this._close();
    }

	/**
	 * Nastaví vybraný měsíc v aktuálním roce
	 * @param i
	 */
    public setMonth(i: number): void {
        this._date = moment(this._date).month(i).toDate();
        this.value = this._date;
        this._init();
        this._initMonths();
        this._close();
    }

    setYear(i: number): void {
        this._date = moment(this._date).year(this.years[i].year).toDate();
        this._init();
        this._initMonths();
        this._initYears();
        this.view = 'days';
    }

    toggleView(): void {
        // Dočasně zrušeno
        // this.view = this.view === 'days' ? 'years' : 'days';
    }

    toggle(): void {
        this.isOpened = !this.isOpened;
    }

    setMinDate(date?: Date): void {
        this.options.minDate = date;

        this._initDayNames();
        this._initMonths();
        this._initYears();
    }

    setMaxDate(date?: Date): void {
        this.options.maxDate = date;

        this._initDayNames();
        this._initMonths();
        this._initYears();
    }

	/**
	 * Zpracovává blur pro input
	 */
    public onInputBlur(event) {
        this._processInputValue();
    }

	/**
	 * Zpracovává ficus událost pro input
	 */
    public onInputFocus() {
        this._open();
    }

	/**
	 * Zpracovává keydown událost inputu
	 * @param event
	 */
    public onInputKeyDown(event: KeyboardEvent) {
        if (event.keyCode == 13 || event.keyCode == 9) {
            this._processInputValue();
            this._close();
        }
    }

	/**
	 * Nastaví text zobrazený v inputu
	 * @param date
	 */
    private setDisplayValue() {
        this.displayValue = this._value ? format(this._value, this.displayFormat, this.locale) : '';
    }

	/**
	 * Zavře kalendář
	 */
    private _close(): void {
        this.isOpened = false;
    }

	/**
	 * Checks if specified date is in range of min and max dates
	 * @param date
	 */
    private _isDateSelectable(date: Date): boolean {
        if (isNil(this.options)) {
            return true;
        }

        const minDateSet = !isNil(this.options.minDate);
        const maxDateSet = !isNil(this.options.maxDate);
        const timestamp = date.valueOf();

        if (minDateSet && (timestamp < this.options.minDate.valueOf())) {
            return false;
        }

        if (maxDateSet && (timestamp > this.options.maxDate.valueOf())) {
            return false;
        }

        return true;
    }

	/**
	 * Checks if specified month is in range of min and max dates
	 * @param month
	 * @param year
	 */
    private _isMonthSelectable(month: number, year: number): boolean {
        if (isNil(this.options)) {
            return true;
        }

        const minDateSet = !isNil(this.options.minDate);
        const maxDateSet = !isNil(this.options.maxDate);

        if (minDateSet) {
            if (year < this.options.minDate.getFullYear()) {
                return false;
            }

            if (year == this.options.minDate.getFullYear() && month < this.options.minDate.getMonth()) {
                return false;
            }
        }

        if (maxDateSet) {
            if (year > this.options.maxDate.getFullYear()) {
                return false;
            }

            if (year == this.options.maxDate.getFullYear() && month > this.options.maxDate.getMonth()) {
                return false;
            }
        }

        return true;
    }

	/**
	 * Inicializace kontrolu
	 */
    private _init(): void {
        const start = moment(this._date).startOf('M').toDate();
        const end = moment(this._date).endOf('M').toDate();

        this.days = eachDay(start, end).map(date => {
            const m = moment(date);

            return {
                date: date,
                day: m.date(),
                month: m.month(),
                year: m.year(),
                inThisMonth: true,
                isToday: isToday(date),
                isSelected: isSameDay(date, this._value) && isSameMonth(date, this._value) && isSameYear(date, this._value),
                isSelectable: this._isDateSelectable(date)
            };
        });

        var add = moment(start).day() - this.firstCalendarDay;
        if (add < 0) {
            add = 7 + add;
        }

        for (let i = 1; i <= add; i++) {
            const date = moment(start).subtract(i, 'd').toDate();
            const m = moment(date);

            this.days.unshift({
                date: date,
                day: m.date(),
                month: m.month(),
                year: m.year(),
                inThisMonth: false,
                isToday: isToday(date),
                isSelected: isSameDay(date, this._value) && isSameMonth(date, this._value) && isSameYear(date, this._value),
                isSelectable: this._isDateSelectable(date)
            });
        }

        let daysCount = this.days.length;
        if (daysCount % 7 > 0) {
            let toAdd = (Math.ceil(daysCount / 7) * 7) - daysCount;

            for (let i = 1; i <= toAdd; i++) {
                const date = moment(end).add(i, 'd').toDate();
                const m = moment(date);

                this.days.push({
                    date: date,
                    day: m.date(),
                    month: m.month(),
                    year: m.year(),
                    inThisMonth: false,
                    isToday: isToday(date),
                    isSelected: isSameDay(date, this._value) && isSameMonth(date, this._value) && isSameYear(date, this._value),
                    isSelectable: this._isDateSelectable(date)
                });
            }
        }

        this.setDisplayValue();

        this._setTitle();
    }

	/**
	 * Inicializuje názvy dnů
	 */
    private _initDayNames(): void {
        this.dayNames = [];
        const start = this.firstCalendarDay;

        for (let i = start; i <= 6 + start; i++) {
            const date = moment(new Date()).day(i).toDate();
            this.dayNames.push(format(date, 'dd', this.locale));
        }
    }

	/**
	 * Inicializuje seznam měsíců
	 */
    private _initMonths(): void {
        this.months = [];

        const thisMonth = moment(new Date());
        const selectedMonth = moment(this._value);

        for (var i = 0; i <= 11; i++) {
            const date = moment(this._date).month(i).date(1);

            this.months.push({
                month: format(date.toDate(), this.monthFormat, this.locale),
                isThisMonth: thisMonth.month() == date.month() && thisMonth.year() == date.year(),
                isSelected: selectedMonth.month() == date.month() && selectedMonth.year() == date.year(),
                isSelectable: this._isMonthSelectable(date.month(), date.year())
            });
        }

        //console.log(this.months);
    }

    /**
     * Inicializuje seznam roků 
     **/
    private _initYears(): void {
        const range = this.maxYear - this.minYear;

        this.years = Array.from(new Array(range), (x, i) => i + this.minYear).map(year => {
            return {
                year: year,
                isThisYear: year === moment(this._date).year()
            };
        });
    }

	/**
	 * Vrací jestli je dané datum nastaveno nebo ne
	 * @param date
	 */
    private _isSet(date: Date): boolean {
        return date != null && typeof (date) !== 'undefined';
    }

	/**
	 * Otevře kalendář
	 */
    private _open(): void {
        this.isOpened = true;
    }

	/**
	 * V reakci na blur nebo enter v inputu zprocesujeme hodnotu z inputu
	 */
    private _processInputValue() {
        // Zkusím zparsovat
        let m = moment(this.displayValue.trim(), this.displayFormat);

        if (!m.isValid()) {
            this.value = null;
        } else if (m.year() < this.minYear || m.year() > this.maxYear) {
            // Nesouhlasí min/max rok
            this.value = null;
            this._uiService.showError(`Zadané datum není platné. Zadejte datum v rozsahu 1.1.${this.minYear} až 31.12.${this.maxYear}.`, 'Chybné datum.');
        } else {
            if (this._isSet(this.value)) {
                let vm = moment(this.value);

                // Pokud je datum na základě hodnoty v inputu stejné jako aktuální hodnota
                // Kontroluju pouze datum, ne čas
                if (m.date() == vm.date() && m.month() == vm.month() && m.year() == vm.year()) {
                    return;
                }
            }

            this.value = m.toDate();
        }

        this.setDisplayValue();
    }

	/**
	 * Nastaví titulek kalendáře
	 */
    private _setTitle() {
        if (this.view === 'months') {
            this.title = format(moment(this._date).startOf('M').toDate(), this.monthTitleFormat, this.locale);
        }
        else {
            this.title = format(moment(this._date).startOf('M').toDate(), this.titleFormat, this.locale);
        }
    }

	/**
	 * Zpracovává událost kliknutí v dokumentu - skryje panel s volbami.
	 * @param event
	 */
    private _onDocumentClick(e: MouseEvent) {
        if (!this.isOpened) {
            return;
        }

        const input = this._elementRef.nativeElement.querySelector('input');

        if (input == null) {
            return;
        }

        if (e.target === input || input.contains(<any>e.target)) {
            return;
        }

        const container = this._elementRef.nativeElement.querySelector('.popup');
        if (container && container !== e.target && !container.contains(<any>e.target) && !(<any>e.target).classList.contains('year-unit')) {
            this._close();
        }
    }

	/**
	 * Vrací root-ový element pro element komponenty.
	 * Řeší to problém s modalem - modal má stopPropagation na click
	 * @param element
	 */
    private _findRootElement(element: ElementRef): any {
        var parent = element.nativeElement.parentNode;

        while (parent.parentNode) {
            if (parent.className.indexOf('content') > -1 && parent.parentNode.className.indexOf('modal') > -1) {
                parent = parent.parentNode;
                break;
            }

            parent = parent.parentNode;
        }

        return parent;
    }

    //
    // Implementace ControlValueAccessor
    //

    private _propagateChange = (_: any) => { };
    private _propagateTouched = () => { };

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
    writeValue(value: any): void {
        //console.log(value);
        if (value) {
            this._date = value;
            this._value = value;

            this._init();

            this.setDisplayValue();

            this._setTitle();
        }
        else {
            this._value = null;

            this.setDisplayValue();
        }
    }

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
    registerOnChange(fn: any): void {
        this._propagateChange = fn;
    }

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
    registerOnTouched(fn: any): void {
        this._propagateTouched = fn;
    }

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}

interface IDay {
    date: Date,
    day: number,
    month: number,
    year: number,
    inThisMonth: boolean,
    isToday: boolean,
    isSelected: boolean,
    isSelectable: boolean
}

interface IMonth {
    month: string,
    isThisMonth: boolean,
    isSelected: boolean,
    isSelectable: boolean
}