import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';

// Pages
import { ListsTransportCarrierPageComponent } from './pages/transport-carrier-list';
import { ListsTransportCarrierDetailPageComponent } from './pages/transport-carrier-detail';

// Services
import { TransportCarriersService } from './services/transport-carriers';

// Exports
export { TransportCarriersService } from './services/transport-carriers';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		CoreModule,
		FormsModule,
		SuiModule
	],
	declarations: [
		ListsTransportCarrierPageComponent,
		ListsTransportCarrierDetailPageComponent
	],
	providers: [
		TransportCarriersService
	]
})
export class TransportCarrierModule { }