import { Component, OnInit, OnDestroy } from '@angular/core';
import { } from '../services/commissions';
import { CommissionsService } from '../services/commissions';
import { CommissionViewModel } from '../services/commission-view-model';
import { Subscription } from 'rxjs';

/**
 * Sekce Průběh
 */

@Component({
	selector: 'div.kt-log-tab',
	templateUrl: "/template/commissions/components/log-tab.cshtml"
})
export class LogTabComponent implements OnInit, OnDestroy {
	private _subs: Array<Subscription> = [];

	// Jestli načítáme
	public loading: boolean = false;

	// Pole položek logu
	public entries: Array<ICommissionLog> = [];

	constructor(
		private _commissionService: CommissionsService,
		private _viewModel: CommissionViewModel) {
	}

	/**
	 * Inicializace komponenty 
	 */
	ngOnInit() {
		this._subs.push(
			this._viewModel.onInitialized.subscribe(x => this._loadLog())
		);

		this._loadLog();
	}

	/**
	 * Destroy komponenty 
	 **/
	ngOnDestroy() {
		this._subs.forEach(x => x.unsubscribe());
	}

	/**
	 * Načte položky logu 
	 */
	private _loadLog(): void {
		this.loading = true;

		this._commissionService.getLog(this._viewModel.model.id)
			.then(x => {
				this.entries = x;

				this.loading = false;
			});
	}
}