import { Injectable } from '@angular/core';
import { ModelService, ApiService, UIService } from '../../../core';
import { CommissionBySerialNumberModel } from './../models';
/**
 * Poskytuje operace nad zakázkami portálu.
 */
@Injectable()
export class CommissionsService extends ModelService {

	constructor(
        private _apiService: ApiService,
		private _uiService: UIService) {
		// Voláme konstruktor bázové třídy
        super('portalCommission', _apiService, _uiService);
	}

	public closeApprovedCommissions(fiscalMonthDate: Date): Promise<boolean> {
        return this._apiService.post<boolean>(super.createUrl('close-approved-commissions'), { fiscalMonthDate: fiscalMonthDate });
    }

    /**
     * Vrací z API textovou reprezentaci pro odeslání do Hisense
     * @param selected
     */
    public exportToHisense(selected: Array<number>): Promise<string> {
        return this._apiService.post<any>(super.createUrl('export-to-hisense'), selected)
            .then(x => {
                return x.result;
            });
    }

    /**
     * Vrací z API seznam zakázek se stejným výrobním číslem.
     * @param serialNumber Výrobní číslo.
     */
    public getCommissionsBySerialNumber(serialNumber: string): Promise<CommissionBySerialNumberModel[]> {
        return this._apiService.get<CommissionBySerialNumberModel[]>(super.createUrl('by-serial-number/' + serialNumber));
    }
}