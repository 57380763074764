import { Injectable } from '@angular/core';
import { ModelService, ApiService, UIService } from '../../../core';

/**
 * Poskytuje operace nad zakázkami portálu.
 */
@Injectable()
export class TransportCarriersService extends ModelService {

	constructor(
		apiService: ApiService,
		uiService: UIService) {
		// Voláme konstruktor bázové třídy
		super('listTransportCarrier', apiService, uiService);
	}

	public getTransportSetup(): Promise<Array<ITransportSetupCarier>> {
		return this.apiService.get<Array<ITransportSetupCarier>>(super.createUrl('get-transport-setup'));
	}
}