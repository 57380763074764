import { DataStorageService } from '../../core';
import { OpenSections } from '../models';

/**
 * Bázová komponenta pro komponenty sekcí zakázky
 **/
export abstract class SectionComponentBase {

	// Jestli je sekce otevřená
	private _opened: boolean = true;

	public get opened(): boolean {
		return this._opened;
	}
	public set opened(value: boolean) {
		this._opened = value;

		// Uložím nastavení
		this._dataStorageService.getUserData<OpenSections>('openedCommissionSections')
			.then(sections => {
				if (!sections) {
					sections = new OpenSections();
				}

				sections[this._sectionCode] = value;

				this._dataStorageService.setUserData('openedCommissionSections', sections);
			});
	}

	constructor(
		protected _dataStorageService: DataStorageService,
		protected _sectionCode: string) {

		// Načtu hodnotu při zobrazení
		this._dataStorageService.getUserData<OpenSections>('openedCommissionSections')
			.then(sections => {
				this._opened = (sections || new OpenSections)[_sectionCode];
			});
	}
}