import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonPermission,CanDeactivateGuard } from '../../core';
import { SettingBranchModule } from './';

// Pages
import { BranchListPageComponent } from './pages/branch-list';
import { BranchDetailPageComponent } from './pages/branch-detail';

const routes: Routes = [
    {
        path: '',
        data: {

        },
        component: BranchListPageComponent
    },

    {
        path: ':id',
        component: BranchDetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        SettingBranchModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SettingBranchRoutingModule { }