import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule, CanDeactivateGuard } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { PortalCoreModule } from '../core';

// Pages
import { InstructionListPageComponent } from './pages/instruction-list';
import { InstructionDetailPageComponent } from './pages/instruction-detail';

const routes: Routes = [
	{
		path: ':garantId',
		children: [
			{ path: '', component: InstructionListPageComponent },
			{ path: ':id', component: InstructionDetailPageComponent, canDeactivate: [CanDeactivateGuard] }
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
        RouterModule.forChild(routes),
        CoreModule,
        FormsModule,
		SuiModule,
		PortalCoreModule
	],
	exports: [
		RouterModule
	],
	declarations: [
		InstructionListPageComponent,
		InstructionDetailPageComponent
	],
	providers: [
	],
	entryComponents: [
	]
})
export class PortalInstructionModule { }