import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { ContactsService } from '../services/contacts';
import { Router, NavigationExtras } from '@angular/router';


import {
	UIService,
	FormComponentBase,
	HelperService,
	AppScopeService,
	AuthService,
	IModelService,
	ModelServiceFactory
} from '../../core';

@Component({
	templateUrl: "/template/contacts/modals/contact-add.cshtml"
})
export class ContactAddModalComponent extends FormComponentBase implements OnInit {
	private _contacts: Array<IContact> = [];

	public ic: string = '';
	public contact: IContact;

    // Jestli se zroovna něco děje
    public working: boolean = false;

	constructor(
		public modal: SuiModal<IContactAddModalComponentContext, IContactAddModalComponentResult>,
		protected _uiService: UIService,
		private _contactService: ContactsService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		private _router: Router,
		protected _appScopeService: AppScopeService) {

		super(_uiService, _helperService, _authService, _appScopeService);
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	/**
	 * Zavře modal => cancel
	 */
	public back(): void {
		this.modal.deny(null);
	}

    /**
	 * Kontrola na firmu - zda existuje, pripadne nacteme z aresu
	 */
	public findByIc(): void {
		if (this.ic.length <= 0) {
			this._uiService.showWarning("Pole IČ není vyplněno.");
			return;
        }

        this.working = true;


		// Načteme model ze serveru
		this._contactService.getByIco(this.ic)
            .then(
				model => {

                    this.working = false;

                    if (model != null) {
						if (model.id == 0) {
							this.contact = { name: model.name, city: model.city, dic: model.dic, ic: model.ic };
						}
						else {
							this._uiService.showConfirmation(
								'Záznam nalezen',
								`Zadané IČ ${this.ic} již v adresáři existuje. Přejete si editovat firmu ${model.name}?`
							).then(result => {
								if (result) {
									this.modal.approve({ contactId: model.id, ic: this.ic });
								}
							});
						}
					} else {
						this._uiService.showConfirmation(
							'Nový záznam',
							`Zadané IČ ${this.ic} nenalezeno. Přesto přejít k vytvoření kontaktu?`
						).then(result => {
							if (result) {
								this.modal.approve({ contactId: null, ic: null });
							}
						});
					}
            },
            reason => {
                // Chyba
                this.working = false;
            })
	}

	/**
	 * Potvrdí vytvoření pro nalezené ičo 
	 */
	public confirm(): void {
		this.modal.approve({ contactId: null, ic: this.contact.ic });
	}
}

export class ContactAddModal extends ComponentModalConfig<IContactAddModalComponentContext, IContactAddModalComponentResult, void> {
	constructor() {
		super(ContactAddModalComponent, {});

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IContactAddModalComponentContext {
}

export interface IContactAddModalComponentResult {
	contactId: number | null;
	ic: string;
}

/**
 * Definice rozhraní pro výpis contaktu ve vysledku overeni na ICO
 */
export interface IContact {
	name: string;
	city: string;
	dic: string;
	ic: string;
}