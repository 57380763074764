import { Directive, Input, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';
import { AppScopeService } from '../services/app-scope';
import { AuthService } from '../services/auth';
import { ScopePermissionType } from '../models';

@Directive({ selector: '[ktIfAppScopePermission]' })
export class IfAppScopePermissionDirective implements OnInit {
	private _permissions: ScopePermissionType = ScopePermissionType.None;
    private _appScope: string = null;
    private _appScopeType: string = null;

	public Read: ScopePermissionType = ScopePermissionType.Read;

	constructor(
		private _templateRef: TemplateRef<any>,
		private _viewContainer: ViewContainerRef,
		private _appScopeService: AppScopeService,
		private _authService: AuthService) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this._updateView();
	}

	/**
	 * Vstup vyžadovaných oprávnění
	 * @param {String} permissionsString Typy oprávnění jako flagy - např. 'Create | Update'
	 */
	@Input()
    set ktIfAppScopePermission(permissionsString: string) {

        //console.log(permissionsString);

		// Parsujeme typy oprávnění
		var parts = permissionsString.split('|').map(x => x.trim());
		var permissions = ScopePermissionType.None;

		parts.forEach(x => {
			if (x in ScopePermissionType) {
				permissions |= ScopePermissionType[x];
			}
        });

        //console.log(permissions);

		// Nastavíme a zobrazíme/skryjeme
		this._permissions = permissions;
	}

	/**
	 * Vstup aplikačního scopu pro který se mají práva kontrolovat
	 * @param {String} appScope
	 */
	@Input()
	set ktIfAppScopePermissionAppScope(appScope: string) {
		this._appScope = appScope;
    }

    /**
	 * Vstup aplikačního scopu pro který se mají práva kontrolovat
	 * @param {String} appScope
	 */
    @Input()
    set ktIfAppScopePermissionType(type: string) {
        this._appScopeType = type;
    }

	/**
	 * Aktualizuje view - v závislosti na tom, jestli má user oprávnění zobrazí nebo ne view container
	 */
	private _updateView() {
		// Buď použijeme definovaný scope, nebo scope aktuální routy
		let scope = this._appScopeService.currentScope;

		//console.log(this._appScope, scope);

		// Definovaný scope může být relativní - začíná tečkou
		if (this._appScope) {
			if (this._appScope[0] == '.') {
				scope += this._appScope;
			}
			else {
				scope = this._appScope;
			}
		}


        if (this._authService.hasAnyAppScopePermission(scope, this._permissions, this._appScopeType)) {
			this._viewContainer.createEmbeddedView(this._templateRef);
		}
		else {
			this._viewContainer.clear();
		}
	}
}