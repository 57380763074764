import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { AngularResizedEventModule } from 'angular-resize-event/dist';

// Services
import { ApiService } from './services/api';
import { AppScopeService } from './services/app-scope';
import { UIService } from './services/ui';
import { HelperService } from './services/helper';
import { AuthService } from './services/auth';
import { AuthenticatedGuard } from './services/authenticated-guard';
import { CanDeactivateGuard } from './services/can-deactivate-guard';
import { FileManagerService } from './services/file-manager';
import { ModelServiceFactory } from './services/model-service-factory';
import { ProjectEnumService } from './services/project-enum';
import { ListsService, IListSettingsHandler } from './services/lists';
import { DataStorageService } from './services/data-storage';
import { ValuesService } from './services/values';
import { FormatService } from './services/format';
import { CoreService } from './services/core';
import { PrintingService } from './services/printing';
import { AppNotificationsService } from './services/app-notifications';
import { AppSettings } from './services/app-settings';
import { ModalService } from './services/modal';
import { LocationService } from './services/location';

// Modals
import { ConfirmModalComponent } from './modals/confirm';
import { MessageModalComponent } from './modals/message';
import { FileManagerModalComponent } from './modals/file-manager';
import { ListFilterModalComponent } from './modals/list-filter';
import { ColumnsSetupModalComponent } from './modals/columns-setup';
import { AddAttachmentModalComponent } from './modals/add-attachment';

// Pages
import { NotAuthorizedPageComponent } from './pages/not-authorized';
import { NotFoundPageComponent } from './pages/not-found';
import { SignInPageComponent } from './pages/sign-in';
import { DashboardPageComponent } from './pages/dashboard';
import { LostPasswordComponent } from './pages/lost-password';
import { FirstLoggedComponent } from './pages/first-logged';
import { PasswordChangeComponent } from './pages/password-change';

// Components
import { NavbarComponent } from './components/navbar';
import { PageTopBarComponent } from './components/page-top-bar';
import { GridComponent } from './components/grid/grid';
import { GridColumnDirective } from './components/grid/grid-column';
import { AppLayoutComponent } from './components/app-layout';
import { AttachmentsComponent } from './components/attachments';
import { DetailFormComponent } from './components/detail-form';
import { SelectComponent } from './components/select';
import { DatepickerComponent } from './components/datepicker';
import { HtmlEditorComponent } from './components/html-editor';
import { PaginationComponent } from './components/pagination';
import { FooterComponent } from './components/footer';
import { InputWithListComponent } from './components/input-with-list';
import { NotificationsSwitchComponent } from './components/notifications-switch';
import { DataTableComponent } from './components/data-table';

// Directives
import { PreventDefaultLinkDirective } from './directives/prevent-default-link';
import { SameValueAsValidatorDirective } from './directives/same-value-as-validator';
import { FormFieldDirective } from './directives/form-field';
import { FormDirective } from './directives/form';
import { IfAppScopePermissionDirective } from './directives/if-app-scope-permission';
import { ToggleDirective } from './directives/toggle';
import { KeepHeightDirective } from './directives/keep-height';

// Pipes
import { CurrencyPipe } from './pipes/currency';
import { DatePipe } from './pipes/date';

//
// Exporty
//

// Services
export { IModelService, ModelService } from './services/model-service';
export { ApiService, ErrorResponse, ErrorMessage } from './services/api';
export { AppScopeService } from './services/app-scope';
export { UIService } from './services/ui';
export { HelperService } from './services/helper';
export { AuthService } from './services/auth';
export { AuthenticatedGuard } from './services/authenticated-guard';
export { CanDeactivateGuard, ICanDeactivateComponent } from './services/can-deactivate-guard';
export { ModelServiceFactory } from './services/model-service-factory';
export { ProjectEnumService } from './services/project-enum';
export { ListsService } from './services/lists';
export { DataStorageService } from './services/data-storage';
export { ValuesService } from './services/values';
export { FormatService } from './services/format';
export { CoreService } from './services/core';
export { PrintingService } from './services/printing';
export { AppNotificationsService } from './services/app-notifications';
export { AppSettings } from './services/app-settings';
export { ModalService } from './services/modal';
export { LocationService } from './services/location';

// Components
export { AppLayoutComponent } from './components/app-layout';
export { GridComponent } from './components/grid/grid';
export { DataTableComponent } from './components/data-table';

// Pages
export { NotAuthorizedPageComponent } from './pages/not-authorized';
export { NotFoundPageComponent } from './pages/not-found';
export { SignInPageComponent } from './pages/sign-in';
export { DashboardPageComponent } from './pages/dashboard';
export { LostPasswordComponent } from './pages/lost-password';
export { FirstLoggedComponent } from './pages/first-logged';
export { PasswordChangeComponent } from './pages/password-change';

// Modals
export { ListFilterModal } from './modals/list-filter';
export { AddAttachmentModal } from './modals/add-attachment';

// Other
export { Constants } from './constants';

export { FormComponentBase } from './classes/form-component-base';
export { ModelFormComponentBase, IModelFormHost, ModalModelFormHost } from './classes/model-form-component-base';

export * from './models';
//export * from './functions';

@NgModule({
	imports: [
        CommonModule,
		RouterModule,
		FileUploadModule,
		FormsModule,
		ReactiveFormsModule,
		SuiModule,
		NgSlimScrollModule,
		AngularResizedEventModule
	],
    declarations: [
		ConfirmModalComponent,
		FileManagerModalComponent,
        NavbarComponent,
        PageTopBarComponent,
        GridComponent,
        GridColumnDirective,
        AppLayoutComponent,
        DetailFormComponent,
        PreventDefaultLinkDirective,
		SameValueAsValidatorDirective,
		AttachmentsComponent,
		NotFoundPageComponent,
        NotAuthorizedPageComponent,
        SignInPageComponent,
        LostPasswordComponent,
        PasswordChangeComponent,
        FirstLoggedComponent,
        DashboardPageComponent,
		FormFieldDirective,
		FormDirective,
		IfAppScopePermissionDirective,
		SelectComponent,
		HtmlEditorComponent,
		DatepickerComponent,
		ListFilterModalComponent,
		PaginationComponent,
		FooterComponent,
		ColumnsSetupModalComponent,
        ToggleDirective,
		InputWithListComponent,
		NotificationsSwitchComponent,
		CurrencyPipe,
		DatePipe,
		KeepHeightDirective,
		AddAttachmentModalComponent,
		MessageModalComponent,
		DataTableComponent
	],
	providers: [
	],
    exports: [
        NavbarComponent,
        PageTopBarComponent,
        GridComponent,
        GridColumnDirective,
        AppLayoutComponent,
		DetailFormComponent,
		AttachmentsComponent,
        PreventDefaultLinkDirective,
		SameValueAsValidatorDirective,
		FormFieldDirective,
		FormDirective,
		IfAppScopePermissionDirective,
		SelectComponent,
		HtmlEditorComponent,
		DatepickerComponent,
		PaginationComponent,
		FooterComponent,
		ToggleDirective,
		InputWithListComponent,
		NotificationsSwitchComponent,
		CurrencyPipe,
		DatePipe,
		KeepHeightDirective,
		DataTableComponent
    ],
    entryComponents: [
		ConfirmModalComponent,
		FileManagerModalComponent,
		ListFilterModalComponent,
		ColumnsSetupModalComponent,
		AddAttachmentModalComponent,
		MessageModalComponent
    ]
})
export class CoreModule {
    static forRoot() {
        return {
            ngModule: CoreModule,
            providers: [
                ApiService,
                UIService,
                AuthService,
				AuthenticatedGuard,
				CanDeactivateGuard,
				HelperService,
				AppScopeService,
				FileManagerService,
				ModelServiceFactory,
				ProjectEnumService,
				ListsService,
				DataStorageService,
				ValuesService,
                FormatService,
				CoreService,
				PrintingService,
				AppNotificationsService,
				AppSettings,
				ModalService,
                LocationService
			]
        }
    }
}