import { Input, Component, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { ApiService, AddAttachmentModal, CommissionAttachmentType, ModalService } from '../../core';
import { CommissionViewModel } from '../services/commission-view-model';
import { saveAs } from 'file-saver';

/**
 * Rozšířený seznam příloh k zakázkám apod.
 */

@Component({
    selector: 'div.kt-commission-attachments',
    templateUrl: "/template/commissions/components/commission-attachments.cshtml"
})
export class CommissionAttachmentsComponent implements OnInit, OnDestroy {
    public loadingAll: boolean = false;

    // Aktuálně vybraný attachment
    public attachment: ICommissionAttachment = null;

    @Input() downloadAllFileName: string;
    @Input() attachments: Array<ICommissionAttachment> = [];

    constructor(
        private _viewModel: CommissionViewModel,
        private _apiService: ApiService,
        private _modalService: ModalService) {
    }

	/**
	 * Inicializace komponenty
	 */
    ngOnInit() {
    }

	/**
	 * Zrušení komponenty
	 */
    ngOnDestroy() {
    }

	/**
	 * Přidání nových příloh
	 */
    public addAttachments(): void {
        this._modalService
            .openModal(new AddAttachmentModal())
            .onApprove((attachments: Array<IAttachment>) => {

                attachments.forEach(x => {
                    var commissionAttachment: ICommissionAttachment = {
                        attachmentType: CommissionAttachmentType.Other,
                        fileName: x.fileName,
                        filePath: x.filePath,
                        thumbnailUrl: x.thumbnailUrl,
                        url: x.url,
                        temporary: x.temporary,
                        id: x.id
                    };

                    this.attachments.push(commissionAttachment);
                });
            });
    }

    /**
     * Aktualizuje přílohy
     */
    public updateAttachments(): void {
        this._viewModel.updateAttachments().then(x => {
        }).catch(reason => {
            reason();
        });
    }

	/**
	 * Vytvoří definici stylu pro background image.
	 * @param url
	 */
    public createBackgroundUrl(url: string): string {
        return 'url("' + url.replace(' ', '%20') + '")';
    }

	/**
	 * Smaže aktuálně vybranou přílohu 
	 */
    public deleteAttachment(): void {
        if (!this.attachment) {
            return;
        }

        let idx = this.attachments.indexOf(this.attachment);
        this.attachments.splice(idx, 1);

        this.attachment = null;
    }

	/**
	 * Stáhne všechny soubory v zipu
	 */
    public downloadAll(): void {
        if (this.loadingAll) {
            return;
        }

        this.loadingAll = true;

        var filePaths = this.attachments
            .filter(x => !x.temporary)
            .map(x => x.filePath);

        this._apiService
            .postGetBlob('files/download', filePaths)
            .then(
                blob => {
                    saveAs(blob, this.downloadAllFileName + '_attachments.zip');

                    this.loadingAll = false;
                },
                reason => {
                    this.loadingAll = false;
                });
    }

	/**
	 * Vrací info jestli je tlačítko Stáhnout vše viditelné
	 */
    public downloadAllVisible(): boolean {
        return this.attachments.filter(x => !x.temporary).length > 0;
    }

	/**
	 * Vrací název typu pro zobrazení
	 * @param type
	 */
    public getAttachmentTypeName(type: CommissionAttachmentType): string {
        switch (type) {
            case CommissionAttachmentType.Label: return 'Štítek';
            case CommissionAttachmentType.SalesReceipt: return 'Prodejní doklad';
            case CommissionAttachmentType.Other: return 'Ostatní';
        }

        console.warn('Not supported commission atachment type: ' + type);

        return 'Příloha';
    }

    /**
     * Vrací pole viditelných příloh (nejsou smazané) 
     **/
    public getVisibleAttachments(): Array<ICommissionAttachment> {
        return this.attachments;
    }

	/**
	 * Vybere zadaný attachment
	 * @param attachment
	 */
    public selectAttachment(attachment: ICommissionAttachment): void {
        this.attachment = attachment;
    }

	/**
	 * Nastaví typ přílohy
	 * @param type
	 */
    public setAttachmentType(type: CommissionAttachmentType): void {
        if (!this.attachment) {
            return;
        }

        this.attachment.attachmentType = type;
    }
}