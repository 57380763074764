import { Component, OnInit, OnDestroy } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { ModelWrapperBuilder } from '../services/model-wrapper-builder';
import { ModelWrapper } from '../models';
import { FormGroup } from '@angular/forms';
import { SelectOption } from '../../core'

@Component({
	templateUrl: "/template/commissions/modals/authorization.cshtml"
})
export class AuthorizationModalComponent implements OnInit {
	// Wrapper nad cenovým návrhem
	private _wrapper: ModelWrapper<ICommissionAuthorization> = null;

	// Model pro cenový návrh
	public get model(): ICommissionAuthorization {
		return this._wrapper.model;
	}

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._wrapper.form;
    };

    // Jestli je tlačítko ok disablované
    public saveDisabled: boolean = false;

	constructor(
		public modal: SuiModal<IAuthorizationModalComponentContext, ICommissionAuthorization, void>,
        private _modelWrapperBuilder: ModelWrapperBuilder) {

        this.saveDisabled = modal.context.saveDisabled;
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this._wrapper = this._modelWrapperBuilder.build<ICommissionAuthorization>(this.modal.context.authorization);
	}

	/**
	 * Zruší formulář 
	 */
	public cancel(): void {
		this.modal.deny(null);
	}

	/**
	 * Uloží návrh 
	 */
	public save(): void {
		this.modal.approve(<ICommissionAuthorization>this.form.getRawValue());
	}

	/**
	 * Zpracovává změnu option v typu
	 * @param option
	 */
	public onTypeOptionSelected(option: SelectOption): void {
		this.model.authorizationTypeName = option.text;
	}
}

export class AuthorizationModal extends ComponentModalConfig<IAuthorizationModalComponentContext, ICommissionAuthorization, void> {
    constructor(authorization: ICommissionAuthorization, saveDisabled: boolean) {
        super(AuthorizationModalComponent, { authorization, saveDisabled });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
		this.mustScroll = true;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IAuthorizationModalComponentContext {
    authorization: ICommissionAuthorization;
    saveDisabled: boolean;
}
