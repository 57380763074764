import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UIService } from '../services/ui';
import { AuthService } from '../services/auth';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: "/template/core/pages/password-change.cshtml"
})
export class PasswordChangeComponent implements OnInit {
    passwordChangeForm: FormGroup;
    loading: boolean = false;

    constructor(
        protected _activatedRoute: ActivatedRoute,
        private _uiService: UIService,
        private _authService: AuthService,
        private _router: Router) {
    }

    

    getTitle() {
        return 'Změna hesla';
    }

    ngOnInit() {

        // Inicializace formuláře
        this.passwordChangeForm = new FormGroup({
            oldPassword: new FormControl(null, [Validators.required]),
            password: new FormControl(null, [Validators.required,
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,50}$')]),
            passwordConfirmation: new FormControl(null, [Validators.required,
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,50}$')])
        });
    }

    isValid(): boolean {

        if (this.passwordChangeForm.get('password').value != this.passwordChangeForm.get('passwordConfirmation').value) {
            return false;
        }

        return this.passwordChangeForm.valid;
    }

    confirm() {
        this.loading = true;

        this._authService
            .passwordChange(this.passwordChangeForm.get('password').value, this.passwordChangeForm.get('passwordConfirmation').value, this.passwordChangeForm.get('oldPassword').value)
            .then(result => {
                if (result.success) {
                    this.loading = false;
                    this._uiService.showSuccess(result.message, 'Heslo bylo úspěšně změněno.');
                }
                else {
                    this.loading = false;
                    this._uiService.showError(result.message, 'Změna hesla se nezdařila');
                }
            });
    }
}