import { Input, Component } from '@angular/core';

/**
 * Layout pro formulář detailu objektu
 */

@Component({
	selector: 'div.kt-detail-form]',
	templateUrl: "/template/core/components/detail-form.cshtml"
})
export class DetailFormComponent {
	constructor() {
	}
}