import { Input, Component } from '@angular/core';
import { ApiService, UIService } from '../../../core';

/**
 * Funkcionalita komentářů pro portál.
 */

@Component({
	selector: 'div.kt-portal-comments',
	templateUrl: "/template/portal/core/components/portal-comments.cshtml"
})
export class PortalCommentsComponent {
	message: '';
	loading: boolean = false;

	@Input() entity: IPortalEntity;
	@Input() entityType: string;

	constructor(
		private _apiService: ApiService,
		private _uiService: UIService) {
	}

	sendComment() {
		this.loading = true;

		this._apiService.post<any>('portalcomment/' + this.entityType + '/' + this.entity.id, JSON.stringify(this.message))
			.then(
				result => {
					this._uiService.showSuccess('', 'Odesláno');

					this.entity.comments.push(result);
					this.message = '';

					this.loading = false;
				},
				reason => {
					this.loading = false;
				});
	}
}

interface IPortalEntity {
	id: number;
	comments: Array<any>;
}