import { Injectable } from '@angular/core';
import { ApiService, ErrorResponse } from './api';
import { UIService } from './ui';
import { Sorting, PageModel, PageInfo } from '../models';
import { IndividualToastrConfig } from 'ngx-toastr';

/**
 * Bázová třída pro služby poskytující operace nad konkrétní entitou.
 */
@Injectable()
export class ModelService implements IModelService {

	constructor(
		public route: string,
		protected apiService: ApiService,
		protected uiService: UIService) {
	}

	/**
	 * Zavolá smzání pro dodané id
	 * @param id
	 * @param query - Dodatečné parametry posílány na API v querystringu
	 */
	public delete(id: number, query: any = null): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.apiService.delete(this.createUrl('/' + id, query))
				.then(
					result => {
						this.uiService.showSuccess('', 'Smazáno');

						resolve(true);
					},
					(error: ErrorResponse) => {
						if (error.isUserFriendly) {
							let message = error.errors
								.map(x => x.message)
								.join('<br>');

							this.uiService.showError(message, 'Nelze smazat');
						} else {
							this.uiService.showError('');
						}

						resolve(false);
					}
				);
		});
	};

	/**
	 * Načte detail objektu pro zadané id
	 * @param id
	 * @param query - Dodatečné parametry posílány na API v querystringu
	 */
	public get<T>(id: number, query: any = null): Promise<T> {
		return new Promise((resolve, reject) => {
			this.apiService.get<T>(this.createUrl('/' + id, query))
				.then(
					result => {
						resolve(result);
					},
					error => {
						resolve(null);
					}
				);
		});
	};

	/**
	 * Uloží model pro dodané id
	 * @param id
	 * @param model
	 * @param query - Dodatečné parametry posílány na API v querystringu
	 */
	public set(id: number, model: any, query: any = null): Promise<any> {
		return new Promise((resolve, reject) => {
			this.apiService.post<any>(this.createUrl('/' + id, query), model)
				.then(
					result => {
						this.uiService.showSuccess('', 'Uloženo');

						resolve(result);
					},
					(error: ErrorResponse) => {
                        if (error.isUserFriendly) {
                            let message = error.errors
                                .map(x => x.message)
                                .join('<br>');


                            if (error.errors.length > 1) {

                                let individualConfig: any = {
                                    /*extendedTimeOut: 3000,*/
                                    timeOut: 10000,
                                    enableHtml: true
                                };
                                                             

                                this.uiService.showError(message, 'Někde je chyba', individualConfig);
                            } else {
                                this.uiService.showError(message, 'Někde je chyba');
                            }


						}
						else {
							this.uiService.showError('');
						}

						resolve(null);
					}
				);
		});
	};

	/**
	 * Načte seznam
	 * @param page Specifikace stránky kterou vrátit - null pokud vrátit vše
	 * @param sorting Podle čeho řadit
	 * @param filter Co filtrovat
	 */
	public list<T>(page: PageInfo, sorting: Sorting, filter: any = null): Promise<PageModel<T>> {
		filter = filter || {};

		let body = { page, sorting, filter };

		return new Promise((resolve, reject) => {
			this.apiService.post<PageModel<T>>(this.route + '/list', body)
				.then(
					result => {
						resolve(result);
					},
					error => {
						reject();
					}
				);
		});
	};

	/**
	 * Provolá export seznamu do excelu
	 * @param sorting
	 * @param filter
	 */
	public listToExcel(sorting: Sorting, filter: any = null): Promise<any> {
        filter = filter || {};

		let body = { sorting, filter};

		return new Promise((resolve, reject) => {
			this.apiService.postGetBlob(this.route + '/list-to-excel', body)
				.then(
					blob => {
						var url = window.URL.createObjectURL(blob);
						var a = document.createElement('a');
						document.body.appendChild(a);
						a.setAttribute('style', 'display: none');
						a.href = url;
						a.download = 'zakazky.xlsx';
						a.click();
						window.URL.revokeObjectURL(url);
						a.remove();

						resolve(null);
					},
					error => {
						reject();
					}
				);
		});
	}

	protected createUrl(route: string, query: any = null): string {
		let retval = (this.route + '/' + route).replace('//', '/');

		if (query != null) {
			retval += '?';
			for (let key of Object.keys(query)) {
				retval += key + '=' + encodeURIComponent(query[key]) + '&';
			}
		}

		return retval;
	};
}

export interface IModelService {
	/**
	 * Vrací routu služby
	 */
	route: string;

	/**
	 * Zavolá smzání pro dodané id
	 * @param id
	 * @param query - Dodatečné parametry posílány na API v querystringu
	 */
	delete(id: number, query?: any): Promise<boolean>;

	/**
	 * Načte detail objektu pro zadané id
	 * @param id
	 * @param query - Dodatečné parametry posílány na API v querystringu
	 */
	get<T>(id: number, query?: any): Promise<T>;

	/**
	 * Uloží model pro dodané id
	 * @param id
	 * @param model
	 * @param query - Dodatečné parametry posílány na API v querystringu
	 */
	set(id: number, model: any, query?: any): Promise<any>;

	/**
	 * Načte seznam
	 * @param page Specifikace stránky kterou vrátit
	 * @param sorting Podle čeho řadit
	 * @param filter Co filtrovat
	 */
	list<T>(page: PageInfo, sorting: Sorting, filter?: any): Promise<PageModel<T>>;

	/**
	 * Provolá export seznamu do excelu
	 * @param sorting
	 * @param filter
     * @param columns
	 */
    listToExcel(sorting: Sorting, filter?: any): Promise<any>;
}