import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UIService } from '../services/ui';
import { AuthService } from '../services/auth';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	templateUrl: "/template/core/pages/first-logged.cshtml"
})
export class FirstLoggedComponent implements OnInit {
    firstLoggedForm: FormGroup;
    loading: boolean = false;

    constructor(
        protected _activatedRoute: ActivatedRoute,
        private _uiService: UIService,
        private _authService: AuthService,
        private _router: Router) {
	}

    public currnetUser: string = this._authService.currentUser.fullName;

    ngOnInit() {
        
		// Inicializace formuláře
        this.firstLoggedForm = new FormGroup({
            password: new FormControl(null, [Validators.required, 
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,50}$') ]),
            passwordConfirmation: new FormControl(null, [Validators.required,
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,50}$')])
		});
	}

	isValid(): boolean {

        if (this.firstLoggedForm.get('password').value != this.firstLoggedForm.get('passwordConfirmation').value) {
            return false;
        }


        return this.firstLoggedForm.valid;
	}

    confirm() {
		this.loading = true;

        this._authService
            .passwordChange(this.firstLoggedForm.get('password').value, this.firstLoggedForm.get('passwordConfirmation').value)
			.then(result => {
                if (result.success) {

                    this._authService.currentUser.needChangePassword = false;

                    // Redirekt
                    this._router.navigate(['app', 'dashboard']);
				}
				else {
					this.loading = false;
					this._uiService.showError(result.message, 'Změna hesla se nezdařila');
				}
			});
	}
}