import {
    Input,
    Output,
    EventEmitter,
    Component,
    OnInit,
    QueryList,
    ContentChildren,
    ContentChild,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    TemplateRef,
    ViewChild,
    ElementRef,
    Renderer2,
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { GridColumnDirective } from './grid-column';
import { IModelService } from '../../services/model-service';
import { ListFilter, ListFilterEntry, List, ColumnDefinition } from '../../models';
import { ListsService, IListSettingsHandler } from '../../services/lists';
import { UIService } from '../../services/ui';
import { HelperService } from '../../services/helper';
import { Subscription } from 'rxjs';
import { PaginationComponent } from '../pagination';

@Component({
    selector: '.kt-grid',
    templateUrl: "/template/core/components/grid/grid.cshtml",
    providers: [DatePipe],
    exportAs: 'grid',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent implements OnInit, AfterViewInit, OnDestroy {

	/*
	 * Private fields
	 */

    // Handler pro nastavení tohohle gridu
    private _settingsHandler: IListSettingsHandler;

    // Vlastní filtr který bude posílán na server
    private _customFilter: any = {};

    // Vertikální pozice gridu, pokud vertikálně skroluje
    private _scrollY: number = 0;

    // Řádky skryté a viditelné hlavičky
    private _headRow: any = null;
    private _hiddenHeadRow: any = null;

    // Subskripce které je potřeba v destruktoru odhlásit
    private _subs: Array<Subscription> = [];

    // Použito pro sloupce na konci gridu - options
    private _defaultColumn: Column = {
        field: 'field',
        directive: {
            cellTemplate: null,
            className: null,
            dateFormat: null,
            field: null,
            fixed: false,
            headerTemplate: null,
            sortable: false,
            title: null,
            width: 24
        },
        styles: {},
        headerStyles: {},
        bodyStyles: {}
    };

	/*
	 * Veřejné
	 */

    // Pole dat načtený ze serveru
    public items: Array<any> = [];

    // Getter vrací aktuální filtr
    public get filter(): ListFilter {
        if (!this._settingsHandler) {
            return new ListFilter();
        }

        return this._settingsHandler.filter;
    }

    // Hledaný výraz
    public searchFor: string = '';

    // Pole IDček zaškrtnutých řádků.
    public checkedIds: List<number> = new List<number>();

    public columns: Array<Column> = [];

    // Column definice pro sloupec s checkboxy
    public rowCheckColumn: Column = {
        field: 'field',
        directive: {
            cellTemplate: null,
            className: null,
            dateFormat: null,
            field: null,
            fixed: false,
            headerTemplate: null,
            sortable: false,
            title: null,
            width: 24
        },
        styles: {},
        headerStyles: {},
        bodyStyles: {}
    };

    // Jestli se zroovna něco děje
    public working: boolean = false;


	/*
	 * Children
	 */

    // Definice sloupců
    @ContentChildren(GridColumnDirective) columnDirectives: QueryList<GridColumnDirective>;

    // Definice butonů pro řádek - poslední buňka s tlačítky
    @ContentChild('itemOptions', { static: false }) optionsTemplate: TemplateRef<any> = null;

    // Reference na div element ve kterém je viditelná hlavička
    @ViewChild('gridHead', { static: false }) gridHead: ElementRef;

    // Reference na div element, ve kterém je tělo gridu
    @ViewChild('gridBody', { static: false }) gridBody: ElementRef;

    // Reference na pager
    @ViewChild('pagination', { static: false }) pagination: PaginationComponent;

	/*
	 * Input/Output
	 */

    // Služba která poskytuje data
    @Input() modelService: IModelService;

    // Umožní nastavit, jestli má být grid stránkovatelný
    @Input() pageable: boolean = true;

    // Umožní nastavit, jestli se má zobrazit pole pro vyheldávání
    @Input() searchable: boolean = false;

    // Umožní nastavit, jestli má být možnost zaškrtnout řádky
    @Input() rowCheckingEnabled: boolean = false;

    // Klíč pod kterým se ukládá nastavení
    @Input() settingsKey: string;

    // Výchozí hodnota nastavená pro vyhledávání
    @Input() initialSearchFor: string;

    // Výchozí hodnota počtu položek na stránku
    @Input() initialPageSize: number = 100;

    // Umožní nastavit, jestli je tohle hlavní grid na stránce
    // - zobrazuje loader přes stránku
    // - nastavuje status bar
    @Input() isMainGrid: boolean = true;

    // Událost spuštěna když je kliknuto na název řádku
    @Output() onRowSelected: EventEmitter<any> = new EventEmitter();

    // Událost spuštěna po načtení dat
    @Output() onDataLoaded: EventEmitter<any[]> = new EventEmitter();

    get customFilter(): any {
        return this._customFilter;
    };
    set customFilter(customFilter: any) {
        this._customFilter = customFilter;
        this.loadData();
    };

    constructor(
        private _datePipe: DatePipe,
        private _changeDetector: ChangeDetectorRef,
        private _renderer: Renderer2,
        private _listsService: ListsService,
        private _helperService: HelperService,
        private _uiService: UIService) {
    }

	/**
	 * Inicializace komponenty
	 */
    ngOnInit() {
        if (this.isMainGrid) {
            this._uiService.showLoader();
        } 

        if (!this.isMainGrid) {
            this.working = true;
        }

        // Nastavím filtr key, pokud nebyl žádný zadaný
        if (!this.settingsKey) {
            this.settingsKey = this.modelService.route;
        }

        this._listsService.getSettingsHandler(this.settingsKey)
            .then((x: IListSettingsHandler) => {
                this._settingsHandler = x;

                // Nastavíme výchozí, nebo uloženou hodnotu pro hledání
                if (this.initialSearchFor) {
                    this.searchFor = this.initialSearchFor;
                    this._setSearchFor();
                }
                else {
                    this.searchFor = x.filter.searchFor;
                }

                // Vychozi pocet polozek na stranku
                if (this.initialPageSize) {
                    this._setPageSize(this.initialPageSize);
                }
                

                // Zpracování událost o změně výrazu pro hledání apod.
                // Schválně je až za nastavením searchFor
                this._subs.push(x.onFilterChanged.subscribe(this.loadData.bind(this)));
                this._subs.push(x.onPageChanged.subscribe(this.loadData.bind(this)));
                this._subs.push(x.onSortingChanged.subscribe(this.loadData.bind(this)));

                // Zpracovává události o změně sloupců
                this._subs.push(x.onColumnsChanged.subscribe(this._buildColumns.bind(this)));

                // Sestavení sloupců
                this._buildColumns().then(x => {
                    // Načteme první data
                    this.loadData();
                });
            });
    }

	/**
	 * Destruktor
	 */
    ngOnDestroy() {
        this._subs.forEach(x => x.unsubscribe());
        this._uiService.setFooterText('');
    };

	/**
	 * Inicializace view
	 */
    ngAfterViewInit() {
        this._headRow = this.gridHead.nativeElement.children[0].children[0].children[0];
        this._hiddenHeadRow = this.gridBody.nativeElement.children[0].children[0].children[0];
    }

	/**
	 * Vrací info jestli má být zaškrtnutý checkbox v halvičce. 
	 */
    public allIsChecked(): boolean {
        return this._numberOfCheckedOnPage() > 0;
    }

	/**
	 * Smaže vyhledávaný výraz
	 */
    public clearSearchFor() {
        this.searchFor = '';
        this._setSearchFor();
    }

	/**
	 * Vrací info jestli se má zobrazit entry pro vyhledaný text 
	 */
    public displaySearchForEntry(): boolean {
        return !!this.filter.searchFor;
    }

	/**
	 * Vrátí zformátovanou hodnotu pro daný sloupec a řádek
	 * @param item
	 * @param column
	 */
    public getColumnValue(item: any, column: GridColumnDirective) {
        let retval = item[column.field];

        // !! Performance problems with date pipe
        if (retval && column.dateFormat != null && column.dateFormat.length > 0) {
            retval = this._datePipe.transform(retval, column.dateFormat);
        }

        return retval;
    }

	/**
	 * Vrátí CSS klasu representující třídění pro daný sloupec
	 * @param column
	 */
    public getSortClass(column: GridColumnDirective): string {
        if (!column.sortable) {
            return '';
        }

        let retval = 'sortable';
        let sorting = this._settingsHandler.sorting;

        if (sorting.sortBy == column.field) {
            retval += ' ' + (sorting.sortDesc ? 'desc' : 'asc');
        }

        return retval;
    }

	/**
	 * Spustí export do excelu 
	 **/
    public exportToExcel(): void {
        if (this.isMainGrid) {
            this._uiService.showLoader();
        }
        if (!this.isMainGrid) {
        this.working = true;
        }

        let filter = this._buildListFilter();

        this.modelService
            .listToExcel(this._settingsHandler.sorting, filter)
            .then(
                x => {
                    if (this.isMainGrid) {
                        this._uiService.hideLoader();
                    } 

                    if (!this.isMainGrid) {
                        this.working = false;
                    }
                },
                reason => {
                    if (this.isMainGrid) {
                        this._uiService.hideLoader();
                    }

                    if (!this.isMainGrid) {
                        this.working = false;
                    }
                }
            );
    }

    /**
	 * Načte data z API
	 */
    public loadData(): void {
        // Může být voláno na začátku, když ještě není služba inicializována
        if (!this.modelService) {
            return;
        }

        if (this.isMainGrid) {
            this._uiService.showLoader();
        }

        if (!this.isMainGrid) {
            this.working = true;
        }

        // Schovávám fake hlavičku
        this._renderer.setStyle(this.gridHead.nativeElement, 'display', 'none');

        let filter = this._buildListFilter();
        let page = this.pageable ? this._settingsHandler.page : null;

        this.modelService
            .list<any>(page, this._settingsHandler.sorting, filter)
            .then(
                x => {
                    this.items = x.items;

                    if (this.pageable) {
                        this.pagination.totalCount = x.totalCount;

                        if (this.isMainGrid) {
                            let footerText = x.totalCount == 0 ? 'Žádné položky nenalezeny' : `Zobrazeno ${x.items.length} z ${x.totalCount} položek`;
                            this._uiService.setFooterText(footerText);
                        }
                    }

                    this.onDataLoaded.next(x.items);

                    if (!this.isMainGrid) {
                        this.working = false;
                    }

                    this._changeDetector.detectChanges();

                    //setTimeout(() => {
                        this._resizeColumns();
                    //}, 100);

                    if (this.isMainGrid) {
                        this._uiService.hideLoader();
                    }
                },
                reason => {
                    this._uiService.showError('Nepodařilo se načíst seznam');

                    this.items = [];

                    this._changeDetector.detectChanges();

                    //setTimeout(() => {
                        this._resizeColumns();
                    //}, 100);

                    if (this.isMainGrid) {
                        this._uiService.hideLoader();
                    } 

                    if (!this.isMainGrid) {
                        this.working = false;
                    }
                });
    }

	/**
	 * Zpracovává událost změny velikosti tabulky
	 * @param event
	 */
    public onResized(event: any) {
        this._resizeColumns();
    }

	/**
	 * Zpracovává událost scroll gridu
	 * @param event
	 */
    public onScroll(event: any) {
        this._setupScroll();
    }

	/**
	 * Obsluhuje událost keypress na vyhledávacím inputu
	 * @param event
	 */
    public onSearchKeyPress(event: any) {
        if (event.charCode == 13) {
            this._setSearchFor();
        }
    }

	/**
	 * Odebere zadanou položku z filtru
	 * @param entry
	 */
    public removeFilterEntry(entry: ListFilterEntry) {
        this._settingsHandler.removeFilterEntry(entry);
    }

	/**
	 * Vrací info jestli je řádek zaškrtnutý.
	 * @param row
	 */
    public rowIsChecked(row: any): boolean {
        let id = <number>row.id;

        return this.checkedIds.contains(id);
    }

	/**
	 * Vrací true/false jestli má být první sloupec v seznamu vykreslen jako klikací.
	 */
    public rowIsSelectable(): boolean {
        return this.onRowSelected.observers.length > 0;
    }

	/**
	 * Spustí hledání
	 */
    public search() {
        this._setSearchFor();
    }

	/**
	 * Vrací info jestli je vyhledání povoleno.
	 */
    public searchIsEnabled(): boolean {
        return this.searchFor && this.searchFor.length > 2;
    }

	/**
	 * Nastaví aktuální třídění na - podle daného sloupce.
	 * @param column
	 */
    public setSort(column: GridColumnDirective) {
        if (!column.sortable) {
            return;
        }

        this._settingsHandler.setSortBy(column.field);
    }

	/**
	 * obsluhuje kliknutí na checkbox v hlavičce 
	 */
    public toggleAllChecked(): void {
        let checked: boolean = true;

        if (this._numberOfCheckedOnPage() > 0) {
            checked = false;
        }

        this.items.forEach(x => {
            let id = <number>x.id;

            if (checked) {
                this.checkedIds.add(id);
            }
            else {
                this.checkedIds.remove(id);
            }
        });
    }

	/**
	 * Voláno po kliknutí na checkbox u řádku.
	 * @param row
	 */
    public toggleRowChecked(row: any): void {
        let id = <number>row.id;

        this.checkedIds.toggle(id);
    }

	/**
	 * Vytvoří filtr pro seznam
	 **/
    private _buildListFilter() {
        // Dělám kopii filtru
        var filter = this._helperService.deepClone(this._settingsHandler.filter);

        // Přidám custom filter
        if (this.customFilter) {
            for (var p in this.customFilter) {
                if (this.customFilter.hasOwnProperty(p)) {
                    filter.entries.push({
                        optionCode: p,
                        value1: this.customFilter[p],
                        value2: null
                    });
                }
            }
        }

        // Viditelne sloupce
        filter.columns = this._settingsHandler.columns;

        // Zatrhnute radky
        filter.checkedIds = this.checkedIds.items;

        return filter;
    }

	/**
	 * Připraví seznam sloupců pro vykreslení 
	 */
    private _buildColumns(): Promise<any> {
        return this._listsService.getColumnDefinitions(this.modelService.route)
            .then(x => {
                this.columns = this._listsService.buildColumnsSettings(x, this._settingsHandler.columns)
                    .filter(y => !y.isHidden)
                    .map<any>(y => <Column>{
                        field: y.field,
                        directive: this.columnDirectives.find(u => u.field == y.field),
                        styles: {}
                    });

                this.rowCheckColumn.directive.fixed = this.columns[0].directive.fixed;
            });
    }

    /**
     * Vrací definici sloupce pro daný index pořadí
     * @param index
     */
    private _getColumn(index: number): Column {
        if (this.rowCheckingEnabled) {
            if (index == 0) {
                return this.rowCheckColumn;
            }

            index -= 1;
        }

        if (index >= this.columns.length) {
            return this._defaultColumn;
        }

        return this.columns[index];
    }

	/**
	 * Vrací počet zaškrtnutých řádků na aktuální stránce 
	 */
    private _numberOfCheckedOnPage(): number {
        let retval: number = 0;

        this.items.forEach(x => {
            retval = retval + (this.checkedIds.contains(x.id) ? 1 : 0);
        });

        return retval;
    };

	/**
	 * Resizujeme buňky zobrazené (fixnuté) hlavičky na základě té skryté.
	 */
    private _resizeColumns() {
        if (!this._headRow || !this._hiddenHeadRow) {
            return;
        }

        this._renderer.setStyle(this.gridHead.nativeElement, 'display', 'table');

        // Iteruju po buňkách skryté hlavičky a nastavuju šířku zobrazené
        for (var i = 0; i < this._hiddenHeadRow.children.length; i++) {
            let column: Column = this._getColumn(i);
            let headHiddenCell = this._hiddenHeadRow.children[i];
            let width = column.directive.width > 0 ?
                column.directive.width :
                headHiddenCell.getBoundingClientRect().width;

            column.headerStyles = {
                'min-width': width + 'px',
                'max-width': width + 'px'
            };

            if (column.directive.width > 0) {
                column.bodyStyles = {
                    'min-width': width + 'px',
                    'max-width': width + 'px'
                };
            }
            else {
                column.bodyStyles = {};
            }
        }

        this._changeDetector.detectChanges();
    }

	/**
	 * Nastaví hledaný string do handleru nastavení -> událost pak přenačte data
	 **/
    private _setSearchFor(): void {
        this._settingsHandler.setSearchFor(this.searchFor);
    }

    /**
     * Nastaví vychozi pocet radku na stranku
     **/
    private _setPageSize(rowCount: number): void {
        this._settingsHandler.setPageSize(rowCount);
    }

	/**
	 * Nastavuje pozici hlavičky při skrolování
	 */
    private _setupScroll() {
        let scrollY = this.gridBody.nativeElement.scrollTop;

        // Pokud se změnil X scroll, nastavíme left hlavičky
        if (scrollY != this._scrollY) {
            let table = this.gridHead.nativeElement.children[0];

            this._renderer.setStyle(table, 'top', scrollY + 'px');

            if (scrollY !== 0) {
                this._renderer.addClass(table, "header-fixed");
            } else {
                this._renderer.removeClass(table, "header-fixed");
            }

            this._scrollY = scrollY;
        }
    }
}

class Column {
    field: string;
    directive: GridColumnDirective;
    styles: { [key: string]: string } = {};
    headerStyles: { [key: string]: string } = {};
    bodyStyles: { [key: string]: string } = {};
}