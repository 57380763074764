import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer, NgForm, NgModelGroup } from '@angular/forms';

/**
 * Funkcionalita marží v detailu cenové hladiny
 */

@Component({
    selector: 'div.kt-price-list-margins',
    templateUrl: "/template/lists/price-list/components/price-list-margins.cshtml",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PriceListMarginsComponent),
			multi: true
		}
	],
	viewProviders: [
		{
			provide: ControlContainer,
			useExisting: NgForm
		}
	]
})
export class PriceListMarginsComponent implements ControlValueAccessor, OnInit {
	public disabled: boolean = false;

	// Model a getter/setter
    private _margins: Array<Margin> = [];

    public get margins(): Array<Margin> {
		return this._margins;
	}
    public set margins(value: Array<Margin>) {
		this._margins = value;
	}

	// Drží 'index' naposledy přidané marže
    private _marginsIdx: number;

    constructor() {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	/**
	 * Přidá nový řádek pro marži
	 */
    addMargin() {
        if (this._margins.length == 1) {            
            this._margins.push({
                id: 0,
                from: 0.00,
                to: 0.00,
                margin: 0,
                $i: this._marginsIdx++
            });
            this._margins.reverse();
        } else {
            let last = this._margins.pop();
            this._marginsIdx--;
            this._margins.push({
                id: 0,
                from: this._margins[this._marginsIdx - 1].to + 0.01,
                to: this._margins[this._marginsIdx - 1].to + 0.01,
                margin: 0,
                $i: this._marginsIdx++
            });
            last.from = parseFloat((this._margins[this._marginsIdx - 1].to + 0.01).toFixed(2));
            this._margins.push(last);
        }

        this.reindexMargins();

        this._propagateChange(this._margins);
	}

	/**
	 * Odebere marži, která je v seznamu na specifikovaném indexu
	 * @param index
	 */
    removeMargin(index: number) {        
        if (index < this._margins.length - 1) {
            this._margins[index + 1].from = this._margins[index].from;
        }
		this._margins.splice(index, 1);        

        this.reindexMargins();
        if (index > 0 && index == this._marginsIdx) {
            this._margins[index - 1].to = 999999.99;
        }
		this._propagateChange(this._margins);
    }

    /**
     * Přepočítání hranice marže od
     * @param index
     */
    calcMargin(index: number) {
        if (this._margins.length <= index + 1) {
            return;
        }

        if (this._margins[index].to < this._margins[index].from) {

        }

        this._margins[index + 1].from = this._margins[index].to + 0.01;
        this._propagateChange(this._margins);
    }

	/**
	 * Vrátí unikátní identitu pro daný díl. Nutné pro správnou funkci ngFor.
	 * @param item
	 * @param index
	 */
    marginIdentity(item: Margin, index: number): any {
		return item.$i;
	}

	//
	// Implementace ControlValueAccessor
	//

	private _propagateChange = (_: any) => { };
    private _propagateTouched = () => { };

    private reindexMargins(): void {
        // modifikoval jsem pole, musím přenastavit index
        this._margins = this._margins.map(function (x, i) {
            x.$i = i;
            return x;
        });

        this._marginsIdx = this._margins.length;
    }

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
	writeValue(value: any): void {
		if (value) {
			// musíme na každý díl nastavit interní property, která drží unikátní hodnotu
			// a uložíme si hodnotu
		    this._margins = value.map(function(x, i) {
		        x.$i = i;
		        return x;
		    });
            this._marginsIdx = this._margins.length;
		}
	}

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}

class Margin {
    id: number;
    from: number;
    to: number;
    margin: number;
    $i: number;
}