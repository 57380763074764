import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import {
	UIService,
	FormComponentBase,
	HelperService,
	AppScopeService,
	AuthService,
	IModelService,
	ModelServiceFactory
} from '../../../core';

@Component({
	templateUrl: "/template/portal/commissions/modals/commission-authorization.cshtml"
})
export class CommissionAuthorizationModalComponent extends FormComponentBase implements OnInit {
	private _commissionId: number;
	private _garantId: number;
	private _modelService: IModelService = null;

	public showDecision: boolean;

	constructor(
		public modal: SuiModal<ICommissionAuthorizationModalContext, void>,
        private _modelServiceFactory: ModelServiceFactory,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		protected _appScopeService: AppScopeService) {

		super(_uiService, _helperService, _authService, _appScopeService);

		this._commissionId = modal.context.commissionId;
		this._garantId = modal.context.garantId;
		this._modelService = _modelServiceFactory.createService('portalCommissionAuthorization');
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		//this._uiService.showLoader();

		// Načteme model pro autorizaci ze serveru
		this._modelService.get(0, { commissionId: this._commissionId })
			.then(model => {
				super.setModel(0, model);
			});

        this.showDecision = this._authService.currentUser.portalSpecialPermission.isAgent;
	}

	/**
	 * Ukládáme, vracíme a zavíráme
	 */
	save() {
		this._modelService.set(0, this.model, null)
			.then(model => {
				this.modal.approve(model);
			});
	}

	/**
	 * Zavře modal => cancel
	 */
	back() {
		this.modal.deny(null);
	}
}

export class CommissionAuthorizationModal extends ComponentModalConfig<ICommissionAuthorizationModalContext, void, void> {
	constructor(commissionId: number, garantId: number) {
		super(CommissionAuthorizationModalComponent, { commissionId, garantId });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface ICommissionAuthorizationModalContext {
	commissionId: number;
	garantId: number;
}