﻿tinymce.addI18n('cs', {
	"Redo": "Znovu",
	"Undo": "Zp\u011bt",
	"Cut": "Vyjmout",
	"Copy": "Kop\u00edrovat",
	"Paste": "Vlo\u017eit",
	"Select all": "Vybrat v\u0161e",
	"New document": "Nov\u00fd dokument",
	"Ok": "OK",
	"Cancel": "Zru\u0161it",
	"Visual aids": "Vizu\u00e1ln\u00ed pom\u016fcky",
	"Bold": "Tu\u010dn\u00e9",
	"Italic": "Kurz\u00edva",
	"Underline": "Podtr\u017een\u00e9",
	"Strikethrough": "P\u0159e\u0161rktnut\u00e9",
	"Superscript": "Horn\u00ed index",
	"Subscript": "Doln\u00ed index",
	"Clear formatting": "Vymazat form\u00e1tov\u00e1n\u00ed",
	"Align left": "Zarovnat vlevo",
	"Align center": "Zarovnat na st\u0159ed",
	"Align right": "Zarovnat vpravo",
	"Justify": "Zarovnat do bloku",
	"Bullet list": "Odr\u00e1\u017eky",
	"Numbered list": "\u010c\u00edslov\u00e1n\u00ed",
	"Decrease indent": "Zmen\u0161it odsazen\u00ed",
	"Increase indent": "Zv\u011bt\u0161it odsazen\u00ed",
	"Close": "Zav\u0159\u00edt",
	"Formats": "Form\u00e1ty",
	"Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "V\u00e1\u0161 prohl\u00ed\u017ee\u010d nepodporuje p\u0159\u00edm\u00fd p\u0159\u00edstup do schr\u00e1nky. Pou\u017eijte pros\u00edm kl\u00e1vesov\u00e9 zkratky Ctrl+X\/C\/V.",
	"Headers": "Nadpisy",
	"Header 1": "Nadpis 1",
	"Header 2": "Nadpis 2",
	"Header 3": "Nadpis 3",
	"Header 4": "Nadpis 4",
	"Header 5": "Nadpis 5",
	"Header 6": "Nadpis 6",
	"Headings": "Nadpisy",
	"Heading 1": "Nadpis 1",
	"Heading 2": "Nadpis 2",
	"Heading 3": "Nadpis 3",
	"Heading 4": "Nadpis 4",
	"Heading 5": "Nadpis 5",
	"Heading 6": "Nadpis 6",
	"Div": "Div (blok)",
	"Pre": "Pre (p\u0159edform\u00e1tov\u00e1no)",
	"Code": "Code (k\u00f3d)",
	"Paragraph": "Odstavec",
	"Blockquote": "Citace",
	"Inline": "\u0158\u00e1dkov\u00e9 zobrazen\u00ed (inline)",
	"Blocks": "Blokov\u00e9 zobrazen\u00ed (block)",
	"Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "Je zapnuto vkl\u00e1d\u00e1n\u00ed \u010dist\u00e9ho textu. Dokud nebude tato volba vypnuta, bude ve\u0161ker\u00fd obsah vlo\u017een jako \u010dist\u00fd text.",
	"Font Family": "Typ p\u00edsma",
	"Font Sizes": "Velikost p\u00edsma",
	"Class": "T\u0159\u00edda",
	"Browse for an image": "Vyhledat obr\u00e1zek",
	"OR": "OR",
	"Drop an image here": "Nahr\u00e1t obr\u00e1zek",
	"Upload": "Nahr\u00e1t",
	"Block": "Blok",
	"Align": "Zarovnat",
	"Default": "V\u00fdchoz\u00ed",
	"Circle": "Kole\u010dko",
	"Disc": "Punt\u00edk",
	"Square": "\u010ctvere\u010dek",
	"Lower Alpha": "Norm\u00e1ln\u00ed \u010d\u00edslov\u00e1n\u00ed",
	"Lower Greek": "Mal\u00e9 p\u00edsmenkov\u00e1n\u00ed",
	"Lower Roman": "Mal\u00e9 \u0159\u00edmsk\u00e9 \u010d\u00edslice",
	"Upper Alpha": "velk\u00e9 p\u00edsmenkov\u00e1n\u00ed",
	"Upper Roman": "\u0158\u00edmsk\u00e9 \u010d\u00edslice",
	"Anchor": "Kotva",
	"Name": "N\u00e1zev",
	"Id": "Id",
	"Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "Id by m\u011blo za\u010d\u00ednat p\u00edsmenem a d\u00e1le obsahovat pouze p\u00edsmena, \u010d\u00edsla, poml\u010dky, te\u010dky, dvojte\u010dky, nebo podtr\u017e\u00edtka.",
	"You have unsaved changes are you sure you want to navigate away?": "M\u00e1te neulo\u017een\u00e9 zm\u011bny. Opravdu chcete opustit str\u00e1nku?",
	"Restore last draft": "Obnovit posledn\u00ed koncept",
	"Special character": "Speci\u00e1ln\u00ed znak",
	"Source code": "Zdrojov\u00fd k\u00f3d",
	"Insert\/Edit code sample": "Vlo\u017eit \/ Upravit uk\u00e1zkov\u00fd k\u00f3d",
	"Language": "Jazyk",
	"Code sample": "Uk\u00e1zkov\u00fd k\u00f3d",
	"Color": "Barva",
	"R": "R",
	"G": "G",
	"B": "B",
	"Left to right": "Zleva doprava",
	"Right to left": "Zprava doleva",
	"Emoticons": "Emotikony",
	"Document properties": "Vlastnosti dokumentu",
	"Title": "Titulek",
	"Keywords": "Kl\u00ed\u010dov\u00e1 slova",
	"Description": "Popis",
	"Robots": "Roboti",
	"Author": "Autor",
	"Encoding": "K\u00f3dov\u00e1n\u00ed",
	"Fullscreen": "Na celou obrazovku",
	"Action": "Akce",
	"Shortcut": "Kl\u00e1vesov\u00e1 zkratka",
	"Help": "N\u00e1pov\u011bda",
	"Address": "Blok s po\u0161tovn\u00ed adresou",
	"Focus to menubar": "P\u0159ej\u00edt do menu",
	"Focus to toolbar": "P\u0159ej\u00edt na panel n\u00e1stroj\u016f",
	"Focus to element path": "P\u0159ej\u00edt na element path",
	"Focus to contextual toolbar": "P\u0159ej\u00edt na kontextov\u00fd panel n\u00e1stroj\u016f",
	"Insert link (if link plugin activated)": "Vlo\u017eit odkaz (pokud je aktivn\u00ed link plugin)",
	"Save (if save plugin activated)": "Ulo\u017eit (pokud je aktivni save plugin)",
	"Find (if searchreplace plugin activated)": "Hledat (pokud je aktivn\u00ed plugin searchreplace)",
	"Plugins installed ({0}):": "Instalovan\u00e9 pluginy ({0}):",
	"Premium plugins:": "Pr\u00e9miov\u00e9 pluginy:",
	"Learn more...": "Zjistit v\u00edce...",
	"You are using {0}": "Pou\u017e\u00edv\u00e1te {0}",
	"Plugins": "Pluginy",
	"Handy Shortcuts": "U\u017eite\u010dn\u00e9 kl\u00e1vesov\u00e9 zkratky",
	"Horizontal line": "Vodorovn\u00e1 \u010d\u00e1ra",
	"Insert\/edit image": "Vlo\u017eit \/ upravit obr\u00e1zek",
	"Image description": "Popis obr\u00e1zku",
	"Source": "URL",
	"Dimensions": "Rozm\u011bry",
	"Constrain proportions": "Zachovat proporce",
	"General": "Obecn\u00e9",
	"Advanced": "Pokro\u010dil\u00e9",
	"Style": "Styl",
	"Vertical space": "Vertik\u00e1ln\u00ed mezera",
	"Horizontal space": "Horizont\u00e1ln\u00ed mezera",
	"Border": "R\u00e1me\u010dek",
	"Insert image": "Vlo\u017eit obr\u00e1zek",
	"Image": "Obr\u00e1zek",
	"Image list": "Seznam obr\u00e1zk\u016f",
	"Rotate counterclockwise": "Oto\u010dit doleva",
	"Rotate clockwise": "Oto\u010dit doprava",
	"Flip vertically": "P\u0159evr\u00e1tit svisle",
	"Flip horizontally": "P\u0159evr\u00e1tit vodorovn\u011b",
	"Edit image": "Upravit obr\u00e1zek",
	"Image options": "Vlastnosti obr\u00e1zku",
	"Zoom in": "P\u0159ibl\u00ed\u017eit",
	"Zoom out": "Odd\u00e1lit",
	"Crop": "O\u0159\u00edznout",
	"Resize": "Zm\u011bnit velikost",
	"Orientation": "Transformovat",
	"Brightness": "Jas",
	"Sharpen": "Ostrost",
	"Contrast": "Kontrast",
	"Color levels": "\u00darovn\u011b barev",
	"Gamma": "Gama",
	"Invert": "Invertovat",
	"Apply": "Pou\u017e\u00edt",
	"Back": "Zp\u011bt",
	"Insert date\/time": "Vlo\u017eit datum \/ \u010das",
	"Date\/time": "Datum\/\u010das",
	"Insert link": "Vlo\u017eit odkaz",
	"Insert\/edit link": "Vlo\u017eit \/ upravit odkaz",
	"Text to display": "Text k zobrazen\u00ed",
	"Url": "URL",
	"Target": "C\u00edl",
	"None": "\u017d\u00e1dn\u00e9",
	"New window": "Nov\u00e9 okno",
	"Remove link": "Odstranit odkaz",
	"Anchors": "Kotvy",
	"Link": "Odkaz",
	"Paste or type a link": "Vlo\u017eit nebo napsat odkaz",
	"The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "Zadan\u00e9 URL vypad\u00e1 jako e-mailov\u00e1 adresa. Chcete doplnit povinn\u00fd prefix mailto:?",
	"The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "Zadan\u00e9 URL vypad\u00e1 jako odkaz na jin\u00fd web. Chcete doplnit povinn\u00fd prefix http:\/\/?",
	"Link list": "Seznam odkaz\u016f",
	"Insert video": "Vlo\u017eit video",
	"Insert\/edit video": "Vlo\u017eit \/ upravit video",
	"Insert\/edit media": "Vlo\u017eit \/ upravit m\u00e9dia",
	"Alternative source": "Alternativn\u00ed zdroj",
	"Poster": "N\u00e1hled",
	"Paste your embed code below:": "Vlo\u017ete k\u00f3d pro vlo\u017een\u00ed n\u00ed\u017ee:",
	"Embed": "Vlo\u017eit",
	"Media": "M\u00e9dia",
	"Nonbreaking space": "Pevn\u00e1 mezera",
	"Page break": "Konec str\u00e1nky",
	"Paste as text": "Vlo\u017eit jako \u010dist\u00fd text",
	"Preview": "N\u00e1hled",
	"Print": "Tisk",
	"Save": "Ulo\u017eit",
	"Find": "Naj\u00edt",
	"Replace with": "Nahradit za",
	"Replace": "Nahradit",
	"Replace all": "Nahradit v\u0161e",
	"Prev": "P\u0159edchoz\u00ed",
	"Next": "Dal\u0161\u00ed",
	"Find and replace": "Naj\u00edt a nahradit",
	"Could not find the specified string.": "Zadan\u00fd \u0159et\u011bzec nebyl nalezen.",
	"Match case": "Rozli\u0161ovat mal\u00e1 a velk\u00e1 p\u00edsmena",
	"Whole words": "Pouze cel\u00e1 slova",
	"Spellcheck": "Kontrola pravopisu",
	"Ignore": "Ignorovat",
	"Ignore all": "Ignorovat v\u0161e",
	"Finish": "Ukon\u010dit",
	"Add to Dictionary": "P\u0159idat do slovn\u00edku",
	"Insert table": "Vlo\u017eit tabulku",
	"Table properties": "Vlastnosti tabulky",
	"Delete table": "Smazat tabulku",
	"Cell": "Bu\u0148ka",
	"Row": "\u0158\u00e1dek",
	"Column": "Sloupec",
	"Cell properties": "Vlastnosti bu\u0148ky",
	"Merge cells": "Slou\u010dit bu\u0148ky",
	"Split cell": "Rozd\u011blit bu\u0148ky",
	"Insert row before": "Vlo\u017eit \u0159\u00e1dek nad",
	"Insert row after": "Vlo\u017eit \u0159\u00e1dek pod",
	"Delete row": "Smazat \u0159\u00e1dek",
	"Row properties": "Vlastnosti \u0159\u00e1dku",
	"Cut row": "Vyjmout \u0159\u00e1dek",
	"Copy row": "Kop\u00edrovat \u0159\u00e1dek",
	"Paste row before": "Vlo\u017eit \u0159\u00e1dek nad",
	"Paste row after": "Vlo\u017eit \u0159\u00e1dek pod",
	"Insert column before": "Vlo\u017eit sloupec vlevo",
	"Insert column after": "Vlo\u017eit sloupec vpravo",
	"Delete column": "Smazat sloupec",
	"Cols": "Sloupc\u016f",
	"Rows": "\u0158\u00e1dek",
	"Width": "\u0160\u00ed\u0159ka",
	"Height": "V\u00fd\u0161ka",
	"Cell spacing": "Vn\u011bj\u0161\u00ed okraj bun\u011bk",
	"Cell padding": "Vnit\u0159n\u00ed okraj bun\u011bk",
	"Caption": "Nadpis",
	"Left": "Vlevo",
	"Center": "Na st\u0159ed",
	"Right": "Vpravo",
	"Cell type": "Typ bu\u0148ky",
	"Scope": "Rozsah",
	"Alignment": "Zarovn\u00e1n\u00ed",
	"H Align": "Horizont\u00e1ln\u00ed zarovn\u00e1n\u00ed",
	"V Align": "Vertik\u00e1ln\u00ed zarovn\u00e1n\u00ed",
	"Top": "Nahoru",
	"Middle": "Uprost\u0159ed",
	"Bottom": "Dol\u016f",
	"Header cell": "Hlavi\u010dkov\u00e1 bu\u0148ka",
	"Row group": "Skupina \u0159\u00e1dk\u016f",
	"Column group": "Skupina sloupc\u016f",
	"Row type": "Typ \u0159\u00e1dku",
	"Header": "Hlavi\u010dka",
	"Body": "T\u011blo",
	"Footer": "Pati\u010dka",
	"Border color": "Barva r\u00e1me\u010dku",
	"Insert template": "Vlo\u017eit \u0161ablonu",
	"Templates": "\u0160ablony",
	"Template": "\u0160ablona",
	"Text color": "Barva p\u00edsma",
	"Background color": "Barva pozad\u00ed",
	"Custom...": "Vlastn\u00ed...",
	"Custom color": "Vlastn\u00ed barva",
	"No color": "Bez barvy",
	"Table of Contents": "Obsah",
	"Show blocks": "Uk\u00e1zat bloky",
	"Show invisible characters": "Zobrazit speci\u00e1ln\u00ed znaky",
	"Words: {0}": "Po\u010det slov: {0}",
	"{0} words": "Po\u010det slov: {0}",
	"File": "Soubor",
	"Edit": "\u00dapravy",
	"Insert": "Vlo\u017eit",
	"View": "Zobrazit",
	"Format": "Form\u00e1t",
	"Table": "Tabulka",
	"Tools": "N\u00e1stroje",
	"Powered by {0}": "Vytvo\u0159il {0}",
	"Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "Editor. Stiskn\u011bte ALT-F9 pro menu, ALT-F10 pro n\u00e1strojovou li\u0161tu a ALT-0 pro n\u00e1pov\u011bdu."
});