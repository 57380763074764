import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../../core';
import { SettingModulesServiceTrendConnectorModule } from './';

// Pages
import { ServiceTrendConnectorDetailPageComponent } from './pages/module';

const routes: Routes = [
    {
        path: '',
        component: ServiceTrendConnectorDetailPageComponent
    },
    {
        path: ':id',
        component: ServiceTrendConnectorDetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        SettingModulesServiceTrendConnectorModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SettingModulesServiceTrendConnectorRoutingModule { }