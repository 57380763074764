import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModelServiceFactory, IModelService } from '../../../core';

@Component({
	templateUrl: "/template/portal/support/pages/support-list.cshtml"
})
export class SupportListPageComponent implements OnInit {
	/**
	 * ID garanta pro kter�ho se zobrazuj� data
	 */
	private _garantId: number;

	/**
	 * Instance model service
	 */
	public modelService: IModelService;

    constructor(
        private _route: ActivatedRoute,
		private _router: Router,
		private _modelServiceFactory: ModelServiceFactory) {

		this.modelService = _modelServiceFactory.createService('portalSupport');
	}

	/**
	 * Inicializace komponenty
	 */
    ngOnInit() {
        // Ukl�d�m id garanta z routy
        this._garantId = this._route.snapshot.params.garantId;
    }

	/**
	 * Redirekt na str�nku pro p�id�n�.
	 */
    add() {
        this._router.navigateByUrl(`/app/portal/support/${this._garantId}/0`);
    }

	/**
	 * Redirekt na str�nku s editac�.
	 * @param item
	 */
    edit(item: any) {
        this._router.navigateByUrl(`/app/portal/support/${this._garantId}/${item.id}`);
    }
}