import { filter, map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

/**
 * Posktyuje metody pro práci s aplikačními scopy
 */
@Injectable()
export class AppScopeService {
	currentScope: string = null;

	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute) {
		// Přihlásíme s odběru události NavigationEnd abychom nastavil scope z dat routy
		this._router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => {
					let route = this._activatedRoute;
					let retval: string = null;

					// Procházíme rekurzivně stromem přes firstChild vlastnost
					while (route) {
						retval = this._getAppScope(route.snapshot) || retval;

						route = route.firstChild;
					}

					return retval;
				})
			)
			.subscribe(scope => {
				this.currentScope = scope;
			});
	}

	/**
	 * Pokusí se dodanou rotu a její předky získat aplikační scope z dat.
	 *
	 * @param route Snapshot routy pro který má vrátit scope
	 */
	public getAppScopeFromRoute(route: ActivatedRouteSnapshot): string {
		let retval = null;

		while (route && retval === null) {
			retval = this._getAppScope(route);

			route = route.parent;
		}

		return retval;
	}

	/**
	 * Pokusí se s routy získat appScope hodnotu.
	 *
	 * @param route
	 */
	private _getAppScope(route: ActivatedRouteSnapshot): string {
		let scope: string = null;

	    //console.log(route.data['appScope']);

		if (route.data['appScope']) {
			scope = <string>route.data['appScope'];

			// appScope může obsahovat zástupný znak pro parametr routy - např. {id}
			// musíme ho replacnout hodnotou parametru
			scope = scope.replace(
				/(\{[^}]*\})/g,
				(found: string, ...y: any[]) => {
					let paramKey = found.substr(1, found.length - 2);
					return route.params[paramKey];
				}
			);
		}

		return scope;
	}
}