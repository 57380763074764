import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent } from '../../core';
import { TransportCarrierCollectionService } from '../services/transportcarriercollection';

@Component({
    templateUrl: "/template/logistics/pages/transportcarriercollection-list.cshtml"
})
export class TransportCarrierCollectionListPageComponent {
    /**
     * Reference na grid komponentu
     */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

    constructor(
        private _router: Router,
        private _uiService: UIService,
        public transportCarrierCollectionService: TransportCarrierCollectionService) {
    }

    /**
     * Redirekt na stránku pro přidání.
     */
        add() {
            this._router.navigateByUrl('/app/logistics/transportcarriercollection/0');
        }


    
	/**
	 * Redirekt na stránku s editací.
	 * @param commission
	 */
    edit(item: any) {
        this._router.navigateByUrl(`/app/logistics/transportcarriercollection/${item.id}`);
	}

     /**
	 * Smazání 
	 * @param user
	 */
    delete(item: any) {
		this._uiService
            .showConfirmation('Odstranění záznam', `Opravdu si přejete odstranit záznam ${item.name}?`)
            .then(result => {
                if (result) {
                    this._uiService.showLoader();

                    this.transportCarrierCollectionService.delete(item.id).then(
                        result => {
                            this._uiService.hideLoader();
                            this._grid.loadData();
                            });
                    }
			    });
        }

	public stateFilter: any = { stateId: null };

	stateFilterChange(value: any) {
		this.stateFilter.stateId = value;
	}
}
