import { Injectable } from '@angular/core';
import { HelperService } from '../services/helper';
import { ApiService } from '../services/api';
import { UIService } from '../services/ui';

/**
 * Ukládání a načítání různých uživatelských dat - např. nastavení seznam.
 * 
 * @export
 * @class DataStorageService
 */
@Injectable()
export class DataStorageService {

	/**
	 * Cache pro user data
	 */
	private _userDataCache: { [key: string]: any; } = {};

	constructor(
		private _helperService: HelperService,
		private _apiService: ApiService,
		private _uiService: UIService) {
	}

	/**
	 * Vrací data definované generickým typem a uložené pod specifikovaným klíčem.
	 * Data jsou různé pro různé uživatele.
	 * @param key
	 */
	public getUserData<TData>(key: string): Promise<TData> {
		return new Promise((resolve, reject) => {
			// Zkusíme načíst z cache
			let retval = this._userDataCache[key];

			if (typeof (retval) != 'undefined') {
				resolve(<TData>retval);
				return;
			}

			this._apiService.get<TData>(`/userdata/${key}`)
				.then(
					x => {
						this._helperService.convertDateStringsToDates(x);
						this._userDataCache[key] = x;

						resolve(x);
					},
					reason => {
						this._uiService.showError('Nepodařilo se načíst uživatelská data');
						resolve(null);
					});
		});
	}

	/**
	 * Uloží data pro aktuálního uživatele.
	 * @param key
	 * @param data
	 */
	public setUserData<TData>(key: string, data: TData): Promise<any> {
		if (!data) {
			this._userDataCache[key] = null;
			return this._apiService.delete(`/userdata/${key}`);
		}

		let serializedData = JSON.stringify(data).replace(/\"/g, '\\"');
		let payload = `"${serializedData}"`;

		this._userDataCache[key] = data;

		return this._apiService.post<void>(`/userdata/${key}`, payload);
	}
}