import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../../core';
import { SettingModulesModule3Module } from './';

// Pages
import { Module3DetailPageComponent } from './pages/module';

const routes: Routes = [
    {
        path: '',
        component: Module3DetailPageComponent
    },
    {
        path: ':id',
        component: Module3DetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        SettingModulesModule3Module,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SettingModulesModule3RoutingModule { }