import { Component, OnInit } from '@angular/core';
import { 
	UIService, 
	ApiService, 
	FormComponentBase,
	HelperService,
	AppScopeService,
	AuthService
} from '../../../core';

@Component({
	templateUrl: "/template/settings/general-settings/pages/general-settings.cshtml"
})
export class GeneralSettingsPageComponent extends FormComponentBase implements OnInit {

	public logoFilePath: string;
	public stampFilePath: string;

	constructor(
		protected _uiService: UIService,
		private _apiService: ApiService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		protected _appScopeService: AppScopeService) {

		super(_uiService, _helperService, _authService, _appScopeService);
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this._uiService.showLoader();

		this._apiService.get<any>('generalsettings').then(
			model => {
				// Posílám 1, aby se permission kontrolovaly na Update
				super.setModel(1, model);

				this.logoFilePath = model.company.logoFilePath;
				this.stampFilePath = model.company.stampFilePath;

				this._uiService.hideLoader();
			}
		);
	}

	/**
	 * Uloží nastavení
	 */
	public save() {
		this._uiService.showLoader();

		this._apiService.post<any>('generalsettings', this.model).then(
			model => {
				// Posílám 1, aby se permission kontrolovaly na Update
				super.setModel(1, model);

				this.logoFilePath = model.company.logoFilePath;
				this.stampFilePath = model.company.stampFilePath;

				this._uiService.hideLoader();
			});
	}

	/**
	 * Zruší vybrané logo 
	 */
	public clearLogo() {
		this.logoFilePath = '';
		this.model.company.logoFilePath = '';
		this.model.company.logoFileContent = '';
	}

	/**
	 * Zruší vybrané razítko 
	 */
	public clearStamp() {
		this.stampFilePath = '';
		this.model.company.stampFilePath = '';
		this.model.company.stampFileContent = '';
	}

	/**
	 * Vrací boolean jestli je nastaven obrázek loga.
	 */
	public hasLogo(): boolean {
		return this.logoFilePath &&
			this.logoFilePath.length > 0;
	}

	/**
	 * Vrací boolean jestli je nastaven obrázek razítka.
	 */
	public hasStamp(): boolean {
		return this.stampFilePath &&
			this.stampFilePath.length > 0;
	}

	/**
	 * Zpracovává událost výběru obrázku
	 * @param event
	 */
	public logoChanged(event) {
		var file: File = event.target.files[0];

		if (file.size > 200000) {
			event.target.value = '';
			this._uiService.showError('Zvolte obrázek o velikosti menší než 200kB.', 'Příliš velký obrázek');
			return;
		}

		var reader: FileReader = new FileReader();

		reader.onloadend = (e) => {
			this.logoFilePath = <string>reader.result;
			this.model.company.logoFilePath = '';
			this.model.company.logoFileContent = (<string>reader.result).split(',')[1];

			event.target.value = '';
		}

		reader.readAsDataURL(file);
	}

	/**
	 * Zpracovává událost výběru razítka
	 * @param event
	 */
	public stampChanged(event) {
		var file: File = event.target.files[0];

		if (file.size > 200000) {
			event.target.value = '';
			this._uiService.showError('Zvolte obrázek o velikosti menší než 200kB.', 'Příliš velký obrázek');
			return;
		}

		var reader: FileReader = new FileReader();

		reader.onloadend = (e) => {
			this.stampFilePath = <string>reader.result;
			this.model.company.stampFilePath = '';
			this.model.company.stampFileContent = (<string>reader.result).split(',')[1];

			event.target.value = '';
		}

		reader.readAsDataURL(file);
	}

	/**
	 * Vrací cestu k obrázku loga.
	 */
	public logoSrc() {
		return this.logoFilePath;
	}

	/**
	 * Vrací cestu k obrázku razítka.
	 */
	public stampSrc() {
		return this.stampFilePath;
	}
}