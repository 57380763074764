import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';


//// Pages
import { PhilipsPageComponent } from './pages/philips';
import { SamsungPageComponent } from './pages/samsung'


@NgModule({
	imports: [
        CommonModule,
        CoreModule,
        RouterModule,
        FormsModule,
        SuiModule
	],
    declarations: [
        PhilipsPageComponent,
        SamsungPageComponent
	],
    exports: [
        RouterModule
    ]
})
export class SvtModule { }