import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';

// Pages
import { Module3DetailPageComponent } from './pages/module';

// Components
import { Module3CategoriesComponent } from './components/module-categories';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        FormsModule,
        SuiModule
    ],
    exports: [
        RouterModule
    ],
    declarations: [
        Module3DetailPageComponent,
        Module3CategoriesComponent
    ],
    providers: [
    ],
    entryComponents: [
    ]
})
export class SettingModulesModule3Module { }