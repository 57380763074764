import {
    Directive,
	Input,
	OnInit,
	forwardRef,
	Output,
	EventEmitter,
	OnDestroy
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/*
 <kt-toggle label="Popisek k toggle"></kt-toggle>
 nebo
 <kt-toggle labelTrue="Zapnuto" labelFalse="Vypnuto"></kt-toggle>
*/

@Directive({
    selector: 'div.ui.checkbox.toggle',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleDirective),
            multi: true
        }
    ]
})
export class ToggleDirective implements OnInit, OnDestroy, ControlValueAccessor {

	/**
	 * Drží hodnoty vybraných voleb.
	 */
	private _value: boolean;

	// Getter a setter pro _value
	get value(): boolean {
		return this._value;
	}
	set value(value: boolean) {
		this._value = value;

        this.label = this.value ? this.labelTrue : this.labelFalse;
		this.change.emit(value);
		this._propagateChange(value);
	}

	// Input/Output

	@Input() public disabled: boolean = false;

    @Input() public label: string;

    @Input() public labelFalse: string;

    @Input() public labelTrue: string;

	@Output('onChange') public change: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
        
	}

	/**
	 * Inicializace komponenty
	 */
    ngOnInit(): void {
        if (typeof (this.labelFalse) === "undefined" || this.labelFalse.length === 0) {
            this.labelFalse = this.label;
        }

        if (typeof (this.labelTrue) === "undefined" || this.labelTrue.length === 0) {
            this.labelTrue = this.label;
        }

        this.label = this.value ? this.labelTrue : this.labelFalse;
	}

	/**
	 * Destrukce komponenty
	 */
	ngOnDestroy() {
	}

	/**
	 * Zpracovává událost kliknutí na control 
	 */
	public click() {
        this.value = !this.value;
    }

	//
	// Implementace ControlValueAccessor
	//

	private _propagateChange = (_: any) => { };
	private _propagateTouched = () => { };

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
	writeValue(value: any): void {
		this._value = value;
	}

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}