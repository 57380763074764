import { Component, OnInit, Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth';
import { UIService } from '../services/ui';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	templateUrl: "/template/core/pages/sign-in.cshtml"
})

@Injectable()
export class SignInPageComponent implements OnInit {
	signInForm: FormGroup;
    loading: boolean = false;
	returnUrl: string;

	window = window;
    
    constructor(
        protected _activatedRoute: ActivatedRoute,
		private _authService: AuthService,
		private _uiService: UIService,
		private _router: Router) {

	}

    ngOnInit() {

        this._activatedRoute.queryParams.subscribe(params => {
            if (params['message'] != undefined)
                this._uiService.showSuccess(params['message'], 'Změna hesla');
        })

        
		// Inicializace formuláře
		this.signInForm = new FormGroup({
			username: new FormControl(null, [ Validators.required ]),
			password: new FormControl(null, [ Validators.required ])
        });

        this.returnUrl = this._activatedRoute.snapshot.queryParams['returnUrl'] || '';
	}

	isValid(): boolean {
		return this.signInForm.valid;
	}

	confirm() {
		this.loading = true;

		this._authService
			.signIn(this.signInForm.get('username').value, this.signInForm.get('password').value)
			.then(result => {
                if (result.success) {

                    if (result.needChangePassword) {
                        // Redirekt
                        this._router.navigate(['first-logged']);
                    } else { 
					    // Redirekt
                        if (this.returnUrl.length > 0) {
                            this._router.navigateByUrl(this.returnUrl);
						} else {
                            this._router.navigate(['app', 'dashboard']);
                        }
                    }
				}
				else {
					this.loading = false;
					this._uiService.showError(result.message, 'Přihlášení se nezdařilo');
				}
			});
	}

	/**
	 * Detekce prohlizece chrome
	 * */
	isChromeBrowser(): boolean {
		return (this.window.navigator.userAgent.indexOf("Chrome") > -1);
	}

}