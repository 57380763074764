import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CommonPermission, CoreModule, CanDeactivateGuard } from '../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';

// Services
import { LogService } from './services/logs';

// Pages
import { LogListPageComponent } from './pages/log-list';

// Routes
const routes: Routes = [
    {
        path: 'logs',
        data: {
        },
        children: [
            { path: '', component: LogListPageComponent }
        ]
    }
]

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        RouterModule.forChild(routes),
        FormsModule,
        SuiModule
    ],
    declarations: [
        LogListPageComponent
    ],
    exports: [
        RouterModule
    ],
    providers: [
        LogService
    ]
})
export class LogModule { }