import { Input, Component, ViewChild, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UIService, GridComponent, IModelService, ModelServiceFactory, AuthService, ModalService } from '../../../core';
import { CommissionAuthorizationModal } from '../modals/commission-authorization';
import { Subscription } from 'rxjs';

/**
 * Funkcionalita autorizací v detailu zakázky
 */

@Component({
	selector: 'div.kt-portal-commission-authorizations',
	templateUrl: "/template/portal/commissions/components/portal-commission-authorizations.cshtml"
})
export class PortalCommissionAuthorizationsComponent implements OnInit, OnDestroy {
	private _subs: Array<Subscription> = [];

	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * Vstup id zakázky pro kterou mají být zobrazeny autorizace
	 */
	@Input() commissionId: number;

	/**
	 * Vstup pro id garanta
	 */
	@Input() garantId: number;

	// Událost spuštěna po načtení dat
	@Output() onDataLoaded: EventEmitter<any[]> = new EventEmitter();

	/**
	 * Instance model service
	 */
	public modelService: IModelService;
    public authService: AuthService;

	constructor(
		private _uiService: UIService,
        private _modelServiceFactory: ModelServiceFactory,
        protected _authService: AuthService,
		private _modalService: ModalService) {

        this.modelService = _modelServiceFactory.createService('portalCommissionAuthorization');
        this.authService = _authService;
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		// Nastavíme commission id aby bylo posíláno na API jako filtr pro autorizace
		this._grid.customFilter = { commissionId: this.commissionId };

		// Forwardujeme událost o načtení dat z gridu výše
		this._subs.push(
			this._grid.onDataLoaded.subscribe(x => {
				this.onDataLoaded.next(x);
			})
		);
	}

	/**
	 * Destrukce komponenty
	 */
	ngOnDestroy() {
		// Odhlásíme ze všech observables
		this._subs.forEach(x => x.unsubscribe());
    }

    /**
     * Mame prava agenta?
     */
    protected isAgent(): boolean {
        return this.authService.currentUser.portalSpecialPermission.isAgent;
    }

	/**
	 * Zobrazí dialog pro přidání nové autorizace.
	 */
	addAuthorization() {
		// Otevřeme modal
		this._modalService
			.openModal(new CommissionAuthorizationModal(this.commissionId, this.garantId))
			.onApprove(result => {
				// Když bylo uloženo, re-načítám data
				this._grid.loadData();
			});
	}
}