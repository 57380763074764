import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIService, ModelFormComponentBase, HelperService, AppScopeService, AuthService, ModelServiceFactory } from '../../../core';

@Component({
	templateUrl: "/template/settings/branch/pages/branch-detail.cshtml"
})
export class BranchDetailPageComponent extends ModelFormComponentBase {
	constructor(
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		protected _appScopeService: AppScopeService,
		private _modelServiceFactory: ModelServiceFactory,
		protected _router: Router) {

		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_modelServiceFactory.createService('branch'),
			_router);
	}

	/**
	 * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
	 */
	getTitle() {
		return this.modelId == 0 ? 'Nová skupina' : 'Editace skupiny';
	}
}