import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIService, ModelServiceFactory, HelperService, AppScopeService, AuthService } from '../../../core';
import { PortalFormComponentBase } from '../../core';

@Component({
	templateUrl: "/template/portal/docs/pages/docs-detail.cshtml"
})
export class DocsDetailPageComponent extends PortalFormComponentBase implements OnInit {

	constructor(
		protected _router: Router,
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		protected _appScopeService: AppScopeService,
		private _modelServiceFactory: ModelServiceFactory) {

		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_modelServiceFactory.createService('portalDocs'),
			_router);
	}

	/**
	 * Override metody v ModelFormComponentBase - po vložení redirektujeme na editaci
	 */
	protected onModelSaved(model: any) {
		if (this.modelId == 0) {
			this._router.navigate(['app', 'portal', 'docs', this.garantId, model.id], {});
		}
	}

	/**
	 * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
	 */
	getTitle() {
		return this.modelId == 0 ? 'Nová dokumentace / software' : this.model.number;
	}
}