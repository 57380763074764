import { Injectable } from '@angular/core';
import { ModelService, ApiService, UIService, HelperService } from '../../../core';

/**
 * Poskytuje operace nad ceníky.
 */
@Injectable()
export class ListsPriceListService extends ModelService {

	constructor(
		apiService: ApiService,
		uiService: UIService,
		private _helperService: HelperService) {
		// Voláme konstruktor bázové třídy
		super('listPriceList', apiService, uiService);
	}

	/**
	 * Přepočítá cenu s marží pro daný ceník
	 * @param pricelist
	 * @param price
	 */
	public computePriceByPricelist(pricelist: IPriceListModel, price: number): number {
		let margin: IPriceListMarginModel = pricelist
			.margins
			.find(x => x.from <= price && x.to >= price);
		let multiplier = margin ?
			(100 + margin.margin) / 100 :
			1;

		return this._helperService.round(price * multiplier, 2);
	}

	/**
	 * Returns model of pricelist specified for commission type with provided identifier
	 * @param commissionTypeId
	 */
	public getByCommissionType(commissionTypeId: number): Promise<IPriceListModel> {
		return this.apiService.get<IPriceListModel>(super.createUrl('commission-type/' + commissionTypeId));
	}
}