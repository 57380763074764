import { Inject, Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth';
import { AppNotificationsService } from '../services/app-notifications';
import { CoreService } from '../services/core';
import { Router, NavigationEnd } from '@angular/router';
import { SectionItem } from '../models';


/**
 * Horní hlavní menu
 */

@Component({
	selector: 'div.kt-navbar',
	templateUrl: "/template/core/components/navbar.cshtml"
})
export class NavbarComponent implements OnInit {
    userFullName: string;
    menuClass: string;
    
    // Model a getter/setter
    public _sections: Array<SectionItem> = [];

    public toggleMenu: boolean = false;

	constructor(
		private _authService: AuthService,
        private _router: Router,
        private _coreService: CoreService,
        private _appNotificationsService: AppNotificationsService) {
	}

    public filteredSections(id: number, position: string) {
        return this._sections.filter(x => x.parentId == id && x.position == position).sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));;
    }


	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
        this.userFullName = this._authService.currentUser.fullName;
        this._coreService.getNavbarModel().then(result => {
            if (result) {
                //console.log(result);
                this._sections = result;
            }
        });

    }

	/**
	 * Provede odhlášení.
	 */
	logOut() {
		this._authService.logOut();

		// Redirekt na přihlášení
		this._router.navigate([ 'signin' ]);
    }

    /**
     * Udalost resici pridani tridy nad div s menu
     * */
    public toggleMenuClick(open: boolean): void {
        this.toggleMenu = open;

        // V menu zustavalo otevrene, je potreba jej uzavrit po prechodu na jinou stranku.
        this._router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                if (open) {
                    this.toggleMenu = false;
                }

            }
        });
    }

    public toggleMenuItem(target: HTMLElement): void {
        if (!target) {
            return;
        }

        let parent = target.parentElement;
        let menu = parent.getElementsByClassName('menu');

        if (!menu.length) {
            target = parent;
            parent = target.parentElement;
            menu = parent.getElementsByClassName('menu');
        }

        if (!menu.length) {
            parent = parent.parentElement;
            menu = parent.getElementsByClassName('menu');
        }

        if (menu && menu.length) {
            menu.item(0).classList.toggle('active');
            target.classList.toggle('active');
        }

    }
}