import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule, CanDeactivateGuard } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { PortalCoreModule } from '../core';

// Pages
import { ComplaintListPageComponent } from './pages/complaint-list';
import { ComplaintDetailPageComponent } from './pages/complaint-detail';

const routes: Routes = [
	{
		path: ':garantId',
		children: [
			{ path: '', component: ComplaintListPageComponent },
			{ path: ':id', component: ComplaintDetailPageComponent, canDeactivate: [CanDeactivateGuard] }
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		CoreModule,
		FormsModule,
		SuiModule,
		PortalCoreModule
	],
	exports: [
		RouterModule
	],
	declarations: [
		ComplaintListPageComponent,
		ComplaintDetailPageComponent
	],
	providers: [
	],
	entryComponents: [
	]
})
export class PortalComplaintsModule { }