import { Component, OnInit, OnDestroy } from '@angular/core';
import {
	SelectOption,
	UIService,
	ModelServiceFactory,
	ListFilter,
	PageInfo,
	Sorting,
	ModalService,
	DataStorageService
} from '../../core';
import { ModelListModal } from '../modals/model-list';
import { CommissionViewModel } from '../services/commission-view-model';
import { FormControlsState } from '../services/form-controls-state';
import { SectionComponentBase } from '../classes/section-component-base';
import { SerialNumberHistoryModal } from '../modals/serial-number-history';
import { CommissionsService } from '../services/commissions';
import { FormGroup } from '@angular/forms';

/**
 * Sekce Přístroj
 */

@Component({
	selector: 'div.kt-device-section',
	templateUrl: "/template/commissions/components/device-section.cshtml"
})
export class DeviceSectionComponent extends SectionComponentBase implements OnInit, OnDestroy {
	// Model device sekce
	private get _model(): ICommissionDevice {
		return this._viewModel.model.device;
	};

	// Jestli se zroovna něco děje
	public working: boolean = false;

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._viewModel.form;
	};

    // Controls state
    public get controlsState(): FormControlsState {
        return this._viewModel.controlsState;
    };

	constructor(
		private _modalService: ModalService,
		private _viewModel: CommissionViewModel,
		private _uiService: UIService,
		private _commissionsService: CommissionsService,
		private _modelServiceFactory: ModelServiceFactory,
		protected _dataStorageService: DataStorageService) {

		super(_dataStorageService, 'device');
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	/**
	 * Destroy komponenty 
	 **/
	ngOnDestroy() {
	}

	/**
	 * Provede "inicializaci" seriového čísla
	 * - pokusí se načíst předchozí zakázky
	 * - zobrazí modal pro možnost překopírovat data z nalezených zakázek
	 */
	public initializeSerialNumber(): void {
		this.working = true;

		// Načítám zakázky které odpovídají zadanému seriovému číslu
		this._commissionsService
			.getSerialNumberHistory(this._model.serialNumber, this._viewModel.model.id)
			.then(
				x => {
					this.working = false;

					// Pokud nebylo nic nalezeno, inicializuju
					if (x.length == 0) {
						this._viewModel.initSerialNumber();
						return;
					}

					// Pokud bylo něco vráceno, otevírám modal
					this._modalService.openModal(new SerialNumberHistoryModal(this._model.serialNumber, x))
						.onApprove((result: ISerialNumberHistoryListModel) => {
							this.working = true;

							// Nepreji si prebirat data z jine zakazky
							if (result == null)
							{
								this.working = false;
								this._viewModel.initSerialNumber();
								return;
                            }
							

							// Přkopírujeme data z vybrané zakázky
							this._viewModel
								.copyDataFromCommission(result.id)
								.then(
									x => {
										// Success
										this.working = false;
										this._viewModel.initSerialNumber();
									},
									reason => {
										// Chyba
										this.working = false;
										this._uiService.showError('Nepodařilo se použít data zakázky.');
									}
								);
						});
				},
				reason => {
					this.working = false;
					this._uiService.showError('Nepodařilo se načíst historii modelu.');
				}
			);
	}

	/**
	 * Zpracovává událost výběru položky v inputu modelu
	 * @param event
	 */
	public onModelOptionSelected(event: SelectOption): void {
		// Nastavím zvolený model
		this._viewModel.setDeviceModel(parseInt(event.value));
	}

	/**
	 * Při vystoupení z modelu 
	 */
	public onModelBlur(): void {
		this._model.modelName = this._model.modelName.trim();
		this._ensureModel();
	}

	/**
	 * Zpracovává keyup událost inputu modelu
	 * @param event
	 */
	public onModelKeyUp(event: KeyboardEvent): void {
		if (event.keyCode == 13) {
			this._ensureModel();
		} else {
			this._model.modelName = this._model.modelName.toUpperCase();
		}
	}

	/**
	 * Zpracovává keyup událost inputu výrobního čísla
	 * @param event
	 */
	public onSerialNumberInputKeyUp(event: KeyboardEvent) {
		if (event.keyCode == 13 && this._model.serialNumber.length > 0) {
			this.initializeSerialNumber();
		} else {
			this._model.serialNumber = this._model.serialNumber.toUpperCase().trim();
		}
	}

	/** Při opuštění IMEI inputu */
	public onImeiBlur(): void {
		this._model.imei = this._model.imei.trim();
    }

	/**
	 * Zobrazí modal pro výběr/editaci modelu.
	 **/
	public openModelList(): void {
		// Otevřeme modal
		this._modalService.openModal(new ModelListModal(this._model.modelId, this._model.modelName))
			.onApprove(result => {
				this.working = true;

				// Nastavím zvolený model
				this._viewModel.setDeviceModel(<number>result)
					.then(
						x => {
							this.working = false;
						},
						reason => {
							this.working = false;
							this._uiService.showError('Nepodařilo se načíst zvolený model.');
						}
					);
			});
	}

	/**
	 * Provádí vyhledání modelu 
	 */
	private _ensureModel(): void {
		this.working = true;

		// Zkusím vyhledat model
		let deviceModelService = this._modelServiceFactory.createService('listDeviceModel');
		let filter = new ListFilter();
		filter.searchFor = `"${this._model.modelName}"`;
		let page: PageInfo = {
			itemsPerPage: 1,
			page: 1
		};

		deviceModelService.list<IDeviceModelListModel>(page, new Sorting(), filter)
			.then(
				x => {
					// Nastavíme nalezený model nebo 0
					let promise = x.items.length == 0 ?
						this._viewModel.setDeviceModel(0) :
						this._viewModel.setDeviceModel(x.items[0].id);

					promise
						.then(x => {
							this.working = false;
						},
							reason => {
								this.working = false;
								this._uiService.showError('Nepodařilo se nastavit model.');
							});

				},
				reason => {
					this.working = false;
					this._uiService.showError('Nepodařilo se vyhledat model.');
				}
			);
	}
}