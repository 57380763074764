import { Directive, Input, HostBinding, HostListener, AfterViewInit, ElementRef } from '@angular/core';

/**
 * Direktiva, která zajistí, že výška elementu je vždy výška okna - diff
 */

@Directive({
	selector: '[kt-keep-height]'
})
export class KeepHeightDirective implements AfterViewInit {
	// Hodnota kterou odečíst od výšky okna
	@Input('kt-keep-height') diff: number = 0;

	constructor(
		private _el: ElementRef) {
	}

	// Event resize pro přepočet
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this._keepHeight();
	}

	ngAfterViewInit() {
		this._keepHeight();
	}

	/**
	 * Nastaví výšku elementu
	 */
	private _keepHeight(): void {
		this._el.nativeElement.style.height = (window.innerHeight - this.diff) + 'px';
	}
}