import { ModelWrapper } from '../models';
import { AbstractControl, FormControl, FormArray, FormGroup } from '@angular/forms'
import {
    CommissionPhase,
    AuthService,
    CommissionPaymentType
} from '../../core';
import { Subscription } from 'rxjs';

const setStateOptions = { onlySelf: false, emitEvent: false };
function setState(abstractControl: AbstractControl, state: boolean): void {
    if (state) {
        abstractControl.enable(setStateOptions);
    }
    else {
        abstractControl.disable(setStateOptions);
    }
}

export class FormControlsState {
    //#region Definice handlerů změn hodnot

    private _valueChangedHandlers: { [path: string]: (value: any) => void } = {
        'payer.contactId': x => {
            this._updateAddressesSection();
        },
        'recipient.contactId': x => {
            this._updateAddressesSection();
        },
        'seller.contactId': x => {
            this._updateAddressesSection();
        },
        'device.modelId': x => {
            this._updateDeviceSection();
        },
        'parts': x => {
            this._updateCommissionSection();
        },
        'texts.isNormallyWorn': x => {
            this._updateTextsSection();
        }
    };

    //#endregion

    private _subs: Array<Subscription> = [];

    // Jen zkratky do wrapperu
    private get _form(): FormGroup {
        return this._wrapper.form;
    }
    private get _model(): ICommission {
        return this._wrapper.model;
    }

    // Jestli je zakázka jen v režimu čtení
    private _isReadOnly: boolean = false;

    // Jestli už bylo inicializováno seriové číslo
    private _serialNumberInitialized: boolean = false;

    /**
     * Vrací info jestli je zakázka readonly
     */
    public get isReadOnly(): boolean {
        return this._isReadOnly;
    }

    constructor(
        private _wrapper: ModelWrapper<ICommission>,
        private _authService: AuthService) {

        // Připnutí handlerů na změnu hodnot
        for (let path in this._valueChangedHandlers) {
            this._subs.push(
                this._wrapper.form.get(path).valueChanges.subscribe(this._valueChangedHandlers[path])
            );
        }
    }

    /**
     * Vrací info jestli je definovaný button zakázaný
     * @param id
     */
    public buttonIsDisabled(id: string): boolean {
        // Otevřít kontakt v adresáři je vždy povoleno
        if (id.startsWith('addressesSection') && id.endsWith('openContact')) {
            return false;
        }

        // Save záleží pouze na readonly
        switch (id) {
            case 'save':
                return this.isReadOnly;
        }

        // Pro readonly nebo Closed vše ostatní zakázáno
        if (this.isReadOnly || this._isPhaseAndCantEditAllPhases(CommissionPhase.Closed)) {
            return true;
        }

        if (id.startsWith('addressesSection.payer')) {
            // Pokud je vybraný u plátce kontakt z typu zakázky, je vše zakázáno
            if (this._model.payer.contactId != null) {
                return true;
            }

            return this._isPhaseAndCantEditAllPhases(CommissionPhase.Ended);
        }

        switch (id) {
            case 'priceProposalSection.add':
            case 'priceProposalModal.ok':
                return this._isPhaseAndCantEditAllPhases(CommissionPhase.Ended);

            case 'commissionSection.addRequestForDeposit':
            case 'executionSection.addFee':
            case 'executionSection.removeOperation':
            case 'executionSection.removeFee':
                return this._isPhaseAndCantEditAllPhases(CommissionPhase.Ended) ||
                    this._hasPayment();

            case 'executionSection.addOperation':
                return this._isPhaseAndCantEditAllPhases(CommissionPhase.Ended) ||
                    this._hasPayment() ||
                    this._model.commissionTypeId == 0;

            case 'executionSection.addPart':
                return this._isPhase(CommissionPhase.Ended) ||
                    this._hasPayment() ||
                    !this._model.isPaired;

            case 'deviceSection.initializeSerialNumber':
                return this._model.device.serialNumber.length <= 0;

            case 'deviceSection.openModelList':
                return !this._serialNumberInitialized;

            case 'statementTab.addSettlement':
                return !this._authService.currentUser.commissionSpecialPermission.isRoleAddPaymentCard &&
                    !this._authService.currentUser.commissionSpecialPermission.isRoleAddPaymentBankAccount;

            case 'statementTab.storno':
                return !this._authService.currentUser.commissionSpecialPermission.isRoleStornoPayment;
        }

        return false;
    }

	/**
	 * Destrukce 
	 */
    public destroy(): void {
        this._subs.forEach(x => x.unsubscribe());
        this._wrapper = null;
    }

    /**
     * Voláno po inicializaci modelu
     * @param serialNumberInitialized
     * @param readonly
     **/
    public init(serialNumberInitialized: boolean, readonly: boolean): void {
        this._serialNumberInitialized = serialNumberInitialized;
        this._isReadOnly = readonly;

        this.updateAll();
    }

    /**
     * Nastaví, jestli bylo seriové číslo inicializováno
     * @param serialNumberInitialized
     */
    public setSerialNumberInitialized(serialNumberInitialized: boolean): void {
        this._serialNumberInitialized = serialNumberInitialized;

        this.updateAll();
    }

    /**
     *Updates state of all managed controls 
     **/
    public updateAll(): void {
        this._updateAddressesSection();
        this._updateCommissionSection();
        this._updateDeviceSection();
        this._updateExecutionSection();
        this._updateGeneralSection();
        this._updateInHomeSection();
        this._updateStatementTab();
        this._updateTextsSection();
        this._updateTransportSection();
    }

    private _updateAddressesSection(): void {
        let payer = <FormGroup>this._form.controls.payer;
        let recipient = <FormGroup>this._form.controls.recipient;
        let seller = <FormGroup>this._form.controls.seller;

        // Disable vše
        if (!this._serialNumberInitialized ||
            this.isReadOnly ||
            this._isPhaseAndCantEditAllPhases(CommissionPhase.Closed)
        ) {
            setState(payer, false);
            setState(recipient, false);
            setState(seller, false);

            return;
        }

        setState(payer, this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Ended) && this._model.payer.contactId == null);
        setState(recipient, this._model.recipient.contactId == null);
        setState(seller, this._model.seller.contactId == null);

        setState(recipient.controls.name, true);
        setState(seller.controls.name, true);
    }

    private _updateCommissionSection(): void {

        if (!this._serialNumberInitialized ||
            this.isReadOnly ||
            this._isPhaseAndCantEditAllPhases(CommissionPhase.Closed)) {

            // Vše disabled
            setState(this._form, false);
            return;
        }

        // Vše povoleno
        setState(this._form, true);

        // Nastavit specialitky
        let hasAnyPart = this._model.parts.length > 0;
        let commissionIsNotEnded = this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Ended);
        let canChangeByRight = this._authService.currentUser.commissionSpecialPermission.isRoleChangeBranchOrTechnician;
        let controls = this._form.controls;

        setState(controls.communicationId, false);
        setState(controls.commissionTypeId, commissionIsNotEnded && !this._hasPayment());
        setState(controls.branchId, commissionIsNotEnded && !hasAnyPart && canChangeByRight);
        setState(controls.technicianId, commissionIsNotEnded && canChangeByRight);
        setState(controls.priceLimit, commissionIsNotEnded);
    }

    private _updateDeviceSection(): void {

        let deviceGroup = <FormGroup>this._form.controls.device;

        // Disable vše
        if (this.isReadOnly || this._isPhaseAndCantEditAllPhases(CommissionPhase.Closed)
        ) {
            setState(deviceGroup, false);

            return;
        }

        // Serial number initialized
        if (!this._serialNumberInitialized) {
            setState(deviceGroup, false);
            setState(deviceGroup.controls.serialNumber, true);

            return;
        }

        setState(deviceGroup, true);

        let modelIsNotSet = this._model.device.modelId == 0;
        setState(deviceGroup.controls.producerId, modelIsNotSet);
        setState(deviceGroup.controls.policyId, modelIsNotSet);
        setState(deviceGroup.controls.categoryId, modelIsNotSet);
    }

    private _updateExecutionSection(): void {
        let executionGroup = <FormGroup>this._form.controls.execution;
        let enabled = !this.isReadOnly && this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Closed);

        setState(this._form.controls.fees, enabled && this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Ended));
        setState(this._form.controls.operations, enabled && this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Ended));
        setState(this._form.controls.parts, enabled);
        setState(executionGroup, enabled);
        setState(executionGroup.controls.authorization, false);
    }

    private _updateGeneralSection(): void {
        setState(this._form.controls.number, false);
        setState(this._form.controls.term, false);
        setState(this._form.controls.stateName, false);
        setState(this._form.controls.phaseName, false);
        setState(this._form.controls.solverName, false);
        setState(this._form.controls.lastEventName, false);
    }

    private _updateInHomeSection(): void {
        let inHomeGroup = <FormGroup>this._form.controls.inHomeService;
        let enabled = !this.isReadOnly &&
            this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Closed);

        setState(inHomeGroup, enabled);
    }

    private _updateStatementTab(): void {
        let discountIsEnabled = !this.isReadOnly &&
            this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Closed) &&
            this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Ended) &&
            this._authService.currentUser.commissionSpecialPermission.isRoleIndividualDiscount &&
            !this._hasPayment();

        setState(this._form.controls.individualDiscount, discountIsEnabled);
    }

    private _updateTextsSection(): void {
        let textsGroup = <FormGroup>this._form.controls.texts;

        const defaultText = "Vzhled přístroje odpovídá stáří, údržbě a běžnému opotřebení.";

        if (this._model.texts.isNormallyWorn) {
            this._model.texts.conditionDescription = defaultText;
        } else if (this._model.texts.conditionDescription === defaultText) {
            this._model.texts.conditionDescription = "";
        }

        if (!this._serialNumberInitialized || this.isReadOnly) {
            setState(textsGroup, false);
            return;
        }

        setState(textsGroup, this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Closed));
        setState(textsGroup.controls.conditionDescription, this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Closed) && !this._model.texts.isNormallyWorn);
        setState(textsGroup.controls.internalNote, true);
    }

    private _updateTransportSection(): void {

        let enabled = !this.isReadOnly &&
            this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Closed) &&
            this._isNotPhaseOrCanEditAllPhases(CommissionPhase.Ended) &&
            !this._hasPayment();

        let issueTransport = <FormGroup>this._form.controls.issueTransport;
        let receiptTransport = <FormGroup>this._form.controls.receiptTransport;

        setState(issueTransport, enabled);
        setState(receiptTransport, enabled);

        setState(issueTransport.controls.price, false);
        setState(receiptTransport.controls.price, false);

        setState(issueTransport.controls.shipmentNumber, true);
    }

    private _canEditInAllPhases(): boolean {
        return this._authService.currentUser.commissionSpecialPermission.isEditInAllPhases;
    }

    private _hasPayment(): boolean {
        return this._model.payments.some(x => x.paymentType != CommissionPaymentType.Deposit && x.isPaid);
    }

    private _isPhase(phase1: CommissionPhase, phase2?: CommissionPhase): boolean {
        if (phase2) {
            return this._model.phase == phase1 || this._model.phase == phase2;
        }

        return this._model.phase == phase1;
    }

    private _isNotPhaseOrCanEditAllPhases(phase: CommissionPhase): boolean {
        return !this._isPhase(phase) || this._canEditInAllPhases();
    }

    private _isPhaseAndCantEditAllPhases(phase1: CommissionPhase, phase2?: CommissionPhase): boolean {
        return this._isPhase(phase1, phase2) && !this._canEditInAllPhases();
    }
}