import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommissionViewModel } from '../services/commission-view-model';
import { SectionComponentBase } from '../classes/section-component-base';
import { SelectOption, DataStorageService } from '../../core';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';

/**
 * Sekce Doprava
 */

@Component({
    selector: 'div.kt-transport-section',
    templateUrl: "/template/commissions/components/transport-section.cshtml"
})
export class TransportSectionComponent extends SectionComponentBase implements OnInit, OnDestroy {
    private _subs: Array<Subscription> = [];
    private _trackingRegex: RegExp = /\{0\}/g;

    // Aktuálně vybraní přepravci
    private _receiptCarrier: ITransportSetupCarier = null;
    private _issueCarrier: ITransportSetupCarier = null;

    // Pole options pro selekty dopravce
    public carriersOptions: Array<SelectOption> = [];

    // Pole options pro selekty typu dopravy
    public receiptTypeOptions: Array<SelectOption> = [];
    public issueTypeOptions: Array<SelectOption> = [];

    public get model(): ICommission {
        return this._viewModel.model;
    };

    // Formulář se zakázkou
    public get form(): FormGroup {
        return this._viewModel.form;
    };

    constructor(
        private _viewModel: CommissionViewModel,
        protected _dataStorageService: DataStorageService) {

        super(_dataStorageService, 'transport');
    }

	/**
	 * Inicializace komponenty 
	 */
    ngOnInit() {
        this._subs.push(
            this._viewModel.onInitialized.subscribe(x => this._onModelInitialized()),
            this.form.get('receiptTransport.transportCarrierId').valueChanges.subscribe(x => this._onReceiptCarrierValueChange(x)),
            this.form.get('receiptTransport.transportTypeId').valueChanges.subscribe(x => this._onReceiptTypeValueChange(x)),
            this.form.get('issueTransport.transportCarrierId').valueChanges.subscribe(x => this._onIssueCarrierValueChange(x)),
            this.form.get('payer.contactId').valueChanges.subscribe(x => this._onPayerContactIdValueChange(x))
        );

        this._onModelInitialized();
    }

	/**
	 * Destrukce komponenty 
	 */
    ngOnDestroy() {
        this._subs.forEach(x => x.unsubscribe());
    }

	/**
	 * Otevře okno s tracking url pro výdej
	 */
    public openIssueTrackingUrl(): void {
        let url = this._issueCarrier.trackingUrl.replace(this._trackingRegex, this.model.issueTransport.shipmentNumber);
        window.open(url);
    }

	/**
	 * Otevře okno s tracking url pro příjem
	 */
    public openReceiptTrackingUrl(): void {
        let url = this._receiptCarrier.trackingUrl.replace(this._trackingRegex, this.model.receiptTransport.shipmentNumber);
        window.open(url);
    }

	/**
	 * vrací info jestli má být zobrazen odkaz na tracking url pro výdej. 
	 */
    public showIssueTrackingUrl(): boolean {
        return !!(this.model.issueTransport.shipmentNumber) &&
            !!(this._issueCarrier) &&
            !!(this._issueCarrier.trackingUrl);
    }

	/**
	 * vrací info jestli má být zobrazen odkaz na tracking url pro příjem. 
	 */
    public showReceiptTrackingUrl(): boolean {
        return !!(this.model.receiptTransport.shipmentNumber) &&
            !!(this._receiptCarrier) &&
            !!(this._receiptCarrier.trackingUrl);
    }

    /** Při opuštění čísla zásilky - příjem inputu */
    public onReceiptShipmentNumberBlur(): void {
        this.model.receiptTransport.shipmentNumber = this.model.receiptTransport.shipmentNumber.trim();
    }

    /** Při opuštění čísla zásilky - výdej inputu */
    public onIssueShipmentNumberBlur(): void {
        this.model.issueTransport.shipmentNumber = this.model.issueTransport.shipmentNumber.trim();
    }

	/**
	 * Vytvoří pole options pro selekty s dopravci 
	 */
    private _buildCarriersOptions(): void {
        this._viewModel
            .transportCalculator
            .getTranportCarriers()
            .then(x => {
                this.carriersOptions = x
                    .map(x => new SelectOption(<any>x.id, x.name));
            });
    }

	/**
	 * Vytvoří pole pro selekty s typy doprav pro příjem
	 */
    private _buildReceiptTypeOptions(carrierId: any): void {
        this._viewModel
            .transportCalculator
            .getTransportCarrier(carrierId)
            .then(x => {
                this._receiptCarrier = x;

                if (!this._receiptCarrier) {
                    this.model.receiptTransport.transportTypeId = null;
                    this.receiptTypeOptions = [];
                    return;
                }

                var payerContactId = this._viewModel.model.payer.contactId;
                let receiptTypeId = this.model.receiptTransport.transportTypeId;

                this.receiptTypeOptions = this._receiptCarrier
                    .types
                    .filter(x =>
                        (!x.isNotActive || x.id == receiptTypeId) &&
                        (x.contactId == null || x.contactId == payerContactId)
                    )
                    .map(x => new SelectOption(<any>x.id, x.name));

                // Clear if current transport type id is not available yet
                if (receiptTypeId != null) {
                    var typeExists = this.receiptTypeOptions
                        .filter(x => x.value == receiptTypeId.toString())
                        .length > 0;

                    if (!typeExists) {
                        this.model.receiptTransport.transportTypeId = null;
                    }
                }
            });
    }

	/**
	 * Vytvoří pole pro selekty s typy doprav pro výdej
	 */
    private _buildIssueTypeOptions(carrierId: any): void {
        this._viewModel
            .transportCalculator
            .getTransportCarrier(carrierId)
            .then(x => {
                this._issueCarrier = x;

                if (!this._issueCarrier) {
                    this.model.issueTransport.transportTypeId = null;
                    this.issueTypeOptions = [];
                    return;
                }

                let payerContactId = this._viewModel.model.payer.contactId;
                let issueTypeId = this.model.issueTransport.transportTypeId;

                this.issueTypeOptions = this._issueCarrier
                    .types
                    .filter(x =>
                        (!x.isNotActive || x.id == issueTypeId) &&
                        (x.contactId == null || x.contactId == payerContactId)
                    )
                    .map(x => new SelectOption(<any>x.id, x.name));

                // Clear if current transport type id is not available yet
                if (issueTypeId != null) {
                    var typeExists = this.issueTypeOptions
                        .filter(x => x.value == issueTypeId.toString())
                        .length > 0;

                    if (!typeExists) {
                        this.model.issueTransport.transportTypeId = null;
                    }
                }
            });
    }

	/**
	 * Inicializuje 
	 */
    private _initOptions(): void {
        this._buildCarriersOptions();
        this._buildReceiptTypeOptions(this.model.receiptTransport.transportCarrierId);
        this._buildIssueTypeOptions(this.model.issueTransport.transportCarrierId);
    }

	/**
	 * Zpracovává událost inicializace modelu 
	 */
    private _onModelInitialized(): void {
        this._initOptions();
    }

	/**
	 * Zpracovává změnu v dopravci u výdeje
	 * @param value
	 */
    private _onIssueCarrierValueChange(value: any) {
        this._buildIssueTypeOptions(value);
    }

	/**
	 * Zpracovává změnu v dopravci u příjmu
	 * @param value
	 */
    private _onReceiptCarrierValueChange(value: any) {
        this._buildReceiptTypeOptions(value);
    }

    /**
     * Zpracovává změnu ID plátce
     * @param value
     */
    private _onPayerContactIdValueChange(value: any) {
        this._buildReceiptTypeOptions(this.model.receiptTransport.transportCarrierId);
        this._buildIssueTypeOptions(this.model.issueTransport.transportCarrierId);
    }

    /**
     * Zpracovává změnu v druhu dopravy u příjmu
     * @param value
     */
    private _onReceiptTypeValueChange(value: any) {
        //Pri zvolenem druhu prednastavime vydej, je-li prazdny
        if (value != null && this.model.issueTransport.transportCarrierId == null && this.model.issueTransport.transportTypeId == null) {
            this.model.issueTransport.transportCarrierId = this.model.receiptTransport.transportCarrierId;
            this.model.issueTransport.transportTypeId = this.model.receiptTransport.transportTypeId;
        }
    }
}