import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer, NgForm, NgModelGroup } from '@angular/forms';

/**
 * Funkcionalita Kategorie vyrobku vs. notifikace email v modulu3
 */

@Component({
    selector: 'div.kt-module3-categories',
    templateUrl: "/template/settings/modules/module3/components/module-categories.cshtml",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => Module3CategoriesComponent),
			multi: true
		}
	],
	viewProviders: [
		{
			provide: ControlContainer,
			useExisting: NgForm
		}
	]
})
export class Module3CategoriesComponent implements ControlValueAccessor, OnInit {
	public disabled: boolean = false;

	// Model a getter/setter
    private _categories: Array<any> = [];

    public get categories(): Array<any> {
        return this._categories;
	}
    public set categories(value: Array<any>) {
        this._categories = value;
	}

	// Drží 'index' naposledy přidaného dílu
    private _categoriesIdx: number;

    constructor() {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	/**
	 * Přidá nový řádek do seznamu
	 */
	addCategories() {
        this._categories.push({
	        id: 0,
	        Name: 0,
	        to: 0,
	        margin: 0,
            $i: this._categoriesIdx++
	    });

        this._propagateChange(this._categories);
	}

	/**
	 * Odebere zaznam, ktery je v seznamu na specifikovaném indexu
	 * @param index
	 */
	removeCategories(index: number) {
        this._categories.splice(index, 1);

        this._propagateChange(this._categories);
	}

	/**
	 * Vrátí unikátní identitu pro daný zaznam. Nutné pro správnou funkci ngFor.
	 * @param item
	 * @param index
	 */
	categoriesIdentity(item: any, index: number): any {
		return item.$i;
	}

	//
	// Implementace ControlValueAccessor
	//

	private _propagateChange = (_: any) => { };
	private _propagateTouched = () => { };

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
	writeValue(value: any): void {
		if (value) {
			// a uložíme si hodnotu
            this._categories = value.map(function(x, i) {
		        x.$i = i;
		        return x;
		    });
            this._categoriesIdx = this._categories.length;
		}
	}

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}