import { Component, OnInit, Input } from '@angular/core';
import { PageInfo, PAGE_SIZES } from '../models';
import { ListsService, IListSettingsHandler } from '../services/lists';

/**
 * Horní hlavní menu
 */

@Component({
	selector: 'div.kt-pagination',
	templateUrl: "/template/core/components/pagination.cshtml"
})
export class PaginationComponent implements OnInit {

	/*
	 * Input/Output
	 */
	// Typ seznamu, který se bude stránkovat
	@Input() public listType: string;

	/*
	 * Private fields
	 */

	// Handler pro nastavení tohohle gridu
	private _settingsHandler: IListSettingsHandler;

	/*
	 * Public fields
	 */

	// Celkový počet položek
	public set totalCount(value: number) {
		this.pageCount = Math.ceil(value / this.pageSize);

		if (this.pageCount < this.page && this.pageCount > 0) {
			this.setPage(1);
		}
		else {
			this._calculate();
		}
	};

	// Aktuální stránka
	public get page(): number {
		if (!this._settingsHandler) {
			return 0;
		}

		return this._settingsHandler.page.page;
	}

	// Aktuální počet na stránku
	public get pageSize(): number {
		if (!this._settingsHandler) {
			return 0;
		}

		return this._settingsHandler.page.itemsPerPage;
	}

	// Počet stránek
	public pageCount: number = 0;

	// Čísla stránek pro vykreslení
	public pages: Array<number> = [];

	// Možné velikosti stránek
	public pageSizes: Array<number> = PAGE_SIZES;

	constructor(
		private _listsService: ListsService) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this._listsService.getSettingsHandler(this.listType)
            .then((x: IListSettingsHandler) => {
				this._settingsHandler = x;
				this._calculate();
			});
	}

	/**
	 * Vrací info jestli existuje předchozí stránka
	 */
	public hasPrevious(): boolean {
		return this.page > 1;
	}

	/**
	 * Vrací info jestli existuje další stránka
	 */
	public hasNext(): boolean {
		return this.page < this.pageCount;
	}

	/**
	 * Nasatví zvolený počet na stránku
	 * @param value
	 */
	public setPageSize(value: string) {
		this._settingsHandler.setPageSize(parseInt(value));
		this._calculate();
	}

	/**
	 * Nastaví zvolenou stránku
	 * @param page
	 */
	public setPage(page: number) {
		this._settingsHandler.setPage(page);
		this._calculate();
	}

	/**
	 * Přepočítá interní stav
	 */
	private _calculate() {
		if (this.pageCount == 0) {
			this.pages = [];
			return;
		}

		let size: number = 5;
		let half: number = Math.floor(size / 2);
		let from = this.page - half;

		if (from < 1) from = 1;

		let to = from + size - 1;

		if (to > this.pageCount) to = this.pageCount;

		this.pages = Array<number>(to - from + 1)
			.fill(from)
			.map((s, i) => s + i);
	}
}