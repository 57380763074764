import { Injectable } from '@angular/core';
import { LocationChangeEvent } from '@angular/common';
import { SuiModalService, ModalConfig } from '@giomamaladze/ng2-semantic-ui';
import { ActiveModal } from '@giomamaladze/ng2-semantic-ui/modules/modal/classes/active-modal';
import { LocationService } from '../services/location';
import { Subscription } from 'rxjs';

/**
 * Posktyuje metody pro modální okna
 */
@Injectable()
export class ModalService {
	static modals: Array<ModalResult<any, any, any>> = [];

	private _popStateSubscription: Subscription;

	constructor(
		private _modalService: SuiModalService,
		private _locationService: LocationService) {

		// Attachnu handler na popState abych chytl back button
		this._popStateSubscription = _locationService.popState.subscribe(this._onPopState.bind(this));
    }

	/**
	 * Otevře modal
	 * @param modal
	 */
	public openModal<T, U, V>(modal: ModalConfig<T, U, V>): IModalResult<T, U, V> {
		let activeModal = this._modalService.open(modal);
		let modalResult = new ModalResult(activeModal);

		ModalService.modals.push(modalResult);

		return modalResult;
    }

    /**
     * Počet současně otevřených oken
     * */
    public openModalsCount(): number {
        return ModalService.modals.length;
    }

	private _onPopState(event: LocationChangeEvent): void {
		ModalService.modals.forEach(x => x.destroy());
	}
}

export interface IModalResult<T, U, V> {
	onApprove(callback: (result: U) => void): ModalResult<T, U, V>;
	onDeny(callback: (result: V) => void): ModalResult<T, U, V>;
	destroy();
}

class ModalResult<T, U, V> implements IModalResult<T, U, V> {

	private _approveCallback: (result: U) => void;
	private _denyCallback: (result: V) => void;
	private _index: number;

	constructor(
		private _activeModal: ActiveModal<T, U, V>) {

		_activeModal
			.onApprove(this._approve.bind(this))
			.onDeny(this._deny.bind(this));

		this._index = ModalService.modals.length + 1;
	}

	public onApprove(callback: (result: U) => void): ModalResult<T, U, V> {
		this._approveCallback = callback;
		return this;
	}

	public onDeny(callback: (result: V) => void): ModalResult<T, U, V> {
		this._denyCallback = callback;
		return this;
	}

	public destroy(): void {
		this._activeModal.destroy();
	}

	private _approve(result: U): void {
		this._modalClosed();

		if (this._approveCallback) {
			this._approveCallback(result);
		}
	}

	private _deny(result: V): void {
		this._modalClosed();

		if (this._denyCallback) {
			this._denyCallback(result);
		}
	}

	private _modalClosed(): void {
		// Odeberu ze stacku
		ModalService.modals.pop();
	}
}