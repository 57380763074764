import { Input, Component, OnInit, OnDestroy } from '@angular/core';
import { ListFilterModal } from '../../core';
import { ListsService, IListSettingsHandler } from '../services/lists';
import { ModalService } from '../services/modal';
import { ListFilter, ListSettings } from '../models';
import { Subscription } from 'rxjs';

/**
 * Top bar str�nky. Obsahuje nadpis, tla��tka apod.
 */

@Component({
	selector: 'div.kt-page-top-bar',
	templateUrl: "/template/core/components/page-top-bar.cshtml"
})
export class PageTopBarComponent implements OnInit, OnDestroy {

	// Handler pro nastaven� tohohle gridu
	private _settingsHandler: IListSettingsHandler;

	// Subskripce kter� je pot�eba v destruktoru odhl�sit
	private _subs: Array<Subscription> = [];

	@Input() pageTitle: any;
	@Input() allowSearch: boolean = false;
	@Input() allowFilter: boolean = false;
	@Input() listType: string = null;

	// Hledan� v�raz
	public searchFor: string = '';

	constructor(
		private _modalService: ModalService,
		private _listsService: ListsService) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this._listsService.getSettingsHandler(this.listType)
			.then((x: IListSettingsHandler) => {
				this._settingsHandler = x;

				this.searchFor = x.filter.searchFor;
			});

		this._subs.push(
			this._listsService.onSettingsChanged.subscribe((x: ListSettings) => {
				if (x.filter.searchFor != this.searchFor) {
					this.searchFor = x.filter.searchFor;
				}
			})
		);
	}

	/**
	 * Destruktor
	 */
	ngOnDestroy() {
		this._subs.forEach(x => x.unsubscribe());
	};

	/**
	 * Sma�e vyhled�van� v�raz
	 */
	public clearSearchFor(): void {
		this.searchFor = '';
		this._setSearchFor();
	}

	/**
	 * Sput� hled�n�
	 */
	public search() {
		this._setSearchFor();
	}

	/**
	 * Vrac� info jestli je vyhled�n� povoleno.
	 */
	public searchIsEnabled(): boolean {
		return this.searchFor && this.searchFor.length > 2;
	}

	/**
	 * Obsluhuje ud�lost keypress na vyhled�vac�m inputu
	 * @param event
	 */
	public onSearchKeyPress(event: any): void {
		if (event.charCode == 13 && this.searchIsEnabled()) {
			this._setSearchFor();
		}
	}

	/**
	 * Zobraz� mod�l s nastavne�m filtru
	 */
	public openListFilter(): void {
		this._modalService
			.openModal(new ListFilterModal(this.listType));
	}

	/**
	 * Nastav� hledan� string do handleru nastaven�
	 **/
	private _setSearchFor(): void {
		this._settingsHandler.setSearchFor(this.searchFor);
	}
}