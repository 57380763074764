import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIService, ModelFormComponentBase, HelperService, AppScopeService, AuthService, ModelServiceFactory } from '../../../../core';

@Component({
	templateUrl: "/template/settings/modules/module3/pages/module.cshtml"
})
export class Module3DetailPageComponent extends ModelFormComponentBase {
	constructor(
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
        protected _appScopeService: AppScopeService,
		private _modelServiceFactory: ModelServiceFactory,
		protected _router: Router) {

		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_modelServiceFactory.createService('module3'),
			_router);
	}

	/**
	 * Vrací nadpis pro stránku 
	 */
	getTitle() {
        return 'Objednání svozu u přepravce';
	}

}