import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { CommissionTypeModule } from './';

// Pages
import { ListsCommissionTypePageComponent } from './pages/commission-type-list';
import { ListsCommissionTypeDetailPageComponent } from './pages/commission-type-detail';

const routes: Routes = [
	{ path: '', component: ListsCommissionTypePageComponent },
	{
		path: ':id',
		component: ListsCommissionTypeDetailPageComponent,
		canDeactivate: [CanDeactivateGuard]
	}
];

@NgModule({
	imports: [
		CommissionTypeModule,
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class CommissionTypeRoutingModule { }