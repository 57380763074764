import { Injectable } from '@angular/core';
import { FileInfo } from '../models';
import { FileManagerModal } from '../modals/file-manager';
import { ApiService } from '../services/api';
import { ModalService } from '../services/modal';

/**
 * Služba pro manažera souborů
 * 
 * @export
 * @class AuthService
 */
@Injectable()
export class FileManagerService {

	constructor(
		private _modalService: ModalService,
		private _apiService: ApiService) {
	}

	/**
	 * Zobrazí dialog správce souborů s možností správy souborů
	 * @param rootPath
	 */
	public selectFiles(rootPath: string): Promise<Array<FileInfo>> {
		if (!rootPath || rootPath.length <= 0) {
			throw 'Missing or invalid required argument rootPath.';
		}

		return new Promise((resolve, reject) => {
			this._modalService
				.openModal(new FileManagerModal(rootPath))
				.onApprove((x: Array<FileInfo>) => resolve(x))
				.onDeny(x => resolve(null));
		});
	}
}