import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { CommissionEventsModule } from './';

// Pages
import { ListsCommissionEventPageComponent } from './pages/commission-event-list';
import { ListsCommissionEventDetailPageComponent } from './pages/commission-event-detail';

const routes: Routes = [
	{ path: '', component: ListsCommissionEventPageComponent },
	{
		path: ':id',
		component: ListsCommissionEventDetailPageComponent,
		canDeactivate: [CanDeactivateGuard]
	}
];

@NgModule({
	imports: [
		CommissionEventsModule,
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class CommissionEventsRoutingModule { }