import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';

@Component({
	templateUrl: "/template/core/modals/add-attachment.cshtml"
})
export class AddAttachmentModalComponent implements OnInit {

	public attachments: Array<IAttachment> = [];

	constructor(
		public modal: SuiModal<IAddAttachmentModalContext, Array<IAttachment>>) {
	}

	/**
	 * Inicializace formuláře
	 */
	ngOnInit() {
	}

	/**
	 * Potvrdí dialog 
	 */
	public confirm(): void {
		this.modal.approve(this.attachments);
	}

	/**
	 * Zruší dialog 
	 */
	public cancel(): void {
		this.modal.deny(null);
	}
}

export class AddAttachmentModal extends ComponentModalConfig<IAddAttachmentModalContext, Array<IAttachment>, void> {
	constructor() {
		super(AddAttachmentModalComponent, {});

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = <ModalSize>'Small top-attached'; // ModalSize.Small;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IAddAttachmentModalContext {

}