import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModelServiceFactory, IModelService, UIService, GridComponent } from '../../../core';

@Component({
	templateUrl: "/template/portal/docs/pages/docs-list.cshtml"
})
export class DocsListPageComponent implements OnInit {
	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * ID garanta pro kterého se zobrazují data
	 */
	private _garantId: number;

	/**
	 * Instance model service
	 */
	public modelService: IModelService;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _modelServiceFactory: ModelServiceFactory,
		private _uiService: UIService) {

		this.modelService = _modelServiceFactory.createService('portalDocs');
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		// Ukládám id garanta z routy
		this._garantId = this._route.snapshot.params.garantId;
	}

	/**
	 * Redirekt na stránku pro přidání.
	 */
	add() {
		this._router.navigateByUrl(`/app/portal/docs/${this._garantId}/0`);
	}

	/**
	 * Smazání
	 * @param docs
	 */
	delete(docs: any) {
		this._uiService
			.showConfirmation('Odstranění dokumentace/sw', `Opravdu si přejete odstranit ${docs.name}?`)
			.then(result => {
				if (result) {
					this._uiService.showLoader();

					this.modelService.delete(docs.id, null).then(
						result => {
							this._uiService.hideLoader();
							this._grid.loadData();
						});
				}
			});
	}

	/**
	 * Redirekt na stránku s editací.
	 * @param docs
	 */
	edit(docs: any) {
		this._router.navigateByUrl(`/app/portal/docs/${this._garantId}/${docs.id}`);
	}
}