import { Injectable } from '@angular/core';
import { ApiService, PrintingService } from '../../core';

/**
 * Poskytuje operace nad zakázkami.
 */
@Injectable()
export class CommissionsService {

	constructor(
        private _apiService: ApiService,
        private _printingService: PrintingService) {
	}

	/**
	 * Vrací "průběh" zakázky
	 * @param commissionId
	 */
	public getLog(commissionId: number): Promise<Array<ICommissionLog>> {
		return this._apiService.get<Array<ICommissionLog>>(`commission/${commissionId}/log`);
	}

	/**
	 * Vrací z API historii zakázek podle seriového čísla.
	 * @param serialNumber
	 * @param excludeCommissionId
	 */
    public getSerialNumberHistory(serialNumber: string, excludeCommissionId: number): Promise<Array<ISerialNumberHistoryListModel>> {
        return this._apiService.get<Array<ISerialNumberHistoryListModel>>(`commission/snhistory?sn=${serialNumber}&ex=${excludeCommissionId}`);
	}

    /**
     * Vrací z API textovou reprezentaci pro odeslání do Hisense
     * @param selected
     */
    public exportToHisense(selected: Array<number>): Promise<string> {
        return this._apiService.post<any>('commission/export-to-hisense', selected)
            .then(x => {
                return x.result;
            });
    }

	/**
	 * Tries to return part for specified code.
	 * @param partCode
	 */
    public findPart(commissionId: number, partCode: string): Promise<IPartData> {
        return this._apiService.get<IPartData>(`commission/${commissionId}/find-part?code=${encodeURIComponent(partCode)}`);
	}

    /**
     * Načte aktuální přílohy pro zakázku a vrátí je
     * @param commissionId id zakázky
     */
    public updateCommissionAttachments(commissionId: number): Promise<Array<ICommissionAttachment>> {
        return this._apiService.get<Array<ICommissionAttachment>>(`commission/${commissionId}/update-attachments`);
    }

    /**
     * Provolá informaci o otevření editace zakázky
     * @param commissionId id zakázky
     */
    public openCommission(commissionId: number): Promise<void> {
        return this._apiService.get<void>(`commission/${commissionId}/open`);
    }

    /**
     * Provolá informaci o zavření editace zakázky
     * @param commissionId id zakázky
     */
    public closeCommission(commissionId: number): Promise<void> {
        return this._apiService.get<void>(`commission/${commissionId}/close`);
    }

    /**
     * Provolá informaci o editaci zakázky
     * @param commissionId id zakázky
     */
    public pingCommission(commissionId: number): Promise<void> {
        return this._apiService.get<void>(`commission/${commissionId}/ping`);
    }

    /**
     * Zjistí, zda je možné editovat zakázku
     * @param commissionId id zakázky
     */
    public canEditCommission(commissionId: number): Promise<boolean> {
        return this._apiService.get<boolean>(`commission/${commissionId}/canedit`);
    }

    /**
     * Zjistí, zda je možné editovat zakázku
     * @param commissionId id zakázky
     */
    public getEditors(commissionId: number): Promise<ICommissionOpen[]> {
        return this._apiService.get<ICommissionOpen[]>(`commission/${commissionId}/editors`);
    }

    /**
     * Vrací nastavení klavesových zkratek
     */
    public getShortcut(): Promise<ICommissionShortcut[]> {
        return this._apiService.get<ICommissionShortcut[]>(`commission/shortcuts`);
    }

    /**
     * Vrací nastavení automatických akcí pro zálohy
     */
    public getStatementAutomaticAction(): Promise<IStatementAutomaticAction[]> {
        return this._apiService.get<IStatementAutomaticAction[]>(`commission/automaticactions`);
    }

	/**
     * Vytiskne daňový doklad
	 * @param payment
     */
    public printPaymentInvoice(payment: ICommissionPayment) {
        this._printingService.print('payment-invoice', { 'pid': payment.id });
    }

	/**
     * Vytiskne daňový doklad - přenosná
	 * @param payment
     */
    public printPaymentInvoice80(payment: ICommissionPayment) {
        this._printingService.printToThermalPrinter('payment-invoice-80', { 'pid': payment.id });
    }

	/**
	 * Vytiskne požadavek na úhradu
	 * @param payment
	 */
    public printRequestForDeposit(payment: ICommissionPayment) {
        this._printingService.print('request-for-deposit', { 'pid': payment.id });
    }
}