import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { routing, appRoutingProviders } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CookieModule } from 'ngx-cookie';
import { ToastrModule } from 'ngx-toastr';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { HotkeyModule } from 'angular2-hotkeys';
import { HttpClientModule } from '@angular/common/http';

// Modules
import { CoreModule, AuthService } from './core';

@NgModule({
    imports: [
        CoreModule.forRoot(),
		BrowserModule,
		routing,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		CookieModule.forRoot(),
		ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: 'toast-bottom-right'
		}),
        SuiModule,
		HotkeyModule.forRoot(),
		HttpClientModule
	],
	declarations: [
		AppComponent
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initFactory,
			deps: [AuthService],
			multi: true
		}
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule {
}

export function initFactory(authService: AuthService) {
	return () => authService.initialize();
};