import { Injectable } from '@angular/core';
import { HelperService } from '../services/helper';
import * as printJS from 'print-js';
import { HttpClient } from '@angular/common/http';
import { PrintToBASE64Response } from '../models';

/**
 * Služba poskytující funkce pro tisk
 * 
 * @export
 * @class PrintingService
 */
@Injectable()
export class PrintingService {

	constructor(
		private _helperService: HelperService,
		private _http: HttpClient
	) {
	}

	/**
	 * Otevre v novem okne download pdf souboru
	 * @param documentCode
	 * @param parameters
	 */
	public openNewWindow(documentCode: string, parameters: { [key: string]: any; }): void {
		let url = `/print/to-file/${documentCode}`;

		if (parameters) {
			url = this._helperService.appendQueryString(url, parameters);
		}

    	window.open(url, '_blank');
	}

	/**
	 * Za pomoci knihovny Print-js otevre dialog pro tisk (u podporovanych prohlizecu)
	 * @param documentCode
	 * @param parameters
	 */
	public print(documentCode: string, parameters: { [key: string]: any; }): void {
		let url = `/print/to-file/${documentCode}`;

		if (parameters) {
			url = this._helperService.appendQueryString(url, parameters);
		}

		printJS({
			printable: url,
			type: 'pdf',
			showModal: true,
			modalMessage: 'Počkejte, probíhá příprava tisku...'
		});
	}

	/**
	 * Provede tisk PDF-ka do přírušní termo-tiskárny
	 * @param documentCode
	 * @param parameters
	 */
	public printToThermalPrinter(documentCode: string, parameters: { [key: string]: any; }): void {
		let url = [
			window.location.protocol,
			'//',
			window.location.hostname,
			':',
			window.location.port,
			`/print/to-base64/${documentCode}`
		].join('');;

		if (parameters) {
			url = this._helperService.appendQueryString(url, parameters);
		}

		this._http
			.get<PrintToBASE64Response>(url)
			.toPromise()
			.then(response => {
				let printUrl = `rawbt:data:application/pdf;base64,${response.data}`;
				window.open(printUrl, '_blank');
			});
	}
}