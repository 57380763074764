import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { UIService } from './ui';
import { IModelService, ModelService } from './model-service';

/**
 * Factory pro instance model services.
 */
@Injectable()
export class ModelServiceFactory {

	constructor(
		private _apiService: ApiService,
		private _uiService: UIService) {
	}

	/**
	 * Vytvoří novou instance model služby pro zadanou routu.
	 * @param route
	 */
	public createService(route: string): IModelService {
		return new ModelService(route, this._apiService, this._uiService);
	}
}