import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { PaymentMethodType } from '../../core';

@Component({
	templateUrl: "/template/commissions/modals/add-settlement.cshtml"
})
export class AddSettlementModalComponent implements OnInit {

	public amount: number;
	public date: Date;
	public paymentMethodType: PaymentMethodType = null;

	constructor(
		public modal: SuiModal<IAddSettlementModalComponentContext, IAddSettlementModalComponentResult, void>) {

		this.amount = modal.context.amount;
		this.date = modal.context.date;
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	/**
	 * Uloží platbu 
	 */
	public save(): void {
		var result: IAddSettlementModalComponentResult = { paymentMethodType: this.paymentMethodType, date: this.date };
		this.modal.approve(result);
	}

	/**
	 * Zruší modal 
	 */
	public cancel(): void {
		this.modal.deny(null);
	}
}

export class AddSettlementModal extends ComponentModalConfig<IAddSettlementModalComponentContext, IAddSettlementModalComponentResult, void> {
	constructor(amount: number, date: Date) {
		super(AddSettlementModalComponent, { amount, date });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
		this.mustScroll = true;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IAddSettlementModalComponentContext {
	amount: number;
	date: Date;
}

export interface IAddSettlementModalComponentResult {
	paymentMethodType: PaymentMethodType,
	date: Date
}