import { Component, OnInit } from '@angular/core';
import { UIService } from '../services/ui';

/**
 * Horní hlavní menu
 */

@Component({
	selector: 'div.kt-footer',
	templateUrl: "/template/core/components/footer.cshtml"
})
export class FooterComponent implements OnInit {

	// Text zobrazený v patičce
	public footerText: string = null;

	constructor(
		private _uiService: UIService) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this._uiService.footerText.subscribe(text => {
			this.footerText = text;
		});
	}
}