import { Component, OnInit } from '@angular/core';
import {
    UIService, FormComponentBase, HelperService, AppScopeService, AuthService
} from '../../../core';
import { SumUpService } from '../services/sumup';

@Component({
    templateUrl: "/template/modules/sumup/pages/settings.cshtml"
})
export class SumUpSettingsPageComponent extends FormComponentBase implements OnInit {
    constructor(
        protected _uiService: UIService,
        protected _helperService: HelperService,
        protected _authService: AuthService,
        protected _appScopeService: AppScopeService,
        private _sumUpService: SumUpService) {

        super(
            _uiService,
            _helperService,
            _authService,
            _appScopeService);
    }

	/**
	 * Inicializace 
	 **/
    ngOnInit() {
        this._sumUpService
            .getSettings()
            .then(x => {
                this.setModel(1, x);
            });
    }

	/**
	 * Vrací nadpis pro stránku 
	 */
    public getTitle() {
        return 'SumUp';
    }

	/**
	 * Provede uložení
	 */
    public save(): void {
        this._uiService.showLoader();

        this._sumUpService
            .setSettings(this.model)
            .then(
                x => {
                    super.setModel(1, this.model);

                    this._uiService.hideLoader();
                },
                reason => {
                    this._uiService.hideLoader();
                });
    }
}