import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIService, ModelFormComponentBase, HelperService, AppScopeService, AuthService, ModelServiceFactory } from '../../core';

@Component({
    templateUrl: "/template/notifications/pages/notification-detail.cshtml"
})
export class NotificationDetailPageComponent extends ModelFormComponentBase {
    // drží 'index' naposledy přidané podmínky
    private _conditionsIdx: number;
    constructor(
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
        protected _appScopeService: AppScopeService,
        private _modelServiceFactory: ModelServiceFactory,
		protected _router: Router) {
		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_modelServiceFactory.createService('notification'),
			_router);
	}

    /**
	 * Override metody v ModelFormComponentBase - potřebujeme inicializovat
	 * @param model
	 */
    protected onModelLoaded(model: any) {
        model.conditions = model.conditions.map((x, i) => {
            x.$i = i;
            return x;
        });
        this._conditionsIdx = model.conditions.length;
    }

    /**
     * Override metody v ModelFormComponentBase - po vložení redirektujeme na editaci
     * @param model
     */
    protected onModelSaved(model: any): void {
        if (this.modelId === 0) {
            this._router.navigate(['app', 'notifications', model.id], {});
        }
    }

	/**
	 * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
	 */
	getTitle() {
        return this.modelId === 0 ? 'Nová notifikace' : 'Editace notifikace';
    }

    resetColor() {
        this.model.markerColor.markerColor = "";
    }
}