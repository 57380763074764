import { Component, OnInit, forwardRef, ElementRef, NgZone, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileManagerService } from '../services/file-manager';
import { FileInfo } from '../models';

import 'tinymce/tinymce';
import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/fullscreen';
import '../../vendor/tinymce/langs/cs';

declare var tinymce: any;

@Component({
	selector: '[ktHtmlEditor]',
	templateUrl: "/template/core/components/html-editor.cshtml",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => HtmlEditorComponent),
			multi: true
		}
	]
})
export class HtmlEditorComponent implements OnInit, ControlValueAccessor {

	private _editor: any = null;
	private _value: string = null;

	@Input() tinymce: any = {};
	@Input() fileManagerRootPath: string = null;

	constructor(
		private _element: ElementRef,
		private _zone: NgZone,
		private _fileManagerService: FileManagerService) {
	}

	/**
	 * Inicializuje komponentu
	 */
	ngOnInit() {
		let id: string = this._element.nativeElement.id;
		let settings: any = Object.assign(
			this.tinymce,
			{
				branding: false,
				skin: false,
				plugins: 'table lists link image textcolor textpattern fullscreen',
				menubar: false,
				toolbar1: 'undo redo | cut copy paste | bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | removeformat fullscreen',
				toolbar2: 'styleselect formatselect fontselect fontsizeselect | blockquote | link unlink anchor image table',
				language: 'cs',
				file_picker_callback: this._filePickerCallback.bind(this)
			}
		);

		this._editor = tinymce.EditorManager.createEditor(id, settings);
		this._editor.render();
		this._editor.on('change keyup', x => {
			const content = this._editor.getContent();

			if (content != this._value) {
				this._value = content;

				this._zone.run(() => {
					this._propagateChange(content);
				});
			}
		});
	}

	//
	// Implementace ControlValueAccessor
	//

	private _propagateChange = (_: any) => { };
	private _propagateTouched = () => { };

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
	writeValue(value: any): void {
		this._editor.setContent(value || '');
	}

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
	setDisabledState?(isDisabled: boolean): void {
		this._editor.setMode(isDisabled ? 'readonly' : 'design');
	}

	private _filePickerCallback(callback: any, value: any, meta: any) {

		this._fileManagerService.selectFiles(this.fileManagerRootPath)
			.then((files: Array<FileInfo>) => {
				if (files != null) {
					callback(files[0].url);
				}
			});
	}
}