import { FormGroup } from '@angular/forms';

export class ModelWrapper<TModel> {
	constructor(
		form: FormGroup,
		model: TModel,
        originalModel: TModel,
        context: ModelWrapperContext
	) {
		this.form = form;
		this.model = model;
        this.originalModel = originalModel;
        this.context = context;
	}
	public form: FormGroup;
	public model: TModel;
    public originalModel: TModel;
    public context: ModelWrapperContext;
	public init(model: TModel): void {
		this.originalModel = model;
		// Není to zrovna typové no
		// Vím že tam bude FormGroupWrapper
		(<any>this.model).update(model);
    };
    public isChanged(): boolean {
        return (<any>this.model).isChanged();
    }
    public collectChanges(): Array<PropertyValueChange> {
        let retval = [];
        (<any>this.model).collectChanges(retval);
        return retval;
    }
    public doOutsideChangeTracking(action: (model: TModel) => any) {
        this.context.setWithOriginal = true;

        action(this.model);

        this.context.setWithOriginal = false;
    }
}

export class ModelWrapperContext {
    // Pokud je true, pak se zaroven s hodnotu property zmeni i jeji original value
    // Nevyskoci pak ze bylo zmeneno
    public setWithOriginal: boolean = false;
}

export class OpenSections {
	general: boolean = true;
	device: boolean = true;
	addresses: boolean = true;
	commission: boolean = true;
	transport: boolean = true;
	texts: boolean = true;
	priceProposal: boolean = false;
	authorization: boolean = false;
	inhome: boolean = false;
	execution: boolean = true;
}

/**
 * Specififes property informations 
 **/
export class PropertyInfo {
    propertyName: string;
    propertyPath: string;
}

/**
 * Drží originální a aktuální hodnotu.  
 **/
export class PropertyValueChange extends PropertyInfo {
    originalValue: any;
    newValue: any;
}

/**
 * Helper pro předávání/vytváření informace o cestě  
 **/
export class PropertyPath {
    private _parts: Array<string> = [];

    public push(part: string): void {
        this._parts.push(part);
    }

    public pop(): void {
        this._parts.splice(this._parts.length - 1, 1);
    }

    public getName(): string {
        if (this._parts.length <= 0) {
            return null;
        }

        return this._parts[this._parts.length - 1];
    }

    public getPath(): string {
        return this._parts.join('.');
    }

    public parse(path: string): void {
        this._parts = path.split('.');
    }
}