import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent, IModelService, ModelServiceFactory, ValuesService } from '../../../core';
import { DataTableOption, ScopePermissionType } from '../../../core/models';

@Component({
    templateUrl: "/template/lists/commission-kinds/pages/commission-kind-list.cshtml"
})
export class ListsCommissionKindPageComponent {
	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * Instance model service
	 */
	public modelService: IModelService;

	/*
	 * Definice tlačítek v seznamu
	 */
	public dataTableOptions: Array<DataTableOption> = [
		{
			icon: 'trash',
			className: 'grey removebut',
			onClick: item => {
				this.delete(item);
			},
			tooltip: 'Odstranit',
			permission: ScopePermissionType.Delete
		}
	];

	constructor(
		private _router: Router,
        private _uiService: UIService,
        private _valuesService: ValuesService,
		private _modelServiceFactory: ModelServiceFactory) {
		this.modelService = _modelServiceFactory.createService('listCommissionKind');
	}

	/**
	 * Redirekt na stránku pro přidání.
	 */
    add() {
		this._router.navigateByUrl('/app/list/commission-kinds/0');
	}

	/**
	 * Smazání
	 * @param item
	 */
	delete(item: ICommissionKindListModel) {
		this._uiService
            .showConfirmation('Odstranění záznamu', `Opravdu si přejete odstranit záznam ${item.name}?`)
            .then(result => {
                if (result) {
                    this._uiService.showLoader();

                    this.modelService.delete(item.id, null).then(
                        result => {
                            this._uiService.hideLoader();
                            this._grid.loadData();
							this._valuesService.clearCacheItem("CommissionKind");
                        });
                }
			});
    }

	/**
	 * Redirekt na stránku s editací
	 * @param item
	 */
	edit(item: ICommissionKindListModel) {
        this._router.navigateByUrl(`/app/list/commission-kinds/${item.id}`);
	}
}