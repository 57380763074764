import { Component, OnInit } from '@angular/core';
import { CommissionViewModel } from '../services/commission-view-model';
import { FormControlsState } from '../services/form-controls-state';
import { SectionComponentBase } from '../classes/section-component-base';
import { FormGroup } from '@angular/forms';
import { AuthorizationModal } from '../modals/authorization';
import { ValuesService, ModalService, CommissionAuthorizationState, DataStorageService } from '../../core';

/**
 * Sekce Cenový návrh
 */

@Component({
	selector: 'div.kt-authorization-section',
	templateUrl: "/template/commissions/components/authorization-section.cshtml"
})
export class AuthorizationSectionComponent extends SectionComponentBase implements OnInit {

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._viewModel.form;
    };

    // Controls state
    public get controlsState(): FormControlsState {
        return this._viewModel.controlsState;
    };

	private _authorizationStates: { [value: string]: string };

	constructor(
		private _modalService: ModalService,
		private _viewModel: CommissionViewModel,
		private _valuesService: ValuesService,
		protected _dataStorageService: DataStorageService) {

		super(_dataStorageService, 'authorization');
	}

	/**
	 * Inicializace komponenty 
	 */
	ngOnInit() {
		this._valuesService
			.getDictionary('EnumType?type=Kentaur.Domain.Commissions.CommissionAuthorizationState')
			.then(x => this._authorizationStates = x);
	}

	/**
	 * Zobrazí modal pro přidání autorizace
	 */
	public add(): void {
		let authorization = this._viewModel.createAuthorization();

        this._modalService.openModal(new AuthorizationModal(
            authorization,
            this.controlsState.buttonIsDisabled('authorizationModal.ok')
        ))
			.onApprove(result => {
				this._viewModel.model.authorizations.push(<ICommissionAuthorization>result);
			});
	}

	/**
	 * Zobrazí modal pro editaci
	 * @param authorization
	 */
	public edit(authorization: FormGroup): void {
		let authorizationObject = authorization.getRawValue();

        this._modalService.openModal(new AuthorizationModal(
            authorizationObject,
            this.controlsState.buttonIsDisabled('authorizationModal.ok')
        ))
			.onApprove(result => {
				authorization.setValue(result);
			});
	}

	/**
	 * Vrací název pro daný stav autorizace
	 * @param state
	 */
	public getCommissionAuthorizationStateName(state: CommissionAuthorizationState): string {
		if (!this._authorizationStates) {
			return '';
		}

		return this._authorizationStates[state.toString()];
	}
}