import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IModelService, AuthService, GridComponent, ModalService, UIService } from '../../../core';
import { CloseApprovedCommissionsModal } from '../modals/close-approved-commissions';
import { CommissionsService } from '../services/commissions';
import { MessageModalType } from '../../../core/modals/message';

@Component({
	templateUrl: "/template/portal/commissions/pages/commission-list.cshtml"
})
export class CommissionListPageComponent implements OnInit {
	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * ID garanta pro kter�ho se zobrazuj� data
	 */
    public garantId: number;

	/**
	 * Instance model service
	 */
    public modelService: IModelService;

    public authService: AuthService;

    constructor(
        private _route: ActivatedRoute,
		private _router: Router,
        private _commissionsService: CommissionsService,
        protected _authService: AuthService,
		private _modalService: ModalService,
		private _uiService: UIService) {

        this.modelService = _commissionsService;
        this.authService = _authService;
    }

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		// Ukl�d�m id garanta z routy
        this.garantId = this._route.snapshot.params.garantId;
    }

	/**
	 * Redirekt na str�nku pro p�id�n�.
	 */
    add() {
        this._router.navigateByUrl(`/app/portal/commissions/${this.garantId}/0`);
    }

    /**
	 * Mame prava agenta?
	 */
    protected isAgent(): boolean {
        return this.authService.currentUser.portalSpecialPermission.isAgent;
    }

	/**
	 * Zobraz� modal pro uzav�en� schv�len�ch zak�zek
	 */
	public closeApproved() {
		// Otev�eme modal
		this._modalService
			.openModal(new CloseApprovedCommissionsModal())
			.onApprove(result => {
				this._grid.loadData();
			});
	}

	/**
	 * Redirekt na str�nku s editac�.
	 * @param commission
	 */
	public edit(commission: any) {
        this._router.navigateByUrl(`/app/portal/commissions/${this.garantId}/${commission.id}`);
	}

	/**
	 * Export dat pro Hisense 
	 **/
	public exportHisense(): void {
		this._commissionsService.exportToHisense(this._grid.checkedIds.items)
			.then(result => {
				this._uiService.showMessage('Export dat Hisense', result, MessageModalType.TextArea);
			});
	}

	/**
	 * Vrac� true/false jestli je za�krtnut� n�jak� ��dek 
	 **/
	public hasSelectedRows(): boolean {
		return this._grid.checkedIds.items.length > 0;
	}
}