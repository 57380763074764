import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommissionViewModel } from '../services/commission-view-model';
import { SectionComponentBase } from '../classes/section-component-base';
import { PricingViewModel } from '../services/pricing-view-model';
import { FormControlsState } from '../services/form-controls-state';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RequestForDepositModal, IRequestForDepositModalComponentResult } from '../modals/request-for-deposit';
import { ModalService, SelectOption, ValuesService, DataStorageService } from '../../core';

/**
 * Sekce Zakázka
 */

@Component({
	selector: 'div.kt-commission-section',
	templateUrl: "/template/commissions/components/commission-section.cshtml"
})
export class CommissionSectionComponent extends SectionComponentBase implements OnInit, OnDestroy {
	private _subs: Array<Subscription> = [];

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._viewModel.form;
	};

	// Pricing view model
	public get pricing(): PricingViewModel {
		return this._viewModel.pricing;
    };

    // Controls state
    public get controlsState(): FormControlsState {
        return this._viewModel.controlsState;
    };

	// Pole pro opions techniků
    public technicianOptions: Array<SelectOption> = [];

    // Info jestli jde o novou zakázku
    public get isNew(): boolean {
        return this._viewModel.isNew;
    }

	// Ridi zobrazeni / skryti tlacitka pro odstraneni zalohy a jeji uhrady na nove zakazce
	public get showBtnRemovePayment(): boolean {
		return (this._viewModel.isNew && this._viewModel.model.payments.length > 0);
	}

	

	constructor(
		private _viewModel: CommissionViewModel,
		private _modalService: ModalService,
        private _valuesService: ValuesService,
		protected _dataStorageService: DataStorageService) {
        super(_dataStorageService, 'commission');
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this._subs.push(
            this.form.get('branchId').valueChanges.subscribe(x => this._onBranchIdValueChange(x))   
		);

		this._onBranchIdValueChange(this._viewModel.model.branchId);
	}

	/**
	 * Destroy komponenty 
	 **/
	ngOnDestroy() {
		this._subs.forEach(x => x.unsubscribe());
	}

	/**
	 * Zobrazí modál pro přidání požadavku na zálohu 
	 */
	public addRequestForDeposit(): void {
		this._modalService.openModal(new RequestForDepositModal(this._viewModel.model))
			.onApprove((result: IRequestForDepositModalComponentResult) => {
				this._viewModel.addRequestForDeposit(result.amount, result.paymentMethodType);
			});
	};

	/**
	 * Zpracovává změnu ID pobočky
	 * @param value
	 */
    private _onBranchIdValueChange(value: number | null) {

		this._valuesService.getValues(`User?branchId=${this._viewModel.model.branchId}&technicianOnly=true&technicianOnly=true`)
			.then(x => {
				this.technicianOptions = x
					.map(x => new SelectOption(<any>x.value, x.text));

				// Zrus vybraneho technika, pokud neni v seznamu
				if (
					this._viewModel.model.technicianId != null &&
					!this.technicianOptions.find(x => x.value == this._viewModel.model.technicianId.toString())) {

					this._viewModel.model.technicianId = null;
				}
			});
	}


	/**
	 * Odstranime pozadavek na zalohu u nove zakazky. Zadna jina uhrada nemuze existovat, proto mazeme cele pole.
	 * */
	public removePayment(): void {

		if (this._viewModel.isNew) {

			while (this._viewModel.model.payments.length !== 0) {
				this._viewModel.model.payments.splice(0, 1);
			}
		}
		
	}

	/**
	 * Zpracovává keyup událost inputu ID zakázky garanta
	 * @param event
	 */
    public onPayerCommissionIdInputKeyUp(event: KeyboardEvent) {
		this._viewModel.model.payerCommissionId = this._viewModel.model.payerCommissionId.trim().replace(/[\n\r\t\s]/g, '');
	}

	/** Při opuštění Rekl. protokol č. inputu */
	public onComplaintProtocolNumberBlur(): void {
		this._viewModel.model.complaintProtocolNumber = this._viewModel.model.complaintProtocolNumber.trim();
	}
}