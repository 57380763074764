import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { Value } from '../models';

/**
 * Služba poskytující hodnoty přes value providery
 * 
 * @export
 * @class ValuesService
 */
@Injectable()
export class ValuesService {

	private _cache: { [source: string]: Array<any>; } = {};

	constructor(
		private _apiService: ApiService) {
	}

	/**
	 * Vrací objekt { value: text } pro hodnoty z daného zdroje.
	 * @param source
	 * @param includeNotActive - Whether to include not ative values (default = false)
	 */
	public getDictionary(source: string, includeNotActive: boolean = false): Promise<{ [value: string]: string }> {
		return new Promise((resolve, reject) => {
			// Beru hodnoty enumu
			this.getValues(source, includeNotActive)
				.then(values => {
					let retval = {};

					values.forEach(x => retval[x.value.toString()] = x.text);

					resolve(retval);
				});
		});
	}

	/**
	 * Vrací zadanou hodnotu daného zdroje
	 * @param source
	 * @param value
	 */
	public getValue(source: string, value: any): Promise<Value> {
		return new Promise((resolve, reject) => {
			// Beru hodnoty enumu
			this.getValues(source, true)
				.then(x => {
					// Filtruju podle hodnoty
					resolve(x.find(y => y.value == value));
				});
		});
	}

	/**
	 * Vrátí pole hodnot pro zadaný zdroj
	 * @param source
	 * @param includeNotActive - Whether to include not ative values (default = false)
	 */
	public getValues(source: string, includeNotActive: boolean = false): Promise<Array<Value>> {
		return new Promise((resolve, reject) => {
			// Koukneme do cache
			let retval = this._cache[source];

			if (typeof (retval) != 'undefined') {
				resolve(this._filterByActive(retval, includeNotActive));
				return;
			}

			// Načítáme a ukládáme do cache
			this._apiService.get<Array<any>>('valuepairs/' + source)
				.then(x => {

					// Sort
					x.sort((a, b) => a.text.localeCompare(b.text));

					this._cache[source] = x;

					resolve(this._filterByActive(x, includeNotActive));
				});
		});
	}

	/**
	 * Vrátí pole rozšířených hodnot pro zadaný zdroj
	 * @param source
	 * @param includeNotActive - Whether to include not ative values (default = false)
	 */
	public getExtendedValues<T extends Value>(source: string, includeNotActive: boolean = false): Promise<Array<T>> {
		return new Promise((resolve, reject) => {
			// Koukneme do cache
			let retval = this._cache[source];

            if (typeof (retval) != 'undefined') {
				resolve(this._filterByActive(retval, includeNotActive));
				return;
			}

			// Načítáme a ukládáme do cache
			this._apiService.get<Array<T>>('valuepairs/' + source)
				.then(x => {

					// Sort
					x.sort((a, b) => a.text.localeCompare(b.text));

					this._cache[source] = x;

					resolve(this._filterByActive(x, includeNotActive));
				});
		});
	}

	/**
	 * Vrátí jednu z pole rozšířených hodnot pro zadanou hodnotu
	 * @param source
	 * @param value
	 */
	public getExtendedValue<T extends Value>(source: string, value: any): Promise<T> {
		// Beru hodnoty enumu
		return this.getExtendedValues<T>(source, true)
			.then(x => {
				// Filtruju podle hodnoty
				return <T>x.find(y => y.value == value);
			});
    }

    /**
     * Smaže položku z cache a obnoví všechny zobrazené selecty s daným zdrojem dat.
     * @param source název položky
     */
    public clearCacheItem(source: string): void {        
        delete this._cache[source];       
	}

	private _filterByActive<T extends Value>(values: Array<T>, includeNotActive: boolean) {
		if (includeNotActive === true) {
			return values;
		}

		return values.filter(x => !x.isNotActive);
	}
}