import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';

// Pages
import { ListsCommissionEventPageComponent } from './pages/commission-event-list';
import { ListsCommissionEventDetailPageComponent } from './pages/commission-event-detail';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		CoreModule,
		FormsModule,
		SuiModule
	],
	exports: [
		RouterModule
	],
	declarations: [
		ListsCommissionEventPageComponent,
		ListsCommissionEventDetailPageComponent
	],
	providers: [
	],
	entryComponents: [
	]
})
export class CommissionEventsModule { }