import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { ModalModelFormHost } from '../../core';

@Component({
	templateUrl: "/template/commissions/modals/model-detail.cshtml"
})
export class ModelDetailModalComponent implements OnInit {
	/**
	 * Implementace IModelFormHost
	 */
	public modelFormHost: ModalModelFormHost;

	constructor(
		public modal: SuiModal<IModelDetailModalComponentContext, void, void>) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this.modelFormHost = new ModalModelFormHost(
			this.modal.context.modelId,
			() => this.modal.deny(null),
			() => this.modal.approve(null)
		);
	}
}

export class ModelDetailModal extends ComponentModalConfig<IModelDetailModalComponentContext, void, void> {
	constructor(modelId: number) {
		super(ModelDetailModalComponent, { modelId });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Normal;
		this.mustScroll = true;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IModelDetailModalComponentContext {
	modelId: number;
}
