import {
    Component,
    Input,
    OnInit,
    forwardRef,
    Output,
    EventEmitter,
    Renderer2,
    OnDestroy,
    ElementRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    HostBinding
} from '@angular/core';
import { SelectComponentBase } from '../classes/select-component-base';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValuesService } from '../services/values';
import { SelectOption } from '../models';
import { isUndefined } from 'util';

@Component({
    selector: 'div.kt-select',
    styles: [`.active, .visible { display:block !important; }`],
    templateUrl: "/template/core/components/select.cshtml",
    host: {
        'class': 'ui dropdown',
        '[class.active]': 'active',
        '[class.visible]': 'active',
        '[class.disabled]': 'disabled',
        '[class.multiple]': 'multiple',
        '[class.upward]': 'upward',
        '[class.fluid]': 'fluid',
        '[class.selection]': 'selection',
        '[id]': 'id',
        '(keydown)': 'onInputKeyDown($event)',
        '[tabindex]': 'disabled ? -1 : 0',
        '(focus)': 'onFocus($event)',
        '(click)': 'onClick($event)'
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent
    extends SelectComponentBase
    implements OnInit, OnDestroy, ControlValueAccessor {

    // Drží hodnoty vybraných voleb.
    private _value: string | Array<string>;

    // Getter a setter pro _value
    get value(): string | Array<string> {
        return this._value;
    }
    set value(value: string | Array<string>) {
        this._value = value;

        this.valueChange.emit(value);
        this.change.emit(this.options.find(x => x.value == value));
        this._propagateChange(value);
    }

    // Input/Output

    @Input() public set dataSource(value: string | Array<SelectOption>) {
        // Setter je tady pro to, aby se aktualizovaly options při změně zdroje
        this._setupOptions(value);
    };
    @Input() public notSetValue: string | null;
    @Input() public notSetText: string;
    @Input() public multiple: boolean = false;
    @Input() public upward: boolean = false;
    @Input() public simple: boolean = false;
    @Input() public icon: string = "dropdown";
    @Input() public position: string = "";

    @Output('onValueChange') public valueChange: EventEmitter<any> = new EventEmitter<any>();
    @Output('onChange') public change: EventEmitter<SelectOption> = new EventEmitter<SelectOption>();
    @Output('onOptionSelected') public optionSelected: EventEmitter<SelectOption> = new EventEmitter<SelectOption>();

    @HostBinding('class.fluid') isFluid: boolean = true;
    @HostBinding('class.selection') isSelection: boolean = true;

    constructor(
        protected _elementRef: ElementRef,
        protected _renderer: Renderer2,
        private _valuesService: ValuesService,
        protected _changeDetectorRef: ChangeDetectorRef) {
        super(_elementRef, _renderer, _changeDetectorRef);
    }

    /**
     * Inicializace komponenty
     */
    ngOnInit(): void {
        // Nastavím ID pokud není nastaveno na elementu
        this.id = this.id || new Date().getTime().toString();
        this.isFluid = !this.simple;
        this.isSelection = !this.simple;
    }

    /**
     * Vrací vybraný option. Pouze pro single výběr.
     */
    public get selectedOption(): SelectOption {
        if (this.multiple || isUndefined(this.value)) {
            return null;
        }

        return this.options.find(x => x.value == this.value);
    }

    /**
     * Vrací pole vybraných option. Pouze pro vícenásobný výběr.
     */
    public get selectedOptions(): Array<SelectOption> {
        if (!this.multiple || isUndefined(this.value)) {
            return [];
        }

        // Filtruju options na základě vybraných hodnot
        let value = <Array<string>>this.value;

        if (!value) {
            return [];
        }

        return this.options.filter(x => value.indexOf(x.value) > -1);
    }

    /**
     * Vrátí text který se zobrazuje jako vybraný
     */
    public get selectedText(): string {
        let selectedOption = this.selectedOption;

        if (selectedOption) {
            return selectedOption.text;
        }

        if (this.value) {
            return this.value.toString();
        }

        return '';
    }

    /**
     * Vrací info jestli je poptaná volba vybraná.
     * @param option
     */
    public isActiveOption(option: any): boolean {
        if (!this.value) {
            return false;
        }

        if (this.multiple) {
            let value = <Array<string>>this.value;
            let index: number = value.indexOf(option.value);

            return index !== -1;
        } else {
            return this.value === option.value;
        }
    }

    /**
     * Zoracovává klik na select
     * @param event
     */
    public onClick(event: any): void {
        this._open(event);
    }

    /**
     * Zpracovává získání focusu na selectu
     * @param event
     */
    public onFocus(event: any): void {

        this._setUpward(event);
        this._open(event);
    }

    /**
 * Zpracuje kliknutí na volbu.
 * @param index
 * @param event
 */
    public optionClick(index: number, event: any): void {
        this._optionSelected(index);

        event.stopPropagation();
    }

    /**
     * Odebere zadanou volbu z vícenásobného seznamu.
     *
     * @param option
     * @param event
     */
    public removeOption(option: SelectOption, event: any): void {
        if (!this.multiple) {
            return;
        }

        let value = <Array<string>>this.value;
        let index: number = value.indexOf(option.value);

        if (index !== -1) {
            value.splice(index, 1);
        }

        this.value = value;
        event.stopPropagation();
    }

    /**
     * Nechceme vyhledávat v Nezadáno apod. volbách
     * @param option
     */
    protected _optionIsSearchable(option: SelectOption): boolean {
        return option.value != this.notSetValue;
    }

    /**
     * Abstraktní metoda pro potomky
     * @param index
     */
    protected _optionSelected(index: number): void {
        let option = this.options[index];

        if (this.multiple) {
            let value = <Array<string>>this.value;
            value.push(option.value);
            this.value = value;

            if (value.length === this.options.length) {
                this.toggle();
            }
        } else {
            this.value = option.value;
            this.toggle();

            this.optionSelected.emit(option);
        }
    }

    /**
     * Vytvoří options na základě dodaného zdroje
     * @param dataSource
     */
    private _setupOptions(dataSource: string | Array<SelectOption>): void {
        if (dataSource instanceof Array) {
            let options = <Array<SelectOption>>dataSource.map(x => x);

            // Přidáme Nezadáno hodnotu
            if (this.notSetValue !== undefined) {
                options.unshift(new SelectOption(this.notSetValue, this.notSetText || 'Nezadáno'));
            }

            this._setOptions(options);
        }
        else if (typeof dataSource === 'string') {
            // Načteme options z API
            this._valuesService.getValues(dataSource).then(
                values => {
                    // Transformujeme na Option
                    let options = values.map(x => new SelectOption(x.value, x.text));

                    // Přidáme Nezadáno hodnotu
                    if (this.notSetValue !== undefined) {
                        options.unshift({ value: this.notSetValue, text: this.notSetText || 'Nezadáno' });
                    }

                    // Zkontrolujeme jestli existuje Option pro aktuálně vybranou hodnotu
                    // pokud ne, pokusíme se ji načíst a přidat
                    let value = this.value;

                    if (typeof (value) !== 'object' && value != null && options.findIndex(x => x.value == value) == -1) {
                        // Vezmeme jen název zdrojového seznamu, před případným otazníkem
                        let dataSourceRoot = dataSource.split('?')[0];

                        this._valuesService
                            .getValue(dataSourceRoot, value)
                            .then(value => {
                                if (value != null) {
                                    options.push(new SelectOption(value.value, value.text));

                                    options = options.sort((a, b) => a.text.localeCompare(b.text))

                                    this._setOptions(options);
                                }
                            });
                    }
                    else {
                        this._setOptions(options);
                    }
                });
        } else {
            console.log(dataSource);
            throw 'Not supported data source type ' + dataSource;
        }
    }

    /**
     * Nastavime selectboxu tridu upward podle pozice na strance
     * @param event
     */
    private _setUpward(event: any) {

        this.upward = false;

        let windowHeight = event.view.innerHeight;
        let elementPositon = document.getElementById(event.target.id).getBoundingClientRect().top;
        if ((windowHeight - elementPositon) < 200) {
            this.upward = true;
        }
    }

    private _setOptions(options: Array<SelectOption>): void {
        this.options = options;
        this._changeDetectorRef.detectChanges();
    }

    //
    // Implementace ControlValueAccessor
    //

    private _propagateChange = (_: any) => { };
    private _propagateTouched = () => { };

    /**
     * Funkce je volána když se má nastavit hodnota do kontrolu
     */
    writeValue(value: any): void {
        this._value = value;

        this._changeDetectorRef.detectChanges();
    }

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
    registerOnChange(fn: any): void {
        this._propagateChange = fn;
    }

    /**
     * Nastaví funkci, která má být volána onTouch
     * @param fn
     */
    registerOnTouched(fn: any): void {
        this._propagateTouched = fn;
    }

    /**
     * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
     * @param isDisabled
     */
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}