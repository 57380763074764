import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Kontroluje, jestli je možné změnit routu.
 * @export
 * @class CanDeactivateGuard
 * @implements {CanDeactivate}
 */
@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ICanDeactivateComponent> {
	/**
	 * Vrací info jetli je možno deaktivovat routu
	 * @param component
	 */
	public canDeactivate(component: ICanDeactivateComponent): boolean | Observable<boolean> | Promise<boolean> {
		return component.canDeactivate();
	}
}

/**
 * Definuje rozhraní komponenty, která může zrušit deaktivaci routy
 */
export interface ICanDeactivateComponent {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}