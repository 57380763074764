import { Directive, Input, ContentChild, TemplateRef } from '@angular/core';

@Directive({
	selector: 'kt-grid-column'
})
export class GridColumnDirective {
	// Inputy
    @Input() title: string = null;
	@Input() field: string = null;
	@Input() className: string = null;
	@Input() sortable = false;
    @Input() dateFormat: string = null;
    @Input() fixed: boolean = false;
    @Input() width: number = 0;

    // Templaty které umožňují přepsat vykreslovádní buňky nebo hlavičky sloupce
    @ContentChild('cellTemplate', { static: true }) cellTemplate: TemplateRef<any> = null;
    @ContentChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any> = null;
}