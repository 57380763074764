import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent, ModalService } from '../../core';
import { ContactsService } from '../services/contacts';
import { ContactAddModal, IContactAddModalComponentResult } from '../modals/contact-add';
import { DataTableOption, ScopePermissionType } from '../../core/models';

@Component({
	templateUrl: "/template/contacts/pages/contact-list.cshtml"
})
export class ContactListPageComponent {
	/*
	 * Definice tlačítek v seznamu
	 */
	public dataTableOptions: Array<DataTableOption> = [
		{
			icon: 'trash',
			className: 'grey removebut',
			onClick: item => {
				this.delete(item);
			},
			tooltip: 'Odstranit',
			permission: ScopePermissionType.Delete
		}
	];

    /**
     * Reference na grid komponentu
     */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

    constructor(
        private _router: Router,
        private _uiService: UIService,
		public modelService: ContactsService,
		private _modalService: ModalService) {
    }

    /**
    * Zobrazí dialog pro přidání nové autorizace.
    */
    public add(): void {
        // Otevřeme modal
        this._modalService.openModal(new ContactAddModal())
            .onApprove((result: IContactAddModalComponentResult) => {
				if (result != null) {
					if (result.contactId) {
						this._router.navigateByUrl(`/app/contacts/contacts/${result.contactId}`);
					}
					else if (result.ic) {
						this._router.navigateByUrl(`/app/contacts/contacts/0?aresIco=${result.ic}`);
					}
					else {
						this._router.navigateByUrl(`/app/contacts/contacts/0`);
					}
                }
            });
    }

	/**
	 * Redirekt na stránku s editací.
	 * @param commission
	 */
    public edit(contact: any): void {
        this._router.navigateByUrl(`/app/contacts/contacts/${contact.id}`);
	}

     /**
	 * Smazání 
	 * @param user
	 */
	public delete(contact: any): void {
		this._uiService
			.showConfirmation('Odstranění záznam', `Opravdu si přejete odstranit záznam ${contact.name}?`)
            .then(result => {
                if (result) {
                    this._uiService.showLoader();

					this.modelService.delete(contact.id).then(
                        result => {
                            this._uiService.hideLoader();
                            this._grid.loadData();
                            });
                    }
			    });
        }

	public stateFilter: any = { stateId: null };

	public stateFilterChange(value: any): void {
		this.stateFilter.stateId = value;
	}
}
