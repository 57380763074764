import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { ListsAccessoriesModule } from './';

// Pages
import { ListsAccessoriesPageComponent } from './pages/accessories-list';
import { ListsAccessoriesDetailPageComponent } from './pages/accessories-detail';

const routes: Routes = [
    { path: '', component: ListsAccessoriesPageComponent },
	{
		path: ':id',
        component: ListsAccessoriesDetailPageComponent,
		canDeactivate: [CanDeactivateGuard]
	}
];

@NgModule({
	imports: [
        ListsAccessoriesModule,
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class ListsAccessoriesRoutingModule { }