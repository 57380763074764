import { Component, OnInit} from '@angular/core';
import { style, state, animate, transition, trigger, keyframes } from '@angular/animations';
import { UIService } from '../services/ui';
import { Router } from '@angular/router';

/**
 * Pouze layout pro část aplikace, která obsahuje horní menu
 */

@Component({
	templateUrl: "/template/core/components/app-layout.cshtml",
	animations: [
		trigger('visibilityChanged', [
			state('shown', style({ opacity: 1, transform: 'translateX(0)' })),
			state('hidden', style({ opacity: 0, transform: 'translateX(100%)' })),
			transition('shown => hidden', [
				animate(500, keyframes([
					style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
					style({ opacity: 0, transform: 'translateX(0)', offset: 0.99 }),
					style({ opacity: 0, transform: 'translateX(100%)', offset: 1.0 })
				]))
			])
		])
	]
})

export class AppLayoutComponent implements OnInit {
    public loaderVisibility: string = 'shown';
    public fixedHeader: boolean = false;

	constructor(
        private _uiService: UIService,
        private _router: Router) {

    }

	/**
	 * Inicializuje komponentu
	 */
	ngOnInit() {
		this._uiService.loaderData.subscribe(data => {
			this.loaderVisibility = data.show ? 'shown' : 'hidden';
        });
    }
 
    /**
    * Obsluha scroole eventu elementu
    * @param $event
    */
    @debounce(20)
    public onElementScroll($event): void {

        // Resime pouze pro detail zakazky
        if (this._router.url.indexOf("/app/commissions/") == -1) {
            this.fixedHeader = false;
            return;
        }

        if ($event.target.scrollTop > 70) {
            this.fixedHeader = true;
        } else {
            this.fixedHeader = false;
        }
    }

    /**
     * Zpracovává událost změny velikosti
     * @param event
     */
    public onResized(event: any) {
        this._uiService.onResized.emit(event);
    }
}

export function debounce(delay: number = 300): MethodDecorator {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        let timeout = null

        const original = descriptor.value;

        descriptor.value = function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => original.apply(this, args), delay);
        };

        return descriptor;
    };
}