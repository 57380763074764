import { Component, OnInit } from '@angular/core';
import { CommissionViewModel } from '../services/commission-view-model';
import { FormGroup } from '@angular/forms';

/**
 * Tab Přílohy
 */

@Component({
	selector: 'div.kt-attachments-tab',
	templateUrl: "/template/commissions/components/attachments-tab.cshtml"
})
export class AttachmentsTabComponent implements OnInit {

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._viewModel.form;
	};

	// Commission view model
	public get model(): ICommission {
		return this._viewModel.model;
	};

	constructor(
		private _viewModel: CommissionViewModel) {
	}

	/**
	 * Inicializace komponenty 
	 */
	ngOnInit() {
	}
}