import { Injectable, EventEmitter } from '@angular/core';
import {
	ListFilterOption,
	ListFilter,
	ListSettings,
	PageInfo,
	ColumnDefinition,
	ColumnSettings,
	Sorting,
	ListSettingsItem,
	ListSettings2,
    ListFilterEntry
} from '../models';
import { ApiService } from '../services/api';
import { HelperService } from '../services/helper';
import { DataStorageService } from '../services/data-storage';

/**
 * Služba poskytující funkcionalitu pro nastavení seznamů
 * 
 * @export
 * @class ListsService
 */
@Injectable()
export class ListsService {
	/**
	 * Událost spuštěna při změně nastavení seznamu
	 */
	public onSettingsChanged: EventEmitter<ListSettings> = new EventEmitter();

	/**
	 * Cache pro načtené možnosti filtru
	 */
	private _optionsCache: { [listType: string]: Array<ListFilterOption>; } = {};

	/**
	 * Cache pro načtené definice sloupců
	 */
	private _columnsCache: { [listType: string]: Array<ColumnDefinition>; } = {};

	/**
	 * Cache pro handlery settingů a jejich Promises
	 */
	private _handlersCache: { [settingsKey: string]: IListSettingsHandler; } = {};
	private _handlersPromisesCache: { [settingsKey: string]: Promise<IListSettingsHandler>; } = {};

	constructor(
		private _apiService: ApiService,
		private _dataStorageService: DataStorageService,
		private _helperService: HelperService) {
	}

	/**
	 * Z dodaných definic sloupců a nastavení vytvoří nové nastavení sloupců.
	 * Uložené nastavení nemusí odpovídat definicím - nové/smazané sloupce.
	 * @param columnsDefinitions
	 * @param columnsSettings
	 */
	public buildColumnsSettings(columnsDefinitions: Array<ColumnDefinition>, columnsSettings: Array<ColumnSettings>): Array<ColumnSettings> {
		// Není předchozí nastavení - vytvořím settingy z definice sloupců
		if (columnsSettings.length == 0) {
			return columnsDefinitions.map(x => {
				return <ColumnSettings>{
					field: x.field,
					isHidden: false
				};
			});
		}

		let retval: Array<ColumnSettings> = [];

		// Pokud už bylo něco dříve nastavené
		columnsSettings.forEach(x => {
			// Pokud existuje definice (sloupec nebyl odebrán), vložím kopii nastavení
			let columnDef = columnsDefinitions.find(y => y.field == x.field);
			if (columnDef) {
				retval.push(this._helperService.deepClone(x));
			}
		});

		// Přidám nové sloupce
		columnsDefinitions.forEach(x => {
			if (!retval.find(y => y.field == x.field)) {
				retval.push(<ColumnSettings>{
					field: x.field,
					isHidden: false
				});
			}
		});

		return retval;
	};

	/**
	 * Smaže uložené nastavení seznamu
	 * @param id
	 */
	public deleteSettings(id: number): Promise<any> {
		return this._apiService.delete(`/listsettings/${id}`);
	}

	/**
	 * Vrací pole definicí sloupců pro zadaný typ seznamu
	 * @param listType
	 */
	public getColumnDefinitions(listType: string): Promise<Array<ColumnDefinition>> {
		return new Promise((resolve, reject) => {
			// Zkusíme načíst z cache
			let retval = this._columnsCache[listType];

			if (retval) {
				resolve(retval);
				return;
			}

			// Načítáme z API
			this._apiService.get<Array<ColumnDefinition>>(`/${listType}/column-definitions`)
				.then(x => {
					this._columnsCache[listType] = x;
					resolve(x);
				});
		});
	};

	/**
	 * Vrátí pole možností pro seznam definovaný typem.
	 * @param listType
	 */
	public getFilterOptions(listType: string): Promise<Array<ListFilterOption>> {
		return new Promise((resolve, reject) => {
			// Zkusíme načíst z cache
			let retval = this._optionsCache[listType];

			if (retval) {
				resolve(retval);
				return;
			}

			// Načítáme z API
			this._apiService.get<Array<ListFilterOption>>(`listFilter/options/${listType}`)
				.then(x => {
					this._optionsCache[listType] = x;
					resolve(x);
				});
		});
	}

	/**
	 * Načte aktuální nastavení konkrétního seznamu.
	 * @param listType
	 */
	public getSettings(listType: string): Promise<ListSettings> {
		return new Promise((resolve, reject) => {
			let key = `listSettings_${listType}`;

			this._dataStorageService.getUserData<ListSettings>(key)
				.then((x: ListSettings) => {

					// Zpětná kompatibilita - přidávám property které nejsou uložené
					x = this._helperService.extend(new ListSettings(), x);

					resolve(x || new ListSettings());
				});
		});
	}

	/**
	 * Vrátí handler pro zadaný settings key.
	 * @param settingsKey
	 */
	public getSettingsHandler(settingsKey: string): Promise<IListSettingsHandler> {
		// Zkusíme načíst z cache
		let retval = this._handlersCache[settingsKey];

		if (retval) {
			return Promise.resolve(retval);
		}

		// Zkusíme načíst promise z cache
		if (!this._handlersPromisesCache[settingsKey]) {
			this._handlersPromisesCache[settingsKey] = new Promise((resolve, reject) => {
				// Načtu nastavení a vkládám handler do cache
				this._dataStorageService.getUserData<ListSettings>(`listSettings_${settingsKey}`)
					.then((x: ListSettings) => {

						// Zpětná kompatibilita - přidávám property které nejsou uložené
						x = this._helperService.extend(new ListSettings(), x);

						let retval = new ListSettingsHandler(settingsKey, x, this._dataStorageService);

						this._handlersCache[settingsKey] = retval;
						this._handlersPromisesCache[settingsKey] = null;

						resolve(retval);
					});
			});
		}

		return this._handlersPromisesCache[settingsKey];
	}

	/**
	 * Načte seznam uložených nastavení pro zadaný typ seznamu
	 * @param listType
	 */
	public loadSettingsList(listType: string): Promise<Array<ListSettingsItem>> {
		return this._apiService.get<Array<ListSettingsItem>>(`/listsettings/${listType}`);
	}

	/**
	 * Uloží nastavení pro aktuálního uživatele
	 * @param listType
	 * @param name
	 * @param settings
	 */
	public saveSettings(listType: string, name: string, settings: ListSettings): Promise<any> {
		let payload = <ListSettingsItem>{
			id: 0,
			name: name,
			settings: <ListSettings2>{
				columns: settings.columns,
				filter: settings.filter
			}
		};

		return this._apiService.post<any>(`/listsettings/${listType}`, payload);
	}
}

export interface IListSettingsHandler {
	/**
	 * Událost spuštěna při změně nastavení sloupců
	 */
	onColumnsChanged: EventEmitter<Array<ColumnSettings>>;

	/**
	 * Událost spuštěna při změně filtru
	 */
	onFilterChanged: EventEmitter<ListFilter>;

	/**
	 * Událost spuštěna při změně stránkování 
	 */
	onPageChanged: EventEmitter<PageInfo>;

	/**
	 * Událost spuštěna při změně nastavení řazení
	 */
	onSortingChanged: EventEmitter<Sorting>;

	/**
	 * Vrací nastavení sloupců 
	 */
	columns: Array<ColumnSettings>;

	/**
	 * Vrací filtr pro seznam
	 */
	filter: ListFilter;

	/**
	 * Vrací aktuální stránkování 
	 */
	page: PageInfo;

	/**
	 * Vrací aktuální nastavení třídění 
	 */
	sorting: Sorting;

	/**
	 * Odebere danou položku z filtru.
	 * @param entry
	 */
	removeFilterEntry(entry: ListFilterEntry): void;

	/**
	 * Vloží nastavení sloupců.
	 * @param columns
	 */
	setColumns(columns: Array<ColumnSettings>): void;

	/**
	 * Vloží nastavení sloupců.
	 * @param columns
	 * @param filter
	 */
	setColumnsAndFilter(columns: Array<ColumnSettings>, filter: ListFilter): void;

	/**
	 * Nastaví filtr
	 * @param filter
	 */
	setFilter(filter: ListFilter): void;

	/**
	 * Nastaví stránku
	 * @param page
	 */
	setPage(page: number): void;

	/**
	 * Nastaví počet záznamů na stránku
	 * @param pageSize
	 */
	setPageSize(pageSize: number): void;

	/**
	 * Nastaví řetězec pro vyhledávání.
	 * @param searchFor
	 * @param force
	 */
	setSearchFor(searchFor: string, force?: boolean): void;

	/**
	 * Nastaví řazení
	 * @param sortBy
	 */
	setSortBy(sortBy: string): void;
}

/**
 * Implementace pro IListSettingsHandler
 * Na klientovi by měla pro každý typ seznamu (settingsKey) existovat právě jedna kešovaná instance.
 */
class ListSettingsHandler implements IListSettingsHandler {
	/**
	 * Událost spuštěna při změně nastavení sloupců
	 */
	public onColumnsChanged: EventEmitter<Array<ColumnSettings>> = new EventEmitter();

	/**
	 * Událost spuštěna při změně filtru
	 */
	public onFilterChanged: EventEmitter<ListFilter> = new EventEmitter();

	/**
	 * Událost spuštěna při změně stránkování 
	 */
	public onPageChanged: EventEmitter<PageInfo> = new EventEmitter();

	/**
	 * Událost spuštěna při změně nastavení řazení
	 */
	public onSortingChanged: EventEmitter<Sorting> = new EventEmitter();

	/**
	 * Vrací nastavení sloupců 
	 */
	public get columns(): Array<ColumnSettings> {
		return this._settings.columns;
	}

	/**
	 * Vrací filtr pro seznam
	 */
	public get filter(): ListFilter {
		return this._settings.filter;
	}

	/**
	 * Vrací aktuální stránkování 
	 */
	public get page(): PageInfo {
		return this._settings.page;
	}

	/**
	 * Vrací aktuální nastavení třídění 
	 */
	public get sorting(): Sorting {
		return this._settings.sorting;
	}

	constructor(
		private _settingsKey: string,
		private _settings: ListSettings,
		private _dataStorageService: DataStorageService) {
	}

	/**
	 * Odebere danou položku z filtru.
	 * @param entry
	 */
	public removeFilterEntry(entry: ListFilterEntry): void {
		let idx = this._settings.filter.entries.indexOf(entry);
		this._settings.filter.entries.splice(idx, 1);

		this.onFilterChanged.emit(this._settings.filter);

		this._save();
	}

	/**
	 * Vloží nastavení sloupců.
	 * @param columns
	 */
	public setColumns(columns: Array<ColumnSettings>): void {
		this._settings.columns = columns;

		this.onColumnsChanged.emit(columns);

		this._save();
	}

	/**
	 * Vloží nastavení sloupců.
	 * @param columns
	 * @param filter
	 */
	public setColumnsAndFilter(columns: Array<ColumnSettings>, filter: ListFilter): void {
		this._settings.columns = columns;
		this._settings.filter = filter;

		this.onColumnsChanged.emit(columns);
		this.onFilterChanged.emit(filter);

		this._save();
	}

	/**
	 * Nastaví filtr
	 * @param filter
	 */
	public setFilter(filter: ListFilter): void {
		this._settings.filter = filter;

		this.onFilterChanged.emit(filter);

		this._save();
	}

	/**
	 * Nastaví stránku
	 * @param page
	 */
	public setPage(page: number): void {
		if (this._settings.page.page != page) {
			this._settings.page.page = page;

			this.onPageChanged.emit(this._settings.page);

			this._save();
		}
	}

	/**
	 * Nastaví počet záznamů na stránku
	 * @param pageSize
	 */
	public setPageSize(pageSize: number): void {
		if (this._settings.page.itemsPerPage != pageSize) {
			this._settings.page.itemsPerPage = pageSize;
			this._settings.page.page = 1;

			this.onPageChanged.emit(this._settings.page);

			this._save();
		}
	}

	/**
	 * Nastaví řetězec pro vyhledávání.
	 * @param searchFor
	 * @param force
	 */
	public setSearchFor(searchFor: string, force: boolean = false): void {
		if (this._settings.filter.searchFor != searchFor || force) {
			this._settings.filter.searchFor = searchFor;

			this.onFilterChanged.emit(this._settings.filter);

			this._save();
		}
	}

	/**
	 * Nastaví řazení
	 * @param sortBy
	 */
	public setSortBy(sortBy: string): void {
		if (this._settings.sorting.sortBy == sortBy) {
			this._settings.sorting.sortDesc = !this._settings.sorting.sortDesc;
		}
		else {
			this._settings.sorting.sortBy = sortBy;
			this._settings.sorting.sortDesc = false;
		}

		this.onSortingChanged.emit(this._settings.sorting);

		this._save();
	}

	/**
	 * Uloží nastavení 
	 */
	private _save(): void {
		this._dataStorageService.setUserData(`listSettings_${this._settingsKey}`, this._settings);
	}
}