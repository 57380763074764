import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIService, ModelFormComponentBase, HelperService, AppScopeService, AuthService, ModelServiceFactory } from '../../../core';

@Component({
    templateUrl: "/template/settings/role/pages/role-detail.cshtml"
})
export class RoleDetailPageComponent extends ModelFormComponentBase {
    constructor(
        protected _activatedRoute: ActivatedRoute,
        protected _uiService: UIService,
        protected _helperService: HelperService,
        protected _authService: AuthService,
        protected _appScopeService: AppScopeService,
        protected _modelServiceFactory: ModelServiceFactory,
        protected _router: Router) {

        super(
            _activatedRoute,
            _uiService,
            _helperService,
            _authService,
            _appScopeService,
            _modelServiceFactory.createService('role'),
            _router);
    }

    private readChecked: boolean[] = [true, true, true, true];

	/**
	 * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
	 */
    getTitle() {
        return this.modelId == 0 ? 'Nová role' : 'Editace role';
    }

    public toogleAll(type: string): void {
        this.checkAllSections(this.model.sections, type);

        if (type == 'C') {
            this.readChecked[0] = !this.readChecked[0];
        }

        if (type == 'R') {
            this.readChecked[1] = !this.readChecked[1];
        }

        if (type == 'U') {
            this.readChecked[2] = !this.readChecked[2];
        }

        if (type == 'D') {
            this.readChecked[3] = !this.readChecked[3];
        }

        
    }

    /**
     * Operace nad radkem 
     * @param type
     * @param sectionId
     */
    public toogleRow(type: string, sectionId: number): void {

        var model = this.model.sections;
        var section = this.findById(model, sectionId);
      
        if (type == 'R' && section.permissionRead) {
            section.permissionCreate = false;
            section.permissionUpdate = false;
            section.permissionDelete = false;
        }

    }

    private checkAllSections(sections: any, type: string): void {

        for (var i = 0; i < sections.length; i++) {

            if (!sections[i].childExists) {
               
            
            if (type == 'C') {
                sections[i].permissionCreate = this.readChecked[0];
            }

            if (type == 'R') {
                sections[i].permissionRead = this.readChecked[1];
            }

            if (type == 'U') {
                sections[i].permissionUpdate = this.readChecked[2];
            }

            if (type == 'D') {
                sections[i].permissionDelete = this.readChecked[3];
                }

            }

                        
            if (sections[i].children != null) {
                if (sections[i].children.length > 0) {
                    this.checkAllSections(sections[i].children, type);
                }
           }

        }    

    }


    

    /**
     * Override metody v ModelFormComponentBase - po vložení redirektujeme na editaci
     * @param model
     */
    protected onModelSaved(model: any): void {
        if (this.modelId === 0) {
            this._router.navigate(['app', 'settings', 'roles', model.id], {});
        }
    }

    /**
     * Recursive search in object
     * @param data
     * @param id
     */
   public findById(data, id) {
        function iter(a) {
            if (a.id === id) {
                result = a;
                return true;
            }
            return Array.isArray(a.children) && a.children.some(iter);
        }

        var result;
        data.some(iter);
        return result
    }

}
