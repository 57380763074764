import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { CommissionsService } from '../services/commissions';
import {
	SelectOption
} from '../../core';
import { InputWithListComponent } from '../../core/components/input-with-list';

@Component({
	templateUrl: "/template/commissions/modals/add-part.cshtml"
})
export class AddPartModalComponent implements OnInit {

	public commissionIdPublic: number;

	// Kód dílu který se má vyhledat
	public searchFor: string = '';

	// Název dílu který se má vyhledat
	public searchForByName: string = '';

	// Jestli se právě načítá
	public loading: boolean = false;

	// Jestli bylo vyhledáno
	public wasSearched: boolean = false;

	// Jestli bylo vyhledáno dle nazvu
	public wasSearchedByName: boolean = false;

	// Reference na input pro zadani kodu dilu
	@ViewChild('inputSearchFor', { static: false }) inputSearchFor: InputWithListComponent;

	// Reference na input pro zadani nazvu dilu
	@ViewChild('inputSearchForByName', { static: false }) inputSearchForByName: InputWithListComponent;

	public result: IAddPartModalComponentResult = {
		isNew: true,
		part: null,
		quantity: 1
	};

	constructor(
		public modal: SuiModal<IAddPartModalComponentContext, IAddPartModalComponentResult, void>,
		private _commissionsService: CommissionsService) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this.commissionIdPublic = this.modal.context.commissionId;
	}

	/**
	 * Vloží díl
	 */
	public add(): void {

		this.result.part.description = this.searchForByName;
		this.modal.approve(this.result);
	}

	/**
	 * Zruší modal 
	 */
	public cancel(): void {
		this.modal.deny(null);
	}

	/**
	 * Vymaže vyhledaný/nastavený díl
	 */
	public clear(): void {
		this.result.part = null;
		this.result.isNew = true;
		this.searchFor = '';
		this.searchForByName = '';
		this.wasSearched = false;
		this.wasSearchedByName = false;

		this.inputSearchFor.clearSearchValue();
		this.inputSearchForByName.clearSearchValue();
	}

	/**
	 * vrací info jestli je nastavený nějaký díl 
	 */
	public partIsSet(): boolean {
		return !!this.result.part;
	}

	/**
	 * vrací info jestli byl díl nalezen, nebo je nový 
	 */
	public partWasFound(): boolean {
		return !this.result.isNew;
	}

	/**
	 * Vyhledá díl 
	 */
	public search(): void {
		this.loading = true;

		this._commissionsService.findPart(this.modal.context.commissionId, this.searchFor)
			.then(part => {

				if (part == null) {

					// Pokud nebyl díl nalezen, vytvářím nový 
					this.result.part = {
						contractorContactId: null,
						description: this.searchForByName,
						partCode: this.searchFor
					};

					this.result.isNew = true;
					this.wasSearched = true;
				}
				else {
					this.result.part = part;
					this.result.isNew = false;
					this.searchForByName = part.description;
					this.wasSearched = true;
					this.wasSearchedByName = true;
				}
								
				this.loading = false;
			},
			reason => {
				this.loading = false;
			});
	}

	/**
	 * Info jestli je button pro hledání povolený 
	 */
	public searchIsEnabled(): boolean {
		return (this.searchFor && this.searchFor.length > 1) || (this.searchForByName && this.searchForByName.length > 1);
	}

	/**
	 * Zpracovává událost výběru položky v inputu search poli
	 * @param event
	 */
	public onSearchOptionSelected(event: SelectOption): void {
		this.searchFor = event.value;
		this.search();
	}

	/**
	 * Zpracovává událost vysledku vyhledavani v rezimu potvrzeni klavesou 
	 * @param event
	 */
	public onSearchByCodeResultFound(event: boolean): void {

		if (!event) {

			//Vyhledavani dle nazvu - nenabizime vytvoreni dilu
			if (this.searchFor && this.searchFor.length > 1) {

				this.result.part = {
					contractorContactId: null,
					description: this.searchForByName,
					partCode: this.searchFor
				};

				this.result.isNew = true;
				this.wasSearched = true;
            }
		}

	}

	

	/**
	 * Zpracovává událost simulujici enter v poli, kde je zadan vyraz pro vyhledavani
	 * @param event
	 */
	public searchWhisperSubmit(): void {

			if (this.searchFor && this.searchFor.length > 1) {
				this.inputSearchFor.onInputKeyUp(new KeyboardEvent('keyup', { code: 'Enter', key: 'Enter' }));
			}

			if (this.searchForByName && this.searchForByName.length > 1) {
				this.inputSearchForByName.onInputKeyUp(new KeyboardEvent('keyup', { code: 'Enter', key: 'Enter' }));
			}

	}

	/**
	* Zpracovává keyup událost inputu search pole
	* @param event
	*/
	public onSearchKeyUp(event: KeyboardEvent): void {
		if (this.result.part != null)
		{
			this.result.part.description = this.inputSearchForByName.value;
		}
	}

	/**
	 * U noveho dilu nesmi byt prazdny jeho nazev
	 * */
	public partNameIsEmpty(): boolean {

		if (this.result.part != null) {

			if (this.result.isNew && this.result.part.description.length == 0) {
				return false;
            }
		}
		else
		{
			return false;
        }

		return true;

    }

}

export class AddPartModal extends ComponentModalConfig<IAddPartModalComponentContext, IAddPartModalComponentResult, void> {
    constructor(commissionId: number) {
        super(AddPartModalComponent, {commissionId: commissionId});

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
		this.mustScroll = true;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IAddPartModalComponentContext {
    commissionId: number
}

export interface IAddPartModalComponentResult {
	part: IPartData;
	quantity: number;
	isNew: boolean;
}
