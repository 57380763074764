import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule, ModalService, CanDeactivateGuard } from '../core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { TransportCarriersService } from '../lists/transport-carriers';
import { DeviceModelModule } from '../lists/device-model';
import { ListsPriceListModule } from '../lists/price-list';
import { SumUpModule } from '../modules/sumup';

// Pages
import { CommissionListPageComponent } from './pages/commission-list';
import { CommissionDetailPageComponent } from './pages/commission-detail';

// Components
import { DeviceSectionComponent } from './components/device-section';
import { AddressesSectionComponent } from './components/addresses-section';
import { CommissionSectionComponent } from './components/commission-section';
import { GeneralSectionComponent } from './components/general-section';
import { TransportSectionComponent } from './components/transport-section';
import { TextsSectionComponent } from './components/texts-section';
import { PriceProposalSectionComponent } from './components/price-proposal-section';
import { InhomeSectionComponent } from './components/inhome-section';
import { ExecutionSectionComponent } from './components/execution-section';
import { StatementTabComponent } from './components/statement-tab';
import { LogTabComponent } from './components/log-tab';
import { AttachmentsTabComponent } from './components/attachments-tab';
import { CommissionAttachmentsComponent } from './components/commission-attachments';
import { AuthorizationSectionComponent } from './components/authorization-section';

//Modals
import { ModelListModalComponent } from './modals/model-list';
import { SerialNumberHistoryModalComponent } from './modals/serial-number-history';
import { NewCommissionCancelationModalComponent } from './modals/new-commission-cancelation';
import { SaveCommissionModalComponent } from './modals/save-commission';
import { RequestForDepositModalComponent } from './modals/request-for-deposit';
import { AddSettlementModalComponent } from './modals/add-settlement';
import { ModelDetailModalComponent } from './modals/model-detail';
import { PriceProposalModalComponent } from './modals/price-proposal';
import { AddPartModalComponent } from './modals/add-part';
import { AuthorizationModalComponent } from './modals/authorization';
import { CommissionIsEditedModalComponent } from './modals/commission-is-edited';

// Services
import { CommissionsService } from './services/commissions';
import { ModelWrapperBuilder } from './services/model-wrapper-builder';
import { CommissionValidator } from './services/commission-validator';

// Routes
const routes: Routes = [
	{
		path: '',
		component: CommissionListPageComponent
	},
	{
		path: ':id',
        component: CommissionDetailPageComponent,
        canDeactivate: [CanDeactivateGuard],
		data: {
			bodyClass: 'commission-detail'
		}
	}
];

@NgModule({
	imports: [
		CommonModule,
		CoreModule,
		FormsModule,
		ReactiveFormsModule,
		SuiModule,
		DeviceModelModule,
        ListsPriceListModule,
		RouterModule.forChild(routes),
		SumUpModule
	],
	declarations: [
		CommissionListPageComponent,
		CommissionDetailPageComponent,
		DeviceSectionComponent,
		AddressesSectionComponent,
		CommissionSectionComponent,
		GeneralSectionComponent,
		TransportSectionComponent,
		TextsSectionComponent,
		PriceProposalSectionComponent,
		InhomeSectionComponent,
		ExecutionSectionComponent,
		ModelListModalComponent,
		SerialNumberHistoryModalComponent,
		NewCommissionCancelationModalComponent,
		StatementTabComponent,
		LogTabComponent,
		SaveCommissionModalComponent,
		RequestForDepositModalComponent,
		AddSettlementModalComponent,
		ModelDetailModalComponent,
		PriceProposalModalComponent,
		AddPartModalComponent,
		AttachmentsTabComponent,
		CommissionAttachmentsComponent,
		AuthorizationSectionComponent,
        AuthorizationModalComponent,
        CommissionIsEditedModalComponent
	],
	exports: [
        RouterModule
	],
	providers: [
		CommissionsService,
		TransportCarriersService,
		ModelWrapperBuilder,
		CommissionValidator,
		// Musí být v každém modulu, který ji využívá
		// LazyLoaded entry components
		ModalService
	],
	entryComponents: [
		ModelListModalComponent,
		SerialNumberHistoryModalComponent,
		NewCommissionCancelationModalComponent,
		SaveCommissionModalComponent,
		RequestForDepositModalComponent,
		AddSettlementModalComponent,
		ModelDetailModalComponent,
		PriceProposalModalComponent,
		AddPartModalComponent,
        AuthorizationModalComponent,
        CommissionIsEditedModalComponent
	]
})
export class CommissionsModule { }