import { Injectable } from '@angular/core';
import {
	UIService,
	CommissionPhase,
	ValuesService,
	CommissionPartState,
	AuthService,
    CommissionPaymentType,
    ModelServiceFactory,
    CommissionParticipation
} from '../../core';

/**
 * Kontroly workflow při uložení zakázky
 */
@Injectable()
export class CommissionValidator {

	// Definice validací při změně fáze
	// POZOR: Podobné validace probíhají i na serveru (PhaseChangeValidator) - je potřeba implementovat i tam
	private _workflowValidations: Array<WorkflowValidation> = [
        // Active || Ended > Closed - Uživatel musí mít práva
        {
            fromPhases: [CommissionPhase.Active, CommissionPhase.Ended],
            toPhases: [CommissionPhase.Closed],
            getError: model => {
                if (!this._authService.currentUser.commissionSpecialPermission.isRoleClosing) {
                    return 'Pro převedení zakázky do fáze Uzavřeno nemáte potřebné oprávnění.';
                }

                return null;
            }
        },
        // Active > Closed - Nelze
        {
            fromPhases: [CommissionPhase.Active],
            toPhases: [CommissionPhase.Closed],
            getError: model => {
                return 'Zakázku je nejprve nutno převést do stavu Dokončeno.';
            }
        },
        // Active > Ended - Zalohy musi uhrazeny
        // Active > Ended - Díly musi být blokovány
        {
			fromPhases: [CommissionPhase.Active],
			toPhases: [CommissionPhase.Ended],
			getError: model => {
				if (model.payments.some(x => x.paymentType == CommissionPaymentType.Deposit && !x.isPaid)) {
					return 'Všechny zálohy musí být uhrazeny.';
				}

				if (model.parts.some(x => (x.partState != CommissionPartState.Blocked && x.partState != CommissionPartState.Unavailable && x.partState != CommissionPartState.Used))) {
                    return 'Všechny díly musí být blokovány.';
                }

				return null;
			}
        },
        // Closed > Active - Nelze
        {
            fromPhases: [CommissionPhase.Closed],
            toPhases: [CommissionPhase.Active],
            getError: model => {
                return 'Zakázka je ve fázi Uzavřeno, nelze změnit fázi na Aktivní.';
            }
        },
        // Closed > Ended - Musí mít práva
        {
            fromPhases: [CommissionPhase.Closed],
            toPhases: [CommissionPhase.Ended],
            getError: model => {
                if (!this._authService.currentUser.commissionSpecialPermission.isRoleClosedToEneded) {
                    return 'Pro převedení zakázky z fáze Uzavřeno do Dokončeno nemáte potřebné oprávnění.';
                }

                return null;
            }
        },
		// Ended > Active - Nelze pokud je uhrazena zakázka nebo spoluúčast
		{
            fromPhases: [CommissionPhase.Ended],
            toPhases: [CommissionPhase.Active],
			getError: model => {
				if (model.payments.some(x => x.isAutoGenerated && x.isPaid)) {
					return 'Zakázku nelze převést do Aktivní fáze, nejprve stornujte provedené úhrady zakázky nebo spoluúčasti.<br /><br />Poznámka: Může provést pouze uživatel s daným oprávněním.';
				}

				return null;
			}
		}	
	];

	constructor(
		private _valuesService: ValuesService,
		private _uiService: UIService,
        private _authService: AuthService,
        private _modelServiceFactory: ModelServiceFactory) {
	}

	/**
	 * Vrací všechny případné chyby pro přechod fáze dané zakázky
	 * @param model
	 * @param toPhase
	 */
	public getWorkflowErrors(model: ICommission, toPhase: CommissionPhase): Array<string> {
        return this._workflowValidations
            .filter(x => x.fromPhases.indexOf(model.phase) > -1 && x.toPhases.indexOf(toPhase) > -1)
			.map(x => x.getError(model))
			.filter(x => x != null);
	}

    public ensureSelectedEvent(
        eventId: number,
        model: ICommission,
        pricesSummary: ICommissionPriceSummary): Promise<boolean> {

		return new Promise<boolean>((resolve, reject) => {
			// Načtení vybrané události
			return this._valuesService.getExtendedValue<ICommissionEventValueModel>('CommissionEvent', eventId)
				.then(event => {
					// Kontrola načtené události

					if (!event) {
						// Událost nebyla podle id nalezena
						this._uiService.showError('Nepodařilo se načíst zvolenou událost.');
						throw new BreakError(false);
					}

					if (event.phaseSet == null) {
						// Nenastavuje fázi, není co řešit
						throw new BreakError(true);
					}

					if (model.phase == event.phaseSet) {
						// Fáze je stejná, není co řešit
						throw new BreakError(true);
					}

					return event;
				})
				.then(event => {
					// Pokud je aktuální a nová fáze stejná, žádné kontroly,
					// může se pokračovat
					if (model.phase == event.phaseSet) {
						throw new BreakError(true);
					}

					return event;
				})
				.then(event => {
					// Validace přechodu
					let errors = this.getWorkflowErrors(model, event.phaseSet);

					if (errors.length > 0) {
						let message = this._uiService.formatList(errors);

						return this._uiService.showMessage('Změnu fáze nelze provést', message)
							.then(x => {
								throw new BreakError(false);
								return event;
							});
					}

					return event;
				})
				.then(event => {
                    // #223 - Upozornit pokud při dokonceni chybi Vykon
                    if (model.phase == CommissionPhase.Active && event.phaseSet == CommissionPhase.Ended && model.operations.length == 0) {
						return this._uiService.showConfirmation('Nejsou žádné výkony', 'Není přidán výkon, opravdu si přejete dokončit zakázku?')
							.then(result => {
								if (result) {
									return event;
								}

								throw new BreakError(false);
							});
					}

					return Promise.resolve(event);
                })
                .then(event => {
                    // #294 - Pokud je pri dokonceni celkova castka zaporna, pak se objevi hlaska
                    if (model.phase == CommissionPhase.Active && event.phaseSet == CommissionPhase.Ended) {

                        // Načtu typ a čekuju spoluúčast
                        let commissionTypeModelService = this._modelServiceFactory.createService('listCommissionType');
                        return commissionTypeModelService.get<ICommissionTypeModel>(model.commissionTypeId)
                            .then(x => {
                                // Můžeme použít 0 pro spoluúčast dle procenta.
                                // Výsledek u procentní sazby spoluúčasti nikdy nepřekročí celkovou cenu
                                // což je tady to oč kráčí
                                let participationWithVat = x.commissionParticipation == CommissionParticipation.PriceWithVat ?
                                    x.commissionParticipationValue :
                                    0;

                                if (pricesSummary.withVat - pricesSummary.paid - participationWithVat > 0) {
                                    return event;
                                }

                                return this._uiService.showConfirmation('Opravdu dokončit?', 'Celková částka je nulová nebo záporná, požadavek o úhradu zakázky nebude vygenerován.<br /><br />Přejete si dokončit zakázku?')
                                    .then(result => {
                                        if (result) {
                                            return event;
                                        }

                                        throw new BreakError(false);
                                    });
                            });
                    }

                    return Promise.resolve(event);
                })
				.then(event => {
					if (model.phase == CommissionPhase.Ended && event.phaseSet == CommissionPhase.Active) {
						if (model.payments.some(x => x.isAutoGenerated)) {
							return this._uiService.showConfirmation('Opravdu převést?', 'Opravdu si přejete zakázku převést do Aktivní fáze?<br /><br />Veškeré požadavky k úhradě budou vymazány a znovu vygenerovány při dokončení zakázky.')
								.then(result => {
									if (result) {
										return event;
									}

									throw new BreakError(false);
								});
						}
					}

					return Promise.resolve(event);
				})
				.then(event => {
					resolve(true);
				})
				.catch(reason => {
					resolve((<BreakError>reason).result);
				});
		});
	}
}

class BreakError extends Error {
	public result: boolean | null = null;
	constructor(
		result: boolean) {

		super('');

		this.result = result;
	}
}

class WorkflowValidation {
	fromPhases: CommissionPhase[];
	toPhases: CommissionPhase[];
	getError: (model: ICommission) => string;
}