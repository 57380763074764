import { Injectable, Inject } from '@angular/core';
import { Router, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth';
import { AppScopeService } from './app-scope';
import { ScopePermissionType } from '../models';
import { Observable } from 'rxjs';

/**
 * Kontroluje, jestli je možný přístup k routě, u které je definován.
 * @export
 * @class AuthenticatedGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthenticatedGuard implements CanActivateChild {

    constructor(
        private _router: Router, 
		private _authService: AuthService,
		private _appScopeService: AppScopeService) { }

	public canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

		// Zkontrolujeme, jestli je přihlášen
        if (!this._authService.currentUser) {            
            this._router.navigate(['signin'], {
                queryParams: {
                    returnUrl: state.url != '/app/dashboard' ? state.url : ''
                }
            });

			return false;
		}

		// Prihlaseny uzivatel doposud nezmenil heslo, vynutime redirect na stranku se zmenou
		if (this._authService.currentUser.needChangePassword)
		{
			this._router.navigate(['first-logged']);
			return false;
		}


		// Zkontrolujeme scope permissions na Read
		let appScope = this._appScopeService.getAppScopeFromRoute(childRoute);

		if (appScope && !this._authService.hasAnyAppScopePermission(appScope, ScopePermissionType.Read)) {
			this._router.navigate(['app/not-authorized']);

			return false;
		}

		// Zkontrolujeme common permissions
		var commonPermissions = childRoute.data.commonPermissions;

		if (Array.isArray(commonPermissions) && commonPermissions.length > 0) {
			let anyPermissionIsMissing = commonPermissions.findIndex(x => !this._authService.hasCommonPermission(x)) > -1;

			if (anyPermissionIsMissing) {
				this._router.navigate(['app/not-authorized']);

				return false;
			}
		}

		return true;
	}
}