import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';

// Services
import { NotificationsService } from './services/notifications';

//// Pages
import { NotificationListPageComponent } from './pages/notification-list';
import { NotificationDetailPageComponent } from './pages/notification-detail'

// Components
import { NotificationConditionsComponent } from './components/notification-conditions';

@NgModule({
	imports: [
        CommonModule,
        CoreModule,
        RouterModule,
        FormsModule,
        SuiModule
	],
    declarations: [
        NotificationListPageComponent,
        NotificationDetailPageComponent,
        NotificationConditionsComponent
	],
    exports: [
        RouterModule
    ],
    providers: [
        NotificationsService
    ]
})
export class NotificationsModule { }