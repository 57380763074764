import { Directive, Input, ElementRef, AfterViewInit, Renderer2, OnDestroy } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';

// Directiva která poskytuje funkcionlitu pro jedeno konkrétní formulářové pole.

@Directive({
	selector: '[kt-form-field]'
})
export class FormFieldDirective implements AfterViewInit, OnDestroy {
	@Input('kt-form-field') ngModel: NgModel;
	@Input('char-counter-min') characterCounterMinimum: number = null;
	@Input('char-counter-max') characterCounterMaximum: number = null;

	private _subs: Array<Subscription> = [];

	constructor(
		private _el: ElementRef,
		private _renderer: Renderer2) {
	}

	/**
	 * Událost po inicializaci view
	 */
	ngAfterViewInit() {
		this._subs.push(
			// Přihlásíme se k odběru události změny stavu
			// Nastavujeme error classu v závislosti na stavu modelu
			this.ngModel.statusChanges.subscribe(x => {
				if (x == 'INVALID') {
					this._renderer.addClass(this._el.nativeElement, 'error');
				}
				else {
					this._renderer.removeClass(this._el.nativeElement, 'error');
				}
			})
		);

		if (this.characterCounterMinimum != null) {
			// Přihlásíme se k odběru událostí změny hodnoty
			// nastavujeme classu pro character counter
			this._subs.push(
				this.ngModel.valueChanges.subscribe(x => {
					if (x) {
						this._renderer.removeClass(this._el.nativeElement, 'character-counter-valid');
						this._renderer.removeClass(this._el.nativeElement, 'character-counter-invalid');

						if (x.length >= this.characterCounterMinimum && x.length <= this.characterCounterMaximum) {
							this._renderer.addClass(this._el.nativeElement, 'character-counter-valid');
						}
						else {
							this._renderer.addClass(this._el.nativeElement, 'character-counter-invalid');
						}
					}
				})
			);
		}
	}

	/**
	 * Událost destrukce direktivy.
	 */
	ngOnDestroy() {
		// Odhlásíme ze všech observables
		this._subs.forEach(x => x.unsubscribe());
	}
}