import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonPermission, CanDeactivateGuard } from '../../core';
import { SettingRoleModule } from './';

// Pages
import { RoleListPageComponent } from './pages/role-list';
import { RoleDetailPageComponent } from './pages/role-detail';

const routes: Routes = [
    {
        path: '',
        data: {
            
        },
        component: RoleListPageComponent
    },
    {
        path: ':id',
        component: RoleDetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }


];

@NgModule({
    imports: [
        SettingRoleModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SettingRoleRoutingModule { }