import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

/**
 * Služba formátování dat
 * 
 * @export
 * @class AuthService
 */
@Injectable()
export class FormatService {
	/**
	 * Zformátuje číslo jako měnu
	 * 
	 * @param value
	 * @param options
	 */
	public currency(value: number, options?: IFormatCurrencyOptions): string {
		let o = options || {};
		let acs = o.addCurrencySymbol || false;

		let retval = this.number(value, { decimalNumbers: 2 });

		if (acs) {
			retval += ' Kč';
		}

		return retval;
	}

	/**
	 * Zformátuje datum
	 * 
	 * @param value
	 */
	public date(value: Date) {
		return formatDate(value, 'd.M.yyyy', 'en-US');
	}

	/**
	 * Zformátuje číslo
	 * 
	 * @param value
	 * @param options
	 */
	public number(value: number, options?: IFormatNumberOptions): string {
		let o = options || {};
		let dd = o.decimalDelimiter || ',';
		let dn = o.decimalNumbers || 0;
		let sd = o.sectionsDelimiter || '';
		let sl = o.sectionLength || 3;

		var re = '\\d(?=(\\d{' + sl + '})+' + (dn > 0 ? '\\D' : '$') + ')',
			num = value.toFixed(Math.max(0, ~~dn));

		return num.replace('.', dd).replace(new RegExp(re, 'g'), '$&' + sd);
	};
}

export interface IFormatCurrencyOptions {
	/**
	 * Info jestli má být přidán znak měny 
	 */
	addCurrencySymbol?: boolean;
}

export interface IFormatNumberOptions {
	/**
	 * Oddělovač desetinné části 
	 */
	decimalDelimiter?: string;

	/**
	 * Počet desetinných míst
	 */
	decimalNumbers?: number;

	/**
	 * Oddělovač sekcí 
	 */
	sectionsDelimiter?: string;

	/**
	 * Počet míst v sekci celé části
	 */
	sectionLength?: number;
}