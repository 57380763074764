import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { CommissionPhase, ValuesService, UIService, ModalService } from '../../core';
import { CommissionValidator } from '../services/commission-validator';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';

@Component({
	templateUrl: "/template/commissions/modals/save-commission.cshtml"
})
export class SaveCommissionModalComponent implements OnInit, AfterViewInit {

	public model: SaveCommissionModalResult = {
		continue: false,
		eventId: null,
		eventComment: null
	};

    public phase: CommissionPhase;
    public isNew: boolean;

	// Formátované chyby při přechodu fáze
	public errors: string = null;

	// Refeence na SelectControl s výběrem událostí
    @ViewChild('eventId', { static: true }) eventSelect: any = null;

	constructor(
		public modal: SuiModal<ISaveCommissionModalComponentContext, SaveCommissionModalResult, void>,
		private _commissionValidator: CommissionValidator,
		private _valuesService: ValuesService,
        private _uiService: UIService,
        private _modalService: ModalService,
        private _hotkeysService: HotkeysService) {

        this.isNew = modal.context.commission.id == 0;
		this.phase = modal.context.commission.phase;
	}

	/**
	 * Inicializace komponenty
	 */
    ngOnInit() {
        
        if (this.modal.context.eventId > 0) {

            // Kontrola zda je mozne pouzit predvybranou udalost
            this.eventSelectCheck();           
            
        } else {

            // Seznam událostí rozbalený pouze pokud nemáme předvybranou událost
            this.eventSelect.active = true;
        }
        
    }

	/**
	 * Po inicializaci view 
	 */
    ngAfterViewInit() {

        // nemame predvybranou udalost, zobrazime nabidku
        if (this.modal.context.eventId == 0) {
            setTimeout(() => this.eventSelect.focus());
        }
        else {
            // udalost je predvybrana, pridavame moznost zavrit modal stiskem enteru. Automaticky volba pokracovat v editaci.
            this.validateChangeEvent(this.modal.context.eventId);

            if (this.errors == null) {

                this._hotkeysService.add(new Hotkey('enter', (event: KeyboardEvent): boolean => {

                    //Zabranime vicenasobnemu otevreni
                    if (this._modalService.openModalsCount() > 1) {
                        return false;
                    }

                    this.save(true);
                    return false;
                }));

            }
        }
	}

	/**
	 * Zruší modal
	 */
	public cancel(): void {
		this.modal.deny(null);
	}

	/**
	 * Handler změny události
	 * @param eventId
	 */
    public onEventChange(eventId: number): void {
        this.validateChangeEvent(eventId);
	}

	/**
	 * Pošle výsledek zpět do volajícího formuláře
	 * @param andContinue
	 */
	public save(andContinue: boolean): void {

        // Kontroly workflow
        this._commissionValidator.ensureSelectedEvent(
            this.model.eventId,
            this.modal.context.commission,
            this.modal.context.priceSummary
        )
			.then(success => {
				if (success) {
					this.model.continue = andContinue;
					this.modal.approve(this.model);
				}
				else {
					//this.modal.deny(null);
				}
			});
    }

    /**
     * Validace zvolené události
     * @param eventId
     */
    private validateChangeEvent(eventId: number): void {
        this.errors = null;

        if (!eventId) {
            return;
        }

        this._valuesService.getExtendedValue<ICommissionEventValueModel>('CommissionEvent', eventId)
            .then(event => {

                if (!event || !event.phaseSet) {
                    return;
                }

                let errors = this._commissionValidator.getWorkflowErrors(this.modal.context.commission, event.phaseSet);
                if (errors.length > 0) {
                    this.errors = this._uiService.formatList(errors);
                }
            });
    }

    /***
     * Kontrola, zda je možné použit předdefinovanou událost
     * */

    private eventSelectCheck(): void {

        let source = "CommissionEvent?phase=" + this.phase;
        this._valuesService.getExtendedValue<ICommissionEventValueModel>(source, this.modal.context.eventId)
            .then(event => {
                if (!event || !event.phaseSet) {
                    this.errors = "Předdefinovaná údalost není dostupná.";
                    return;
                }

                this.model.eventId = event.value;
            });
    }
}

export class SaveCommissionModal extends ComponentModalConfig<ISaveCommissionModalComponentContext, SaveCommissionModalResult, void> {
    constructor(commission: ICommission, eventId: number, priceSummary: ICommissionPriceSummary) {
        super(SaveCommissionModalComponent, { commission, eventId, priceSummary });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Normal;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface ISaveCommissionModalComponentContext {
    commission: ICommission;
    eventId: number;
    priceSummary: ICommissionPriceSummary;
}

export class SaveCommissionModalResult {
	public continue: boolean;
	public eventId: number | null;
    public eventComment: string | null;
}