import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	ModelFormComponentBase,
	UIService,
	HelperService,
	AuthService,
	AppScopeService,
	IModelService,
	ScopePermissionType
} from '../../../core';
import { FormControl } from '@angular/forms';

/**
 * Bázová třída pro detaily v portálu.
 */
export abstract class PortalFormComponentBase extends ModelFormComponentBase implements OnInit {
	// ID portal garanta
	public garantId: number = 0;

	constructor(
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		protected _appScopeService: AppScopeService,
		protected _modelService: IModelService,
		protected _router: Router) {

		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_modelService,
			_router)
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		// Načítám z routy id garanta
		this.garantId = parseInt(this._activatedRoute.snapshot.params['garantId']);

		// Kontrola id garanta
		if (isNaN(this.garantId)) {
			this._uiService.hideLoader();

			throw 'Activated route doesn\'t contains required parameter "garantId"';
		}

		// Volám inicializaci bázové třídy
		super.ngOnInit();
	}

	/**
	 * Override metody v ModelFormComponentBase - potřebujeme do API posílat query string s garantId
	 */
	protected getApiCustomQuery(): any {
		return { garantId: this.garantId };
	}

	/**
	 * Override metody ve FormComponentBase - máme custom logiku
	 * @param controlScope
	 * @param permissionType
	 */
    protected scopeIsEnabled(controlScope: string, permissionType: ScopePermissionType = null): boolean {

		// Pokud je stav nastaven jako WorkerDisabled, koukám, jestli ma aktuální pravo Agent.
        if (this.model && this.model.assignment && this.model.assignment.stateData.WorkerDisabled) {

            return this._authService.currentUser.portalSpecialPermission.isAgent;
		}

		return super.scopeIsEnabled(controlScope, permissionType);
	}
}