import {
	Component,
	Input,
	OnInit,
	forwardRef,
	Output,
	EventEmitter,
	Renderer2,
	OnDestroy,
	ElementRef,
	ChangeDetectionStrategy,
	ChangeDetectorRef
} from '@angular/core';
import { SelectComponentBase } from '../classes/select-component-base';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiService } from '../services/api';
import { Value, SelectOption } from '../models';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const MIN_LENGTH_TO_SEARCH: number = 2;

@Component({
	selector: 'div.kt-input-with-list',
	styles: [`.active, .visible { display:block !important; }`],
	templateUrl: "/template/core/components/input-with-list.cshtml",
	host: {
		'class': 'ui fluid selection dropdown',
		'[class.active]': 'active',
		'[class.visible]': 'active',
		'[class.disabled]': 'disabled',
		'[class.loading]': 'working',
		'[class.error]': 'resultExist',
		'[id]': 'id',
		'(keydown)': 'onInputKeyDown($event)'
	},
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputWithListComponent),
			multi: true
		}
	]
})
export class InputWithListComponent	extends SelectComponentBase	implements OnInit, OnDestroy, ControlValueAccessor {

	// Index aktivního option
	public activeOptionIndex: number = null;

	//Existuej vysledek vyhledavani
	public resultExist: boolean = false;

	// Probiha vyhledavani
	public working: boolean = false;

	// Stream do kterého se posílají hledané hodnoty.
	private _searchedValue: Subject<string> = new Subject();

	// Drží aktuální hodnotu inputu
	private _value: string;

	// Getter a setter pro _value
	get value(): string {
		return this._value;
	}
	set value(value: string) {
		this._value = value;

		this._propagateChange(value);
	}

	// Input/Output

	@Input() public dataSource: string | Array<SelectOption>;

	//Moznost zadat kod klavesy, na kterou ma reagovat vyhledavani 
	@Input() public searchKeyCode: string = null;
	

	@Output('onOptionSelected') public optionSelected: EventEmitter<SelectOption> = new EventEmitter<SelectOption>();
	@Output('onSearchByCodeResultFound') public searchByCodeResultFound: EventEmitter<boolean> = new EventEmitter<boolean>();
	
	constructor(
		protected _elementRef: ElementRef,
		protected _renderer: Renderer2,
		private _apiService: ApiService,
		protected _changeDetectorRef: ChangeDetectorRef) {
		super(_elementRef, _renderer, _changeDetectorRef);
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit(): void {
		// Nastavím ID pokud není nastaveno na elementu
		this.id = this.id || new Date().getTime().toString();

		// Setup hledání

		// Delegát která bude vykonávat hledání - default je none
		var search = value => { };

		if (this.dataSource instanceof Array) {
			search = value => {
				// Vyhledávám v zadaném seznamu options
				let options = <Array<SelectOption>>this.dataSource;
				this.options = options.filter(x => x.text.toLowerCase().indexOf(value.toLowerCase()) > -1);

				// Clearujeme
				this.selectedOptionIndex = null;
				this.activeOptionIndex = null;

				this._open();
			};
		}
		else if (typeof this.dataSource === 'string') {
			search = value => {

				this.resultExist = false;
				this.working = true;

				// Ohlášení z aktuálního načítání - zrušení
				if (subscription) {
					subscription.unsubscribe();
				}

				// Načteme options z API
				var qsPart = '?searchFor=';
				if ((<string>this.dataSource).indexOf('?') > -1) {
					qsPart = '&searchFor=';
				}
				var subscription = this._apiService.getObservable<Array<Value>>('valuepairs/' + this.dataSource + qsPart + encodeURIComponent(value))
					.subscribe(
						values => {

							this.working = false;

							// Transformujeme na Option
							this.options = values.map(x => new SelectOption(x.value, x.text));

							// Clearujeme
							this.selectedOptionIndex = null;
							this.activeOptionIndex = null;


							// Doplnime tridu v pripade neuspesneho vyhledavani
							if (this.searchKeyCode && values.length == 0) {
								this.resultExist = true;
								this.searchByCodeResultFound.emit(false);
							} else {

								//Pouze jedna moznost, rovnou odesilame jako result
								if (this.searchKeyCode && this.options.length == 1) {
									this._optionSelected(0);
								} else {
									this._open();
                                }
						
                            }


							subscription = null;

					},
						reason => {
						this._close();
						this.working = false;

						subscription = null;
					});
			};
		}

		// Přihlašuju se k odběru hledaných hodnot
		// musí být 300ms pauza
		// hodnota se musí změnit
		this._subs.push(
			this._searchedValue
				.pipe(debounceTime(300))
				.pipe(distinctUntilChanged())
				.subscribe(value => {
					if (value.length < MIN_LENGTH_TO_SEARCH) {
						this.options.length = 0;
						this._close();

						return;
					}

					search(value);
				})
		);
	}


	/**
	 * Clear naposledy hledaneho vyrazu
	 * */
	public clearSearchValue(): void {
		this._searchedValue.next("");
		this.selectedOptionIndex = null;
		this.activeOptionIndex = null;
		this.resultExist = false;
	}



	/**
	 * Zpracovává blur event
	 * @param event
	 */
	public onBlur(event: any): void {
		if (!this.active) {
			this.blur.next(event);
		}
	}


	/**
	 * Zpracovává keyup událost inputu
	 * @param event
	 */
	public onInputKeyUp(event: KeyboardEvent) {

		if (this.searchKeyCode) {

			this.resultExist = false;

			if (event.key == this.searchKeyCode) {

				if (this.activeOptionIndex === null) {

					this.value = this.value.trim();
					this._searchedValue.next(this.value);
				}
				else {
					this.activeOptionIndex = null;
				}
			}
		}
		else {
			if (event.keyCode == 38 || event.keyCode == 40 || event.keyCode == 13 || event.keyCode == 9) {
				return;
			}

			this._searchedValue.next(this.value);
		}


	}

	/**
	 * Zpracuje kliknutí na ikonu šipky
	 * @param event
	 */
	public dropdownIconClick(event: any): void {
		this.toggle();

		event.stopPropagation();
	}

	/**
	 * Zpracuje kliknutí na volbu.
	 *
	 * @param index
	 * @param event
	 */
	public optionClick(index: number, event: any): void {
		this._optionSelected(index);
		
		event.stopPropagation();
	}

	/**
	 * Abstraktní metoda pro potomky
	 * @param index
	 */
	protected _optionSelected(index: number): void {
		let option = this.options[index];

		this.activeOptionIndex = index;
		this.value = option.text;
		this._close();

		this.optionSelected.emit(option);

		this.options.length = 0;

	}

	//
	// Implementace ControlValueAccessor
	//

	private _propagateChange = (_: any) => { };
	private _propagateTouched = () => { };

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
	writeValue(value: any): void {
		this._value = value;
	}

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}