import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';

// Pages
import { ListsDevicePolicyPageComponent } from './pages/device-policy-list';
import { ListsDevicePolicyDetailPageComponent } from './pages/device-policy-detail';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		CoreModule,
		FormsModule,
		SuiModule
	],
	declarations: [
		ListsDevicePolicyPageComponent,
		ListsDevicePolicyDetailPageComponent
	]
})
export class DevicePolicyModule { }