import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    UIService,
    HelperService,
    AppScopeService,
    AuthService,
    ProjectEnumService,
    ProjectEnumType
} from '../../../core';
import { PortalFormComponentBase } from '../../core';
import { CommissionsService } from '../services/commissions';

@Component({
    templateUrl: "/template/portal/commissions/pages/commission-detail.cshtml"
})
export class CommissionDetailPageComponent extends PortalFormComponentBase implements OnInit {
    /** Počet autorizací - nastavuje se v reakci na událost komponenty */
    public numberOfAuthorizations: number = 0;

    /** Počet objednávek ND */
    public numberOfOrders: number = 0;

    /** Selected branch id */
    public branchId: number = 0;

    /** Commission number */
    public commissionNumber: string = '';

    /** Portal commission number */
    public number: string = '';

    constructor(
        protected _router: Router,
        protected _activatedRoute: ActivatedRoute,
        protected _uiService: UIService,
        protected _helperService: HelperService,
        protected _authService: AuthService,
        protected _appScopeService: AppScopeService,
        private _commissionsService: CommissionsService,
        private _projectEnumService: ProjectEnumService) {

        super(
            _activatedRoute,
            _uiService,
            _helperService,
            _authService,
            _appScopeService,
            _commissionsService,
            _router);
    }

    /**
     * Override metody v ModelFormComponentBase - po vložení redirektujeme na editaci
     */
    protected onModelSaved(model: any) {
        if (this.modelId == 0) {
            this._router.navigate(['app', 'portal', 'commissions', this.garantId, model.id], {});
        }
    }

    protected onModelLoaded(model: any) {
        this.commissionNumber = model.requirement.commissionNumber;
        this.number = model.number;
        this.branchId = model.assignment.branchId;
    }

    /**
     * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
     */
    public getTitle() {
        return this.modelId == 0 ? 'Nová zakázka' : this.model.number;
    }

    /**
     * Nastaví stav zakázky na K vyúčtování
     */
    public charge() {
        this._uiService.showConfirmation('Odeslat k vyúčtování?', 'Opravdu si přejete zakázku odeslat k vyúčtování?<br /><br />Pokud máte zakázku kompletně doplněnou včetně příloh, stiskněte Ano')
            .then(x => {
                if (x) {
                    // Načítám hodnotu pro vyúčtování
                    this._projectEnumService.getValue(ProjectEnumType.PortalCommissionState, 'clearance')
                        .then(x => {
                            this.model.assignment.stateId = x.id;
                            this.save();
                        })
                }
            });
    }

    public onSerialNumberBlur() {
        if (!this.model.requirement.serialNumber.length) {
            return;
        }

        this._commissionsService.getCommissionsBySerialNumber(this.model.requirement.serialNumber).then((commissions) => {
            if (!commissions.length) {
                return;
            }

            let content = '<table class="ui table display celled striped"><thead><tr><th>Číslo zakázky</th><th>ID Garanta</th><th>Datum přijetí</th></tr></thead><tbody>' + commissions.map(x => {
                return `<tr>
<td><a href="/#/app/portal/commissions/${this.garantId}/${x.id}" target="_blank">${x.number}</a></td>
<td>${x.garantId}</td>
<td>${x.dateOfReceiving}</td>
</tr>`;
            }).join('') + '</tbody></table>';

            this._uiService.showMessage('Zakázky s výrobním číslem: ' + this.model.requirement.serialNumber, content);
        });
    }

    public saveAndOrder(shouldSave: boolean) {
        if (!shouldSave) {
            this.navigateToOrder();
            return;
        }

        this.save().then(() => {
            this.navigateToOrder();
        });
    }

    private navigateToOrder() {
        var queryParams = {
            model: this.model.requirement.modelNumber,
            hsso: this.model.requirement.hsso,
            number: this.model.number
        };
        let url = this._router.serializeUrl(
            this._router.createUrlTree(['app', 'portal', 'orders', this.garantId, 0], { queryParams: queryParams })
        )
        url = `${window.location.origin}/#${url}`;
        window.open(url, "_blank");
    }
}