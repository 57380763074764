import { Injectable, Inject, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { HelperService } from '../services/helper';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

/**
 * Služba pro lowlevel volání API.
 */
@Injectable()
export class ApiService {
	private static readonly TOKEN_COOKIE_NAME = 'tkn';

	private _token: string = null;
	private _apiUrl: string = null;

	constructor(
		private _http: HttpClient,
		private _cookieService: CookieService,
		private _helperService: HelperService,
		private _injector: Injector) {

		this._apiUrl = [
			window.location.protocol,
			'//',
			window.location.hostname,
			':',
			window.location.port,
			'/webapi'
		].join('');
	}

	/**
	 * Vrátí nebo nastaví JWT token
	 * @readonly
	 * @type {string}
	 */
	public get token(): string {
		// Pokud je token null, pokusíme se načíst z cookie
		// Pokud není v cookie, nastavíme prázdný string
		if (this._token == null) {
			this._token = this._cookieService.get(ApiService.TOKEN_COOKIE_NAME) || '';
		}

		return this._token;
	}
	public set token(value: string) {
		// Pokud neni zadano, pak mazu cookie; jinak ukládám do cookie
		if (!value || value.length === 0) {
			this._cookieService.remove(ApiService.TOKEN_COOKIE_NAME);
			this._token = '';

			return;
		}

		this._cookieService.put(ApiService.TOKEN_COOKIE_NAME, value);
		this._token = value;
	}

	/**
	 * DELETE
	 */
	public delete(path: string): Promise<any> {
		return this._http
			.delete(
				this.createUrl(path),
				{
					headers: this._createHeaders()
				}
			)
			.pipe(
				catchError(this._handleError)
			)
			.toPromise();
	}

	/**
	 * GET
	 */
	public get<T>(path: string): Promise<T> {
		return this._http
			.get<T>(
				this.createUrl(path),
				{
					headers: this._createHeaders()
				}
			)
			.pipe(
				catchError(this._handleError)
			)
			.toPromise();
	}

	/**
	 * GET - returns observable
	 */
	public getObservable<T>(path: string): Observable<T> {
		return this._http
			.get<T>(
				this.createUrl(path),
				{
					headers: this._createHeaders()
				}
			)
			.pipe(
				catchError(this._handleError)
			);
	}

	/**
	 * POST
	 */
	public post<T>(path: string, body: any): Promise<T> {
		this._helperService.convertDatesToDateStrings(body);

		return this._http
			.post<T>(
				this.createUrl(path),
				body,
				{
					headers: this._createHeaders()
				}
			)
			.pipe(
				catchError(this._handleError)
			)
			.toPromise();
	}

	/**
	 * POST
	 */
	public postGetBlob(path: string, body: any): Promise<Blob> {
		this._helperService.convertDatesToDateStrings(body);

		return this._http
			.post(
				this.createUrl(path),
				body,
				{
					headers: this._createHeaders(),
					responseType: 'blob'
				}
			)
			.pipe(
				catchError(this._handleError)
			)
			.toPromise();
	}

	/**
	 * PUT
	 */
	public put<T>(path: string, body: any): Promise<T> {
		this._helperService.convertDatesToDateStrings(body);

		return this._http
			.put<T>(
				this.createUrl(path),
				body,
				{
					headers: this._createHeaders()
				}
			)
			.pipe(
				catchError(this._handleError)
			)
			.toPromise();
	}

	/**
	 * Vytvoří výslednou url pro volání.
	 */
	createUrl(path: string): string {
		if (!path) {
			throw new Error('Unable to call API without path specified');
		}

		if (path[0] != '/') {
			path = '/' + path;
		}

		return this._apiUrl + path;
	}

	/**
	 * Vytvoří základní hlavičky
	 * @param contentType
	 */
	private _createHeaders(contentType: string = null): HttpHeaders {
		let headers = new HttpHeaders();

		// Nastavíme JWT token do hlaviček
		let token = this.token;

		if (token) {
			headers = headers.set('Authorization', `Bearer ${token}`);
		}

		headers = headers.set('Content-Type', contentType || 'application/json; charset=utf-8');
		headers = headers.set('X-Requested-With', 'XMLHttpRequest');

		return headers;
	}

	/**
	 * Metoda pro zpracování vyjímek při API voláních.
	 */
	private _handleError(response: HttpErrorResponse) {
		// Pro 401 - Unauthorized děláme redirekt na login
		if (response.status == 401) {
			this.token = null;

			let router = this._injector.get(Router);

			router.navigate(['/signin']);

			return throwError('Not authorized');
		}

		let error: ErrorResponse = {
			status: response.status,
			errors: [],
			isUserFriendly: false
		};

		if (typeof response.error != 'undefined') {
			error.isUserFriendly = response.error.isUserFriendly || false;

			if ('errors' in response.error && Array.isArray(response.error.errors)) {
				error.errors = response.error.errors;
			}
			else {
				error.errors.push({ key: null, message: response.statusText });
			}
		}

		return throwError(error);
	}
}

export class ErrorResponse {
	status: number = null;
	errors: Array<ErrorMessage> = [];
	isUserFriendly: boolean = false;
}

export class ErrorMessage {
	key: string = null;
	message: string = null;
}