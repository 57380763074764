import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../../core';
import { SettingModulesModule1Module } from './';

// Pages
import { Module1DetailPageComponent } from './pages/module';

const routes: Routes = [
    {
        path: '',
        component: Module1DetailPageComponent
    },
    {
        path: ':id',
        component: Module1DetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        SettingModulesModule1Module,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SettingModulesModule1RoutingModule { }