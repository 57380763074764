import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule, CanDeactivateGuard } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { PortalCoreModule } from '../core';

// Pages
import { SupportListPageComponent } from './pages/support-list';
import { SupportDetailPageComponent } from './pages/support-detail';

const routes: Routes = [
	{
		path: ':garantId',
		children: [
			{ path: '', component: SupportListPageComponent },
			{ path: ':id', component: SupportDetailPageComponent, canDeactivate: [CanDeactivateGuard] }
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
        RouterModule.forChild(routes),
        CoreModule,
        FormsModule,
		SuiModule,
		PortalCoreModule
	],
	exports: [
		RouterModule
	],
	declarations: [
		SupportListPageComponent,
		SupportDetailPageComponent
	],
	providers: [
	],
	entryComponents: [
	]
})
export class PortalSupportModule { }