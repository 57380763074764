import { OnDestroy, ViewChild } from '@angular/core';
import {
	ICanDeactivateComponent,
	UIService,
	HelperService,
	ScopePermissionType,
	AuthService,
	AppScopeService
} from '../../core';
import { NgForm, AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * Bázová třída pro komponenty reprezentující editační formuláře.
 */
export abstract class FormComponentBase implements ICanDeactivateComponent, OnDestroy {
	public model: any = {};
	public modelId: number = 0;

	private _subs: Array<Subscription> = [];
	private _initialValue: any = null;
	private _currentPermissionType: ScopePermissionType;
	private _initialized: boolean = false;
	private _changeValueSubscription: Subscription = null;

	// Získáváme referenci na NgForm ve view komponenty
    @ViewChild('ngForm', { static: true }) protected _form: NgForm;

	constructor(
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		protected _appScopeService: AppScopeService) {
	}

	/**
	 * Událost destrukce direktivy.
	 */
	ngOnDestroy() {
		if (this._changeValueSubscription != null) {
			this._changeValueSubscription.unsubscribe();
		}

		// Odhlásíme ze všech observables
		this._subs.forEach(x => x.unsubscribe());
	}

	/**
	 * Vrací true/false jestli může být komponenta deaktivována
	 */
	canDeactivate(): any {
		if (!this.changed) {
			return true;
		}

		return this._uiService.showConfirmation('Opravdu odejít?', 'Neuložil/a jste změny. Přejete si přesto odejít?');
	}

	// Vrací true/false jestli je form validní nebo ne
	public get valid(): boolean {
		return this._form.valid;
	}

	// Vrací true/false jestli je formulář změněný
	public changed: boolean = false;

	// Vrací true/false jestli lze formulář odeslat
	public allowSubmit(): boolean {
		return this.valid && this.changed;
	};

	/**
	 * Nastaví model a enabluje/disabluje kontroly na základě oprávnění.
	 * Pokud je volána poprvé, pak i inicializuje sledování změn.
	 *
	 * @param modelId
	 * @param model
	 */
    protected setModel(modelId: number, model: any) {

		// Inicializace po otevření
		if (this._changeValueSubscription == null) {
			// Přihlásíme se k odběru události změny hodnoty -
			// pouze jednou pro inicializaci výchozí hodnoty
			var initSubscription = this._form.valueChanges.pipe(debounceTime(100)).subscribe(x => {
				// Odhlášení
				initSubscription.unsubscribe();

				//console.log(this._form);

				// Ukládáme si výchozí hodnotu události změny hodnoty
				this._initialValue = this._helperService.deepClone(this._form.form.getRawValue());

				// Voláme onFormInitialzed - poděděné třídy mohou přepsat
				this.onFormInitialized();

				// Přihlásíme se k odběru události změny hodnoty
				this._changeValueSubscription = this._form.valueChanges.subscribe(x => {
					//console.log(this._initialValue, this._form.form.getRawValue());
					//console.log(this._helperService.deepEquals(this._initialValue, this._form.form.getRawValue()));

					// porovnáváme vůči getRawValue(). V x nejsou hodnoty disablovaných kontrolů
					this.changed = !this._helperService.deepEquals(this._initialValue, this._form.form.getRawValue());
				});

				// Projedu kontroly rekurzivně
				let scopeBuilder = new ScopeBuilder(this._appScopeService.currentScope);
				this._processControls(this._form.controls, scopeBuilder);
			});
		}

		this.modelId = modelId;
		this.model = model;
		this.changed = false;
        
		// Nastavím, pro jaký typ se mají kontrolovat permissions
		this._currentPermissionType = modelId == 0 ? ScopePermissionType.Create : ScopePermissionType.Update;

		// Inicializace po uložení
		if (this._changeValueSubscription != null) {


            // Ukládáme si výchozí hodnotu události změny hodnoty
			this._initialValue = this._helperService.deepClone(this._form.form.getRawValue());

            this._helperService.convertDateStringsToDates(this._initialValue);

			// Projedu kontroly rekurzivně
			let scopeBuilder = new ScopeBuilder(this._appScopeService.currentScope);
			this._processControls(this._form.controls, scopeBuilder);
        }

        //console.log("model", model);
	}

	/**
	 * Metoda volána po inicializaci formuláře.
	 */
	protected onFormInitialized() {
	}

	/**
	 * Vrátí form control pro požadovanou cestu. Např.
	 *
	 * @param controlPath
	 */
	protected getControl(controlPath: string): AbstractControl {
		if (!controlPath) {
			return null;
		}

		let parts = controlPath.split('.');
		let group = this._form.control;

		for (let i = 0; i < parts.length; i++) {
			let part = parts[i];
			let control = group.controls[part];

			// nenalezeno
			if (!control) {
				return null;
			}

			// pokud jde o hledaný kontrol, vracíme
			if (i == parts.length - 1) {
				return control;
			}

			if (control instanceof FormGroup) {
				group = <FormGroup>control;
				continue;
			}

			throw 'Control pro cestu "' + controlPath + '" nebyl nalezen.';
		}
	}

	/**
	 * Zaregistruje Subscription proto aby mohla být při destroy komponenty odregistrována.
	 * @param subscription
	 */
	protected registerSubscription(subscription: Subscription) {
		this._subs.push(subscription);
	}

	/**
	 * Metoda volána pro zjištění, jestli je scope povolený.
	 * Umožňuje přepsat defaultní chování v potomkovi.
	 * @param controlScope
	 * @param permissionType
	 */
	protected scopeIsEnabled(controlScope: string, permissionType: ScopePermissionType = null): boolean {
		permissionType = permissionType || this._currentPermissionType;

		return this._authService.hasAnyAppScopePermission(controlScope, permissionType);
	}

	private _processControls(controls: { [key: string]: AbstractControl }, scopeBuilder: ScopeBuilder) {
		for (let key of Object.keys(controls)) {
			let control = controls[key];

			// Proces FormGroup
			if (control instanceof FormGroup) {
				scopeBuilder.push(key);

				this._processControls(control.controls, scopeBuilder);

				scopeBuilder.pop();
			}

			// Proces FormControl
			if (control instanceof FormControl) {
				if (control.disabled) {
					continue;
				}

				// zkusíme, jestli je scope enabled. Může být přepsáno v potomkovi
				let controlScope = scopeBuilder.createScope(key);
				let enabled = this.scopeIsEnabled(controlScope, this._currentPermissionType);

				if (!enabled) {
					control.disable({ emitEvent: false });
				}
				else {
					control.enable({ emitEvent: false });
				}
			}
		}
	}
}

class ScopeBuilder {
	constructor(
		private scope: string) {
	}

	public push(name: string) {
		this.scope += '.' + name;
	}

	public pop() {
		let idx = this.scope.lastIndexOf('.');
		this.scope = this.scope.substr(0, idx);
	}

	public createScope(name: string): string {
		return this.scope + '.' + name;
	}
}