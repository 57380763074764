import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommissionViewModel } from '../services/commission-view-model';
import { SectionComponentBase } from '../classes/section-component-base';
import { FormControlsState } from '../services/form-controls-state';
import { FormGroup } from '@angular/forms';
import { ValuesService, CommissionPartState, SelectOption, ApiService } from '../../core';
import { UIService, ModalService, DataStorageService, ErrorResponse } from '../../core';
import { AddPartModal, IAddPartModalComponentResult } from '../modals/add-part';
import { Subscription } from 'rxjs';

/**
 * Sekce Výkon
 */

@Component({
	selector: 'div.kt-execution-section',
	templateUrl: "/template/commissions/components/execution-section.cshtml"
})
export class ExecutionSectionComponent extends SectionComponentBase implements OnInit, OnDestroy {
	private _subs: Array<Subscription> = [];

	// Index dílu který se edituje
	public idx: number = null;

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._viewModel.form;
	};

	// Jestli je zakázka sprována
	public get isPaired(): boolean {
		return this._viewModel && this._viewModel.model.isPaired;
	}

    // Jestli je zvolena pobočka
    public get isBranchSelected(): boolean {
        return this._viewModel && this._viewModel.model.branchId && this._viewModel.model.branchId > 0;
    }

    // Controls state
    public get controlsState(): FormControlsState {
        return this._viewModel.controlsState;
	};

	// Aktuiální CommissionTypeId
	public get commissionTypeId(): number {
		return this._viewModel.model.commissionTypeId;
	}

	// Jestli se zroovna něco děje
	public working: boolean = false;

	// Operationtypes a options pro ně
	private _operationTypes: Array<IOperationTypeValueModel> = [];

	constructor(
		private _viewModel: CommissionViewModel,
		private _valuesService: ValuesService,
		private _uiService: UIService,
		private _modalService: ModalService,
        protected _dataStorageService: DataStorageService,
        private _apiService: ApiService) {

		super(_dataStorageService, 'execution');
	}

	/**
	 * Inicializace komponenty 
	 */
	ngOnInit() {
		this._subs.push(
			this._viewModel.onInitialized.subscribe(x => this._onModelInitialized()),
			this.form.get('commissionTypeId').valueChanges.subscribe(x => this._onCommissionTypeChanged(x))
		);

		this._onModelInitialized();
		this._loadOperationTypes(this._viewModel.model.commissionTypeId);
	}

	/**
	 * Destrukce komponenty 
	 */
	ngOnDestroy() {
		this._subs.forEach(x => x.unsubscribe());
    }

	/**
	 * Přidá nový poplatek 
	 */
    public addFee(): void {
        this._valuesService.getExtendedValues<IFeesValueModel>('Fees')
            .then(fees => {
                if (fees.length == 0) {
                    this._uiService.showError('Žádné poplatky nejsou definovány.');
                    return;
                }

                this._viewModel.model.fees.push({
                    id: 0,
                    feesId: fees[0].value,
                    price: fees[0].flatRate
                });
            });
    }

	/**
	 * Přidá nový výkon 
	 */
	public addOperation(): void {
		this._viewModel.model.operations.push({
			id: 0,
			operationTypeId: this._operationTypes[0].value,
			price: this._operationTypes[0].flatRateDefault
		});
	}

	/**
	 * Vrací bool jestli je povoleno tlačítko pro přidání výkonu 
	 **/
	public addOperationIsDisabled(): boolean {
        return this.controlsState.buttonIsDisabled('executionSection.addOperation') ||
            this._operationTypes.length == 0;
	}

	/**
	 * Otevře modál pro přidání nového dílu. 
	 */
    public addPart(): void {
        this._modalService.openModal(new AddPartModal(this._viewModel.model.id))
			.onApprove((result: IAddPartModalComponentResult) => {
				let addedIndexes: Array<number> = [];

				for (let i = 0; i < result.quantity; i++) {
					let part: ICommissionPart = {
						id: 0,
						contractorContactId: result.part.contractorContactId,
						description: result.part.description,
						isNewPart: result.isNew,
						orderExternalNumber: null,
						originalPartCode: null,
						originalSerialNumber: null,
						partCode: result.part.partCode,
						partState: CommissionPartState.NotSet,
						purchasePrice: 0,
						salePrice: 0,
                        serialNumber: null,
                        position: null,
                        sectionIrisId: null,
                        faultIrisId: null,
                        repairIrisId: null
					};

					addedIndexes.push(this._viewModel.model.parts.push(part) - 1);
				}

				// Přepočíst ceny dílů
				this._viewModel.recomputePartsPrices().then(
					() => {},
					() => {
						// Pokud při přepočtu došlo k chybě, odeberu zase díl
						addedIndexes.reverse().forEach(x => this._viewModel.model.parts.splice(x, 1));
					}
				);
			});
	}


	/**
	 * Aktualni faze zakazky 
	 * */
	public commissionPhase(): CommissionPhase {
		return this._viewModel.model.phase;
	} 


	/**
	 * Zpracovává změnu hodnoty v typu zákroku
	 * @param operationTypeId
	 * @param index
	 */
	public onOperationValueChange(operationTypeId: number, index: number): void {
		let operation = this._viewModel.model.operations.find((o, i) => i == index);

		this._valuesService.getExtendedValue<IOperationTypeValueModel>('OperationType', operationTypeId)
			.then(x => {
				operation.price = x.flatRateDefault;
			});
	}

	/**
	 * Vrací info jestli je díl nový
	 * @param part
	 */
	public partIsNew(part: FormGroup): boolean {
		return part.controls.id.value == 0;
	}

	/**
	 * Vrací info jestli je díl spárován s objednávkou
	 * @param part
	 */
	public partIsPired(part: FormGroup): boolean {
		return part.controls.orderExternalNumber.value &&
			part.controls.orderExternalNumber.value.length > 0;
    }

    /**
     * Volá tisk štítku přes Servstrend API
     * @param part
     */
    public printPartLabel(part: FormGroup): void {
        this.working = true;

        this._apiService.get(`/svt/print-part-label/${this._viewModel.model.number}/${part.controls.id.value}`)
            .then(
                result => {
                    this._uiService.showSuccess('Tisk proběhl v pořádku');
                    this.working = false;
                },
                (reason: ErrorResponse) => {
                    if (reason.isUserFriendly && reason.errors.length > 0) {
                        this._uiService.showError(reason.errors[0].message, 'Při tisku se vyskytla chyba.');
                    }
                    else {
                        this._uiService.showError('Při tisku se vyskytla chyba.');
                    }

                    this.working = false;
                });
    }

	/**
	 * Odebere poplatek
	 * @param index
	 */
    public removeFee(index: number): void {
        this._viewModel.model.fees.splice(index, 1);
    }

	/**
	 * Odebere výkon
	 * @param index
	 */
    public removeOperation(index: number): void {
        this._viewModel.model.operations.splice(index, 1);
	}

	/**
	 * Odebere díl
	 * @param index
	 */
    public removePart(index: number): void {
        this._viewModel.model.parts.splice(index, 1);
    }

	/**
	 * Zpracovává změnu hodnoty v poplatku
	 * @param feesId
	 * @param index
	 */
	public onFeeValueChange(feesId: number, index: number): void {
		let fee = this._viewModel.model.fees.find((fee, i) => i == index);

		this._valuesService.getExtendedValue<IFeesValueModel>('Fees', feesId)
			.then(x => {
				fee.price = x.flatRate;
			});
	}

	/**
	 * Zpracovává událost inicializace modelu 
	 */
	private _onModelInitialized(): void {
    }

	/**
	 * Zpracovává událost změny typu zakázky - přenačítá typy výkonu
	 * @param commissionTypeId
	 */
	private _onCommissionTypeChanged(commissionTypeId: number): void {
		this._loadOperationTypes(commissionTypeId)
			.then(() => {
				// Smaz operace jejichz typy nejsou validni pro aktualni typ zakazky
				let allowedOperationTypeIds = this._operationTypes.map(x => x.value);
				let indexesToRemove: Array<number> = [];

				this._viewModel.model.operations.forEach((x, i) => {
					if (allowedOperationTypeIds.indexOf(x.operationTypeId) == -1) {
						indexesToRemove.push(i);
					}
				});

				indexesToRemove.reverse().forEach(x => this.removeOperation(x));
			});
	}

	/**
	 * Načítá typy výkonů 
	 * @param commissionTypeId
	 **/
	private _loadOperationTypes(commissionTypeId: number): PromiseLike<void> {
		return this._valuesService.getExtendedValues<IOperationTypeValueModel>('OperationType')
			.then(types => {
				this._operationTypes = types
					.filter(x => x.commissionTypesIds.includes(commissionTypeId));
			});
	}

	public setPartStateUnavailable(index: number): void {

		let part = this._viewModel.model.parts.find((part, i) => i == index);

		this._uiService.showConfirmation('Opravdu si přejete změnit stav dílu na Nedostupný? ', 'Změnu nelze vrátit zpět.')
			.then(x => {
				if (x) {
					part.partState = CommissionPartState.Unavailable;
					part.salePrice = 0;
				}
			});
		
    }

}