import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';

// Services
import { SumUpService } from './services/sumup';

// Pages
import { SumUpSettingsPageComponent } from './pages/settings';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        FormsModule,
        SuiModule
    ],
    exports: [
        RouterModule
    ],
    declarations: [
        SumUpSettingsPageComponent
    ],
    providers: [
        SumUpService
    ],
    entryComponents: [
    ]
})
export class SumUpModule { }