import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { ColumnSettings, ColumnDefinition } from '../models';
import { ListsService } from '../services/lists';

@Component({
	templateUrl: "/template/core/modals/columns-setup.cshtml"
})
export class ColumnsSetupModalComponent implements OnInit {
	// Dvojice definice/nastavení
	public columns: Array<Pair> = [];

	private _settings: Array<ColumnSettings>;
	private _columns: Array<ColumnDefinition>;

	constructor(
		public modal: SuiModal<IColumnsSetupModalContext, Array<ColumnSettings>>,
		private _listsService: ListsService) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this._settings = this.modal.context.columnsSettings;
		this._columns = this.modal.context.columnsDefinitions;

		let settings = this._listsService.buildColumnsSettings(this._columns, this._settings);

		// mapuju do párů definice/nastavení - je to kvůli ngFor v šabloně, ať tam mám vše pohromadě
		this.columns = settings.map(x => {
			return <Pair>{
				definition: this.getColumnDefinition(x.field),
				settings: x
			};
		});
	}

	/**
	 * Zaškrtne všechny sloupce 
	 **/
	public checkAll(): void {
		this.columns.forEach(x => {
			if (!x.definition.isMandatory) {
				x.settings.isHidden = false;
			}
		});
	}

	/**
	 * Potvrzení a uložení změn 
	 */
	public confirm(): void {
		this.modal.approve(this.columns.map(x => x.settings));
	}

	/**
	 * Vyhledá a vrátí definici pro sloupec se zadaným fieldem
	 * @param field
	 */
	public getColumnDefinition(field: string): ColumnDefinition {
		return this._columns.find(x => x.field == field);
	}

	/**
	 * Nastaví isHidden 
	 */
	public toggle(settings: ColumnSettings): void {
		settings.isHidden = !settings.isHidden;
	}

	/**
	 * Odškrtne všechny sloupce 
	 **/
	public uncheckAll(): void {
		this.columns.forEach(x => {
			if (!x.definition.isMandatory) {
				x.settings.isHidden = true;
			}
		});
	}
}

export class ColumnsSetupModal extends ComponentModalConfig<IColumnsSetupModalContext, void, void> {
	constructor(
		columnsSettings: Array<ColumnSettings>,
		columnsDefinitions: Array<ColumnDefinition>) {
		super(ColumnsSetupModalComponent, { columnsSettings: columnsSettings, columnsDefinitions: columnsDefinitions });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Large;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IColumnsSetupModalContext {
	columnsSettings: Array<ColumnSettings>;
	columnsDefinitions: Array<ColumnDefinition>;
}

class Pair {
	public definition: ColumnDefinition;
	public settings: ColumnSettings;
}