import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	UIService,
	ModelServiceFactory,
	HelperService,
	AppScopeService,
	AuthService,
	ProjectEnumService,
	ProjectEnumType
} from '../../../core';
import { PortalFormComponentBase } from '../../core';

@Component({
	templateUrl: "/template/portal/orders/pages/order-detail.cshtml"
})
export class OrderDetailPageComponent extends PortalFormComponentBase implements OnInit {
	// drží 'index' naposledy přidaného dílu
	private _partsIdx: number;

	constructor(
		protected _router: Router,
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		protected _appScopeService: AppScopeService,
		private _modelServiceFactory: ModelServiceFactory,
		private _projectEnumService: ProjectEnumService) {

		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_modelServiceFactory.createService('portalOrder'),
			_router);
	}

	/**
	 * Override metody v ModelFormComponentBase - potřebujeme inicializovat parts
	 * @param model
	 */
	protected onModelLoaded(model: any) {
		// musíme na každý díl nastavit interní property, která drží unikátní hodnotu
		// a uložíme si hodnotu
		model.parts = model.order.parts.map(function (x, i) { x.$i = i; return x; })
		this._partsIdx = model.order.parts.length;

		this._activatedRoute.queryParams.subscribe(params => {
			try {
				if (params.branchId !== undefined && params.branchId > 0) {
					model.assignment.branchId = params.branchId;
				}

				if (params.commissionNumber !== undefined && params.commissionNumber.length > 0) {
					model.order.commissionNumber = params.commissionNumber;
				}

				if (params.model !== undefined && params.model.length > 0) {
					model.order.modelNumber = params.model;
				}

				if (params.hsso !== undefined && params.hsso.length > 0) {
					model.order.hssoNumber = params.hsso;
				}

				if (params.number !== undefined && params.number.length > 0) {
					model.order.commissionNumber = params.number;
                }
			} catch { }
		});
	}

	/**
	 * Override metody v ModelFormComponentBase - po vložení redirektujeme na editaci
	 */
	protected onModelSaved(model: any) {
		if (this.modelId == 0) {
			this._router.navigate(['app', 'portal', 'orders', this.garantId, model.id], {});
		}
	}

	/**
	 * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
	 */
	getTitle() {
		return this.modelId == 0 ? 'Nová objednávka ND' : this.model.number;
	}

	/**
	 * Přidá nový řádek pro náhradní díl do seznamu
	 */
	addPart() {
		this._projectEnumService.getDefaultValueId(ProjectEnumType.PortalOrderPartState)
			.then(x => {
				this.model.order.parts.push({
					id: 0,
					partNumber: '',
					quantity: 1,
					stateId: x,
					$i: this._partsIdx++
				});
			});
	}

	/**
	 * Odebere náhradní díl který je v seznamu na specifikovaném indexu 
	 * @param index
	 */
	removePart(index: number) {
		this.model.order.parts.splice(index, 1);
	}

	/**
	 * Vrátí unikátní identitu pro daný díl. Nutné rpo správnou funkci ngFor.
	 * @param item
	 * @param index
	 */
	partIdentity(item: any, index: number): any {
		return item.$i;
	}
}