import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';

@Component({
	templateUrl: "/template/commissions/modals/new-commission-cancelation.cshtml"
})
export class NewCommissionCancelationModalComponent implements OnInit {
	constructor(
		public modal: SuiModal<INewCommissionCancelationModalComponentContext, CancelationChoice, void>) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	public cancel(): void {
		this.modal.approve(CancelationChoice.cancel);
	}

	public doNotCreate(): void {
		this.modal.approve(CancelationChoice.doNotCreate);
	}

	public saveAsConcept(): void {
		this.modal.approve(CancelationChoice.saveAsConcept);
	}
}

export class NewCommissionCancelationModal extends ComponentModalConfig<INewCommissionCancelationModalComponentContext, CancelationChoice, void> {
	constructor() {
		super(NewCommissionCancelationModalComponent, {});

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
	}
}

/**
 * Specifikuje zvolenou akci v modalu 
 */
export enum CancelationChoice {
	cancel = 0,
	doNotCreate = 1,
	saveAsConcept = 2
}

/**
 * Rozhraní pro kontext modalu
 */
interface INewCommissionCancelationModalComponentContext {
}
