import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../services/auth';
import { UIService } from '../services/ui';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	templateUrl: "/template/core/pages/lost-password.cshtml"
})
export class LostPasswordComponent implements OnInit {
    lostPassForm: FormGroup;
    loading: boolean = false;

    public userGuid: string = "";
    public lostPassIdent: string = "";

    constructor(
        protected _activatedRoute: ActivatedRoute,
		private _authService: AuthService,
		private _uiService: UIService,
        private _router: Router) {
	}

    ngOnInit() {

        this._activatedRoute.queryParams.subscribe(params => {
            if (params['user'] != undefined)
                this.userGuid = params['user'];

            if (params['ident'] != undefined)
                this.lostPassIdent = params['ident'];
          

        })

		// Inicializace formuláře
        this.lostPassForm = new FormGroup({
            password: new FormControl(null, [Validators.required,
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,50}$') ]),
            passwordConfirmation: new FormControl(null, [Validators.required,
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,50}$') ])
		});
	}

	isValid(): boolean {

        if (this.lostPassForm.get('password').value != this.lostPassForm.get('passwordConfirmation').value) {
            return false;
        }

        return this.lostPassForm.valid;
	}

    validate(): boolean {
        if (this.userGuid == "") {
            this._uiService.showError('Chybný identifikátor klienta.', 'Formulář nelze odeslat.');
            return false;
        }

        if (this.lostPassIdent == "") {
            this._uiService.showError('Chybějící identifikátor pro změnu hesla.', 'Formulář nelze odeslat.');
            return false;
        }


        return true;
	}


    confirm() {
        if (!this.validate())
        {
            return false;
        }

		this.loading = true;

        this._authService
            .passwordReset(this.lostPassIdent, this.userGuid, this.lostPassForm.get('password').value, this.lostPassForm.get('passwordConfirmation').value)
			.then(result => {
				if (result.success) {
                    // Redirekt
                    this._router.navigate(['signin'], { queryParams: { message: 'Heslo bylo úspěšně nastaveno. Nyní se můžete přihlásit.'} });
				}
				else {
					this.loading = false;
					this._uiService.showError(result.message, 'Změna hesla se nezdařila');
				}
			});
	}
}