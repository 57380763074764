import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router'
import { CommissionViewModel } from '../services/commission-view-model';
import { FormControlsState } from '../services/form-controls-state';
import { SectionComponentBase } from '../classes/section-component-base';
import { SelectOption, DataStorageService, ValuesService } from '../../core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { } from '../';

/**
 * Sekce Adresy
 */

@Component({
    selector: 'div.kt-addresses-section',
    templateUrl: "/template/commissions/components/addresses-section.cshtml"
})
export class AddressesSectionComponent extends SectionComponentBase implements OnInit, OnDestroy {
    private _subs: Array<Subscription> = [];

    // Jestli se zroovna něco děje
    public working: boolean = false;

    // Jestli jsou pole disablované
    // protože ještě nebylo inicializované seriové číslo
    public get disabled(): boolean {
        return !this._viewModel.serialNumberInitialized;
    }

    // Commission view model
    public get model(): ICommission {
        return this._viewModel.model;
    };

    // Formulář se zakázkou
    public get form(): FormGroup {
        return this._viewModel.form;
    };

    // Controls state
    public get controlsState(): FormControlsState {
        return this._viewModel.controlsState;
    };

    constructor(
        private _viewModel: CommissionViewModel,
        private _router: Router,
        protected _dataStorageService: DataStorageService,
        private _valuesService: ValuesService) {

        super(_dataStorageService, 'addresses')
    }

	/**
	 * Inicializace komponenty
	 */
    ngOnInit() {
        this._subs.push(
            this.form.get('commissionTypeId').valueChanges.subscribe(x => this._onCommissionTypeIdValueChange(x))
        );
    }

	/**
	 * Destroy komponenty 
	 **/
    ngOnDestroy() {
        this._subs.forEach(x => x.unsubscribe());
    }

	/**
	 * Zruší vazbu pro danou adresu.
	 * @param address
	 */
    public clearBinding(address: ICommissionAddress): void {
        address.contactId = null;
    }

    /**
     * Otevře okno s kontaktem
     * @param id
     */
    public openContact(id: number): void {
        let urlTree = this._router.createUrlTree(['app', 'contacts', 'contacts', id]);
        let url = '#' + this._router.serializeUrl(urlTree);
        window.open(url, "_blank");
    }

	/**
	 * Zkopíruje (převezme) data z jedné do druhé adresy.
	 * @param from
	 * @param to
	 */
    public copyFromTo(from: ICommissionAddress, to: ICommissionAddress): void {
        this._viewModel.copyAddressFromTo(from, to);
    }

	/**
	 * Zpracovává událost výběru položky v inputu názvu plátce
	 * @param event
	 */
    public onPayerContactOptionSelected(event: SelectOption): void {
        this.working = true;

        let contactId = parseInt(event.value);
        this._viewModel.setPayerAddress(contactId).then(
            x => {
                this.working = false;
            },
            reason => {
                this.working = false;
            }
        );
    }

	/**
	 * Zpracovává událost výběru položky v inputu názvu příjemce
	 * @param event
	 */
    public onRecipientContactOptionSelected(event: SelectOption): void {
        this.working = true;

        let contactId = parseInt(event.value);
        this._viewModel.setRecipientAddress(contactId).then(
            x => {
                this.working = false;
            },
            reason => {
                this.working = false;
            }
        );
    }

	/**
	 * Zpracovává událost výběru položky v inputu názvu prodejce
	 * @param event
	 */
    public onSellerContactOptionSelected(event: SelectOption): void {
        this.working = true;

        let contactId = parseInt(event.value);
        this._viewModel.setSellerAddress(contactId).then(
            x => {
                this.working = false;
            },
            reason => {
                this.working = false;
            }
        );
    }

	/**
	 * Vrací info jestli je adresa plátce nasvázána na kontakt.
	 */
    public payerIsBound(): boolean {
        return this.model.payer.contactId == null;
    }

	/**
	 * Vrací info jestli je adresa příjemce nasvázána na kontakt.
	 */
    public recipientIsBound(): boolean {
        return this.model.recipient.contactId == null;
    }

	/**
	 * Vrací info jestli je adresa prodejce nasvázána na kontakt.
	 */
    public sellerIsBound(): boolean {
        return this.model.seller.contactId == null;
    }

    /**
     * Zpracovává změnu plátce na základě ID typu zakázky
     * @param value ID typu zakázky
     */
    private _onCommissionTypeIdValueChange(value: number | null) {
        if (value === null || value === 0) {
            return;
        }

        this._valuesService.getExtendedValue<ICommissionTypeValueModel>('CommissionType', value).then(x => {
            let garantId: number | null = x.garantId;

            if (garantId > 0) {
                this._viewModel.setPayerAddress(garantId).then(x => {});
                return;
            }


            this._viewModel.clearPayerAddressContactId();
        });
    }
}