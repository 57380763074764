import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommissionViewModel } from '../services/commission-view-model';
import { PricingViewModel } from '../services/pricing-view-model';
import {
	ValuesService,
	DiscountType,
	PaymentMethodType,
	ModalService,
	AuthService,
	CommissionPaymentState,
	CommissionPaymentType
} from '../../core';
import { AddSettlementModal, IAddSettlementModalComponentResult } from '../modals/add-settlement';
import { CommissionsService } from '../services/commissions';
import { SumUpService } from '../../modules/sumup/services/sumup';

/**
 * Tab Vyúčtování
 */

@Component({
	selector: 'div.kt-statement-tab',
	templateUrl: "/template/commissions/components/statement-tab.cshtml"
})
export class StatementTabComponent implements OnInit {
	@Output() notifyCommissionDetail: EventEmitter<number> = new EventEmitter<number>();

	private _commissionPaymentTypes: { [value: string]: string };
	private _sumUpIsEnabled: boolean = false;

	// Commission view model
	public get model(): ICommission {
		return this._viewModel.model;
	}

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._viewModel.form;
	}

	// Pricing view model
	public get pricing(): PricingViewModel {
		return this._viewModel.pricing;
	}

	// Vrací text s procenty slevy podle typu zakázky
	public get discountByTypePercentText(): string {
		if (this._viewModel.model.discountByType.type == DiscountType.Percentage) {
			return `: ${this._viewModel.model.discountByType.value}%`;
		}

		return '';
	}

	constructor(
		private _viewModel: CommissionViewModel,
		private _valuesService: ValuesService,
		private _modalService: ModalService,
		private _commissionService: CommissionsService,
		private _authService: AuthService,
		private _sumUpService: SumUpService) {
	}

	/**
	 * Inicializace komponenty 
	 */
	ngOnInit() {
		this._valuesService
			.getDictionary('CommissionPaymentType')
			.then(x => this._commissionPaymentTypes = x);

		this._sumUpService.isReady().then(x => this._sumUpIsEnabled = x);
	}

	/**
	 * Přidá úhradu platby
	 * @param payment
	 */
	public addSettlement(payment: ICommissionPayment): void {

		// Otevřeme modal
		this._modalService.openModal(new AddSettlementModal(payment.requestedAmount, new Date()))
			.onApprove((result: IAddSettlementModalComponentResult) => {
				// Zapisuju platbu
				payment.paymentMethod = result.paymentMethodType;
				payment.paidAmount = payment.requestedAmount;
				payment.paidDate = result.date;
				payment.paidToUser = this._authService.currentUser.fullName;
				payment.paymentState = CommissionPaymentState.NotSaved;

				//Zpracovani automatickych akci z nastaveni
				this._commissionService.getStatementAutomaticAction()
					.then(actions => {
						let modalService = this._modalService;

						for(let action of actions) {

							if (payment.paymentType != action.paymentType) {
								continue;
							}

							if (modalService.openModalsCount() > 0) {
								return false;
							}

							this.notifyCommissionDetail.emit(action.eventId);
						};

					}, reason => {
							console.log('reason', reason);
					}).catch((ex) => {
						console.log('catch', ex);
					});
				
			});
	}

	/**
	 * Vrací název pro daný typ platby
	 * @param type
	 */
	public getCommissionPaymentTypeName(type: CommissionPaymentType): string {
		if (!this._commissionPaymentTypes) {
			return '';
		}

		return this._commissionPaymentTypes[type.toString()];
	}

	/**
	 * Vrací text pro payment method type
	 * @param method
	 */
	public getPaymentMethodTypeName(method: PaymentMethodType): string {
		switch (method) {
			case PaymentMethodType.BankTransfer: return 'Bankovním převodem';
			case PaymentMethodType.Cash: return 'Hotově';
			case PaymentMethodType.PaymentCard: return 'Platební kartou';
			case PaymentMethodType.CashOnDelivery: return 'Dobírkou';
			default: return '';
		}
	}

	/**
	 * Inicializuje platbu kartou
	 * @param payment
	 */
	public payByCard(payment: ICommissionPayment): void {
		this._sumUpService
			.getPaymentUrl(this._viewModel.model, payment)
			.then(x => {
				// Otevírám platební odkaz ...
				window.location.href = x;

				// ... a zavírám tohle okno
				window.close();
			});
	}

	/**
     * Vytiskne daňový doklad
	 * @param payment
     */
	public printPaymentInvoice(payment: ICommissionPayment) {
		this._commissionService.printPaymentInvoice(payment);
	}

	/**
     * Vytiskne daňový doklad - přenosná
	 * @param payment
     */
	public printPaymentInvoice80(payment: ICommissionPayment) {
		this._commissionService.printPaymentInvoice80(payment);
	}

	/**
	 * Vytiskne požadavek na úhradu
	 * @param payment
	 */
	public printRequestForDeposit(payment: ICommissionPayment) {
		this._commissionService.printRequestForDeposit(payment);
	}

	/**
	 * Odebere požadavek na úhradu
	 * @param payment
     * @param force
	 */
    public removePayment(payment: ICommissionPayment, force: boolean): void {
        if (!force && payment.paymentState != CommissionPaymentState.NotPaid) {
			return;
		}

		//Neulozeny pozadavek rovnou odstranime
		if (payment.id == 0)
		{
			let idx = this._viewModel.model.payments.indexOf(payment);
			this._viewModel.model.payments.splice(idx, 1);
		} else {
			payment.paymentState = CommissionPaymentState.Removed;
		}
    }

    /**
     * Zda  má technik právo na storno dokladu
     * @param payment
     */
    public stornoDisabledByPermission(payment: ICommissionPayment): boolean {
		return this._viewModel.controlsState.buttonIsDisabled('statementTab.storno') ||
			(payment.paymentState != CommissionPaymentState.Paired && payment.paymentState != CommissionPaymentState.Temporary);
	}

	/**
	 * Vrací jestli je dotazovaná akce/prvek viditelná
	 * @param payment
	 * @param action
	 */
	public isVisible(payment: ICommissionPayment, action: string): boolean {
		switch (action) {
			case 'addSettlement':
				return !this._viewModel.controlsState.buttonIsDisabled('statementTab.addSettlement') &&
					payment.paymentState == CommissionPaymentState.NotPaid &&
					(payment.paymentType != CommissionPaymentType.Commission || !this._viewModel.isBulkInvoice);

			case 'payByCard':
				return this._sumUpIsEnabled && this.isVisible(payment, 'addSettlement');

			case 'notSaved':
				return payment.paymentState == CommissionPaymentState.NotSaved || payment.paymentState == CommissionPaymentState.Removed

			case 'notPaired':
				return payment.paymentState == CommissionPaymentState.NotPaired;

			case 'externalNumber':
				return payment.paymentState == CommissionPaymentState.Paired || payment.paymentState == CommissionPaymentState.Temporary;

			case 'spareDocument':
				return payment.paymentState == CommissionPaymentState.Temporary;

			case 'bulkInvoice':
				return payment.paymentState == CommissionPaymentState.NotPaid &&
					payment.paymentType == CommissionPaymentType.Commission &&
					this._viewModel.isBulkInvoice;
		}

		console.warn(`Not supported action ${action}`);
		return true;
	}
}