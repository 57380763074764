import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent, IModelService, ValuesService } from '../../../core';
import { ListsPriceListService } from '../services/lists-price-list';

@Component({
    templateUrl: "/template/lists/price-list/pages/price-list-list.cshtml"
})
export class ListsPriceListPageComponent {
	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * Instance model service
	 */
	public modelService: IModelService;

	constructor(
		private _router: Router,
        private _uiService: UIService,
        private _valuesService: ValuesService,
		protected _listsPriceList: ListsPriceListService) {
		this.modelService = _listsPriceList;
	}

	/**
	 * Redirekt na stránku pro přidání.
	 */
    add() {
        this._router.navigateByUrl('/app/list/pricelists/0');
	}

	/**
	 * Smazání
	 * @param item
	 */
    delete(item: IPriceListListModel) {
        if (item.isDefault) {
            this._uiService.showWarning("Není možné smazat výchozí cenovou hladinu. Nejprve vyberte jinou hladinu jako výchozí.");
            return;
        }
		this._uiService
            .showConfirmation('Odstranění záznamu', `Opravdu si přejete odstranit záznam ${item.name}?`)
            .then(result => {
                if (result) {
                    this._uiService.showLoader();

                    this.modelService.delete(item.id, null).then(
                        result => {
                            this._uiService.hideLoader();
                            this._grid.loadData();
                            this._valuesService.clearCacheItem("PriceList");
                        }).catch(reason => {
                            this._uiService.hideLoader();
                        });
                }
			});
    }

	/**
	 * Redirekt na stránku s editací
	 * @param item
	 */
    edit(item: IPriceListListModel) {
        this._router.navigateByUrl(`/app/list/pricelists/${item.id}`);
	}
}