import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule, CanDeactivateGuard } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { PortalCoreModule } from '../core';

// Pages
import { OrderListPageComponent } from './pages/order-list';
import { OrderDetailPageComponent } from './pages/order-detail';

// Components
import { PortalOrderPartsComponent } from './components/portal-order-parts';

const routes: Routes = [
	{
		path: ':garantId',
		children: [
			{ path: '', component: OrderListPageComponent },
			{ path: ':id', component: OrderDetailPageComponent, canDeactivate: [CanDeactivateGuard] }
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		CoreModule,
		FormsModule,
		SuiModule,
		PortalCoreModule
	],
	exports: [
		RouterModule
	],
	declarations: [
		OrderListPageComponent,
		OrderDetailPageComponent,
		PortalOrderPartsComponent
	],
	providers: [
	],
	entryComponents: [
	]
})
export class PortalOrdersModule { }