import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonPermission, CanDeactivateGuard } from '../../core';
import { SettingUserModule } from './';

// Pages
import { UserListPageComponent } from './pages/user-list';
import { UserDetailPageComponent } from './pages/user-detail';

const routes: Routes = [
    {
        path: '',
        data: {
           
        }, component: UserListPageComponent },
    {
        path: ':id',
        component: UserDetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        SettingUserModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SettingUserRoutingModule { }