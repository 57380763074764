import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonPermission, CanDeactivateGuard } from '../../core';
import { SettingModuleListModule } from './';

// Pages
import { ModuleListPageComponent } from './pages/module-list';

const routes: Routes = [
    {
        path: '',
        data: {
          
        }, component: ModuleListPageComponent
    }
];

@NgModule({
    imports: [
        SettingModuleListModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class SettingModuleListRoutingModule { }