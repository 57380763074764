import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../core';
import { NotificationsModule } from './';

// Pages
import { NotificationListPageComponent } from './pages/notification-list';
import { NotificationDetailPageComponent } from './pages/notification-detail';


const routes: Routes = [
    { path: '', component: NotificationListPageComponent },
    {
        path: ':id',
        component: NotificationDetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        NotificationsModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class NotificationsRoutingModule { }