import { Injectable } from '@angular/core';
import { ModelService, ApiService, UIService } from '../../core';

/**
 * Poskytuje operace nad uživateli.
 */
@Injectable()
export class NotificationsService extends ModelService {

	constructor(
        apiService: ApiService,
		uiService: UIService) {
        // Voláme konstruktor bázové třídy
        super('notification', apiService, uiService);
    }

    public copy(id: number): Promise<number> {
        this.uiService.showLoader();
        return this.apiService.get<number>(super.createUrl('copy/' + id));
    }

    public getConditionTypes(): Promise<string> {
        return this.apiService.get<string>(super.createUrl('getConditionTypes'));
    }

}