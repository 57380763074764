import { Injectable } from '@angular/core';
import { TransportCarriersService } from '../../lists/transport-carriers';

/**
 * Poskytuje operace nad dopravami v zakázce
 */
@Injectable()
export class TransportCalculator {
	// Načtené data o dopravách
	private _transportCarriers: Array<ITransportSetupCarier> = null;

	constructor(
		private _transportCarriersService: TransportCarriersService) {
	}

	/**
	 * Spočítá cenu dopravy
	 * @param transport
	 */
	public calculate(transport: ICommissionTransport): void {

		transport.price = null;

		if (!transport.transportCarrierId) {
			return;
		}

		this.getTransportCarrier(transport.transportCarrierId)
			.then(carrier => {
				

				let type = carrier.types.find(x => x.id == transport.transportTypeId);

				// Typ dopravy není zadán nebo není zadaná cena u typu
				if (!type || type.transportValue <= 0) {
					return;
				}

				if (type.priceByDistance) {
					if (!transport.distance) {
						// Není zadaná vzdálenost
						return;
					}

					transport.price = type.transportValue * transport.distance;
				}
				else {
					transport.price = type.transportValue;
				}
			});
	}

	/**
	 * Vrací transport carrier pro dané id.
	 * @param id
	 */
	public getTransportCarrier(id: number): Promise<ITransportSetupCarier> {
		return this.getTranportCarriers()
			.then(x => {
				return x.find(x => x.id == id);
			});
	}

	/**
	 * Vrací nastavení doprav.
	 */
	public getTranportCarriers(): Promise<Array<ITransportSetupCarier>> {
		return new Promise((resolve, reject) => {
			// Zkouším z předem načtených
			if (this._transportCarriers != null) {
				resolve(this._transportCarriers);
				return;
			}

			// Načítám z API
			this._transportCarriersService.getTransportSetup()
				.then(
					x => {
						this._transportCarriers = x;

						resolve(x);
					});
		});
	}
}