import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent, IModelService, ModelServiceFactory } from '../../../core';

@Component({
    templateUrl: "/template/settings/modules/pages/module-list.cshtml"
})
export class ModuleListPageComponent {
    /**
     * Reference na grid komponentu
     */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

    /**
     * Instance model service
     */
    public modelService: IModelService;

    constructor(
        private _router: Router,
        private _uiService: UIService,
        private _modelServiceFactory: ModelServiceFactory) {

        this.modelService = _modelServiceFactory.createService('module');
    }

    /**
     * Redirekt na stránku s  editací
     */
    edit(item: any) {
        // TODO: Predelat
        if (item.code == 'SumUp') {
            this._router.navigateByUrl('/app/modules/sumup/settings');
        }
        else {
            this._router.navigateByUrl('/app/settings/' + item.code.toLowerCase() + '/' + item.id);
        }
    }
}