import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent, IModelService, ModelServiceFactory } from '../../../core';

@Component({
    templateUrl: "/template/settings/role/pages/role-list.cshtml"
})
export class RoleListPageComponent {
	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * Instance model service
	 */
    public modelService: IModelService;

    constructor(
        private _router: Router,
        private _uiService: UIService,
        private _modelServiceFactory: ModelServiceFactory) {

        this.modelService = _modelServiceFactory.createService('role');
    }

	/**
	 * Redirekt na stránku pro přidání.
	 */
    add() {
        this._router.navigateByUrl('/app/settings/roles/0');
    }

    /**
	 * Smazání
	 * @param role
	 */
    delete(role: any) {
        this._uiService
            .showConfirmation('Odstranění role', 'Opravdu si přejete odstranit roli ' + role.name + '?')
            .then(result => {
                if (result) {
                    this._uiService.showLoader();

                    this.modelService.delete(role.id, null).then(
                        result => {
                            this._uiService.hideLoader();
                            this._grid.loadData();
                        });
                }
            });
    }

	/**
	 * Redirekt na stránku s editací
	 */
    edit(item: any) {
        this._router.navigateByUrl('/app/settings/roles/' + item.id);
    }
}