import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { DeviceCategoryModule } from './';

// Pages
import { ListsDeviceCategoryPageComponent } from './pages/device-category-list';
import { ListsDeviceCategoryDetailPageComponent } from './pages/device-category-detail';

const routes: Routes = [
	{ path: '', component: ListsDeviceCategoryPageComponent },
	{
		path: ':id',
		component: ListsDeviceCategoryDetailPageComponent,
		canDeactivate: [CanDeactivateGuard]
	}
];

@NgModule({
	imports: [
		DeviceCategoryModule,
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class DeviceCategoryRoutingModule { }