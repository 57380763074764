import { Component, OnInit, OnDestroy } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { ModelWrapperBuilder } from '../services/model-wrapper-builder';
import { ModelWrapper } from '../models';
import { FormGroup } from '@angular/forms';
import { ListsPriceListService } from '../../lists/price-list';
import { UIService, AppSettings, HelperService } from '../../core';
import { Subscription } from 'rxjs';

@Component({
	templateUrl: "/template/commissions/modals/price-proposal.cshtml"
})
export class PriceProposalModalComponent implements OnInit, OnDestroy {
	private _subs: Array<Subscription> = [];

	// Wrapper nad cenovým návrhem
	private _wrapper: ModelWrapper<ICommissionPriceProposal> = null;

	// Aktuální ceník podle typu zakázky
	private _pricelist: IPriceListModel;

	// Jestli se právě načítá
	public loading: boolean = false;

    // Jestli je tlačítko ok disablované
    public saveDisabled: boolean = false;

	// Model pro cenový návrh
	public get model(): ICommissionPriceProposal {
		return this._wrapper.model;
	}

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._wrapper.form;
	};

	constructor(
		public modal: SuiModal<IPriceProposalModalComponentContext, ICommissionPriceProposal, void>,
		private _modelWrapperBuilder: ModelWrapperBuilder,
		private _listsPriceListService: ListsPriceListService,
		private _uiService: UIService,
		private _helperService: HelperService,
        private _appSettings: AppSettings) {

        this.saveDisabled = modal.context.saveDisabled;
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this.loading = true;

		this._listsPriceListService.getByCommissionType(this.modal.context.commissionTypeId)
			.then(
				x => {
					this._pricelist = x;
					this.loading = false;
				},
				reason => {
					this._uiService.showError('Nepodařilo se načíst ceník pro zakázku.');
					this.loading = false;
					this.modal.deny(null);
				}
			);

		this._wrapper = this._modelWrapperBuilder.build<ICommissionPriceProposal>(this.modal.context.priceProposal);

		this._wrapper.form.controls.partsSellingPrice.disable({ emitEvent: false });
		this._wrapper.form.controls.sumPriceWithoutVat.disable({ emitEvent: false });
		this._wrapper.form.controls.sumPriceWithVat.disable({ emitEvent: false });

		this._subs.push(
			this._wrapper.form.controls.partsPurchasePrice.valueChanges.subscribe(x => { this._recalculate(); }),
			this._wrapper.form.controls.operationPrice.valueChanges.subscribe(x => { this._recalculate(); }),
			this._wrapper.form.controls.transportPrice.valueChanges.subscribe(x => { this._recalculate(); }),
			this._wrapper.form.controls.otherPrice.valueChanges.subscribe(x => { this._recalculate(); })
		);
	}

	ngOnDestroy() {
		this._subs.forEach(x => x.unsubscribe());
	}

	/**
	 * Zruší formulář 
	 */
	public cancel(): void {
		this.modal.deny(null);
	}

	/**
	 * Uloží návrh 
	 */
	public save(): void {
		this.modal.approve(<ICommissionPriceProposal>this.form.getRawValue());
	}

	/**
	 * Přepočítá návrh 
	 */
	private _recalculate(): void {
		let proposal = this._wrapper.model;
		let partsPurchasePrice = proposal.partsPurchasePrice;

		proposal.partsSellingPrice = this._listsPriceListService.computePriceByPricelist(this._pricelist, partsPurchasePrice);
		proposal.sumPriceWithoutVat = proposal.partsSellingPrice +
			proposal.operationPrice +
			proposal.transportPrice +
			proposal.otherPrice;

		proposal.sumPriceWithVat = this._helperService.round(proposal.sumPriceWithoutVat * (100 + this._appSettings.vatValue) / 100, 2);
	}
}

export class PriceProposalModal extends ComponentModalConfig<IPriceProposalModalComponentContext, ICommissionPriceProposal, void> {
    constructor(priceProposal: ICommissionPriceProposal, commissionTypeId: number, saveDisabled: boolean) {
        super(PriceProposalModalComponent, { priceProposal, commissionTypeId, saveDisabled });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
		this.mustScroll = true;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IPriceProposalModalComponentContext {
	priceProposal: ICommissionPriceProposal;
    commissionTypeId: number;
    saveDisabled: boolean;
}
