import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { PaymentMethodType } from '../../core';


@Component({
	templateUrl: "/template/commissions/modals/request-for-deposit.cshtml"
})
export class RequestForDepositModalComponent implements OnInit {

	public amount: number = null;
	public paymentMethodType: PaymentMethodType = null;
	public isNew: boolean;


	constructor(
		public modal: SuiModal<IRequestForDepositModalComponentContext, IRequestForDepositModalComponentResult, void>) {

		this.isNew = modal.context.commission.id == 0;
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	public add(): void {
		var result: IRequestForDepositModalComponentResult = { paymentMethodType: this.paymentMethodType, amount: this.amount };
		this.modal.approve(result);
	}

	public cancel(): void {
		this.modal.deny(null);
	}
}

export class RequestForDepositModal extends ComponentModalConfig<IRequestForDepositModalComponentContext, number, void> {
	constructor(commission: ICommission) {
		super(RequestForDepositModalComponent, { commission });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IRequestForDepositModalComponentContext {
	commission: ICommission;
}

export interface IRequestForDepositModalComponentResult {
	paymentMethodType: PaymentMethodType,
	amount: number
}
