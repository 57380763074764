import { Injectable } from '@angular/core';
import { ModelService, ApiService, UIService } from '../../core';

/**
 * Poskytuje operace nad uživateli.
 */
@Injectable()
export class TransportCarrierCollectionService extends ModelService {

	constructor(
        apiService: ApiService,
		uiService: UIService) {
        // Voláme konstruktor bázové třídy
        super('transportcarriercollection', apiService, uiService);
    }

    public checkOrderStatus(id: number): Promise<any> {
        return this.apiService.get<any>(super.createUrl('check-order-status/' + id));
    }
}