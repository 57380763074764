import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmModal } from '../modals/confirm';
import { MessageModal, MessageModalType } from '../modals/message';
import { ToastrService, IndividualConfig, ActiveToast } from 'ngx-toastr';
import { ModalService } from './modal';

/**
 * Posktyuje různé metody pro UI
 */
@Injectable()
export class UIService {
	public loaderData = new BehaviorSubject<LoaderData>(new LoaderData());
	public footerText = new BehaviorSubject<string>('');
	public onResized: EventEmitter<any> = new EventEmitter(true);

	constructor(
		private _modalService: ModalService,
        private _toastrService: ToastrService) {
    }

	/**
	 * Zformátuje daný seznam stringů do HTML listu
	 * @param items
	 */
	public formatList(items: Array<string>): string {
		let itemsHtml = items.map(x => `<li>${x}</li>`).join();
		return `<ul class="list">${itemsHtml}</ul>`;
	}

	/**
	 * Skryje navbar
	 */
	public hideLoader(): void {
		this.loaderData.next({ show: false });
	}

	/**
	 * Nastaví text to patičky
	 * @param text
	 */
	public setFooterText(text: string): void {
		this.footerText.next(text);
	};

	/**
	 * Zobrazí dialog s možnostmi Ano/Ne a vrátí Promise který je resolvován zvolenou hodnotu true/false
	 * @param title
	 * @param message
	 */
	public showConfirmation(title: string, message: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this._modalService
				.openModal(new ConfirmModal(title, message))
				.onApprove(() => resolve(true))
				.onDeny(() => resolve(false));
		});
	}

	/**
	 * Zobrazí error hlášku.
	 * @param message
	 * @param title
	 * @param optionsOverride
	 */
	public showError(message: string, title?: string, optionsOverride?: IndividualConfig): ActiveToast<any> {
		return this._toastrService.error(message, title || 'Něco se nepovedlo', optionsOverride);
	}

	/**
	 * Zobrazí info hlášku.
	 * @param message
	 * @param title
	 * @param optionsOverride
	 */
	public showInfo(message: string, title?: string, optionsOverride?: IndividualConfig): ActiveToast<any> {
		return this._toastrService.info(message, title, optionsOverride);
	}

	/**
	 * Zobrazí loader
	 * @param text
	 */
	public showLoader(): void {
		this.loaderData.next({ show: true });
	}

	/**
	 * Zobrazí dialog se zprávou.
	 * @param title
	 * @param message
	 * @param modalType
	 */
	public showMessage(title: string, message: string, modalType: MessageModalType = MessageModalType.Html): Promise<any> {
		return new Promise((resolve, reject) => {
			this._modalService
				.openModal(new MessageModal(title, message, true, modalType))
				.onApprove(() => resolve(null))
				.onDeny(() => resolve(null));
		});
	}

	/**
	 * Zobrazí success hlášku.
	 * @param message
	 * @param title
	 * @param optionsOverride
	 */
	public showSuccess(message: string, title?: string, optionsOverride?: IndividualConfig): ActiveToast<any> {
        return this._toastrService.success(message, title, optionsOverride);
    }

	/**
	 * Zobrazí warning hlášku.
	 * @param message
	 * @param title
	 * @param optionsOverride
	 */
	public showWarning(message: string, title?: string, optionsOverride?: IndividualConfig): ActiveToast<any> {
        return this._toastrService.warning(message, title, optionsOverride);
    }

    /**
     * Vyscroluje na zacatek stranky
     * */
    public scrollTop(): void {
        document.querySelector('.kt-content').scrollTop = 0;
    }

}

export class LoaderData {
	show: boolean = false;
}