import { Injectable } from '@angular/core';
import { ModelService, ApiService, UIService } from '../../core';

/**
 * Poskytuje operace nad uživateli.
 */
@Injectable()
export class ContactsService extends ModelService {

	constructor(
        apiService: ApiService,
		uiService: UIService) {
        // Voláme konstruktor bázové třídy
        super('contact', apiService, uiService);
    }

    public getByIco(ico: string): Promise<any> {
        return this.apiService.get<any>(super.createUrl('get-by-ico/' + ico));
    }

    public checkIcExist(id: number, ico: string): Promise<boolean> {
        return this.apiService.get<boolean>(super.createUrl('ico-exist/' + id + '/' + ico));
    }

}