import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { DeviceProducerModule } from './';

// Pages
import { ListsDeviceProducerPageComponent } from './pages/device-producer-list';
import { ListsDeviceProducerDetailPageComponent } from './pages/device-producer-detail';

const routes: Routes = [
	{ path: '', component: ListsDeviceProducerPageComponent },
	{
		path: ':id',
		component: ListsDeviceProducerDetailPageComponent,
		canDeactivate: [CanDeactivateGuard]
	}
];

@NgModule({
	imports: [
		DeviceProducerModule,
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class DeviceProducerRoutingModule { }