import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { ListsFinalSolutionModule } from './';

// Pages
import { ListsFinalSolutionPageComponent } from './pages/final-solution-list';
import { ListsFinalSolutionDetailPageComponent } from './pages/final-solution-detail';

const routes: Routes = [
    { path: '', component: ListsFinalSolutionPageComponent },
    {
        path: ':id',
        component: ListsFinalSolutionDetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        ListsFinalSolutionModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ListsFinalSolutionRoutingModule { }