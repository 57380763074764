import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '../services/format';

@Pipe({
	name: 'ktDate'
})
export class DatePipe implements PipeTransform {

	constructor(
		private _formatService: FormatService) {
	}

	transform(value: Date): string {
		return this._formatService.date(value);
	}
}