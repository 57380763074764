import { Injectable } from '@angular/core';
import { ApiService } from './api';
import { ProjectEnumValue } from '../models';

/**
 * Služba poskytující project-enum funkcionalitu.
 * 
 * @export
 * @class ProjectEnumService
 */
@Injectable()
export class ProjectEnumService {

	private _defaultsCache: { [enumType: string]: number; } = {};
	private _cache: { [enumType: string]: Array<ProjectEnumValue>; } = {};

	constructor(
		private _apiService: ApiService) {
	}

	/**
	 * Vrátí ID defaultní hodnoty enumu daného typu
	 * @param enumType
	 */
	public getDefaultValueId(enumType: string): Promise<number> {
		// TODO: Předělat na načítání z _cache podle property isDefault
		return new Promise((resolve, reject) => {
			// Koukneme do cache
			let retval = this._defaultsCache[enumType];

			if (typeof (retval) == 'number') {
				resolve(retval);
			}

			this._apiService.get<number>('projectenum/' + enumType + '/default')
				.then(x => {
					this._defaultsCache[enumType] = x;
					resolve(x);
				});
		});
	}

	/**
	 * Vrací zadanou hodnotu daného enumu
	 * @param enumType
	 * @param value
	 */
	public getValue(enumType: string, value: string): Promise<ProjectEnumValue> {
		return new Promise((resolve, reject) => {
			// Beru hodnoty enumu
			this.getValues(enumType)
				.then(x => {
					// Filtruju podle hodnoty
					resolve(x.find(x => x.value == value));
				});
		});
	}

	/**
	 * Vrátí pole hodnot pro zadaný enum.
	 * @param enumType
	 */
	public getValues(enumType: string): Promise<Array<ProjectEnumValue>> {
		return new Promise((resolve, reject) => {
			// Koukneme do cache
			let retval = this._cache[enumType];

			if (typeof (retval) != 'undefined') {
				resolve(retval);
			}

			// Načítáme a ukládáme do cache
			this._apiService.get<Array<ProjectEnumValue>>('projectenum/' + enumType)
				.then(x => {
					this._cache[enumType] = x;
					resolve(x);
				});
		});
    }

    /**
     * Vrací zadanou hodnotu daného enumu. Hleda v textu
     * @param enumType
     * @param value
     */
    public getText(enumType: string, value: string): Promise<ProjectEnumValue> {
        return new Promise((resolve, reject) => {
            // Beru hodnoty enumu
            this.getValues(enumType)
                .then(x => {
                    // Filtruju podle hodnoty
                    resolve(x.find(x => x.text == value));
                });
        });
    }

}