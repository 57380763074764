import { Component, OnInit, ViewChild } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { ModelServiceFactory, IModelService, GridComponent, ModalService } from '../../core';
import { Router } from '@angular/router'

@Component({
	templateUrl: "/template/commissions/modals/serial-number-history.cshtml"
})
export class SerialNumberHistoryModalComponent implements OnInit {
	// Seriové číslo pro které zobrazuju zaznamy
	public serialNumber: string = null;

	/**
	 * Reference na grid komponentu
	 */
	@ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * Instance model service
	 */
	public modelService: IModelService;

	constructor(
		public modal: SuiModal<ISerialNumberHistoryModalComponentContext, ISerialNumberHistoryListModel, void>,
		private _router: Router,
		private _modelServiceFactory: ModelServiceFactory,
		private _modalService: ModalService) {

		this.modelService = _modelServiceFactory.createService('listCommissionSerialNumberHistory');
	}


	// Položky historie
	public history: Array<ISerialNumberHistoryListModel> = [];


	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
		this.serialNumber = this.modal.context.serialNumber;
		this.history = this.modal.context.history;
	}

	/**
	 * Převezme data ze zakázky defineované daným itemem
	 * @param item
	 */
	public take(item: ISerialNumberHistoryListModel): void {
		this.modal.approve(item);
	}

	/**
	 * Otevre detail zakazy v novem okne
	 * @param item
	 */
	public detail(item: ISerialNumberHistoryListModel): void {
		let urlTree = this._router.createUrlTree(['app', 'commissions', item.id]);
		let url = '#' + this._router.serializeUrl(urlTree);
		window.open(url, "_blank");
    }
}

export class SerialNumberHistoryModal extends ComponentModalConfig<ISerialNumberHistoryModalComponentContext, ISerialNumberHistoryListModel, void> {
	constructor(serialNumber: string, history: Array<ISerialNumberHistoryListModel>) {
		super(SerialNumberHistoryModalComponent, { serialNumber, history });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Large;
		this.mustScroll = true;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface ISerialNumberHistoryModalComponentContext {
	serialNumber: string | null;
	history: Array<ISerialNumberHistoryListModel>;
}
