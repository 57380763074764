import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { CommissionKindModule } from './';

// Pages
import { ListsCommissionKindPageComponent } from './pages/commission-kind-list';
import { ListsCommissionKindDetailPageComponent } from './pages/commission-kind-detail';

const routes: Routes = [
	{ path: '', component: ListsCommissionKindPageComponent },
	{
		path: ':id',
		component: ListsCommissionKindDetailPageComponent,
		canDeactivate: [CanDeactivateGuard]
	}
];

@NgModule({
	imports: [
		CommissionKindModule,
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class CommissionKindRoutingModule { }