import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UIService } from '../../core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';

@Component({
    templateUrl: "/template/commissions/modals/commission-is-edited.cshtml"
})
export class CommissionIsEditedModalComponent implements OnInit {
    public editors: string;
    constructor(
        private _router: Router,
        private _uiService: UIService,
        public modal: SuiModal<ICommissionIsEditedModalComponentContext, ICommissionIsEditedModalResult, void>) {

        this.editors = modal.context.editors.map(x => {
            return "<li>" + x + "</li>";
        }).join('');
    }

	/**
	 * Inicializace komponenty
	 */
    ngOnInit() {
    }


    public cancel(): void {
        this.modal.deny(null);
        this._uiService.hideLoader();
        this._router.navigateByUrl('/app/commissions');
    }

    public open(): void {
        this.modal.approve({ readOnly: false });
    }

    public openRead(): void {
        this.modal.approve({ readOnly: true });
    }
}

export class CommissionIsEditedModal extends ComponentModalConfig<ICommissionIsEditedModalComponentContext, ICommissionIsEditedModalResult, void> {
    constructor(editors: string[]) {
        super(CommissionIsEditedModalComponent, { editors: editors });

        this.isClosable = false;
        this.size = ModalSize.Normal;
        this.transitionDuration = 200;
    }
}

interface ICommissionIsEditedModalComponentContext {
    editors: string[];
}

export interface ICommissionIsEditedModalResult {
    readOnly: boolean;
}
