import {
    Component, ElementRef, Input, OnInit, ViewChild, Output,
    EventEmitter, ChangeDetectionStrategy, Renderer2
} from '@angular/core';
import 'tabulator-tables';
import { IModelService } from '../services/model-service';
import { UIService } from '../services/ui';
import { HelperService } from '../services/helper';
import { ListsService, IListSettingsHandler } from '../services/lists';
import { ListFilter, ListFilterEntry, List, ColumnDefinition, ColumnSettings, DataTableOption } from '../models';
import { Subscription } from 'rxjs';
import { PaginationComponent } from './pagination';
import Tabulator from 'tabulator-tables';
import { AppScopeService } from '../services/app-scope';
import { AuthService } from '../services/auth';

@Component({
    selector: '.kt-data-table',
    templateUrl: "/template/core/components/data-table.cshtml",
    exportAs: 'dataTable'
})
export class DataTableComponent implements OnInit {
    /*
     * Private fields
     */

    // Definice vzhledu a chování sloupců
    private _columnDefinitions: Array<ColumnDefinition>;

    // Vlastní filtr který bude posílán na server
    private _customFilter: any = {};

    // Jestli jsme v responsivním módu
    private _responsive: boolean = false;

    // Handler pro nastavení tohohle gridu
    private _settingsHandler: IListSettingsHandler;

    // Subskripce které je potřeba v destruktoru odhlásit
    private _subs: Array<Subscription> = [];

    // DataTable jQuery objekt
    private _table: any;

    // Jestli se aktualne nacitaji data
    private _loading: boolean = false;

    /*
     * Public fields
     */

    // Pole IDček zaškrtnutých řádků.
    public checkedIds: List<number> = new List<number>();

    // Getter vrací aktuální filtr
    public get filter(): ListFilter {
        if (!this._settingsHandler) {
            return new ListFilter();
        }

        return this._settingsHandler.filter;
    }

    // Pole dat načtený ze serveru
    public items: Array<any> = [];

    // Hledaný výraz
    public searchFor: string = '';

    /*
     * Input/Output
     */

    // Výchozí hodnota počtu položek na stránku
    @Input() initialPageSize: number = 100;

    // Výchozí hodnota nastavená pro vyhledávání
    @Input() initialSearchFor: string;

    // Umožní nastavit, jestli je tohle hlavní grid na stránce
    // - zobrazuje loader přes stránku
    // - nastavuje status bar
    @Input() isMainGrid: boolean = true;

    // Služba která poskytuje data
    @Input() modelService: IModelService;

    // Definice buttonů v posledním sloupci
    @Input() options: Array<DataTableOption>;

    // Umožní nastavit, jestli má být grid stránkovatelný
    @Input() pageable: boolean = true;

    // Umožní nastavit, jestli má být možnost zaškrtnout řádky
    @Input() rowCheckingEnabled: boolean = false;

    // Klíč pod kterým se ukládá nastavení
    @Input() settingsKey: string;

    // Událost spuštěna po načtení dat
    @Output() onDataLoaded: EventEmitter<any[]> = new EventEmitter();

    // Událost spuštěna když je kliknuto na název řádku
    @Output() onRowSelected: EventEmitter<any> = new EventEmitter();

    /*
     * Children
     */

    // Reference na div element ve kterém je tabulka
    @ViewChild('tableBody', { static: false }) tableBodyElement: ElementRef;

    // Reference na pager
    @ViewChild('pagination', { static: false }) pagination: PaginationComponent;

    get customFilter(): any {
        return this._customFilter;
    };
    set customFilter(customFilter: any) {
        this._customFilter = customFilter;
        this._loadData();
    };

    constructor(
        private _uiService: UIService,
        private _helperService: HelperService,
        private _listsService: ListsService,
        private _renderer: Renderer2,
        private _appScopeService: AppScopeService,
        private _authService: AuthService) {
    }

    /**
     * Iniializace komponenty 
     **/
    ngOnInit() {

        // Nastavím filtr key, pokud nebyl žádný zadaný
        if (!this.settingsKey) {
            this.settingsKey = this.modelService.route;
        }

        Promise
            .all([
                this._listsService.getSettingsHandler(this.settingsKey),
                this._listsService.getColumnDefinitions(this.modelService.route)
            ])
            .then(results => {
                this._settingsHandler = results[0];
                this._columnDefinitions = results[1];

                // Nastavíme výchozí, nebo uloženou hodnotu pro hledání
                if (this.initialSearchFor) {
                    this.searchFor = this.initialSearchFor;
                    this._setSearchFor();
                }
                else {
                    this.searchFor = this._settingsHandler.filter.searchFor;
                }

                // Vychozi pocet polozek na stranku
                if (this.initialPageSize) {
                    this._setPageSize(this.initialPageSize);
                }

                // Zpracování událost o změně výrazu pro hledání apod.
                // Schválně je až za nastavením searchFor
                this._subs.push(this._settingsHandler.onFilterChanged.subscribe(this._loadData.bind(this)));
                this._subs.push(this._settingsHandler.onPageChanged.subscribe(this._loadData.bind(this)));
                this._subs.push(this._settingsHandler.onSortingChanged.subscribe(this._loadData.bind(this)));

                // Zpracovává události o změně sloupců
                this._subs.push(this._settingsHandler.onColumnsChanged.subscribe(this._setColumnsVisibility.bind(this)));

                this._setResponsive();
                this._initDataTable();
                this._loadData();
            });

        this._subs.push(this._uiService.onResized.subscribe(x => {
            if (this._setResponsive() && this._settingsHandler /* Voláno při refreshi a tohle není ještě nastaveno */) {
                let columns = this._buildColumns();
                this._table.setColumns(columns);
            }
        }));
    }

	/**
	 * Smaže vyhledávaný výraz
	 */
    public clearSearchFor() {
        this.searchFor = '';
        this._setSearchFor();
    }

	/**
	 * Vrací info jestli se má zobrazit entry pro vyhledaný text 
	 */
    public displaySearchForEntry(): boolean {
        return !!this.filter.searchFor;
    }

	/**
	 * Spustí export do excelu 
	 **/
    public exportToExcel(): void {
        if (this.isMainGrid) {
            this._uiService.showLoader();
        }

        let filter = this._buildListFilter();

        this.modelService
            .listToExcel(this._settingsHandler.sorting, filter)
            .then(
                x => {
                    if (this.isMainGrid) {
                        this._uiService.hideLoader();
                    }
                },
                reason => {
                    if (this.isMainGrid) {
                        this._uiService.hideLoader();
                    }
                }
            );
    }

	/**
	 * Obsluhuje událost keypress na vyhledávacím inputu
	 * @param event
	 */
    public onSearchKeyPress(event: any) {
        if (event.charCode == 13) {
            this._setSearchFor();
        }
    }

	/**
	 * Odebere zadanou položku z filtru
	 * @param entry
	 */
    public removeFilterEntry(entry: ListFilterEntry) {
        this._settingsHandler.removeFilterEntry(entry);
    }

	/**
	 * Spustí hledání
	 */
    public search() {
        this._setSearchFor();
    }

	/**
	 * Vrací info jestli je vyhledání povoleno.
	 */
    public searchIsEnabled(): boolean {
        return this.searchFor && this.searchFor.length > 2;
    }

    /**
     * Sestaví definici sloupců pro tabulator plugin 
     **/
    private _buildColumns(): Array<any> {
        let settings = this._listsService.buildColumnsSettings(this._columnDefinitions, this._settingsHandler.columns);

        // Připravím definici sloupců
        let columns: Array<any> = this._columnDefinitions.map((x, idx) => {
            let columnSettings: ColumnSettings = settings.find(y => y.field == x.field);

            let retval = {
                title: x.title,
                field: x.field,
                frozen: x.isFixed,
                sorter: NullSorter, // Nechceme aby tabulka řadila sama
                visible: this._isColumnVisible(columnSettings, x)
            };

            if (x.formatter) {
                this._setUpColumn(retval, x.formatter, x);
            }
            else if (idx == 0 && this.onRowSelected.observers.length > 0) {
                this._setUpColumn(retval, 'rowSelect', x);
            }
            else {
                this._setUpColumn(retval, 'default', x);
            }

            if (x.width) {
                retval['width'] = x.width;
            }

            return retval;
        });

        // Přidám checkbox sloupec
        if (this.rowCheckingEnabled) {
            columns.splice(0, 0, {
                formatter: 'rowSelection',
                titleFormatter: 'rowSelection',
                hozAlign: 'center',
                headerSort: false,
                frozen: this._columnDefinitions[0].isFixed,
                headerHozAlign: 'center',
                width: 50
            });
        }

        // Přidat options sloupec
        if (this.options && this.options.length > 0) {
            columns.push({
                formatter: (cell, params, onRendered) => {
                    let optionsElement = this._renderer.createElement('span');
                    let scope = this._appScopeService.currentScope;

                    this.options.forEach(option => {
                        // Zkontroluju práva
                        if (!this._authService.hasAnyAppScopePermission(scope, option.permission, null)) {
                            return;
                        }

                        let iconElement = this._renderer.createElement('i');
                        this._renderer.addClass(iconElement, 'icon');
                        this._renderer.addClass(iconElement, option.icon)

                        let buttonElement = this._renderer.createElement('button');
                        this._renderer.appendChild(buttonElement, iconElement);
                        this._renderer.addClass(buttonElement, 'ui');
                        this._renderer.addClass(buttonElement, 'tiny');
                        this._renderer.addClass(buttonElement, 'icon');
                        this._renderer.addClass(buttonElement, 'button');

                        if (option.className) {
                            option.className.split(' ').forEach(className => {
                                this._renderer.addClass(buttonElement, className);
                            })
                        }

                        if (option.tooltip) {
                            this._renderer.setAttribute(buttonElement, 'data-tooltip', option.tooltip);
                            this._renderer.setAttribute(buttonElement, 'data-position', 'left center');
                            this._renderer.setAttribute(buttonElement, 'data-inverted', '');
                        }

                        this._renderer.listen(buttonElement, 'click', event => {
                            option.onClick(cell.getRow().getData());
                        });

                        this._renderer.appendChild(optionsElement, buttonElement);
                    });

                    return optionsElement;
                },
                headerSort: false,
                width: 50,
                cssClass: 'options'
            });
        }

        return columns;
    }

	/**
	 * Vytvoří filtr pro seznam
	 **/
    private _buildListFilter() {
        // Dělám kopii filtru
        var filter = this._helperService.deepClone(this._settingsHandler.filter);

        // Přidám custom filter
        if (this.customFilter) {
            for (var p in this.customFilter) {
                if (this.customFilter.hasOwnProperty(p)) {
                    filter.entries.push({
                        optionCode: p,
                        value1: this.customFilter[p],
                        value2: null
                    });
                }
            }
        }

        // Viditelne sloupce
        filter.columns = this._settingsHandler.columns;

        // Zatrhnute radky
        filter.checkedIds = this.checkedIds.items;

        return filter;
    }

    /**
     * Inicializuje data table jQuery plugin 
     **/
    private _initDataTable(): void {
        let columns = this._buildColumns();

        this._table = new Tabulator(this.tableBodyElement.nativeElement, {
            dataSorting: (sorters: Array<Sorter>) => {
                if (this._loading) {
                    return;
                }

                if (sorters.length == 0) {
                    return;
                }

                this._settingsHandler.setSortBy(sorters[0].field);
            },
            rowSelectionChanged: (data: Array<any>, rows: Array<any>) => {
                if (this._loading) {
                    return;
                }

                this.checkedIds.items = data.map(x => <number>x.id);
            },
            height: 500, // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
            data: [],
            layout: 'fitDataStretch',
            resizableColumns: false,
            columns: columns,
            rowFormatter: row => {
                if (this.rowCheckingEnabled) {
                    row.getElement().classList.add('row-check');
                }
            }
        });
    }

    /**
     * Varcí true/false jestli má být sloupec viditelný 
     **/
    private _isColumnVisible(columnSettings: ColumnSettings, columnDefinition: ColumnDefinition): boolean {

        if (this._responsive && !columnDefinition.isVisibleInResponsive) {
            return false;
        }

        if (!columnSettings) {
            return true;
        }

        return !columnSettings.isHidden;
    }

    /**
	 * Načte data z API
	 */
    private _loadData(): void {
        // Může být voláno na začátku, když ještě není služba inicializována
        if (!this.modelService) {
            return;
        }

        this._loading = true;

        if (this.isMainGrid) {
            this._uiService.showLoader();
        }

        let filter = this._buildListFilter();
        let page = this.pageable ? this._settingsHandler.page : null;

        this.modelService
            .list<any>(page, this._settingsHandler.sorting, filter)
            .then(
                x => {
                    this.items = x.items;

                    if (this.pageable) {
                        this.pagination.totalCount = x.totalCount;

                        if (this.isMainGrid) {
                            let footerText = x.totalCount == 0 ? 'Žádné položky nenalezeny' : `Zobrazeno ${x.items.length} z ${x.totalCount} položek`;
                            this._uiService.setFooterText(footerText);
                        }
                    }

                    this.onDataLoaded.next(x.items);

                    this._table.setData(x.items);

                    this._updateRowsChecks();

                    if (this.isMainGrid) {
                        this._uiService.hideLoader();
                    }

                    this._loading = false;
                },
                reason => {
                    this._uiService.showError('Nepodařilo se načíst seznam');

                    this.items = [];

                    this._table.clearData();

                    if (this.isMainGrid) {
                        this._uiService.hideLoader();
                    }

                    this._loading = false;
                });
    }

    /**
     * Skryje/zobrazí sloupce
     * @param settings
     */
    private _setColumnsVisibility(settings: Array<ColumnSettings>): void {
        this._table.getColumns()
            .forEach(column => {
                let field = column.getField();
                let columnSettings: ColumnSettings = settings.find(x => x.field == field);
                let columnDefinition: ColumnDefinition = this._columnDefinitions.find(x => x.field == field);

                if (this._isColumnVisible(columnSettings, columnDefinition)) {
                    column.show();
                }
                else {
                    column.hide();
                }
            });
    }

    /**
     * nastaveí jestli jsme nebo ne v responsivním módu 
     **/
    private _setResponsive(): boolean {
        let responsive = window.innerWidth <= 768;

        if (this._responsive != responsive) {
            this._responsive = responsive;

            return true;
        }

        return false;
    }

    /**
     * Nastaví vše potřebné pro konkrétní formát sloupce
     * @param format
     */
    private _setUpColumn(column: any, format: string, columnDefinition: ColumnDefinition): void {
        switch (format) {
            case 'rowSelect':
                column['formatter'] = (cell, params, onRendered) => {
                    return '<a>' + cell.getValue() + '</a>';
                };
                column['cellClick'] = (e, cell: any) => {
                    this.onRowSelected.emit(cell.getRow().getData());
                };
                break;

            case 'commissionRowSelect':
                column['formatter'] = (cell, params, onRendered) => {
                    let retval: string = '<a>' + cell.getValue() + '</a>';
                    let data = cell.getRow().getData();

                    if (data.markerColor) {
                        retval += '<span class="marker" style="background-color: ' + data.markerColor + '"> </span>';
                    }

                    return retval;
                };
                column['cellClick'] = (e, cell: any) => {
                    this.onRowSelected.emit(cell.getRow().getData());
                };
                break;

            case 'default':
                // Zatim nepouzito
                break;
        }
    }

    /**
     * Nastaví vychozi pocet radku na stranku
     **/
    private _setPageSize(rowCount: number): void {
        this._settingsHandler.setPageSize(rowCount);
    }

	/**
	 * Nastaví hledaný string do handleru nastavení -> událost pak přenačte data
	 **/
    private _setSearchFor(): void {
        this._settingsHandler.setSearchFor(this.searchFor);
    }

    /**
     * Aktualizuje zaškrtnutí u řádků/hlavičky podle toho jestli jsou vybrané 
     **/
    private _updateRowsChecks(): void {

        let selectedRows: Array<number> = [];

        this._table.getRows()
            .forEach(row => {
                if (this.checkedIds.contains(row.getData().id)) {
                    selectedRows.push(row.getIndex());
                }
            });

        this._table.selectRow(selectedRows);
    }
}

class Sorter {
    column: any;
    dir: string;
    field: string;
}

function NullSorter(): number {
    return 0;
}