import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';

/**
 * Nastavení aplikace
 */
@Injectable()
export class AppSettings {
	public maxFileSize: number = 20000000;
	public vatValue: number = 21;
	public appNotifications: any = {
		url: '/app-notifications',
		reconnectAfterSeconds: 5,
		logLevel: signalR.LogLevel.Warning
	};
}