import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent } from '../../../core';
import { UsersService } from '../../services/users';


@Component({
	templateUrl: "/template/settings/user/pages/user-list.cshtml"
})
export class UserListPageComponent {
	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	constructor(
		private _router: Router,
        private _uiService: UIService,
        public usersService: UsersService) {
	}

	/**
	 * Redirekt na stránku pro přidání.
	 */
    add() {
		this._router.navigateByUrl('/app/settings/users/0');
	}

	/**
	 * Smazání
	 * @param user
	 */
	delete(user: any) {
		this._uiService
			.showConfirmation('Odstranění uživatele', `Opravdu si přejete odstranit uživatele ${user.fullName}?`)
            .then(result => {
                if (result) {
                    this._uiService.showLoader();

                    this.usersService.delete(user.id).then(
                        result => {
                            this._uiService.hideLoader();
                            this._grid.loadData();
                        });
                }
			});
    }

	/**
	 * Redirekt na stránku s editací
	 * @param user
	 */
    edit(user: any) {
		this._router.navigateByUrl(`/app/settings/users/${user.id}`);
	}
}