import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIService, ModelFormComponentBase, HelperService, AppScopeService, AuthService, ValuesService } from '../../../core';
import { ListsPriceListService } from '../services/lists-price-list';

@Component({
    templateUrl: "/template/lists/price-list/pages/price-list-detail.cshtml"
})
export class ListsPriceListDetailPageComponent extends ModelFormComponentBase {
    // drží 'index' naposledy přidaného dílu
    private _marginsIdx: number;
    private _isDefault: boolean;

    constructor(
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
        protected _appScopeService: AppScopeService,
        protected _router: Router,
        private _valuesService: ValuesService,
		protected _listsPriceList: ListsPriceListService) {
		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_listsPriceList,
			_router);
	}

    /**
	 * Override metody v ModelFormComponentBase - potřebujeme inicializovat margins
	 * @param model
	 */
    protected onModelLoaded(model: any) {
        this._isDefault = model.isDefault;
        // musíme na každou marži nastavit interní property, která drží unikátní hodnotu
        // a uložíme si hodnotu
        model.margins = model.margins.map(function(x, i) {
            x.$i = i;
            return x;
        });
        this._marginsIdx = model.margins.length;
    }

    /**
     * Override metody v ModelFormComponentBase - po vložení redirektujeme na editaci
     * @param model
     */
    protected onModelSaved(model: any): void {
        this._valuesService.clearCacheItem("PriceList");
        if (this.modelId === 0) {
            this._router.navigate(['app', 'list', 'pricelists', model.id], {});
        }
    }

	/**
	 * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
	 */
	getTitle() {
        return this.modelId === 0 ? 'Nová cenová hladina dílů' : 'Editace cenové hladiny dílů';
    }

    marginsOk(margins: Array<any>): boolean {        
        for (var i = 0; i < margins.length; i++) {
            if (margins[i].from > margins[i].to) {
                return false;
            }
        }

        return true;
    }

    isDefault(): boolean {
        console.log(this._isDefault);
        return this._isDefault;
    }
}