import { Directive, Input, OnDestroy } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[sameValueAs]',
	providers: [{ provide: NG_VALIDATORS, useExisting: SameValueAsValidatorDirective, multi: true }]
})
export class SameValueAsValidatorDirective implements Validator, OnDestroy {
	@Input('sameValueAs') otherProperty: string;

	private _control: AbstractControl = null;
	private _otherControl: AbstractControl = null;
	private _valueChangeSubscription: Subscription = null;

	/**
	 * Provede validaci controlu
	 * @param control
	 */
	validate(control: AbstractControl): { [key: string]: any } {
		this._control = control;

		// ziskame instanci controlu, se kterým porovnáváme
		if (this._otherControl == null) {
			var formGroup = <FormGroup>control.parent;

			this._otherControl = formGroup.controls[this.otherProperty];

			if (!this._otherControl) {
				throw `Unable to validate same value as ... Other field '${this.otherProperty}' was not found.`;
			}

			// prihlasim se k odberu info o zmene hodnoty v other control - chceme prevalidovat tenhle control
			this._valueChangeSubscription = this._otherControl.valueChanges.subscribe(() => {
				this._control.updateValueAndValidity();
			});
		}

		if (this._otherControl.value == control.value) {
			return null;
		}

		return {
			sameValueAs: {
				value: control.value
			}
		};
	}

	/**
	 * Destroy direktivy - odhlášení z oběru událostí
	 */
	ngOnDestroy() {
		if (this._valueChangeSubscription != null) {
			this._valueChangeSubscription.unsubscribe();
		}
	}
}