import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UIService, GridComponent, IModelService, ModelServiceFactory, ValuesService } from '../../../core';

@Component({
	templateUrl: "/template/lists/commission-types/pages/commission-type-list.cshtml"
})
export class ListsCommissionTypePageComponent {
	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * Instance model service
	 */
	public modelService: IModelService;

	constructor(
		private _router: Router,
        private _uiService: UIService,
        private _valuesService: ValuesService,
		private _modelServiceFactory: ModelServiceFactory) {
		this.modelService = _modelServiceFactory.createService('listCommissionType');
	}

	/**
	 * Redirekt na stránku pro přidání.
	 */
    add() {
        this._router.navigateByUrl('/app/list/commission-types/0');
	}

	/**
	 * Smazání
	 * @param commissiontype
	 */
	delete(commissiontype: ICommissionTypeListModel) {
		this._uiService
			.showConfirmation('Odstranění záznamu', `Opravdu si přejete odstranit záznam ${commissiontype.name}?`)
            .then(result => {
                if (result) {
                    this._uiService.showLoader();

					this.modelService.delete(commissiontype.id, null).then(
                        result => {
                            this._uiService.hideLoader();
                            this._grid.loadData();
                            this._valuesService.clearCacheItem("CommissionType");
                        });
                }
			});
    }

	/**
	 * Redirekt na stránku s editací
	 * @param commissiontype
	 */
    edit(commissiontype: ICommissionTypeListModel) {
        this._router.navigateByUrl(`/app/list/commission-types/${commissiontype.id}`);
	}
}