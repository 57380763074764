import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommissionViewModel } from '../services/commission-view-model';
import { Subscription, combineLatest } from 'rxjs';
import {
    UIService,
    SelectOption,
    ValuesService,
    CommissionPhase,
    PrintingService,
    ModalService,
    AuthService,
    AppNotificationsService,
    ICanDeactivateComponent
} from '../../core';
import { NewCommissionCancelationModal, CancelationChoice } from '../modals/new-commission-cancelation';
import { SaveCommissionModalResult, SaveCommissionModal } from '../modals/save-commission';
import { FormGroup } from '@angular/forms';
import { CommissionsService } from '../services/commissions';
import { CommissionIsEditedModal, ICommissionIsEditedModalResult } from '../modals/commission-is-edited'
import { HotkeysService, Hotkey } from 'angular2-hotkeys';

@Component({
    templateUrl: "/template/commissions/pages/commission-detail.cshtml",
    providers: [
        // Pro každé zobrazení detailu zakázky je nová instance view modelu
        CommissionViewModel
    ]
})
export class CommissionDetailPageComponent implements OnInit, OnDestroy, ICanDeactivateComponent {
    private _subs: Array<Subscription> = [];
    private _hotkeys: Array<Hotkey> = [];
    private _suspendChangedCheck: boolean = false;

    // Id intervalu pro notifikace o otevření zakázky
    private _editPingInterval: number;

    // Id platby - pokud jsme přišli z platby přes terminál SumUp
    // tak je tady ID uhrazené platby
    private _fromPayment: number = null;

    // Formulář se zakázkou
    public get form(): FormGroup {
        return this._viewModel.form;
    };

    // Commission view model
    public get model(): ICommission {
        return this._viewModel.model;
    };

    // Vrací info, jestli je zakázka změněna
    public get isChanged(): boolean {
        return this._viewModel.isChanged();
    }

    // Vrací info jestli má nějaký neuhrazený požadavek
    public get hasUnpaidPayment(): boolean {
        return this._viewModel.hasUnpaidPayment();
    };

    // Číslo aktuálního tabu
    public tab: number = 1;

    // Info jestli jde o novou zakázku
    public isNew: boolean;

    // Info, jestli byl view model inicializován (data byly načteny)sa
    public initialized: boolean = false;

    // Info, jestli zakázku edituje více uživatelů najednou
    public editMultiUser: boolean = false;

    // Seznam uživatelů, kteří aktuálně editují zakázku
    public multiUserEditors: string = "";

    // Title zakázky
    public commissionTitle: string = null;

    // Pole pro opions techniků
    public commissionPrevOptions: Array<SelectOption> = [];
    public commissionNextOptions: Array<SelectOption> = [];

    // Sections visibility
    public generalSectionVisible: boolean = true;
    public priceProposalSectionVisible: boolean = true;
    public inhomeSectionVisible: boolean = true;
    public executionSectionVisible: boolean = true;

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _viewModel: CommissionViewModel,
        private _uiService: UIService,
        private _printingService: PrintingService,
        private _modalService: ModalService,
        private _commissionService: CommissionsService,
        private _appNotificationService: AppNotificationsService,
        private _authService: AuthService,
        private _hotkeysService: HotkeysService,
        private _valuesService: ValuesService,
        private _zone: NgZone) {
    }

	/**
	 * Inicializace komponenty 
	 */
    ngOnInit() {

        // Pro načtení zobrazujeme hlavní loader
        this._uiService.showLoader();

        // Potřebuju dostat do jedné subskripce paramMap i queryParamMap
        let params = this._activatedRoute.paramMap;
        let queryParams = this._activatedRoute.queryParamMap;

        this._subs.push(
            combineLatest(params, queryParams)
                .subscribe(results => {
                    let params = results[0];
                    let queryParams = results[1];

                    let id = parseInt(params.get('id'));

                    if (isNaN(id)) {
                        throw 'Invalid or missing commission identifier.';
                    }

                    if (id > 0) {
                        this._commissionService.canEditCommission(id).then((result) => {
                            if (!result) {
                                // Zakázka je již editována někým jiným.
                                this._getEditors(id).then((editors) => {
                                    this._modalService.openModal(new CommissionIsEditedModal(editors)).onApprove((result: ICommissionIsEditedModalResult) => {
                                        // Pokračuzji na detail zakázky a chci ji editovat/pro čtení.                                    
                                        if (result.readOnly) {
                                            // Pokud je zakázka pro čtení, neodesílám notifikaci o otevření.
                                            this._initialize(id, false);

                                        } else {
                                            this._commissionService.openCommission(id).then(() => {
                                                this._setCommissionOpenNotification(id);
                                                this._initialize(id, true);
                                            });

                                        }
                                    });
                                });
                            } else {
                                // Zakázka není nikým editována, inicializuji pro editaci.
                                this._commissionService.openCommission(id).then(() => {
                                    this._initialize(id, true);
                                    this._setCommissionOpenNotification(id);
                                    this._setCommissionKeyboardShortcuts();
                                });
                            }
                        }, reason => {
                            this._redirectToList('Při načítání zakázky došlo k chybě.');
                        }).catch(() => {
                            this._redirectToList('Při načítání zakázky došlo k chybě.');
                        });
                    } else {
                        this._initialize(id, true);
                    }

                    this._subs.push(
                        this._appNotificationService.onCommissionOpen.subscribe((notification: ICommissionOpenAppNotification) => {
                            if (isNaN(id) || notification.commissionId != id) {
                                return;
                            }
                            setTimeout(() => { this._showEditors(id) }, 500);
                        })
                    );

                    // Pokud jdeme z platby přes terminál - chceme tisknout účtenku
                    this._fromPayment = <number>queryParams['fromPayment'];
                })
        );
    }

	/**
	 * Destroy komponenty 
	 **/
    ngOnDestroy() {
        // Unsubscribe z událostí
        this._subs.forEach(x => x.unsubscribe());
        clearInterval(this._editPingInterval);
        this._hotkeys.forEach(x => this._hotkeysService.remove(x));
    }

    /**
     * Vrací true/false jestli může být komponenta deaktivována
     */
    canDeactivate(): any {
        if (this._suspendChangedCheck) {
            this._suspendChangedCheck = false;
            return true;
        }

        if (this._viewModel == null || !this._viewModel.isChanged()) {
            return true;
        }

        return this._uiService.showConfirmation('Opravdu odejít?', 'Neuložil/a jste změny. Přejete si přesto odejít?');
    }

	/**
	 * Kliknutí na Zavřít 
	 **/
    public close(): void {
        // Pro novou zakázku zobrazuji modal s dotazem
        if (this.isNew && this._viewModel.serialNumberInitialized) {
            this._modalService.openModal(new NewCommissionCancelationModal())
                .onApprove((result: CancelationChoice) => {
                    switch (result) {
                        case CancelationChoice.cancel:
                            break;

                        case CancelationChoice.doNotCreate:
                            this._suspendChangedCheck = true;
                            this._router.navigateByUrl('/app/commissions');
                            break;

                        case CancelationChoice.saveAsConcept:
                            this.saveAsConcept();
                            break;

                        default:
                            throw 'Unknown cancelation choice ' + result;
                    }
                });

            return;
        }

        this._viewModel.close(this._viewModel.model.id);
        this._router.navigateByUrl('/app/commissions');
    }

	/**
	 * Spustí tisk potvrzení přijetí
	 **/
    public printCommissionReceipt(): void {
        this._printingService.print('commission-receipt', { 'cid': this._viewModel.model.id });
    }

	/**
	 * Spustí tisk potvrzení přijetí - přenosná
	 **/
    public printCommissionReceipt80(): void {
        this._printingService.printToThermalPrinter('commission-receipt-80', { 'cid': this._viewModel.model.id });
    }

	/**
	 * Spustí tisk zakázkového listu
	 * @param type
	 */
    public printCommissionSheet(type: number): void {
        this._printingService.print('commission-sheet', { 'cid': this._viewModel.model.id, 'type': type });
    }

	/**
	 * Spustí tisk zakázkového listu - přenosná
	 * @param type
	 */
    public printCommissionSheet80(type: number): void {
        this._printingService.printToThermalPrinter('commission-sheet-80', { 'cid': this._viewModel.model.id, 'type': type });
    }

	/**
	 * Provede uložení zakázky.
	 * @param continueToNew
     * @param eventIdSet - predvolena hodnota
	 */
    public save(continueToNew: boolean, eventIdSet: number): void {

        // Jen pro jistotu ...
        if (this.saveIsDisabled()) {
            return;
        }

        if (this.isNew) {
            // NOVÁ ZAKÁZKA
            this._uiService.showLoader();

            this._viewModel.save().then(
                id => {
                    this._uiService.hideLoader();
                    this._uiService.scrollTop();

                    if (continueToNew) {
                        // Inicializuju view model pro novou zakázku
                        this._initialize(0, true);
                    }
                    else {
                        // V téhle chvíli nechceme kontrolovat, jestli se něco změnilo.
                        this._suspendChangedCheck = true;
                        this._router.navigateByUrl('/app/commissions/' + id);
                    }
                },
                reason => {
                    this._uiService.hideLoader();
                });

            return;
        }

        // EDITACE ZAKÁZKY
        // Pro editaci zakázky zobrazujeme modal pro zadání události
        this._modalService.openModal(new SaveCommissionModal(this._viewModel.model, eventIdSet, this._viewModel.pricing.summary))
            .onApprove((saveCommissionModalResult: SaveCommissionModalResult) => {
                this._viewModel.model.eventId = saveCommissionModalResult.eventId;
                this._viewModel.model.eventComment = saveCommissionModalResult.eventComment;

                this._uiService.showLoader();

                this._viewModel.save().then(
                    id => {
                        this._uiService.hideLoader();
                        this._uiService.scrollTop();


                        if (saveCommissionModalResult.continue) {
                            // Inicializuju view model pro stejnou zakázku
                            this._initialize(id, true);
                        }
                        else {
                            this._viewModel.close(id);

                            this._suspendChangedCheck = true;

                            this._router.navigateByUrl('/app/commissions');
                        }
                    },
                    reason => {
                        this._uiService.hideLoader();
                    });
            });
    }

	/**
	 * Uloží zakázku jako koncept. 
	 **/
    public saveAsConcept(): void {
        this._uiService.showLoader();

        // Nastavím fázi na Koncept
        this._viewModel.model.phase = CommissionPhase.Concept;

        this._viewModel.save().then(
            id => {
                this._uiService.hideLoader();
                this._viewModel.close(this._viewModel.model.id);
                this._router.navigateByUrl('/app/commissions');
            },
            reason => {
                this._uiService.hideLoader();
            });
    }

    /**
     * Vrací info jestli je uložení zakázáno 
     **/
    public saveIsDisabled(): boolean {
        return this._viewModel.controlsState.buttonIsDisabled('save');
    }

    /**
	 * Zpracovává změnu hodnoty v typu zákroku
	 * @param commissionId
	 * @param index
	 */
    public onCommissionNextPrevValueChange(commissionId: number, index: number): void {
        window.open(`/#/app/commissions/${commissionId}`, "_blank");
    }

    /**
     * Načtení seznamu uživatelů, kteří momentálně editují zakázku
     * @param id
     */
    private _showEditors(id: number) {
        this._getEditors(id).then(editors => {
            this.multiUserEditors = editors.join(", ");
        });
    }

    private _getEditors(id: number): Promise<string[]> {
        return this._commissionService.getEditors(id).then((editors) => {
            let otherEditors = editors.filter(x => x.userId != this._authService.currentUser.id);

            if (otherEditors.length > 0) {
                this.editMultiUser = true;
            } else {
                this.editMultiUser = false;
                this.multiUserEditors = "";
            }

            return otherEditors.map(x => {
                return x.userName + " (od " + x.date.toLocaleString('cs-CZ') + ")";
            });
        }).catch(reason => {
            return [];
        });
    }

    /**
     * Pokud jsme přišli z platby přes terminál, pak chceme tisknout účtenku 
     **/
    private _checkPaymentPrint(): void {

        if (!this._fromPayment) {
            return;
        }

        // Zkouším, jestli už je platba spárovaná
        let payment = this._viewModel.model.payments.find(x => x.id == this._fromPayment);
        if (!payment) {
            return;
        }

        if (payment.paymentState == CommissionPaymentState.Paired) {
            this._commissionService.printPaymentInvoice80(payment);
        }
        else {
            this._subs.push(
                this._appNotificationService.onCommissionPaymentPaired.subscribe((notification: ICommissionPaymentPairedAppNotification) => {
                    if (this._fromPayment == notification.commissionPaymentId) {
                        let payment = this._viewModel.model.payments.find(x => x.id == this._fromPayment);
                        if (!payment) {
                            return;
                        }

                        this._commissionService.printPaymentInvoice80(payment);
                    }
                })
            );
        }
    }

	/**
	 * Inicializuje formulář pro zakázku
	 * @param id
     * @param forEdit Inicializovat detail pro editaci/readOnly
	 */
    private _initialize(id: number, forEdit: boolean = true): void {
        this._uiService.showLoader();

        this._viewModel.init(id, !forEdit).then(
            x => {
                this.isNew = this._viewModel.isNew;
                this.commissionTitle = this._viewModel.model.number == "" ? this._viewModel.model.phaseName : this._viewModel.model.number;

                this.initialized = true;

                this.generalSectionVisible = !this._viewModel.isNew;
                this.priceProposalSectionVisible = !this._viewModel.isNew;
                this.inhomeSectionVisible = !this._viewModel.isNew;
                this.executionSectionVisible = !this._viewModel.isNew;

                this._checkPaymentPrint();

                this._uiService.hideLoader();

                if (!this.isNew) {
                    this._showEditors(id);
                    this._setCommissionNextPrevOptions(id);
                }
            },
            reason => {
                this._redirectToList('Při načítání zakázky došlo k chybě.');
            });
    }

    /**
     * Přesměrování na seznam zakázek
     * @param errorMsg Chybová zpráva, pokud je
     */
    private _redirectToList(errorMsg: string = "") {
        if (errorMsg.length > 0) {
            this._uiService.showError(errorMsg);
        }

        this._uiService.hideLoader();
        this._router.navigateByUrl('/app/commissions');
    }

    /** Nastaví interval na notifikaci o otevřené zakázce
     * @param id Id zakázky
     * */
    private _setCommissionOpenNotification(id: number): void {
        this._commissionService.pingCommission(id);
        return;
        // Nechceme spouštět ChangeDetection
        this._zone.runOutsideAngular(() => {
            this._editPingInterval = <any>setInterval(() => {
                this._commissionService.pingCommission(id);
            }, 2500);
        });
    }

    /**
     * Nastaveni klavesovych zkratek pro detail zakazky 
     * */
    private _setCommissionKeyboardShortcuts(): void {
        let self = this;

        //automaticky definovane v nastaveni
        this._commissionService.getShortcut()
            .then(shortcuts => {
                let keyService = this._hotkeysService;
                let modalService = this._modalService;
                let base = this;

                shortcuts.forEach(function (shortcut) {
                    let key = new Hotkey(shortcut.shortcut, (event: KeyboardEvent): boolean => {
                        if (shortcut.isEvent) {
                            if (!base.isNew) {
                                if (modalService.openModalsCount() > 0) {
                                    return false;
                                }

                                base.save(true, shortcut.eventId);
                            }
                        }

                        if (shortcut.isPrint) {
                            if (shortcut.eventId === 30) {
                                base.printCommissionReceipt();
                            } else {
                                base.printCommissionSheet(shortcut.eventId);
                            }
                        }
                        return false; // Prevent bubbling
                    }, ['INPUT', 'TEXTAREA', 'SELECT']);


                    keyService.add(key);
                    self._hotkeys.push(key);
                })
            }, reason => {
                console.log('reason');
            }).catch((ex) => {
                console.log('catch', ex);
            });
    }

    /**
     * Načtení seznamů předchozích a dalších zakázek na základě stejného výrobního čísla v zakázce.
     * @param id Id zakázky
     */
    private _setCommissionNextPrevOptions(id: number): void {
        this._valuesService.getExtendedValues<ICommissionForDeviceValueModel>(`CommissionForDevice?id=${id}&older=true`)
            .then(x => {
                this.commissionPrevOptions = x
                    .sort((a, b) => { return <any>b.dateOfReceipt - <any>a.dateOfReceipt })
                    .reverse()
                    .map(x => new SelectOption(<any>x.value, x.text));
            });

        this._valuesService.getExtendedValues<ICommissionForDeviceValueModel>(`CommissionForDevice?id=${id}&newer=true`)
            .then(x => {
                this.commissionNextOptions = x
                    .sort((a, b) => { return <any>b.dateOfReceipt - <any>a.dateOfReceipt })
                    .map(x => new SelectOption(<any>x.value, x.text));
            });
    }

    /**
     * Prijeti eventu z podrizene komponenty
     * @param eventId
     */
    private onNotifyCommissionDetail(eventId: number): void {
        this.save(true, eventId);
    }
}