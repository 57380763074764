import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommissionViewModel } from '../services/commission-view-model';
import { SectionComponentBase } from '../classes/section-component-base';
import { FormGroup } from '@angular/forms';
import { DataStorageService } from '../../core';

/**
 * Sekce Obecné
 */

@Component({
	selector: 'div.kt-general-section',
	templateUrl: "/template/commissions/components/general-section.cshtml"
})
export class GeneralSectionComponent extends SectionComponentBase implements OnInit, OnDestroy {

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._viewModel.form;
	}

	// Jestli je zakázka správána
	public get isPaired(): boolean {
		return this._viewModel && this._viewModel.model.isPaired;
	}

	constructor(
		private _viewModel: CommissionViewModel,
		protected _dataStorageService: DataStorageService) {

		super(_dataStorageService, 'general');
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	/**
	 * Destroy komponenty 
	 **/
	ngOnDestroy() {
	}
}