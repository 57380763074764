import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '../../core';
import { ListsFeesModule } from './';

// Pages
import { ListsFeesPageComponent } from './pages/fees-list';
import { ListsFeesDetailPageComponent } from './pages/fees-detail';

const routes: Routes = [
    { path: '', component: ListsFeesPageComponent },
    {
        path: ':id',
        component: ListsFeesDetailPageComponent,
        canDeactivate: [CanDeactivateGuard]
    }
];

@NgModule({
    imports: [
        ListsFeesModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class ListsFeesRoutingModule { }