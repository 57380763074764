import { Component, OnInit, ViewChild } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { ModelServiceFactory, IModelService, GridComponent, ModalService } from '../../core';
import { ModelDetailModal } from '../modals/model-detail';

@Component({
	templateUrl: "/template/commissions/modals/model-list.cshtml"
})
export class ModelListModalComponent implements OnInit {
	/**
	 * Reference na grid komponentu
	 */
    @ViewChild(GridComponent, { static: true }) private _grid: GridComponent;

	/**
	 * Instance model service
	 */
	public modelService: IModelService;

	constructor(
		public modal: SuiModal<IModelListModalComponentContext, number, void>,
		private _modelServiceFactory: ModelServiceFactory,
		private _modalService: ModalService) {

		this.modelService = _modelServiceFactory.createService('listDeviceModel');
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	/**
	 * Otevře editační formulář
	 * @param item
	 */
	public edit(item: IDeviceModelModel): void {
		// Otevřeme modal
		this._modalService.openModal(new ModelDetailModal(item.id))
			.onApprove(result => {
				this._grid.loadData();
			});
	}

	/**
	 * Použije údaje ze zvoleného modelu do zakázky.
	 * @param item
	 */
	public use(item: IDeviceModelModel): void {
		this.modal.approve(item.id)
	}
}

export class ModelListModal extends ComponentModalConfig<IModelListModalComponentContext, number, void> {
	constructor(modelId: number | null, modelName: string | null) {
		super(ModelListModalComponent, { modelId, modelName });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Normal;
		this.mustScroll = true;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface IModelListModalComponentContext {
	modelId: number | null;
	modelName: string | null;
}
