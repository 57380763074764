import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule, CanDeactivateGuard, ModalService } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { PortalCoreModule } from '../core';

// Services
import { CommissionsService } from './services/commissions';

// Modals
import { CommissionAuthorizationModalComponent } from './modals/commission-authorization';
import { CloseApprovedCommissionsModalComponent } from './modals/close-approved-commissions';

// Pages
import { CommissionListPageComponent } from './pages/commission-list';
import { CommissionDetailPageComponent } from './pages/commission-detail';

// Components
import { PortalCommissionAuthorizationsComponent } from './components/portal-commission-authorizations';
import { PortalCommissionPartsComponent } from './components/portal-commission-parts';
import { PortalCommissionOrdersComponent } from './components/portal-commission-orders';

const routes: Routes = [
	{
		path: ':garantId',
		children: [
			{ path: '', component: CommissionListPageComponent },
			{
				path: ':id',
				component: CommissionDetailPageComponent,
				canDeactivate: [CanDeactivateGuard]
			}
		]
	}
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		CoreModule,
		FormsModule,
		SuiModule,
		PortalCoreModule
	],
	exports: [
		RouterModule
	],
	declarations: [
		CommissionListPageComponent,
		CommissionDetailPageComponent,
		CommissionAuthorizationModalComponent,
		PortalCommissionAuthorizationsComponent,
		PortalCommissionPartsComponent,
		PortalCommissionOrdersComponent,
		CloseApprovedCommissionsModalComponent,
	],
	providers: [
		CommissionsService,
		// Musí být v každém modulu, který ji využívá
		// LazyLoaded entry components
		ModalService
	],
	entryComponents: [
		CommissionAuthorizationModalComponent,
		CloseApprovedCommissionsModalComponent,
	]
})
export class PortalCommissionsModule { }