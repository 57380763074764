import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModelService,UIService, GridComponent, ApiService} from '../../core';
import { SyncService } from '../services/sync';
import { Injectable } from '@angular/core';

@Component({
	templateUrl: "/template/sync/pages/dashboard.cshtml"
})

export class SyncDashboardPageComponent extends ModelService implements OnInit  {
    dashboardForm: FormGroup;

    constructor(
        public apiService: ApiService,
        protected uiService: UIService,
        public syncService: SyncService) {
        // Voláme konstruktor bázové třídy
        super('sync', apiService, uiService);
    }

    ngOnInit() {

        // Inicializace formuláře
        this.dashboardForm = new FormGroup({


        });
    }


    public runExport() {

        this.apiService.get<any>(super.createUrl('sync-export-run'))
            .then(result => {
                if (result != null) {
                    this.uiService.showSuccess('', result.message);

                }
            });
    }

    public syncItem(item: any) {

        var id = item.id;

        this.apiService.get<any>(super.createUrl('sync-export-repeat/' + id))
            .then(result => {
                if (result != null) {
                    this.uiService.showSuccess('', result.message);

                }
            });
    }

}
