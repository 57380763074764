import { Component, OnInit } from '@angular/core';
import { CommissionViewModel } from '../services/commission-view-model';
import { FormControlsState } from '../services/form-controls-state';
import { SectionComponentBase } from '../classes/section-component-base';
import { FormGroup } from '@angular/forms';
import { PriceProposalModal } from '../modals/price-proposal';
import { ValuesService, CommissionPriceProposalState, ModalService, PrintingService, DataStorageService } from '../../core';

/**
 * Sekce Cenový návrh
 */

@Component({
	selector: 'div.kt-price-proposal-section',
	templateUrl: "/template/commissions/components/price-proposal-section.cshtml"
})
export class PriceProposalSectionComponent extends SectionComponentBase implements OnInit {

	private _priceProposalStates: { [value: string]: string };

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._viewModel.form;
    };

    // Controls state
    public get controlsState(): FormControlsState {
        return this._viewModel.controlsState;
    };

	constructor(
		private _modalService: ModalService,
		private _viewModel: CommissionViewModel,
		private _valuesService: ValuesService,
		private _printingService: PrintingService,
		protected _dataStorageService: DataStorageService) {

		super(_dataStorageService, 'priceProposal');
	}

	/**
	 * Inicializace komponenty 
	 */
	ngOnInit() {
		this._valuesService
			.getDictionary('EnumType?type=Kentaur.Domain.Commissions.CommissionPriceProposalState')
			.then(x => this._priceProposalStates = x);
	}

	/**
	 * Zobrazí modal pro přidání cenového návrhu
	 */
	public add(): void {
		let proposal = this._viewModel.createPriceProposal();

        this._modalService.openModal(new PriceProposalModal(
            proposal,
            this._viewModel.model.commissionTypeId,
            this.controlsState.buttonIsDisabled('priceProposalModal.ok')
        ))
			.onApprove(result => {
				this._viewModel.model.priceProposals.push(<ICommissionPriceProposal>result);
			});
	}

	/**
	 * Zobrazí modal pro editaci
	 * @param proposal
	 */
	public edit(proposal: FormGroup): void {
        let proposalObject = proposal.getRawValue();

        this._modalService.openModal(new PriceProposalModal(
            proposalObject,
            this._viewModel.model.commissionTypeId,
            this.controlsState.buttonIsDisabled('priceProposalModal.ok')
        ))
			.onApprove(result => {
				proposal.setValue(result);
			});
	}

	/**
	 * Vrací název pro daný stav návrhu
	 * @param state
	 */
	public getCommissionPriceProposalStateName(state: CommissionPriceProposalState): string {
		if (!this._priceProposalStates) {
			return '';
		}

		return this._priceProposalStates[state.toString()];
	}

	/**
	 * Vytiskne daný cenový návrh
	 * @param proposal
	 */
	public printPriceProposal(proposal: FormGroup): void {
		this._printingService.print('price-proposal', { 'pid': proposal.controls.id.value });
	}
}