import { Component } from '@angular/core';
import { CommissionViewModel } from '../services/commission-view-model';
import { SectionComponentBase } from '../classes/section-component-base';
import { FormGroup } from '@angular/forms';
import { DataStorageService } from '../../core';

/**
 * Sekce Servis u zákazníka
 */

@Component({
	selector: 'div.kt-inhome-section',
	templateUrl: "/template/commissions/components/inhome-section.cshtml"
})
export class InhomeSectionComponent extends SectionComponentBase {
	// Jestli je sekce otevřená - překrývá bázovou funkčnost
	public get opened(): boolean {
		return this._viewModel.model.inHomeService.enabled;
	}
	public set opened(value: boolean) {
		this._viewModel.model.inHomeService.enabled = value;
	}

	// Formulář se zakázkou
	public get form(): FormGroup {
		return this._viewModel.form;
	};

	constructor(
		private _viewModel: CommissionViewModel,
		protected _dataStorageService: DataStorageService) {

		super(_dataStorageService, 'inhome');
	}
}