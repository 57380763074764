import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer, NgForm, NgModelGroup } from '@angular/forms';

/**
 * Funkcionalita dílů v detailu zakázky
 */

@Component({
	selector: 'div.kt-portal-commission-parts',
	templateUrl: "/template/portal/commissions/components/portal-commission-parts.cshtml",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PortalCommissionPartsComponent),
			multi: true
		}
	],
	viewProviders: [
		{
			provide: ControlContainer,
			useExisting: NgModelGroup
		}
	]
})
export class PortalCommissionPartsComponent implements ControlValueAccessor, OnInit {

	public disabled: boolean = false;

	// Model a getter/setter
	private _parts: Array<any> = [];

	public get parts(): Array<any> {
		return this._parts;
	}
	public set parts(value: Array<any>) {
		this._parts = value;
	}

	// Drží 'index' naposledy přidaného dílu
	private _partsIdx: number;

	constructor() {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	/**
	 * Přidá nový řádek pro náhradní díl do seznamu
	 */
	addPart() {
		this._parts.push({
			id: 0,
			partNumber: '',
			newSerialNumber: '',
			originalSerialNumber: '',
			quantity: 1,
			price: 0,
			$i: this._partsIdx++
		});

		this._propagateChange(this._parts);
	}

	/**
	 * Odebere náhradní díl který je v seznamu na specifikovaném indexu 
	 * @param index
	 */
	removePart(index: number) {
		this._parts.splice(index, 1);

		this._propagateChange(this._parts);
	}

	/**
	 * Vrátí unikátní identitu pro daný díl. Nutné rpo správnou funkci ngFor.
	 * @param item
	 * @param index
	 */
	partIdentity(item: any, index: number): any {
		return item.$i;
	}

	//
	// Implementace ControlValueAccessor
	//

	private _propagateChange = (_: any) => { };
	private _propagateTouched = () => { };

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
	writeValue(value: any): void {
		if (value) {
			// musíme na každý díl nastavit interní property, která drží unikátní hodnotu
			// a uložíme si hodnotu
			this._parts = value.map(function (x, i) { x.$i = i; return x; })
			this._partsIdx = this._parts.length;
		}
	}

    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}