import { Component, OnInit } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { FileManagerService } from '../services/file-manager';
import { ApiService } from '../services/api';
import { UIService } from '../services/ui';
import { FileInfo } from '../models';
import { FileUploader, FileLikeObject, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { AppSettings } from '../services/app-settings';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	templateUrl: "/template/core/modals/file-manager.cshtml"
})
export class FileManagerModalComponent implements OnInit {
	private _root: string = null;

	// Instance FileUploaderu
	public uploader: FileUploader;

	// Seznam souborů v adresáři
	public files: Array<FileInfo> = [];

	// Drží aktuálně zvolený file
	public selectedFile: FileInfo = null;

	constructor(
		public modal: SuiModal<IFileManagerModalContext, void>,
		private _apiService: ApiService,
		private _uiService: UIService,
		private _domSanitizer: DomSanitizer,
		private _appSettings: AppSettings) {

		this._root = modal.context.rootPath;

		// inicializace uploaderu
		this.uploader = new FileUploader({
			url: _apiService.createUrl('files/addfiles'),
			autoUpload: true,
			authToken: 'Bearer ' + _apiService.token,
			maxFileSize: _appSettings.maxFileSize
		});

		this.uploader.onCompleteItem = this._onCompleteItem.bind(this);
		this.uploader.onWhenAddingFileFailed = this._onWhenAddingFileFailed.bind(this);
		this.uploader.onBuildItemForm = this._onBuildItemForm.bind(this);
	}

	/**
	 * Inicializace formuláře
	 */
	ngOnInit() {
		this._loadFiles();
	}

	/**
	 * Smaže zadaný soubor.
	 * @param file
	 */
	delete(file: FileInfo) {
		this._apiService.post('files/deletefile', JSON.stringify(file.filePath))
			.then(x => {
				this._loadFiles();
			});
	}

	/**
	 * Vytvoří definici stylu pro background image.
	 * @param url
	 */
	createBackgroundUrl(url: string) {
		return 'url("' + url.replace(' ', '%20') + '")';
	}

	/**
	 * Vrací info jestli je soubor vybraný.
	 * @param file
	 */
	isSelected(file: FileInfo): boolean {
		return this.selectedFile != null && this.selectedFile.filePath == file.filePath;
	}

	/**
	 * Zruší nebo nastaví soubor jako vybraný.
	 * @param file
	 */
	toggleSelected(file: FileInfo) {
		if (this.isSelected(file)) {
			this.selectedFile = null;
		}
		else {
			this.selectedFile = file;
		}
	}

	/**
	 * vrátí cestu k aktuálnímu adresáři
	 */
	private _getCurrentPath() {
		// TODO
		return this._root;
	}

	/**
	 * Načte seznam souborů pro aktuální adresář
	 */
	private _loadFiles() {
		this._apiService.get<Array<FileInfo>>(`files/getfiles?path=${this._getCurrentPath()}`)
			.then((files: Array<FileInfo>) => {
				this.files = files;
			});
	}

	/**
	 * Obsluhuje událost chyby při přidání souboru do fronty
	 * @param item
	 * @param filter
	 * @param options
	 */
	private _onWhenAddingFileFailed(item: FileLikeObject, filter: any, options: any): any {
		if (filter.name == 'fileSize') {
			this._uiService.showError(
				'Maximální povolená velikost souboru je ' + (this._appSettings.maxFileSize / 1000000) + 'MB',
				'Soubor nelze nahrát');
		}

		return { item, filter, options };
	}

	/**
	 * Obsluhuje událost nahrání souboru na API
	 * @param item
	 * @param response
	 * @param status
	 * @param headers
	 */
	private _onCompleteItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {

		if (item.isSuccess) {
			// odebereme nahraný file z fronty ...
			this.uploader.removeFromQueue(item);

			// přenačteme soubory
			this._loadFiles();
		}

		return { item, response, status, headers };
	}

	/**
	 * Obsluhuje událost vytvoření file položky ve formuláři pro odeslání.
	 * @param item
	 * @param form
	 */
	private _onBuildItemForm(item: FileItem, form: any) {
		// Přidáváme do formuláře cestu k adresáři, kam uložit
		form.append('path', this._getCurrentPath());
	}
}

export class FileManagerModal extends ComponentModalConfig<IFileManagerModalContext, void, void> {
	constructor(rootPath: string) {
		super(FileManagerModalComponent, { rootPath });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Large;
	}
}

/**
 * Rozhraní pro kontext file manager modalu
 */
interface IFileManagerModalContext {
	/**
	 * Cesta k rootovému adrsáři který se má zobrazit.
	 */
	rootPath: string;
}