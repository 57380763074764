import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
import { NotificationsService } from '../services/notifications'
import { startWith } from 'rxjs/operators';

/**
 * Funkcionalita podmínek v detailu notifikací
 */

@Component({
    selector: 'div.kt-notification-conditions',
    templateUrl: "/template/notifications/components/notification-conditions.cshtml",
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NotificationConditionsComponent),
			multi: true
		}
	],
	viewProviders: [
		{
			provide: ControlContainer,
			useExisting: NgForm
		}
	]
})
export class NotificationConditionsComponent implements ControlValueAccessor, OnInit {
	public disabled: boolean = false;
    public conditionTypes: any;

	// Model a getter/setter
	private _conditions: Array<INotificationConditionLine> = [];


    public get conditions(): Array<INotificationConditionLine> {
        return this._conditions;
	}
    public set conditions(value: Array<INotificationConditionLine>) {
        this._conditions = value;
	}
	// Drží 'index' naposledy přidaného dílu
    private _conditionsIdx: number;

    constructor(public notificationService: NotificationsService) {
	}

	/**
	 * Inicializace komponenty
	 */
    ngOnInit() {
        this.getConditionTypes();
    }

	/**
	 * Přidá nový řádek pro podmínku notifikace do seznamu
	 */
	addCondition() {
        this._conditions.push({
            id: 0,
            type: 0,
            compare: 0,
            value: "",
            valueMax: "",
			valueId: 0,
            isNegation: false,
            $i: this._conditionsIdx++
	    });

        this._propagateChange(this._conditions);
	}

	/**
	 * Odebere podmínku notifikace, která je v seznamu na specifikovaném indexu
	 * @param index
	 */
	removeCondition(index: number) {
        this._conditions.splice(index, 1);

        this._propagateChange(this._conditions);
	}

	/**
	 * Vrátí unikátní identitu pro podmínku notifikace. Nutné pro správnou funkci ngFor.
	 * @param item
	 * @param index
	 */
	conditionIdentity(item: INotificationConditionLine, index: number): any {
		return item.$i;
	}

	//
	// Implementace ControlValueAccessor
	//

	private _propagateChange = (_: any) => { };
	private _propagateTouched = () => { };

	/**
	 * Funkce je volána když se má nastavit hodnota do kontrolu
	 */
	writeValue(value: any): void {
		if (value) {
			// musíme na každý díl nastavit interní property, která drží unikátní hodnotu
			// a uložíme si hodnotu
            this._conditions = value.map((x, i) => {
		        x.$i = i;
		        return x;
		    });
            this._conditionsIdx = this._conditions.length;
		}
	}
         
    /**
     * Nastaví funkci která má být volána při změně
     * @param fn
     */
	registerOnChange(fn: any): void {
		this._propagateChange = fn;
	}

	/**
	 * Nastaví funkci, která má být volána onTouch
	 * @param fn
	 */
	registerOnTouched(fn: any): void {
		this._propagateTouched = fn;
	}

	/**
	 * Funkce je volána pokud se stav controlu změní z/na 'DISABLED'
	 * @param isDisabled
	 */
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
    }

    getConditionTypes() {
        this.notificationService.getConditionTypes().then((result) => {
            this.conditionTypes = result;
        });
    }

    checkConditions(conditionTypeId: number, type: string): boolean {
        return this.conditionTypes[conditionTypeId].indexOf(type) > -1;
    }

    public onConditionTypeSelected(type: number, compare: number, index: number) {
        const text = [0, 1, 2];
        const date = [1, 2, 3, 4];
        const number = [1, 2, 3, 4];
        const bool = [2];
        let selectedType = [];
        const id = index;

        switch (this.conditionTypes[type][0]) {
            case "text":
                selectedType = text;
                break;
            case "date":
                selectedType = date;
                break;
            case "number":
                selectedType = number;
                break;
            case "bool":
                selectedType = bool;
                break;
        }

        if (selectedType.indexOf(compare) == -1) {
            this._conditions[id].compare = selectedType[0];
        }

		if (this.isValueFromSelect(type, index)) {
			this._conditions[id].value = null;
			this._conditions[id].valueId = null;
		}
    }

	public onValueFromSelectChanged(value: number, index: number) {
		this._conditions[index].value = value.toString();
	}

	public isValueFromSelect(type: number, index: number): boolean {
		var item = this._conditions[index];

		if (item.compare != 1) {
			return false;
		}

		if (this.conditionTypes[type].indexOf("id") === -1) {
			return false;
		}

		return true;
	}
}

export interface INotificationCondition {
	id: number,
	type: number,
	compare: number,
	value: string,
	valueMax: string,
	valueId: number,
	isNegation: boolean
	
}

export interface INotificationConditionLine extends INotificationCondition {
	$i: number
}