import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../core';
import { FormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';

// Components
import { PortalCommentsComponent } from './components/portal-comments';

// Exports
export { PortalFormComponentBase } from './classes/portal-form-component-base';

@NgModule({
	imports: [
		CommonModule,
		CoreModule,
		FormsModule,
		SuiModule
	],
	exports: [
		PortalCommentsComponent
	],
	declarations: [
		PortalCommentsComponent
	],
	providers: [
	],
	entryComponents: [
	]
})
export class PortalCoreModule { }