import { Injectable } from '@angular/core';
import { ModelService, ApiService, UIService } from '../../core';

/**
 * Poskytuje operace nad uživateli.
 */
@Injectable()
export class SyncService extends ModelService {

	constructor(
        apiService: ApiService,
		uiService: UIService) {
        // Voláme konstruktor bázové třídy
        super('sync', apiService, uiService);
	}


}