import { Component, OnInit, ViewChild } from '@angular/core';
import { SuiModal, ComponentModalConfig, ModalSize } from '@giomamaladze/ng2-semantic-ui';
import { NgForm } from '@angular/forms';
import { CommissionsService } from '../services/commissions';
import { CloseApprovedCommissionsModel } from '../models';
import { UIService } from '../../../core';

@Component({
	templateUrl: "/template/portal/commissions/modals/close-approved-commissions.cshtml"
})
export class CloseApprovedCommissionsModalComponent implements OnInit {
	public model: CloseApprovedCommissionsModel = new CloseApprovedCommissionsModel();

	// Získáváme referenci na NgForm ve view komponenty
    @ViewChild('ngForm', { static: true }) private _form: NgForm;

	constructor(
		public modal: SuiModal<ICloseApprovedCommissionsModalContext, boolean>,
		private _commissionsService: CommissionsService,
		private _uiService: UIService) {
	}

	/**
	 * Inicializace komponenty
	 */
	ngOnInit() {
	}

	// Vrací true/false jestli lze formulář odeslat
	public allowSubmit(): boolean {
		return this._form.valid;
	};

	/**
	 * Zavře modal => cancel
	 */
	public back() {
		this.modal.deny(null);
	}

	/**
	 * Provede uzavření zakázek
	 */
	public process() {
		if (!this._form.valid) {
			return;
		}

		// Zobrazit potvrzení
		this._uiService.showConfirmation('Opravdu uzavřít?', 'Veškeré zakázky se stavem Schváleno budou převedeny do stavu Uzavřeno. Pokud máte správně zvolen Fiskální měsíc uzávěrky, klikněte na Ano a provede se uzávěrka.')
			.then(x => {
				if (x) {
					// Můžeme uzavřít
					this._uiService.showLoader();

					this._commissionsService.closeApprovedCommissions(this.model.fiscalMonthDate)
						.then(x => {
							this._uiService.hideLoader();

							this.modal.approve(x);
						});
				}
			});
	}
}

export class CloseApprovedCommissionsModal extends ComponentModalConfig<ICloseApprovedCommissionsModalContext, void, void> {
	constructor() {
		super(CloseApprovedCommissionsModalComponent, { });

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = ModalSize.Small;
	}
}

/**
 * Rozhraní pro kontext modalu
 */
interface ICloseApprovedCommissionsModalContext {
}