import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIService, ModelFormComponentBase, HelperService, AppScopeService, AuthService, ModelServiceFactory, ValuesService } from '../../../core';

@Component({
    templateUrl: "/template/lists/commission-kinds/pages/commission-kind-detail.cshtml"
})
export class ListsCommissionKindDetailPageComponent extends ModelFormComponentBase {

	public allChecked: any = { 16: false, 1: false, 2: false, 4: false, 8: false };

    constructor(
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
        protected _appScopeService: AppScopeService,
        private _modelServiceFactory: ModelServiceFactory,
        private _valuesService: ValuesService,
		protected _router: Router) {
		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_modelServiceFactory.createService('listCommissionKind'),
			_router);
	}

	/**
	 * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
	 */
	public getTitle(): string {
        return this.modelId === 0 ? 'Nový druh zakázky' : 'Editace druhu zakázky';
    }

	/**
	 * Chekne/odčekne všechny pro danou fázi
	 * @param phase
	 */
	public toggleAll(phase: number): void {

		let checked = !this.allChecked[phase];
		this.allChecked[phase] = checked;

		(<ICommissionKindModel>this.model).propertyNodes.forEach(x => {
			this._setChecked(x, phase, checked);
		});
	}

    /**
     * Override metody v ModelFormComponentBase - po vložení redirektujeme na editaci
     * @param model
     */
    protected onModelSaved(model: any): void {
        this._valuesService.clearCacheItem("CommissionKind");
        if (this.modelId === 0) {
            this._router.navigate(['app', 'list', 'commission-kinds', model.id], {});
        }
	}

	private _setChecked(node: ICommissionKindPropertyNodeModel, phase: number, checked: boolean): void {
		if (node.isAllwaysRequired) {
			return;
		}

		node.requiredByPhase[phase] = checked;

		node.children.forEach(x => {
			this._setChecked(x, phase, checked);
		});
	}
}