import { Injectable } from '@angular/core';
import { ApiService } from '../../../core';
import { SumUpSettings, PaymentSessionKeyModel } from '../models';

/**
 * Poskytuje operace pro SumUp
 */
@Injectable()
export class SumUpService {

    constructor(
        private _apiService: ApiService) {
    }

	/**
	 * Vytvoří url pro inicializaci platby
	 * @param commission
	 * @param payment
	 */
    public getPaymentUrl(commission: ICommission, payment: ICommissionPayment): Promise<string> {
        return Promise
            .all([
                this.getSettings(),
                this._apiService.post<PaymentSessionKeyModel>(`sumup/session/${commission.id}/${payment.id}`, {})
            ])
            .then(x => {
                let settings = <SumUpSettings>x[0];
                let sessionKey = <string>x[1].key;

                let retval = 'sumupmerchant://pay/1.0';
                retval += '?affiliate-key=' + settings.affiliateKey;
                retval += '&app-id=' + settings.appId;
                retval += '&total=' + payment.requestedAmount.toString();
                retval += '&currency=CZK';
                retval += '&title=' + encodeURIComponent('Zakázka ' + commission.number);
                retval += '&receipt-mobilephone=' + encodeURIComponent(commission.payer.phone1);
                retval += '&receipt-email=' + encodeURIComponent(commission.payer.email);

                let currentUrl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port;
                retval += '&callback=' + encodeURIComponent(currentUrl + '/sumup/callback/' + sessionKey);

                return retval;
            });
    }

	/**
	 * Načte settingy 
	 **/
    public getSettings(): Promise<SumUpSettings> {
        return this._apiService.get<SumUpSettings>('sumup/settings');
    }

	/**
	 * Vrací info jestli lze modul použít 
	 **/
    public isReady(): Promise<boolean> {
        return this.getSettings()
            .then(x => {
                return (x.affiliateKey || '').length > 0 && (x.appId || '').length > 0;
            });
    }

	/**
	 * Uloží settingy
	 * @param settings
	 */
    public setSettings(settings: SumUpSettings): Promise<void> {
        return this._apiService.post('sumup/settings', settings);
    }
}