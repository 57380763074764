import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../services/users';
import { UIService, ModelFormComponentBase, HelperService, AppScopeService, AuthService } from '../../../core';

@Component({
	templateUrl: "/template/settings/user/pages/user-detail.cshtml"
})
export class UserDetailPageComponent extends ModelFormComponentBase {

	public stampFilePath: string;

	constructor(
		protected _activatedRoute: ActivatedRoute,
		protected _uiService: UIService,
		protected _helperService: HelperService,
		protected _authService: AuthService,
		protected _appScopeService: AppScopeService,
		protected _usersService: UsersService,
		protected _router: Router) {

		super(
			_activatedRoute,
			_uiService,
			_helperService,
			_authService,
			_appScopeService,
			_usersService,
			_router);
	}

	/**
	 * Vrací nadpis pro stránku v závislosti na tom, zda jde o editaci nebo přidání
	 */
	getTitle() {
		return this.modelId == 0 ? 'Nový uživatel' : 'Editace uživatele';
	}

	/**
	 * Zruší vybrané razítko 
	 */
	public clearStamp() {
		this.stampFilePath = '';
		this.model.stampFilePath = '';
		this.model.stampFileContent = '';
	}

	/**
	 * Mame prava agenta?
	 */
	public isAgent(): boolean {
		return this._authService.currentUser.portalSpecialPermission.isAgent;
	}

	/**
	 * Vrací boolean jestli je nastaven obrázek razítka.
	 */
	public hasStamp(): boolean {
		return this.stampFilePath &&
			this.stampFilePath.length > 0;
	}

	/**
	 * Zpracovává událost výběru razítka
	 * @param event
	 */
	public stampChanged(event) {
		var file: File = event.target.files[0];

		if (file.size > 200000) {
			event.target.value = '';
			this._uiService.showError('Zvolte obrázek o velikosti menší než 200kB.', 'Příliš velký obrázek');
			return;
		}

		var reader: FileReader = new FileReader();

		reader.onloadend = (e) => {
			this.stampFilePath = <string>reader.result;
			this.model.stampFilePath = '';
			this.model.stampFileContent = (<string>reader.result).split(',')[1];

			event.target.value = '';
		}

		reader.readAsDataURL(file);
	}

	/**
	 * Vrací cestu k obrázku razítka.
	 */
	public stampSrc() {
		return this.stampFilePath;
	}

	/**
	 * Po inicializaci formuláře.
	 */
	protected onFormInitialized() {
		// Přihlásíme se k odběru události změny hodnoty isDefaultAgent
        var control = super.getControl('isDefaultAgent');

        super.registerSubscription(
            control.valueChanges.subscribe(value => {
                if (value) {
                    // Načteme aktuálního defaultního agenta
                    this._usersService.getDefaultPortalAgent(1)
                        .then(currentDefault => {
                            if (currentDefault != null) {
                                // Zobrazím potvrzovací dialog
                                this._uiService.showConfirmation(
                                    'Změnit výchozího agenta?',
                                    `Opravdu si přejete změnit výchozího agenta z ${currentDefault.fullName} na ${this.model.fullName}`)
                                    .then(result => {
                                        // Pokud 'ne', pak nastavím zpět na false
                                        if (!result) {
                                            control.setValue(false);
                                        }
                                    });
                            }
                        });
                }
            })
        );
	}

	/**
	 * Po načtení modelu
	 **/
	protected onModelLoaded(model: any) {
		this.stampFilePath = model.stampFilePath;
	}
}